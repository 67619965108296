import 'react-toastify/dist/ReactToastify.css';
import * as React from 'react';
import { Slide, toast as rtToast, ToastContainer as WrappedToastContainer } from 'react-toastify';

export function ToastContainer(): JSX.Element {
  return (
    <WrappedToastContainer
      position="top-right"
      transition={Slide}
      autoClose={3000}
      hideProgressBar
      pauseOnFocusLoss
      draggable
      pauseOnHover
      newestOnTop
    />
  );
}

export const toast = {
  ...rtToast,
  error: (...params: Parameters<typeof rtToast>): ReturnType<typeof rtToast> => {
    return rtToast.error(params[0], { autoClose: 5000, ...params[1] });
  },
};
