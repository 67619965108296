import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Await,
  defer,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { DeepWritable, Writable } from 'ts-essentials';
import { isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { TextCardWithButton } from '../../../../components/TextCardWithButton';
import { toast } from '../../../../lib/toast';
import { IAMRole } from '../../../../lib/types';
import { prettyJSON } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { RoleMembersTable } from '../lib/MembersTable';
import { RoleFormBody } from '../lib/RoleFormBody';

export interface AccessPolicyError {
  attribute: string;
  type: 'info' | 'warning' | 'error';
  message: string;
}

// This function returns a value from a JSON object given a string attribute.
// For example, if json is '{ "names": ["A", "B"] }' and attribute is 'name[0]',
// the function will return "A"
export function getJSONValueFromAttribute(json: any, attribute: string): string | undefined {
  // split on period, open bracket, close bracket
  const attributes = attribute.split(/[.[\]]/).filter((attribute) => attribute !== '');

  let currentJSON = json;
  for (let i = 0; i < attributes.length; i++) {
    if (currentJSON[attributes[i]]) {
      currentJSON = currentJSON[attributes[i]];
    } else {
      return undefined;
    }
  }

  return currentJSON;
}

type UserListData = Writable<Awaited<ReturnType<typeof zapehr.project.user.listV2>>['data']>;
type DeveloperListData = Writable<Awaited<ReturnType<typeof zapehr.project.developer.listV2>>['data']>;
type M2MListData = Writable<Awaited<ReturnType<typeof zapehr.project.m2m.listV2>>['data']>;

type RoleDetailLoaderData = {
  role: IAMRole;
  users: DeepWritable<Awaited<ReturnType<typeof zapehr.project.user.listV2>>>;
  developers: DeepWritable<Awaited<ReturnType<typeof zapehr.project.developer.listV2>>>;
  m2ms: DeepWritable<Awaited<ReturnType<typeof zapehr.project.m2m.listV2>>>;
};

export function RoleDetailLoader(args: LoaderFunctionArgs): ReturnType<typeof defer> {
  const { params } = args;
  const { roleId } = params;
  if (!roleId) {
    throw new Error('No role ID in request url');
  }
  return defer({
    role: zapehr.project.role.get({ roleId }),
    users: zapehr.project.user.listV2({ roleId }),
    developers: zapehr.project.developer.listV2({ roleId }),
    m2ms: zapehr.project.m2m.listV2({ roleId }),
  });
}

export function RoleDetailPage(): JSX.Element {
  const data = useLoaderData() as RoleDetailLoaderData;
  const pathParams = useParams();
  const { roleId } = pathParams;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.primary">
          {`Role - ${roleId}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
          <Await
            resolve={Promise.all([data.role, data.users, data.developers, data.m2ms])}
            errorElement={<RootErrorBoundary />}
          >
            {([role, users, developers, m2ms]: [
              role: RoleDetailLoaderData['role'],
              users: RoleDetailLoaderData['users'],
              developers: RoleDetailLoaderData['developers'],
              m2ms: RoleDetailLoaderData['m2ms']
            ]) => {
              return <LoadedComponent role={role} users={users.data} developers={developers.data} m2ms={m2ms.data} />;
            }}
          </Await>
        </Suspense>
      </Grid>
    </Grid>
  );
}

const initialFormStateFromRole = (role: IAMRole): { defaultValues: any } => {
  return {
    defaultValues: {
      name: role.name,
      description: role.description,
      accessPolicy: role.accessPolicy ? prettyJSON(role.accessPolicy) : null,
    },
  };
};

function LoadedComponent({
  role,
  users,
  developers,
  m2ms,
}: {
  role: IAMRole;
  users: UserListData;
  developers: DeveloperListData;
  m2ms: M2MListData;
}): JSX.Element {
  const formReturn = useForm(initialFormStateFromRole(role));
  const { handleSubmit } = formReturn;
  const [loading, setLoading] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const members = useMemo(() => ({ user: users, developer: developers, m2m: m2ms }), [users, developers, m2ms]);

  const totalMembers = useMemo(() => {
    return Object.values(members).reduce((totalMems, currentList) => {
      return totalMems + currentList.length;
    }, 0);
  }, [members]);

  const onSubmit = useCallback(
    (data: any): void => {
      setLoading(true);

      data.accessPolicy = isValidJSON(data.accessPolicy);

      if (!data.accessPolicy) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        setLoading(false);
        return;
      }

      zapehr.project.role
        .update({ roleId: role.id, ...data })
        .then(() => {
          toast.success('Role successfully updated');
        })
        .catch((error) => {
          toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [role.id]
  );

  const deleteHandler = useCallback(async () => {
    try {
      await zapehr.project.role.delete({ roleId: role.id });
      toast.success('Role deleted');
      revalidator.revalidate();
      navigate(`/${Services.iam.rootPath}/roles`);
    } catch (error) {
      toast.error(`An error occurred: ${(error as any).message || 'Unknown error'}`);
      setDeletePending(false);
    }
  }, [navigate, revalidator, role.id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RoleFormBody
        formUtils={formReturn}
        renderErrors={(errors) =>
          errors.description && (
            <Grid item>
              <Typography variant="body1" color="error" role="alert">
                {errors.description?.message?.toString()}
              </Typography>
            </Grid>
          )
        }
        renderMembersTable={() => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <Typography variant="h5">Assigned users </Typography>
            <RoleMembersTable members={members} />
          </Box>
        )}
        renderUpdateButton={() => (
          <Grid item container direction="column" spacing={2}>
            <Grid item textAlign="center">
              <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        )}
        renderDeleteButton={
          totalMembers > 0
            ? undefined
            : () => (
                <Grid item container direction="column" spacing={2}>
                  <Grid item textAlign="left">
                    <TextCardWithButton
                      title={`Delete Role`}
                      description={`This role has no members and may be deleted.`}
                      variant="danger"
                      buttonTitle={`Delete`}
                      action={() => setDeletePending(true)}
                    />
                  </Grid>
                </Grid>
              )
        }
      />
      <ConfirmationDialog
        handleAction={deleteHandler}
        open={deletePending}
        message={`Are you sure you want to remove ${role.name} from this project?`}
        buttonTitle="Delete"
        handleClose={() => setDeletePending(false)}
      />
    </form>
  );
}
