import { AccountBox, Add, Calculate } from '@mui/icons-material';
import { alpha, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense } from 'react';
import { Await, defer, Link, Outlet, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { AdminSidebarItem } from '../../../components/AdminSidebar';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { PageContainer } from '../../../components/PageContainer';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { Application } from '../../../lib/client';
import { Services } from '../../../services';

// Layout
const sidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Applications',
      icon: <Calculate />,
      path: `/${Services.app.rootPath}`,
    },
    {
      label: 'Users',
      icon: <AccountBox />,
      path: `/${Services.app.rootPath}/users`,
    },
  ],
];

export function AppServiceLayout(): JSX.Element {
  return (
    <PageContainer sidebarItems={sidebarItems}>
      <Outlet />
    </PageContainer>
  );
}

// Loader
export const appPageId = 'app-page';
export function appPageLoader(): ReturnType<typeof defer> {
  return defer({ applications: zapehr.project.application.list() });
}
export type AppPageLoaderData = { applications: ReturnType<typeof zapehr.project.application.list> };

// Components
export function AppPage(): JSX.Element {
  const data = useRouteLoaderData(appPageId) as AppPageLoaderData;

  return (
    <Grid container>
      <Grid item xs={12} display={'flex'} marginBottom={2}>
        <Typography variant="h4" sx={{ margin: 0 }}>
          Applications
        </Typography>
        <ArticleShortcutIconButton link="https://docs.oystehr.com/services/app/applications/" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Applications provide authentication for{' '}
          <MUILink href={'https://docs.oystehr.com/services/app/users/'} target="_blank">
            Users
          </MUILink>{' '}
          in the apps you build on Oystehr.
        </Typography>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to={`/${Services.app.rootPath}/new`}>
          <Button aria-label="new" variant="contained" startIcon={<Add />}>
            Create
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ height: '60vh', marginTop: 4 }}>
          <Suspense fallback={<ApplicationList loading applications={[]} />}>
            <Await resolve={data.applications} errorElement={<RootErrorBoundary />}>
              {(applications) => {
                return <ApplicationList loading={false} applications={applications} />;
              }}
            </Await>
          </Suspense>
        </Paper>
      </Grid>
    </Grid>
  );
}

interface ApplicationListProps {
  applications: Application[];
  loading: boolean;
}
function ApplicationList(props: ApplicationListProps): JSX.Element {
  const { applications, loading } = props;

  return (
    <DataGridPro
      loading={loading}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 0.75,
          renderCell: (params) => <Link to={params.row.id}>{params.row.name}</Link>,
        },
        { field: 'description', headerName: 'Description', flex: 1.25 },
        { field: 'id', headerName: 'ID', flex: 1 },
      ]}
      rows={applications.map((application: Application) => ({
        id: application.id,
        name: application.name,
        description: application.description,
      }))}
      components={{ Toolbar: GridToolbar }}
      // todo remove code duplication with SearchControl
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          pl: 1.5,
          gap: 4,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '& .MuiButtonBase-root': {
            color: otherColors.charcoal87,
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
        },
      }}
    />
  );
}

interface ApplicationListContext {
  applications: Application[];
  loadApplications: () => void;
}
export function useApplicationsFromOutletContext(): ApplicationListContext {
  return useOutletContext<ApplicationListContext>();
}
