import { Typography } from '@mui/material';
import * as React from 'react';

export function PageHeader({ text }: { text: string }): JSX.Element {
  return (
    <Typography variant="h4" mt={0} mb={5} color="text.primary">
      {text}
    </Typography>
  );
}
