import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import {
  DataGridPro,
  GridCallbackDetails,
  GridRowId,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { IAMRoleListItem } from '../../../lib/types';
import { Services } from '../../../services';
import { ProjectSettingsPageLoaderData } from '..';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface DefaultRole {
  id: string;
  name: string;
}
interface RoleTableProps {
  existingDefaultRole: DefaultRole | undefined | null;
  currentSelection: string | null | undefined;
  updateCurrentSelection: (selection: string | null) => void;
}

export const SelectRoleWidget: React.FC<RoleTableProps> = ({
  currentSelection,
  existingDefaultRole,
  updateCurrentSelection,
}) => {
  const data = useLoaderData() as ProjectSettingsPageLoaderData;
  const [showSelectionGrid, setShowSelectionGrid] = useState(existingDefaultRole == null);
  const [initialSelectedGridRow, setInitialSelectedGridRow] = useState<GridRowId[]>([]);

  useEffect(() => {
    if (showSelectionGrid) {
      let initial = currentSelection;

      if (!initial) {
        initial = existingDefaultRole?.id;
      }
      const initialModel = initial ? [initial] : [];
      setInitialSelectedGridRow(initialModel);
    } else {
      setInitialSelectedGridRow([]);
    }
  }, [currentSelection, existingDefaultRole?.id, showSelectionGrid]);

  const selectedRow: GridRowId[] = useMemo(() => {
    if (initialSelectedGridRow.length) {
      return initialSelectedGridRow;
    }
    if (currentSelection === null) {
      return [];
    } else if (currentSelection) {
      return [currentSelection];
    }
    return [];
  }, [currentSelection, initialSelectedGridRow]);

  const updateRowSelectionModel = (rowSelectionModel: GridRowId[], _details: GridCallbackDetails): void => {
    if (rowSelectionModel.length === 0) {
      updateCurrentSelection(null);
    } else {
      const id = rowSelectionModel.pop() as string;
      updateCurrentSelection(id);
    }
  };

  const clearSelection = (): void => {
    updateCurrentSelection(null);
    setShowSelectionGrid(true);
  };

  return (
    <Box sx={{ width: '100%', height: '300px' }}>
      {existingDefaultRole && !showSelectionGrid && (
        <ChosenRoleWidget currentSelectedRole={existingDefaultRole} handleChangeClick={clearSelection} />
      )}
      {showSelectionGrid && (
        <Suspense
          fallback={
            <RoleSelectionGrid
              selectedRow={selectedRow}
              roles={[]}
              loading={true}
              updateRowSelectionModel={updateRowSelectionModel}
            />
          }
        >
          <Await resolve={data.roles} errorElement={<RootErrorBoundary />}>
            {(roles: Awaited<ProjectSettingsPageLoaderData['roles']>) => {
              return (
                <RoleSelectionGrid
                  selectedRow={selectedRow}
                  roles={roles}
                  loading={false}
                  updateRowSelectionModel={updateRowSelectionModel}
                />
              );
            }}
          </Await>
        </Suspense>
      )}
    </Box>
  );
};

interface SelectionGridProps {
  selectedRow: GridRowId[];
  roles: readonly IAMRoleListItem[];
  loading: boolean;
  updateRowSelectionModel: (rowSelectionModel: GridRowId[], details: GridCallbackDetails) => void;
}

const RoleSelectionGrid: React.FC<SelectionGridProps> = ({ selectedRow, updateRowSelectionModel, roles, loading }) => {
  return (
    <DataGridPro
      loading={loading}
      density="compact"
      checkboxSelection
      checkboxSelectionVisibleOnly
      disableMultipleSelection={true}
      selectionModel={selectedRow}
      onSelectionModelChange={updateRowSelectionModel}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          renderCell: (params) => (
            <Link to={`/${Services.iam.rootPath}/roles/${params.row.id}`}>{params.row.name}</Link>
          ),
        },
        { field: 'id', headerName: 'ID', flex: 1 },
      ]}
      rows={roles.map((role) => ({
        id: role.id,
        name: role.name,
      }))}
      components={{ Toolbar: RoleWidgetHeader }}
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          pl: 1.5,
          gap: 4,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '& .MuiButtonBase-root': {
            color: otherColors.charcoal87,
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '& .MuiDataGrid-footerContainer': {
          display: 'none',
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
        },
        '& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root': {
          display: 'none',
        },
      }}
    />
  );
};

const RoleWidgetHeader: React.FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

interface ChosenRoleWidgetProps {
  currentSelectedRole: DefaultRole;
  handleChangeClick: () => void;
}

const ChosenRoleWidget: React.FC<ChosenRoleWidgetProps> = ({ currentSelectedRole, handleChangeClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
      }}
    >
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <Link to={`/${Services.iam.rootPath}/roles/${currentSelectedRole.id}`}>
          <Typography>{currentSelectedRole.name}</Typography>
        </Link>
        <Button variant="outlined" onClick={handleChangeClick}>
          Change
        </Button>
      </Stack>
    </Box>
  );
};
