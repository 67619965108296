import { FormControl, FormHelperText, InputLabel, Link as MUILink, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputField, TriggerMethodType } from '../../../../lib/types';

interface ZambdaSelectTriggerProps {
  field: InputField;
  value: TriggerMethodType;
  setValue: (value: TriggerMethodType) => void;
}

const ZambdaSelectTrigger: FC<ZambdaSelectTriggerProps> = ({ field, value, setValue }) => {
  const { name, disabled, label, required } = field;
  const triggerMethodTypes: TriggerMethodType[] = Object.values(TriggerMethodType);
  const menuItems: TriggerMethodType[] = triggerMethodTypes;
  const { register } = useFormContext();

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...register(name, {
          required: required && `${label} is a required field`,
        })}
        labelId={`${name}-label`}
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value as TriggerMethodType)}
        inputProps={{ 'aria-label': label }}
        sx={{ backgroundColor: 'white' }}
      >
        {menuItems.map((menuItem: TriggerMethodType) => (
          <MenuItem key={menuItem} value={menuItem}>
            {menuItem}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        Learn more about trigger methods in the{' '}
        <MUILink target="_blank" href="https://docs.oystehr.com/services/zambda/">
          documentation
        </MUILink>
        .
      </FormHelperText>
    </FormControl>
  );
};

export default ZambdaSelectTrigger;
