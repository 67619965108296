import * as React from 'react';
import { formatHumanName, HumanNameFormatOptions } from '../helpers';
import { HumanName } from '../lib/fhir-types';

export interface HumanNameDisplayProps {
  value?: HumanName;
  options?: HumanNameFormatOptions;
}

export function HumanNameDisplay(props: HumanNameDisplayProps): JSX.Element | null {
  const name = props.value;
  if (!name) {
    return null;
  }

  return <>{formatHumanName(name, props.options)}</>;
}
