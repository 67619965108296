import { Breadcrumbs, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PathHistoryBreadcrumb } from '../lib/types';

interface BreadcrumbsBasicProps {
  prevPaths: PathHistoryBreadcrumb[];
  currPathText: string;
}

const BreadcrumbsBasic: FC<BreadcrumbsBasicProps> = ({ prevPaths, currPathText }) => {
  const theme = useTheme();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {prevPaths.map((prevPath: PathHistoryBreadcrumb) => (
        <Link key={prevPath.from} to={prevPath.from} style={{ color: theme.palette.text.secondary }}>
          {prevPath.text}
        </Link>
      ))}
      <Typography color={theme.palette.text.primary}>{currPathText}</Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbsBasic;
