import { Grid, Paper, TextField } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AccessPolicyInput } from '../../../../components/AccessPolicyInput';
import { RolesEditComponent } from '../../../../components/RolesEditComponent/RolesEditComponent';
import { IAMRole } from '../../../../lib/types';
import { prettyJSON } from '../../../../lib/utils';

export interface DeveloperFields {
  email: string;
  practitioner: string;
  accessPolicy: string;
  roles: string;
}

export type DeveloperFieldName = 'email' | 'practitioner' | 'accessPolicy' | 'roles';

export const developerDefaults = {
  defaultValues: {
    email: '',
    practitioner: prettyJSON({
      resourceType: 'Practitioner',
      name: [{ family: 'LAST_NAME', given: ['FIRST_NAME'] }],
    }),
    accessPolicy: prettyJSON({
      rule: [
        {
          resource: ['*'],
          action: ['*'],
          effect: 'Allow',
        },
      ],
    }),
    roles: '',
  },
};

interface FormProps {
  formUtils: UseFormReturn<DeveloperFields>;
  hiddenFields?: DeveloperFieldName[];
  renderErrors?: (errors: any) => ReactElement;
  renderPreFormBody?: () => ReactElement;
  renderActionButton?: () => ReactElement;
  renderDeleteButton?: () => ReactElement;
  assignedRoles?: IAMRole[];
}

export const DeveloperFormBody: React.FC<FormProps> = ({
  formUtils,
  hiddenFields = [],
  renderErrors,
  renderPreFormBody,
  renderActionButton,
  renderDeleteButton,
  assignedRoles = [],
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = formUtils;

  const accessPolicy: string | undefined = useWatch({
    name: 'accessPolicy',
    control,
    defaultValue:
      formUtils.getValues().accessPolicy ??
      prettyJSON({
        rule: [
          {
            resource: ['*'],
            action: ['*'],
            effect: 'Allow',
          },
        ],
      }),
  });
  console.log('access policy in form body', accessPolicy);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} mt={4}>
        <Paper>
          <Grid container direction="column" spacing={4} padding={2}>
            {renderErrors && renderErrors(errors)}
            {renderPreFormBody && renderPreFormBody()}
            <Grid item xs={12} sx={{ display: hiddenFields.includes('email') ? 'none' : 'inherit' }}>
              <TextField
                {...register('email', {
                  required: !hiddenFields.includes('email') && `Email is a required field`,
                })}
                fullWidth
                label="Email"
                error={!!errors.email}
                helperText={(errors.email?.message as string) || 'Email of the developer'}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: hiddenFields.includes('practitioner') ? 'none' : 'inherit' }}>
              <TextField
                {...register('practitioner', {
                  required: !hiddenFields.includes('practitioner') && `Practitioner is a required field`,
                })}
                fullWidth
                multiline
                label="Practitioner"
                error={!!errors.practitioner}
                helperText={
                  (errors.practitioner?.message as string) ||
                  'Practitioner resource to be associated with the developer'
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: hiddenFields.includes('accessPolicy') ? 'none' : 'inherit' }}>
              <AccessPolicyInput
                config={{ label: 'Access Policy', helperText: 'Access policy to assign this developer' }}
                accessPolicy={accessPolicy}
                setAccessPolicy={(newVal: string | undefined) => setValue('accessPolicy', newVal ?? '')}
                accessPolicyErrors={[]}
              />
            </Grid>
            {hiddenFields.includes('roles') ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <RolesEditComponent
                  assignedRolesList={assignedRoles}
                  setRolesValue={(value) => formUtils.setValue('roles', value)}
                />
              </Grid>
            )}
            <Grid item xs={12} textAlign="center">
              {renderActionButton && renderActionButton()}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} textAlign="center">
        {renderDeleteButton && renderDeleteButton()}
      </Grid>
    </Grid>
  );
};
