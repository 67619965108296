import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AccessPolicyInput } from '../../../../components/AccessPolicyInput';
import { RolesEditComponent } from '../../../../components/RolesEditComponent/RolesEditComponent';
import { Application } from '../../../../lib/client';
import { prettyJSON } from '../../../../lib/utils';
import { AppPageLoaderData } from '../../Applications';

export interface UserFields {
  applicationId: string;
  username: string;
  email: string;
  roles: string;
  resource: string;
  accessPolicy: string;
}

export type UserFieldName =
  | 'applicationId'
  | 'username'
  | 'email'
  | 'phone_number'
  | 'roles'
  | 'resource'
  | 'accessPolicy';

export const userDefaults = {
  defaultValues: {
    applicationId: '',
    username: '',
    email: '',
    roles: '',
    resource: prettyJSON({
      resourceType: 'Practitioner',
      name: [{ family: 'LAST_NAME', given: ['FIRST_NAME'] }],
    }),
    accessPolicy: prettyJSON({
      rule: [
        {
          resource: 'FHIR:Patient:*',
          action: ['FHIR:Read'],
          effect: 'Allow',
        },
      ],
    }),
  },
};

interface FormProps {
  formUtils: UseFormReturn<UserFields>;
  hiddenFields?: UserFieldName[];
  renderErrors?: (errors: any) => ReactElement;
  renderPreFormBody?: () => ReactElement;
  renderActionButton?: () => ReactElement;
  renderDeleteButton?: () => ReactElement;
  applications?: Awaited<AppPageLoaderData['applications']>;
  assignedRoles?: { id: string; name: string }[];
}

export const UserFormBody: React.FC<FormProps> = ({
  formUtils,
  hiddenFields = [],
  renderErrors,
  renderPreFormBody,
  renderActionButton,
  renderDeleteButton,
  applications = [],
  assignedRoles = [],
}) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = formUtils;

  const accessPolicy: string | undefined = useWatch({
    name: 'accessPolicy',
    control,
    defaultValue:
      formUtils.getValues().accessPolicy ??
      prettyJSON({
        rule: [
          {
            resource: ['FHIR:Patient'],
            action: ['FHIR:Read'],
            effect: 'Allow',
            condition: 'email=',
          },
        ],
      }),
  });

  useWatch({ name: 'applicationId', control });
  const showApplicationError = errors.applicationId && !getValues().applicationId;

  const getApplicationsSelector = (): JSX.Element => {
    if (applications.length == 0) {
      return (
        <FormHelperText className="Mui-error">
          You don't have any applications, please create one to be able to invite users.
        </FormHelperText>
      );
    }

    if (applications[0] && applications[0].id == '-1') {
      return (
        <Grid style={{ width: '100%' }}>
          <CircularProgress style={{ margin: 'auto', display: 'block', marginBottom: '15px' }} />
          <p style={{ textAlign: 'center' }}>Loading list of Applications...</p>
        </Grid>
      );
    }

    return (
      <FormControl id="applicationSelectorControl" variant="outlined" fullWidth>
        <InputLabel id="Application-label" className={showApplicationError ? 'Mui-error' : ''}>
          Application
        </InputLabel>
        <Select
          {...register('applicationId', {
            required: !hiddenFields.includes('applicationId') && `Application is a required field`,
          })}
          labelId={`Application-label`}
          label={'Application'}
          inputProps={{ 'aria-label': 'Application' }}
          className={showApplicationError ? 'Mui-error' : ''}
        >
          {applications.map((menuItem: Application) => (
            <MenuItem key={menuItem.id} value={menuItem.id}>
              {menuItem.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          style={{ display: 'block', marginLeft: '14px' }}
          className={showApplicationError ? 'Mui-error' : ''}
        >
          {showApplicationError
            ? (errors.applicationId?.message as string)
            : 'Application that you want to add user to'}
        </FormHelperText>
      </FormControl>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper>
          <Grid container direction="column" spacing={4} padding={2}>
            {renderErrors && renderErrors(errors)}
            {renderPreFormBody && renderPreFormBody()}
            <Grid item sx={{ display: hiddenFields.includes('applicationId') ? 'none' : 'block' }}>
              {getApplicationsSelector()}
            </Grid>
            <Grid item sx={{ display: hiddenFields.includes('username') ? 'none' : 'inherit' }}>
              <TextField
                {...register('username', {
                  required: !hiddenFields.includes('username') && `User name is a required field`,
                })}
                fullWidth
                label="User name"
                error={!!errors.username}
                helperText={(errors.username?.message as string) || 'First and last name of the user'}
              />
            </Grid>
            <Grid item sx={{ display: hiddenFields.includes('email') ? 'none' : 'inherit' }}>
              <TextField
                {...register('email', {
                  required: !hiddenFields.includes('email') && `Email is a required field`,
                })}
                fullWidth
                label="Email"
                error={!!errors.email}
                helperText={(errors.email?.message as string) || 'Email of the user'}
              />
            </Grid>
            <Grid item sx={{ display: hiddenFields.includes('resource') ? 'none' : 'inherit' }}>
              <TextField
                {...register('resource', {
                  required: !hiddenFields.includes('resource') && `Resource is a required field`,
                })}
                fullWidth
                multiline
                label="Resource"
                error={!!errors.resource}
                helperText={(errors.resource?.message as string) || 'Resource to be associated with the user'}
              />
            </Grid>
            <Grid item sx={{ display: hiddenFields.includes('accessPolicy') ? 'none' : 'inherit' }}>
              <AccessPolicyInput
                config={{ label: 'Access Policy', helperText: 'Access policy to assign this user' }}
                accessPolicy={accessPolicy}
                setAccessPolicy={(newVal: string | undefined) => setValue('accessPolicy', newVal ?? '')}
                accessPolicyErrors={[]}
              />
            </Grid>
            {hiddenFields.includes('roles') ? (
              <></>
            ) : (
              <Grid item>
                <RolesEditComponent
                  assignedRolesList={assignedRoles}
                  setRolesValue={(value) => setValue('roles', value)}
                />
              </Grid>
            )}
            <Grid item textAlign="center">
              {renderActionButton && renderActionButton()}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} textAlign="center">
        {renderDeleteButton && renderDeleteButton()}
      </Grid>
    </Grid>
  );
};
