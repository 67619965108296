import { DataObject, Engineering, Groups } from '@mui/icons-material';
import { Typography } from '@mui/material';
import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { PageContainer } from '../../components/PageContainer';
import { Services } from '../../services';

export const userSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'M2M Clients',
      icon: <DataObject />,
      path: `/${Services.iam.rootPath}/m2m-clients`,
    },
    {
      label: 'Developers',
      icon: <Engineering />,
      path: `/${Services.iam.rootPath}/developers`,
    },
    /*
    {
      label: 'Access Policies',
      icon: <Fort />,
      path: `/${Services.iam.rootPath}/access-policies`,
    },*/
    {
      label: 'Roles',
      icon: <Groups />,
      path: `/${Services.iam.rootPath}/roles`,
    },
  ],
];

export function IAMPage(): JSX.Element {
  const { pathname } = useLocation();
  console.log('pathname', pathname);

  return (
    <PageContainer sidebarItems={userSidebarItems}>
      {pathname === `/${Services.iam.rootPath}` ? <UsersElement /> : <Outlet />}
    </PageContainer>
  );
}

const UsersElement: React.FC = () => (
  <>
    <Typography variant="h4">Dashboard</Typography>
    <Typography variant="body1">Manage your Developers and Users.</Typography>
  </>
);
