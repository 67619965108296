const services = {
  fhir: {
    name: 'FHIR',
    rootPath: 'fhir/resources',
    includeInNavbar: true,
    features: [],
  },
  iam: {
    name: 'IAM',
    rootPath: 'iam',
    includeInNavbar: false,
    features: [
      { name: 'M2M Clients', path: 'm2m-clients' },
      { name: 'Developers', path: 'developers' },
      { name: 'Roles', path: 'roles' },
    ],
  },
  zambdas: {
    name: 'Zambdas',
    rootPath: 'zambdas',
    includeInNavbar: true,
    features: [
      { name: 'Functions', path: '' },
      { name: 'Secrets', path: 'secrets' },
    ],
  },
  app: {
    name: 'App',
    rootPath: 'app',
    includeInNavbar: true,
    features: [
      { name: 'Applications', path: '' },
      { name: 'Users', path: 'users' },
    ],
  },
  messaging: {
    name: 'Messaging',
    rootPath: 'messaging',
    includeInNavbar: true,
    features: [{ name: 'SMS', path: 'sms' }],
  },
  z3: {
    name: 'Z3',
    rootPath: 'z3',
    includeInNavbar: true,
    features: [{ name: 'Buckets', path: '' }],
  },
};

export const Services = {
  ...services,
  all: Object.values(services),
};
