import { LoadingButton } from '@mui/lab';
import { Grid, Paper, TextField, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from '../lib/toast';
import { InputField } from '../lib/types';
import { Services } from '../services';

interface SendSMSFormProps {
  inputFields: InputField[];
}

export function SendSMSForm({ inputFields }: SendSMSFormProps): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any): void => {
    setLoading(true);
    zapehr.project.transactionalSMS
      .send(data)
      .then(() => {
        toast.success(`Sent message`);
        navigate(`/${Services.messaging.rootPath}`);
      })
      .catch((error) => {
        toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper>
      <Typography variant="h5" color="text.primary" padding={2}>
        Send SMS
      </Typography>
      {/* todo reduce duplication with other similar files */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={4} padding={2}>
          {inputFields.map((field) => (
            <Grid item key={field.name}>
              <TextField
                {...register(field.name, { required: field.required && `${field.label} is a required field` })}
                label={field.label}
                fullWidth
                error={!!errors[field.name]}
                helperText={(errors[field.name]?.message as string) || field.helperText}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ))}
          <Grid item textAlign="left">
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Send SMS
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
