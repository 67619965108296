import { Add } from '@mui/icons-material';
import { alpha, Box, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { FC, Suspense, useEffect, useState } from 'react';
import { Await, Link, Outlet, useLoaderData, useMatch } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { Annotation, Communication } from '../../../lib/fhir-types';
import { Services } from '../../../services';

const SMSPage: FC = () => {
  const initialMessagingPromise = useLoaderData() as { messages: Promise<Communication[]> };

  return (
    <Grid container direction="column" rowSpacing={5}>
      <Grid item xs={8}>
        <Suspense fallback={<SMSElement loading initialMessages={[]} />}>
          <Await resolve={initialMessagingPromise.messages} errorElement={<RootErrorBoundary />}>
            {(messages) => {
              return <SMSElement initialMessages={messages} loading={false} />;
            }}
          </Await>
        </Suspense>
      </Grid>
    </Grid>
  );
};

interface SMSElementProps {
  initialMessages: Communication[];
  loading: boolean;
}

const SMSElement: FC<SMSElementProps> = ({ initialMessages, loading }) => {
  const [pageSize, setPageSize] = useState(10);
  const match = useMatch(`/${Services.messaging.rootPath}/sms/*`);

  const [messages, setMessages] = useState<Communication[]>([]);
  useEffect(() => setMessages(initialMessages), [initialMessages]);

  const columns: GridColDef[] = [
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      renderCell: (params) => (
        <Link to={`/${Services.fhir.rootPath}/Communication/${params.row.id}`}>{params.value}</Link>
      ),
      flex: 1,
    },
    {
      field: 'resource',
      headerName: 'Resource',
      renderCell: (params) => (
        <Link to={`/${Services.fhir.rootPath}/${params.row.resource}`}>{params.row.resource}</Link>
      ),
      flex: 1,
    },
    { field: 'message', headerName: 'Message', flex: 1 },
  ];

  return match && match.pathname !== match.pathnameBase ? (
    <Outlet />
  ) : (
    <Grid container>
      <Grid item xs={10} display="flex" marginBottom={2}>
        <Typography variant="h4">SMS</Typography>
        <ArticleShortcutIconButton link="https://docs.oystehr.com/services/messaging/transactional-sms/" />
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <Link to="new">
          <Button aria-label="new" variant="contained" startIcon={<Add />}>
            Send SMS
          </Button>
        </Link>
      </Grid>
      <Typography>
        Oystehr's{' '}
        <MUILink href={'https://docs.oystehr.com/services/messaging/transactional-sms/'} target="_blank">
          Transactional SMS
        </MUILink>{' '}
        feature makes it easy to send a text message to your{' '}
        <MUILink href={'https://docs.oystehr.com/services/app/users/'} target="_blank">
          Users
        </MUILink>{' '}
        with a{' '}
        <MUILink
          href={'https://api-reference.oystehr.com/reference/post_messaging-transactional-sms-send'}
          target="_blank"
        >
          single API call
        </MUILink>
        .
      </Typography>
      <Grid item xs={12}>
        <Paper sx={{ marginTop: 4 }}>
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGridPro
              columns={columns}
              rows={messages.map((message) => {
                return {
                  id: message.id,
                  phoneNumber: (message?.note as Annotation[])
                    ?.find((n) => n.text.startsWith('Message sent to number: '))
                    ?.text.replace('Message sent to number: ', ''),
                  resource: message?.recipient?.[0]?.reference,
                  message: (message?.payload?.[0] as any)?.contentString,
                };
              })}
              loading={loading}
              headerHeight={56}
              autoHeight
              getRowId={(row) => row.id}
              pagination
              pageSize={pageSize}
              onPageSizeChange={(size) => setPageSize(size)}
              rowsPerPageOptions={[10, 25, 100]}
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  pl: 1.5,
                  gap: 4,
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                  '& .MuiButtonBase-root': {
                    color: otherColors.charcoal87,
                  },
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SMSPage;
