import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { Reference, Resource } from '../lib/fhir-types';
import { useResource } from './index';
import { ResourceBreadCrumb } from './ResourceBreadCrumb';

export interface ResourceHeaderProps {
  resource: Resource | Reference | undefined;
}

export function ResourceHeader(props: ResourceHeaderProps): JSX.Element | null {
  const resource = useResource(props.resource);

  const { resourceType, resourceId } = useMemo(() => {
    let resourceType = resource?.resourceType;
    let resourceId = '';
    if (resource?.resourceType === 'Reference') {
      const asRef = resource as Reference;
      const split = asRef.reference?.split('/');
      if (split && split.length === 2) {
        resourceType = split[0];
        resourceId = split[1];
      } else {
        resourceType = undefined;
      }
    } else {
      resourceId = resource?.id ?? '';
    }

    return { resourceType, resourceId };
  }, [resource]);

  if (!resourceType) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: 'primary.contrast', marginLeft: '-36px', marginRight: '-36px', marginTop: '-36px' }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ padding: '4px 25px 25px' }}>
        <ResourceBreadCrumb resourceType={resourceType} resourceName={resourceId} />
      </Stack>
    </Box>
  );
}
