/* eslint-disable import/no-named-as-default-member */
import mixpanel from 'mixpanel-browser';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useZapehr } from './ZapehrProvider';

export function MixpanelProvider({ token, children }: { token?: string; children: React.ReactNode }): JSX.Element {
  const { zapehrUserId } = useZapehr();
  const { trackPageview } = useMixpanel();

  // Init
  const [mixpanelInitComplete, setMixpanelInitComplete] = useState(false);
  useEffect(() => {
    if (token) {
      mixpanel.init(token, { track_pageview: true }); // this tracks full page load page views
      setMixpanelInitComplete(true);
    }
  }, [token]);

  // Identify
  useEffect(() => {
    if (mixpanelInitComplete) {
      if (zapehrUserId) {
        mixpanel.identify(zapehrUserId);
      } else {
        mixpanel.reset();
      }
    }
  }, [mixpanelInitComplete, zapehrUserId]);

  // Track page view for SPA screens
  const location = useLocation();
  useEffect(() => {
    if (mixpanelInitComplete) {
      trackPageview();
    }
  }, [location, mixpanelInitComplete, trackPageview]);

  return <>{children}</>;
}

export function useMixpanel(): {
  track: (...params: Parameters<typeof mixpanel.track>) => ReturnType<typeof mixpanel.track>;
  trackPageview: (...params: Parameters<typeof mixpanel.track_pageview>) => ReturnType<typeof mixpanel.track_pageview>;
} {
  const { currentProject } = useZapehr();
  const sharedProperties = useMemo(() => {
    return {
      projectUUID: currentProject?.id,
      environment: process.env.REACT_APP_ENV,
      userType: 'developer',
    };
  }, [currentProject]);
  return {
    track: (eventName, properties, opts) => {
      // don't track for project
      if (currentProject?.id === 'f3d649b6-17fe-4e83-93a9-55ac48e32864') {
        return;
      }
      mixpanel.track(
        eventName,
        {
          ...properties,
          ...sharedProperties,
        },
        opts
      );
    },
    trackPageview: (properties) => {
      if (currentProject?.id === 'f3d649b6-17fe-4e83-93a9-55ac48e32864') {
        return;
      }
      mixpanel.track_pageview({
        ...properties,
        ...sharedProperties,
      });
    },
  };
}
