import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { App } from './App';
import { ZapehrProvider } from './components/index';
import { AdminThemeProvider } from './contexts/AdminThemeProvider';

const useSentry = ['testing', 'staging', 'production'].includes(process.env.environment ?? '');

if (useSentry) {
  Sentry.init({
    dsn: 'https://edeeaf1b12b9a3673b6965b5d6afd93a@o4506482149556224.ingest.us.sentry.io/4506520669847552',
    release: process.env.gitSha,
    environment: process.env.environment,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const app = (
  <Auth0Provider
    domain={process.env.AUTH_DOMAIN || ''}
    clientId={process.env.AUTH_CLIENT_ID || ''}
    audience={process.env.AUTH_AUDIENCE || ''}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <ZapehrProvider>
      <AdminThemeProvider>
        <App />
      </AdminThemeProvider>
    </ZapehrProvider>
  </Auth0Provider>
);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>{useSentry ? <Sentry.ErrorBoundary showDialog={false}>{app}</Sentry.ErrorBoundary> : app}</StrictMode>
);
