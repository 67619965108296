import { LoadingButton } from '@mui/lab';
import { Grid, Paper, TextField, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { AccessPolicyInput, isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { RolesEditComponent } from '../../../../components/RolesEditComponent/RolesEditComponent';
import { toast } from '../../../../lib/toast';
import { updateListFieldInput } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { m2mClientFields as fields } from '..';

export function CreateM2MClient(): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [accessPolicy, setAccessPolicy] = useState<string | undefined>(
    fields.find((field) => field.type === 'access policy')?.defaultValue
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: any): void => {
      if (loading) {
        return;
      }

      const dataFormatted = updateListFieldInput(data, [...fields, { name: 'roles', label: 'Roles', json: true }]);
      dataFormatted.accessPolicy = isValidJSON(accessPolicy);
      if (!dataFormatted.accessPolicy) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        return;
      }
      if (!Array.isArray(dataFormatted.roles)) {
        dataFormatted.roles = [];
      }
      setLoading(true);
      zapehr.project.m2m
        .create(dataFormatted)
        .then(() => {
          toast.success('Client successfully created');
          setLoading(false);
          revalidator.revalidate();
          navigate(`/${Services.iam.rootPath}/m2m-clients`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        });
    },
    [accessPolicy, loading, navigate, revalidator]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Create M2M Client
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={4} padding={2}>
              {fields.map((field) => (
                <Grid item key={field.name}>
                  {field.type === 'access policy' ? (
                    <AccessPolicyInput
                      config={{ label: 'Access Policy', helperText: 'Access policy of the M2M Client' }}
                      accessPolicy={accessPolicy || field.defaultValue}
                      setAccessPolicy={setAccessPolicy}
                      accessPolicyErrors={[]}
                    />
                  ) : (
                    <TextField
                      {...register(field.name, {
                        required: field.required && `${field.label} is a required field`,
                        ...(field.maxLength && {
                          maxLength: {
                            value: field.maxLength,
                            message: `${field.label} has a maximum length of ${field.maxLength} characters`,
                          },
                        }),
                      })}
                      fullWidth
                      label={field.label}
                      error={!!errors[field.name]}
                      helperText={(errors[field.name]?.message as string) || field.helperText}
                      multiline={field.multiline}
                      minRows={field.minRows}
                    />
                  )}
                </Grid>
              ))}
              <Grid item>
                <RolesEditComponent assignedRolesList={[]} setRolesValue={(value) => setValue('roles', value)} />
              </Grid>
              <Grid item textAlign="center">
                <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
