import { Autocomplete, Box, CircularProgress, createFilterOptions, Stack, SxProps, Typography } from '@mui/material';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { DEFAULT_SEARCH_COUNT } from '../../lib/searchUtils';
import { AutocompleteOption } from '../../lib/types';
import { CustomTextField } from './CustomTextField';

export interface AutocompleteProps {
  optionsList?: AutocompleteOption[];
  optionsAsync?: () => Promise<AutocompleteOption[]>;
  label?: string;
  sx?: SxProps;
  reference?: boolean;
  onChange?: (value: AutocompleteOption | null) => void;
  updateOptionsList?: (searchValue: string, searchParam: string) => Promise<AutocompleteOption[]>;
  value?: string;
  initialPropertyValue?: string;
  disabled?: boolean;
}

export const FhirFormAutocomplete: FC<AutocompleteProps> = ({
  optionsList,
  optionsAsync,
  label,
  reference,
  sx,
  onChange,
  updateOptionsList,
  value,
  initialPropertyValue,
  disabled,
}) => {
  const [options, setOptions] = useState(optionsList ?? []);
  const [initialOptions, setInitialOptions] = useState<AutocompleteOption[] | null>(null);

  const { editModeOn } = useResourceEditingContext();

  // handling cases where the value set in the db is not among the list of expected values that
  // are stored in the zapehr db (for instance a reference that is represented by a link to another
  // ehr system)
  useEffect(() => {
    if (initialPropertyValue) {
      const matchingOption = options.find((option) => option.value === initialPropertyValue) ?? null;
      if (!matchingOption) {
        const nonDBOption: AutocompleteOption = {
          label: initialPropertyValue,
          value: initialPropertyValue,
        };
        setOptions([nonDBOption, ...options]);
      }
    }
  }, [initialPropertyValue, options]);

  const selectedOption: AutocompleteOption | null = useMemo(() => {
    if (value) {
      return options.find((option) => option.value === value) ?? null;
    }
    return null;
  }, [options, value]);

  const sxStyle: SxProps = sx ?? {};

  useEffect(() => {
    const getResults = async (): Promise<void> => {
      if (optionsAsync) {
        const fetchedOptions = await optionsAsync();
        setOptions(fetchedOptions ?? []);
        setInitialOptions(fetchedOptions ?? []);
      }
    };

    if (optionsAsync) {
      void getResults();
    } else if (optionsList) {
      setOptions(optionsList);
      setInitialOptions(optionsList);
    }
  }, [optionsAsync, optionsList, reference, value]);

  const sortedOptions = useMemo(() => {
    return options.sort((a, b) => {
      if (a.value === selectedOption?.value) {
        return -1;
      }
      if (b.value === selectedOption?.value) {
        return 1;
      }
      if (a.group && b.group) {
        const groupCompare = a.group.localeCompare(b.group);
        if (groupCompare > 0) {
          return 1;
        } else if (groupCompare < 0) {
          return -1;
        }
      }

      return a.label.localeCompare(b.label);
    });
  }, [options, selectedOption?.value]);

  const searchParam = useMemo(() => {
    // important note: during search by _id user should pass exact full value
    // otherwise 400 will be returned
    if (!initialOptions || initialOptions.length == 0) {
      return '_id';
    }
    return initialOptions[0].subtext ? 'name:contains' : '_id';
  }, [initialOptions]);

  const [loadingFilteredOptions, setLoadingFilteredOptions] = useState(false);
  const triggerOptionsUpdate = useCallback(
    (searchValue: string): void => {
      if (updateOptionsList) {
        setLoadingFilteredOptions(true);
        updateOptionsList(searchParam, searchValue)
          .then((updateOptions) => {
            if (updateOptions && updateOptions.length > 0) {
              setOptions(updateOptions);
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => setLoadingFilteredOptions(false));
      }
    },
    [searchParam, updateOptionsList]
  );

  // debouncing options update call for network optimisation
  //--------------------------------------------------------
  const [filterInputValue, setFilerInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterInputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterInputValue);
    }, 1000);

    return () => {
      // this will run on the next useEffect call
      clearTimeout(handler);
    };
  }, [filterInputValue]);

  useEffect(() => {
    if (debouncedValue) {
      triggerOptionsUpdate(debouncedValue);
    } else {
      setOptions(initialOptions ?? []);
    }
  }, [debouncedValue, triggerOptionsUpdate, initialOptions]);
  //--------------------------------------------------------

  const handleInputTextChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: string): void => {
      // if we initially have less data than a "pack limit", so there's nothing more to fetch
      if (!initialOptions || initialOptions.length < DEFAULT_SEARCH_COUNT || !updateOptionsList) {
        return;
      }
      setFilerInputValue(value);
    },
    [initialOptions, updateOptionsList]
  );

  return (
    <Box>
      <Autocomplete
        componentsProps={{
          popper: { disablePortal: true, popperOptions: { placement: 'bottom-start', strategy: 'fixed' } },
        }}
        options={sortedOptions}
        value={selectedOption ?? null}
        onChange={(_, val) => {
          console.log('val selected', val);
          if (val !== null) {
            let value: AutocompleteOption;
            if (typeof val === 'string') {
              value = { value: val, label: val };
            } else {
              value = val;
            }
            if (onChange) {
              console.log('val selected on change finished', value);
              onChange(value);
            }
          } else {
            onChange?.(null);
          }
        }}
        loading={loadingFilteredOptions}
        onInputChange={handleInputTextChange}
        disabled={disabled ?? !editModeOn ?? false}
        // on select item remove focus
        blurOnSelect={true}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        freeSolo={!editModeOn}
        groupBy={(option) => option.group || ''}
        // size="small"
        filterOptions={createFilterOptions({
          // Match on either label or group
          // Todo this is not ideal because of how it does the matching.
          // For example, label "FHIR Admin" with group "Service"
          // will match on "FHIR Admin Service" when it shouldn't.
          // We should try to find a way to match on label _or_ group.
          stringify: (option) => `${option.label} ${option.group}`,
        })}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Stack>
              <Typography variant="body1" sx={{ width: '100%' }}>
                {option.label}
              </Typography>
              <Typography variant="overline" sx={{ width: '100%' }}>
                {option.subtext}
              </Typography>
            </Stack>
          </li>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            label={label}
            fullWidth={true}
            displayOnly={!editModeOn}
            sx={sx !== undefined ? sxStyle : { width: '50%' }}
            InputProps={{
              ...params.InputProps,
              ...(!editModeOn || disabled ? { endAdornment: '' } : {}),
              size: 'medium',
              endAdornment: (
                <>
                  {loadingFilteredOptions ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
