import DataObjectIcon from '@mui/icons-material/DataObject';
import { Button } from '@mui/material';
import * as React from 'react';

export const blankAccessPolicies = ['', '{}', '[]'];

export interface BlankAccessPolicyButtonProps {
  setAccessPolicy: (data: string | undefined) => void;
}

export function BlankAccessPolicyButton(props: BlankAccessPolicyButtonProps): JSX.Element {
  return (
    <Button
      variant="contained"
      color="primary"
      sx={{ marginLeft: 2 }}
      startIcon={<DataObjectIcon />}
      onClick={() => {
        props.setAccessPolicy('{ "rule": [] }');
      }}
    >
      Set Blank Access Policy
    </Button>
  );
}
