import {
  AccountTreeOutlined,
  ArrowForward,
  CalculateOutlined,
  CodeOutlined,
  ContentCopy,
  DescriptionOutlined,
  Inventory2Outlined,
  ManageAccountsOutlined,
  SmsOutlined,
  Storage,
  VpnKeyOutlined,
} from '@mui/icons-material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LinkIcon from '@mui/icons-material/Link';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { generateDefaultOptionList, Header, resourcesAutocomplete, useZapehr } from '../../components';
import { HomePageInfoCard } from '../../components/HomePageInfoCard';
import { HomePageResourceCard } from '../../components/HomePageResourceCard';
import { HomePageRoundIcon } from '../../components/HomePageRoundIcon';
import { ResourceSubCard } from '../../components/ResourceSubCard';
import { SearchBarAutocomplete } from '../../components/WrappedInputs/SearchBarAutocomplete';
import { otherColors } from '../../contexts/AdminThemeProvider';
import { useMixpanel } from '../../contexts/MixpanelProvider';
import useScrollToTop from '../../hooks/useScrollToTop';
import { toast } from '../../lib/toast';
import { copyToClipboard } from '../../lib/utils';
import { Services } from '../../services';

export const HomePage: FC = () => {
  return (
    <Box
      id="authenticated-homepage-root"
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Header />
      <HomePageContent />
    </Box>
  );
};

const HomePageContent: FC = () => {
  const match = useMatch('/');
  const theme = useTheme();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { getAuthToken, currentProject } = useZapehr();
  const { track } = useMixpanel();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const copyAccessTokenCardOnClick = (): void => {
    if (accessToken) {
      copyToClipboard(accessToken, 'Access token copied to clipboard!');
      track('Access Token Copied');
    } else {
      toast.error('Access token is not yet loaded.');
    }
  };

  const copyProjectIdCardOnClick = (): void => {
    if (currentProject?.id) {
      copyToClipboard(currentProject.id, 'Project ID copied to clipboard!');
      track('Project ID Copied');
    } else {
      toast.error('Project ID is not yet loaded.');
    }
  };

  useEffect(() => {
    if (!accessToken) {
      void getAuthToken().then((token) => setAccessToken(token));
    }
  }, [accessToken, getAuthToken]);

  useScrollToTop();

  const UrlNames = {
    'FHIR API base URL': process.env.FHIR_API_URL,
    'Project API base URL': process.env.PROJECT_API_URL,
    'Auth Audience': process.env.AUTH_AUDIENCE,
    'Auth Domain': process.env.AUTH_DOMAIN,
  };

  return match && match.pathname === '/' ? (
    <>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth maxWidth="md">
        <DialogContent>
          <DialogContentText id="dialog-description">
            {Object.entries(UrlNames).map(([name, url]) => {
              return (
                <React.Fragment key={url}>
                  <Typography variant="body1" marginBottom={5}>
                    <Box sx={{ color: theme.palette.primary.main }}>
                      {name}
                      <IconButton onClick={() => copyToClipboard(url as string, `${url} copied to clipboard`)}>
                        <ContentCopy sx={{ color: theme.palette.primary.dark }} />
                      </IconButton>
                    </Box>
                    <Box sx={{ color: theme.palette.primary.light }}>{url}</Box>
                  </Typography>
                </React.Fragment>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Grid container sx={{ minHeight: '90vh' }}>
        <Grid item xs={7} sx={{ py: theme.spacing(5), px: theme.spacing(7.5) }}>
          <Grid container rowSpacing="24px" direction="column">
            <Grid item>
              <Typography variant="h4">Welcome to Oystehr</Typography>
            </Grid>
            {/* <Grid item>
            <Typography variant="body1">Place for short description or status</Typography>
          </Grid> */}
            {/* <Grid item>
            <Typography variant="h5">Services</Typography>
          </Grid> */}
            <Grid item>
              <CustomAutocomplete />
            </Grid>
          </Grid>
          <Grid container direction="column" rowSpacing="24px">
            <Grid item>
              <HomePageResourceCard
                icon={<HomePageRoundIcon backgroundColor={otherColors.applicationsIconBackground} icon={<Storage />} />}
                label="Applications"
                description="Create and edit applications. For example, in your “sandbox” project, you might create RCM, patient portal, and EHR applications."
                path={Services.app.rootPath}
              />
            </Grid>
            <Grid item>
              <HomePageResourceCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.fhirIconBackground} icon={<AccountTreeOutlined />} />
                }
                label="FHIR Resources"
                description="View and edit your core FHIR data model and records."
                path={Services.fhir.rootPath}
              >
                <>
                  <ResourceSubCard label="Patient" path={`/${Services.fhir.rootPath}/Patient`} />
                  <ResourceSubCard label="Practitioner" path={`/${Services.fhir.rootPath}/Practitioner`} />
                  <ResourceSubCard label="Medication" path={`/${Services.fhir.rootPath}/Medication`} />
                  <ResourceSubCard label="Schedule" path={`/${Services.fhir.rootPath}/Schedule`} />
                  <ResourceSubCard label="Slot" path={`/${Services.fhir.rootPath}/Slot`} />
                </>
              </HomePageResourceCard>
            </Grid>
            <Grid item>
              <HomePageResourceCard
                icon={
                  <HomePageRoundIcon
                    backgroundColor={otherColors.iamIconBackground}
                    icon={<ManageAccountsOutlined />}
                  />
                }
                label="IAM"
                description="Manage your developer accounts, machine-to-machine access, and associated access policies."
                path={Services.iam.rootPath + '/m2m-clients'}
              >
                <>
                  <ResourceSubCard label="M2M Clients" path={`/${Services.iam.rootPath}/m2m-clients`} />
                  <ResourceSubCard label="Developers" path={`/${Services.iam.rootPath}/developers`} />
                </>
              </HomePageResourceCard>
            </Grid>
            <Grid item>
              <HomePageResourceCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.messagingIconBackground} icon={<SmsOutlined />} />
                }
                label="Messaging"
                description="SMS and chat based communication and engagement service. Send an SMS directly from the console."
                path={Services.messaging.rootPath}
              />
            </Grid>
            <Grid item>
              <HomePageResourceCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.zambdasIconBackground} icon={<CalculateOutlined />} />
                }
                label="Zambdas"
                description="Host your own server-side code with hooks into Oystehr."
                path={Services.zambdas.rootPath}
              >
                <>
                  <ResourceSubCard label="Functions" path={`/${Services.zambdas.rootPath}`} />
                  <ResourceSubCard label="Secrets" path={`/${Services.zambdas.rootPath}/secrets`} />
                </>
              </HomePageResourceCard>
            </Grid>
            <Grid item>
              <HomePageResourceCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.z3IconBackground} icon={<Inventory2Outlined />} />
                }
                label="Z3"
                description="Securely store and retrieve files with Oystehr."
                path={Services.z3.rootPath}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            background: otherColors.homePageSideBarBackground,
          }}
        >
          <Grid container rowSpacing="24px" direction="column" sx={{ px: '60px', py: '40px' }}>
            <Grid item>
              <Typography variant="h5" sx={{ color: theme.palette.primary.light }}>
                Getting Started
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                Copy your Developer access token or dig into the documentation.
              </Typography>
            </Grid>
            <Grid item>
              <HomePageInfoCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.infoCardsIconBackground} icon={<VpnKeyOutlined />} />
                }
                label="Click to copy your access token"
                textColor={theme.palette.primary.contrastText}
                backgroundColor={otherColors.cornflowerBlue12}
                onClick={copyAccessTokenCardOnClick}
                iconAtTheEnd={<ContentCopy sx={{ color: theme.palette.primary.light }} />}
                centerIcons={true}
              >
                <Typography
                  variant="body2"
                  noWrap
                  textOverflow="ellipsis"
                  sx={{
                    color: theme.palette.primary.light,
                    maxWidth: '250px',
                  }}
                >
                  {!accessToken ? (
                    <Skeleton
                      variant="text"
                      width={250}
                      animation="wave"
                      sx={{
                        display: 'inline-flex',
                        bgcolor: theme.palette.primary.light,
                      }}
                    ></Skeleton>
                  ) : (
                    accessToken
                  )}
                </Typography>
              </HomePageInfoCard>
            </Grid>
            <Grid item>
              <HomePageInfoCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.infoCardsIconBackground} icon={<VpnKeyOutlined />} />
                }
                label="Click to copy your Project ID"
                textColor={theme.palette.primary.contrastText}
                backgroundColor={otherColors.cornflowerBlue12}
                onClick={copyProjectIdCardOnClick}
                iconAtTheEnd={<ContentCopy sx={{ color: theme.palette.primary.light }} />}
                centerIcons={true}
              >
                <Typography
                  variant="body2"
                  noWrap
                  textOverflow="ellipsis"
                  sx={{
                    color: theme.palette.primary.light,
                    maxWidth: '250px',
                  }}
                >
                  {!currentProject?.id ? (
                    <Skeleton
                      variant="text"
                      width={250}
                      animation="wave"
                      sx={{
                        display: 'inline-flex',
                        bgcolor: theme.palette.primary.light,
                      }}
                    ></Skeleton>
                  ) : (
                    currentProject.id
                  )}
                </Typography>
              </HomePageInfoCard>
            </Grid>
            <Grid item>
              <HomePageInfoCard
                icon={
                  <HomePageRoundIcon
                    backgroundColor={otherColors.infoCardsIconBackground}
                    icon={<DescriptionOutlined />}
                  />
                }
                label="Documentation"
                description="Learn about Oystehr and follow the quickstart guide."
                textColor={theme.palette.primary.contrastText}
                backgroundColor={otherColors.cornflowerBlue12}
                iconAtTheEnd={
                  <ArrowForward sx={{ width: '24px', height: '24px', color: theme.palette.primary.light }} />
                }
                centerIcons={true}
                path="https://docs.oystehr.com/"
              />
            </Grid>
            <Grid item>
              <HomePageInfoCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.infoCardsIconBackground} icon={<CodeOutlined />} />
                }
                label="API Reference"
                description="Learn the finer points of every endpoint in the Oystehr APIs."
                textColor={theme.palette.primary.contrastText}
                backgroundColor={otherColors.cornflowerBlue12}
                iconAtTheEnd={
                  <ArrowForward sx={{ width: '24px', height: '24px', color: theme.palette.primary.light }} />
                }
                centerIcons={true}
                path="https://api-reference.oystehr.com/"
              />
            </Grid>
            <Grid item>
              <HomePageInfoCard
                icon={
                  <HomePageRoundIcon backgroundColor={otherColors.infoCardsIconBackground} icon={<PersonAddIcon />} />
                }
                label="Invite a Team Member"
                textColor={theme.palette.primary.contrastText}
                backgroundColor={otherColors.cornflowerBlue12}
                iconAtTheEnd={<ArrowForward sx={{ color: theme.palette.primary.light }} />}
                centerIcons={true}
                path="/iam/developers/new"
              />
            </Grid>
            <Grid item>
              <HomePageInfoCard
                icon={<HomePageRoundIcon backgroundColor={otherColors.infoCardsIconBackground} icon={<LinkIcon />} />}
                label="Click to View API URLs"
                textColor={theme.palette.primary.contrastText}
                backgroundColor={otherColors.cornflowerBlue12}
                onClick={() => setShowDialog(true)}
                iconAtTheEnd={<FullscreenIcon sx={{ color: theme.palette.primary.light }} />}
                centerIcons={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <Outlet />
  );
};

function CustomAutocomplete(): JSX.Element {
  const { currentProject } = useZapehr();
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: '24px',
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.action.hover,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #eee',
        },
        label: {
          color: theme.palette.text.secondary,
        },
        input: {
          color: theme.palette.text.secondary,
        },
      }}
    >
      <SearchBarAutocomplete
        disabled={false}
        optionsList={resourcesAutocomplete(currentProject?.fhirVersion).concat([
          { label: 'Project Settings', path: '/project', group: 'Project', value: 'Project Settings' },
          ...generateDefaultOptionList(Services.all),
        ])}
        placeholder="Search services..."
        freeSolo
        selectToRedirect={true}
      />
    </Box>
  );
}
