import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import FileUploadBox from '../../../../components/FileUploadBox';
import { TextFieldWithCopyButton } from '../../../../components/TextFieldWithCopyButton';
import { Zambda, ZambdaFileInfo } from '../../../../lib/client';
import { toast } from '../../../../lib/toast';
import { InputField, TriggerMethodType } from '../../../../lib/types';
import { assert, copyToClipboard } from '../../../../lib/utils';
import { ZambdaSchedule, ZambdaScheduleDefaults } from './ZambdaSchedule';
import ZambdaSelectTrigger from './ZambdaSelectTrigger';

interface ZambdaFormsProps {
  method: 'create' | 'update';
  zambdaFields: InputField[];
  zambdaId?: string;
  zambdaFileInfo?: ZambdaFileInfo;
}

const scheduleFields = ['expression', 'start', 'end'];
const fiftyMB = 50 * 1024 * 1024;

export function ZambdaForms({ method, zambdaFields, zambdaId, zambdaFileInfo }: ZambdaFormsProps): JSX.Element {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const [hasChanged, setHasChanged] = useState({
    start: false,
    end: false,
  });
  const [loading, setLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState<string>();
  const [fileForUpload, setFileForUpload] = useState<File | null>(null);
  const [fileForDisplay, setFileForDisplay] = useState<File | ZambdaFileInfo | null>(zambdaFileInfo ?? null);
  const submitDisabled = useMemo(() => {
    // loading, no file selected, or file greater than 50 MB
    return (
      loading ||
      (method === 'create' && !fileForUpload) ||
      (fileForUpload?.size ?? 0) > fiftyMB ||
      (fileForUpload?.name ?? '').includes(' ')
    );
  }, [loading, method, fileForUpload]);
  useEffect(() => {
    if ((fileForUpload?.size ?? 0) > fiftyMB) {
      setUploadError('File size must be less than 50 MB');
    } else if ((fileForUpload?.name ?? '').includes(' ')) {
      setUploadError('File name cannot contain spaces');
    } else {
      setUploadError(undefined);
    }
  }, [fileForUpload]);
  const form = useForm();
  const {
    control,
    formState: { errors },
  } = form;
  const triggerMethod = useWatch({
    control,
    name: 'triggerMethod',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    defaultValue: (zambdaFields.find((field) => field.name === 'triggerMethod')?.defaultValue ??
      'http_auth') as TriggerMethodType,
  });

  const onSubmit = (data: any): void => {
    const { name, triggerMethod, expression, start, end, runtime, maximumEventAge, maximumRetry } = data;
    assert(method === 'create' || zambdaId, 'No zambda ID in request url');
    setLoading(true);
    const dataToSubmit: any = {
      name,
      triggerMethod,
      runtime,
    };
    if (triggerMethod === TriggerMethodType.cron) {
      // If the value is updated/created, use it, otherwise default to the value of the existing schedule if it exists.
      const startDate = hasChanged.start ? start : zambdaSchedDefaults.start ? zambdaSchedDefaults.start : undefined;
      const endDate = hasChanged.end ? end : zambdaSchedDefaults.end ? zambdaSchedDefaults.end : undefined;
      dataToSubmit.schedule = {
        expression,
        start: startDate?.toISO().split('.')[0],
        end: endDate?.toISO().split('.')[0],
        retryPolicy: {
          maximumEventAge: parseInt(maximumEventAge, 10),
          maximumRetry: parseInt(maximumRetry, 10),
        },
      };
    }

    const promise =
      method === 'create'
        ? zapehr.project.zambda.create(dataToSubmit)
        : zapehr.project.zambda.update({ id: zambdaId, ...dataToSubmit });

    promise
      .then(async (data: Zambda) => {
        const id = zambdaId ?? data.id;
        assert(id, 'No zambda ID in response');
        toast.success(`Zambda ${method}d`);

        if (fileForUpload) {
          zapehr.project.zambda
            .uploadFile({ id, file: fileForUpload, filename: fileForUpload.name })
            .then(async () => {
              setUploadComplete(true);
              toast.success('File uploaded');
              revalidator.revalidate();
              if (method === 'create') {
                setTimeout(() => {
                  navigate(`/zambdas/${id}`);
                }, 2000);
              }
            })
            .catch((err: any) => {
              setUploadError(err.message);
              toast.error('File upload error');
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          revalidator.revalidate();
          method === 'create' && navigate(`/zambdas/${id}`);
        }
      })
      .catch((error: any) => {
        toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
        setLoading(false);
      });
  };

  const copyZambdaIdButtonHandler = (): void => {
    zambdaId && copyToClipboard(zambdaId, 'Zambda ID copied to clipboard!');
  };

  const zambdaSchedDefaults: ZambdaScheduleDefaults = useMemo(() => {
    return zambdaFields.reduce(
      (schedFields, current) => {
        if (current.name === 'start') {
          schedFields.start = current.defaultValue ? DateTime.fromISO(current.defaultValue) : undefined;
        }
        if (current.name === 'end') {
          schedFields.end = current.defaultValue ? DateTime.fromISO(current.defaultValue) : undefined;
        }
        if (current.name === 'expression') {
          schedFields.expression = current.defaultValue;
        }
        if (current.name === 'maximumEventAge') {
          schedFields.maximumEventAge = current.defaultValue ? parseInt(current.defaultValue, 10) : undefined;
        }
        if (current.name === 'maximumRetry') {
          schedFields.maximumRetry = current.defaultValue ? parseInt(current.defaultValue, 10) : undefined;
        }
        return schedFields;
      },
      { start: undefined, end: undefined, expression: undefined } as ZambdaScheduleDefaults
    );
  }, [zambdaFields]);

  return (
    <Paper>
      <Typography variant="h5" color="text.primary" padding={2}>
        Basic Information
      </Typography>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={4} padding={2}>
            {zambdaId && (
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={zambdaId}
                      label="Zambda ID"
                      helperText="This ID cannot be edited"
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={copyZambdaIdButtonHandler}
                />
              </Grid>
            )}
            {zambdaFields
              // Exclude schedule fields
              .filter((field) => !scheduleFields.includes(field.name))
              .map((field) => (
                <Grid item key={field.name}>
                  {field.isSelect && field.name === 'triggerMethod' ? (
                    <ZambdaSelectTrigger
                      field={field}
                      value={triggerMethod}
                      setValue={(value) => form.setValue('triggerMethod', value)}
                    />
                  ) : field.isSelect ? (
                    <FormControl fullWidth disabled={field.disabled}>
                      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                      <Select
                        {...form.register(field.name, {
                          required: field.required && `${field.label} is a required field`,
                        })}
                        label={field.label}
                        fullWidth
                        error={!!errors[field.name]}
                        defaultValue={field.defaultValue}
                        placeholder={field.placeholder}
                        disabled={field.disabled}
                      >
                        {field.options?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      {...form.register(field.name, {
                        required: field.required && `${field.label} is a required field`,
                      })}
                      label={field.label}
                      fullWidth
                      error={!!errors[field.name]}
                      helperText={(errors[field.name]?.message as string) || field.helperText}
                      defaultValue={field.defaultValue}
                      placeholder={field.placeholder}
                      disabled={field.disabled}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Grid>
              ))}
            {/* If triggerMethod is cron, show schedule fields */}
            {triggerMethod === TriggerMethodType.cron && (
              <ZambdaSchedule
                errors={errors}
                defaults={zambdaSchedDefaults}
                hasChanged={hasChanged}
                setHasChanged={setHasChanged}
                methods={form}
                expressionRequired={triggerMethod === TriggerMethodType.cron}
              />
            )}
            <Grid item>
              <FileUploadBox
                file={fileForDisplay}
                isLoading={loading && !!fileForUpload}
                isUploadComplete={uploadComplete}
                uploadError={uploadError}
                onUpload={(file) => {
                  setFileForUpload(file);
                  setFileForDisplay(file);
                }}
                onClear={() => {
                  if (loading) {
                    return;
                  }
                  setFileForUpload(null);
                  setFileForDisplay(null);
                  setUploadComplete(false);
                  setUploadError(undefined);
                }}
              />
            </Grid>
            <Grid item textAlign="left">
              <LoadingButton type="submit" variant="contained" loading={loading} disabled={submitDisabled}>
                {method === 'create' ? 'Deploy Zambda' : 'Update'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Paper>
  );
}
