import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Await, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import { AccessPolicyInput, isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RolesEditComponent } from '../../../../components/RolesEditComponent/RolesEditComponent';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { TextCardWithButton } from '../../../../components/TextCardWithButton';
import { TextFieldWithCopyButton } from '../../../../components/TextFieldWithCopyButton';
import { M2MClient } from '../../../../lib/client';
import { toast } from '../../../../lib/toast';
import { copyToClipboard, getValueForInputField, prettyJSON, updateListFieldInput } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { m2mClientFields as fields } from '..';

export function M2MClientDetailPage(): JSX.Element {
  const userPromise = useLoaderData() as { m2mUser: Promise<M2MClient> };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.primary">
          M2M Client Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
          <Await resolve={userPromise.m2mUser} errorElement={<RootErrorBoundary />}>
            {(m2mUser) => {
              return <ContentElement m2mClient={m2mUser as M2MClient} loading={false} />;
            }}
          </Await>
        </Suspense>
      </Grid>
    </Grid>
  );
}

interface M2MClientProps {
  m2mClient: M2MClient;
  loading: boolean;
}

const ContentElement: React.FC<M2MClientProps> = ({ m2mClient, loading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [accessPolicy, setAccessPolicy] = useState<string | undefined>(undefined);
  const [actionLoading, setActionLoading] = useState(false);
  const [secretValue, setSecretValue] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showUpdatedSecretDialog, setShowUpdatedSecretDialog] = useState(false);
  const [showRotateSecretDialog, setShowRotateSecretDialog] = useState(false);
  const revalidator = useRevalidator();
  const navigate = useNavigate();

  useEffect(() => {
    setAccessPolicy(prettyJSON(m2mClient.accessPolicy));
  }, [m2mClient.accessPolicy]);

  const saveChangesButtonHandler = (data: any): void => {
    if (actionLoading || loading) {
      return;
    }
    const dataFormatted = updateListFieldInput(data, [...fields, { name: 'roles', label: 'Roles', json: true }]);

    dataFormatted.accessPolicy = isValidJSON(accessPolicy);
    if (!dataFormatted.accessPolicy) {
      toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
      return;
    }
    if (!Array.isArray(dataFormatted.roles)) {
      dataFormatted.roles = [];
    }
    setActionLoading(true);
    zapehr.project.m2m
      .update({ id: m2mClient.id, ...dataFormatted })
      .then(() => {
        toast.success('Changes saved');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const rotateSecretButtonHandler = (): void => {
    setShowRotateSecretDialog(true);
  };

  const rotateSecret = useCallback((): void => {
    if (actionLoading || loading) {
      return;
    }
    setActionLoading(true);
    zapehr.project.m2m
      .rotateSecret({ id: m2mClient.id })
      .then((result) => {
        setSecretValue(result.secret ?? null);
        setShowUpdatedSecretDialog(true);
        toast.success('Successfully rotated client secret');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setActionLoading(false);
      });
    handleCloseRotateSecretDialog();
  }, [actionLoading, loading, m2mClient.id]);

  const deleteApplicationButtonHandler = (): void => {
    setShowDeleteDialog(true);
  };

  const deleteApplication = useCallback((): void => {
    if (actionLoading || loading) {
      return;
    }
    setActionLoading(true);
    zapehr.project.m2m
      .delete({ id: m2mClient.id })
      .then(() => {
        toast.success(`"${m2mClient.name}" has been deleted`);
        revalidator.revalidate();
        navigate(`/${Services.iam.rootPath}/m2m-clients`);
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        setActionLoading(false);
      })
      .finally(() => {
        handleCloseDeleteDialog();
        setActionLoading(false);
      });
  }, [actionLoading, loading, m2mClient.id, m2mClient.name, navigate, revalidator]);

  const copyZapEHRIDButtonHandler = (): void => {
    copyToClipboard(m2mClient.id, 'zapEHR ID copied to clipboard!');
  };

  const copyClientIDButtonHandler = (): void => {
    copyToClipboard(m2mClient.clientId, 'Client ID copied to clipboard!');
  };

  const copySecretButtonHandler = (): void => {
    if (secretValue) {
      copyToClipboard(secretValue, 'Secret key copied to clipboard!');
    } else {
      toast.error('An error occurred copying the secret key, please try again or contact us');
    }
  };

  const theme = useTheme();

  const renderMessage = (): JSX.Element | null => {
    if (!m2mClient.message) {
      return null;
    }
    return (
      <Grid item xs={12}>
        <Typography
          sx={{
            color: theme.palette.error.main,
            padding: 0,
            margin: 0,
            marginTop: '10px',
          }}
        >
          {m2mClient.message}
        </Typography>
      </Grid>
    );
  };

  const handleCloseDeleteDialog = (): void => {
    setShowDeleteDialog(false);
  };

  const handleCloseRotateSecretDialog = (): void => {
    setShowRotateSecretDialog(false);
  };

  return (
    <Grid container spacing={4}>
      {renderMessage()}
      <Grid item xs={12} mt={4}>
        <Paper>
          <form onSubmit={handleSubmit(saveChangesButtonHandler)}>
            <Grid container direction="column" spacing={4} padding={2}>
              {errors.description && (
                <Grid item>
                  <Typography variant="body1" color="error" role="alert">
                    {errors.description?.message?.toString()}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={m2mClient.id}
                      label="zapEHR ID"
                      helperText="This ID cannot be edited."
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={copyZapEHRIDButtonHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={m2mClient.clientId}
                      label="Client ID"
                      helperText="This ID cannot be edited."
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={copyClientIDButtonHandler}
                />
              </Grid>
              {fields.map((field) => (
                <Grid item key={field.name}>
                  {field.type === 'access policy' ? (
                    <AccessPolicyInput
                      config={{ label: 'Access Policy', helperText: 'Access policy of the M2M Client' }}
                      accessPolicy={accessPolicy || prettyJSON(m2mClient.accessPolicy)}
                      setAccessPolicy={setAccessPolicy}
                      accessPolicyErrors={[]}
                    />
                  ) : (
                    <TextField
                      {...register(field.name, {
                        required: field.required && `${field.label} is a required field`,
                        ...(field.maxLength && {
                          maxLength: {
                            value: field.maxLength,
                            message: `${field.label} has a maximum length of ${field.maxLength} characters`,
                          },
                        }),
                      })}
                      fullWidth
                      label={field.label}
                      error={!!errors[field.name]}
                      helperText={(errors[field.name]?.message as string) || field.helperText}
                      multiline={field.multiline}
                      minRows={field.minRows}
                      defaultValue={getValueForInputField(m2mClient, field)}
                    />
                  )}
                </Grid>
              ))}
              <Grid item>
                <RolesEditComponent
                  assignedRolesList={m2mClient.roles}
                  setRolesValue={(value) => setValue('roles', value)}
                />
              </Grid>
              <Grid item xs={12} textAlign={'center'}>
                <LoadingButton variant="contained" type="submit" sx={{ width: '200px' }} loading={actionLoading}>
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TextCardWithButton
          title="Rotate secret"
          description="Your secret key can be used to get tokens to call the API. Once you generate a new one, you will not be able to access it again from this page. Store it somewhere securely."
          variant="warning"
          buttonTitle="Rotate"
          action={rotateSecretButtonHandler}
          loading={actionLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <TextCardWithButton
          title={`Delete M2M Client`}
          description={`If you delete this client, all apps using it will stop working.`}
          variant="danger"
          buttonTitle={`Delete M2M Client`}
          action={deleteApplicationButtonHandler}
          loading={actionLoading}
        />
      </Grid>
      <Dialog
        open={showUpdatedSecretDialog}
        onClose={() => setShowUpdatedSecretDialog(false)}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Updated secret key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your secret key can be used to get tokens to call the API. Once you generate a new one, you will not be able
            to access it again from this page.
          </DialogContentText>
          <TextFieldWithCopyButton
            textField={
              <TextField
                variant="standard"
                fullWidth
                value={secretValue}
                sx={{ mt: '20px' }}
                helperText="This is your new secret key. Store it securely."
                disabled={true}
              />
            }
            copyButtonOnClick={copySecretButtonHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShowUpdatedSecretDialog(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {showDeleteDialog && (
        <ConfirmationDialog
          handleAction={deleteApplication}
          open={showDeleteDialog}
          title="Delete"
          handleClose={handleCloseDeleteDialog}
        />
      )}

      {showRotateSecretDialog && (
        <ConfirmationDialog
          handleAction={rotateSecret}
          open={showRotateSecretDialog}
          handleClose={handleCloseRotateSecretDialog}
        />
      )}
    </Grid>
  );
};
