import { capitalize } from '@mui/material';
import { Address, FhirResource, HumanName, Period, Timing } from '../lib/fhir-types';

export const constructName = (name: any): string => {
  let fullName = '';

  // Add prefix, if it exists
  if (name.prefix && name.prefix.length > 0) {
    fullName += name.prefix[0] + ' ';
  }

  // Add given name, if it exists
  if (name.given && name.given.length > 0) {
    fullName += name.given[0] + ' ';
  }

  // Add family name, if it exists
  if (name.family) {
    fullName += name.family + ' ';
  }

  // Add suffix, if it exists
  if (name.suffix && name.suffix.length > 0) {
    fullName += name.suffix[0];
  }

  return fullName.trim();
};

export function isCapitalized(word: string | undefined): boolean {
  return word?.charAt(0) !== undefined && word.charAt(0) === capitalize(word.charAt(0));
}

export const fhirResourceCsvDownloadFields = (resourceType: string, resource: FhirResource): any => {
  switch (resourceType) {
    case 'Patient':
      return {
        id: resource.id,
        lastUpdated: resource.meta?.lastUpdated,
        // @ts-expect-error is missing definitions for different types of fhirResource.
        name: (resource?.name && constructName(resource?.name[0])) || '',
        // @ts-expect-error is missing definitions for different types of fhirResource.
        gender: resource.gender ?? '',
        // @ts-expect-error is missing definitions for different types of fhirResource.
        birthDate: resource.birthDate ?? '',
      };
    case 'Practitioner':
      return {
        id: resource.id,
        lastUpdated: resource.meta?.lastUpdated,
        // @ts-expect-error is missing definitions for different types of fhirResource.
        name: (resource?.name && constructName(resource?.name[0])) || '',
        // @ts-expect-error is missing definitions for different types of fhirResource.
        gender: resource.gender ?? '',
        // @ts-expect-error is missing definitions for different types of fhirResource.
        birthDate: resource.birthDate ?? '',
      };
    case 'Medication':
      return {
        id: resource.id,
        lastUpdated: resource.meta?.lastUpdated,
      };
    case 'Schedule':
      return {
        id: resource.id,
        lastUpdated: resource.meta?.lastUpdated,
      };
    case 'Slot':
      return {
        id: resource.id,
        lastUpdated: resource.meta?.lastUpdated,
      };
    default:
      return {
        id: resource.id,
        lastUpdated: resource.meta?.lastUpdated,
      };
  }
};

export function formatPeriod(period: Period | undefined): string {
  if (!period || (!period.start && !period.end)) {
    return '';
  }
  return formatDateTime(period.start) + ' - ' + formatDateTime(period.end);
}

export function formatDateTime(dateTime: string | undefined, options?: Intl.DateTimeFormatOptions): string {
  if (!dateTime) {
    return '';
  }
  const d = new Date(dateTime);
  if (!isValidDate(d)) {
    return '';
  }
  return d.toLocaleString(undefined, options);
}

export function formatDateTimeUTC(timestamp: number): string {
  const d = new Date(timestamp);
  if (!isValidDate(d)) {
    return '';
  }
  return d.toISOString().replace('T', ' ');
}

export function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export interface HumanNameFormatOptions {
  all?: boolean;
  prefix?: boolean;
  suffix?: boolean;
  use?: boolean;
}

export function formatHumanName(name: HumanName, options?: HumanNameFormatOptions): string {
  const builder = [];

  if (name.prefix && options?.prefix !== false) {
    builder.push(...name.prefix);
  }

  if (name.given) {
    builder.push(...name.given);
  }

  if (name.family) {
    builder.push(name.family);
  }

  if (name.suffix && options?.suffix !== false) {
    builder.push(...name.suffix);
  }

  if (name.text) {
    builder.push(name.text);
  }

  if (name.use && (options?.all || options?.use)) {
    builder.push('[' + name.use + ']');
  }

  if (name.period && (options?.all || options?.use)) {
    builder.push('[' + formatPeriod(name.period) + ']');
  }

  return builder.join(' ').trim();
}

export interface AddressFormatOptions {
  all?: boolean;
  use?: boolean;
}

export function formatAddress(address: Address, options?: AddressFormatOptions): string {
  const builder = [];

  if (address.line) {
    builder.push(...address.line);
  }

  if (address.city) {
    builder.push(address.city);
  }

  if (address.district) {
    builder.push(address.district);
  }

  if (address.state) {
    builder.push(address.state);
  }

  if (address.postalCode) {
    builder.push(address.postalCode);
  }

  if (address.text) {
    builder.push(address.text);
  }

  if (address.use && (options?.all || options?.use)) {
    builder.push('[' + address.use + ']');
  }

  if (address.period) {
    builder.push('[' + formatPeriod(address.period) + ']');
  }

  return builder.join(', ').trim();
}

const unitAdverbForm: Record<string, string> = {
  s: 'every second',
  min: 'every minute',
  h: 'hourly',
  d: 'daily',
  wk: 'weekly',
  mo: 'monthly',
  a: 'annually',
};

const singularUnits: Record<string, string> = {
  s: 'second',
  min: 'minute',
  h: 'hour',
  d: 'day',
  wk: 'week',
  mo: 'month',
  a: 'year',
};

const pluralUnits: Record<string, string> = {
  s: 'seconds',
  min: 'minutes',
  h: 'hours',
  d: 'days',
  wk: 'weeks',
  mo: 'months',
  a: 'years',
};

export function formatTiming(timing: Timing | undefined): string {
  if (!timing) {
    return '';
  }

  const builder: string[] = [];

  if (timing.repeat?.periodUnit) {
    const frequency = timing.repeat.frequency || 1;
    const period = timing.repeat.period || 1;
    const periodUnit = timing.repeat.periodUnit;

    if (frequency === 1 && period === 1) {
      builder.push(unitAdverbForm[periodUnit]);
    } else {
      if (frequency === 1) {
        builder.push('once');
      } else {
        builder.push(frequency + ' times');
      }

      if (period === 1) {
        builder.push('per ' + singularUnits[periodUnit]);
      } else {
        builder.push('per ' + period + ' ' + pluralUnits[periodUnit]);
      }
    }

    if (timing.repeat.dayOfWeek) {
      builder.push('on ' + timing.repeat.dayOfWeek.map(capitalize).join(', '));
    }

    if (timing.repeat.timeOfDay) {
      builder.push('at ' + timing.repeat.timeOfDay.map((t) => formatTime(t)).join(', '));
    }
  }

  if (timing.event) {
    builder.push(timing.event.map((d) => formatDateTime(d)).join(', '));
  }

  return capitalize(builder.join(' ').trim());
}

export function formatTime(time: string | undefined, options?: Intl.DateTimeFormatOptions): string {
  if (!time) {
    return '';
  }
  const d = new Date('2000-01-01T' + time + 'Z');
  if (!isValidDate(d)) {
    return '';
  }
  return d.toLocaleTimeString(undefined, options);
}
