import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGridPro, GridRowId, GridToolbarContainer } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { MemberItem, MemberType } from '../../../../lib/types';

interface MembersTableProps {
  members: Record<MemberType, MemberItem[]>;
}

const columns = [
  {
    field: 'id',
    width: 150,
  },
  {
    field: 'name',
    flex: 0.5,
  },
  {
    field: 'profile',
    headerName: 'FHIR profile',
    flex: 1,
  },
];

export const RoleMembersTable: React.FC<MembersTableProps> = ({ members }) => {
  const [currentType, setCurrentType] = useState<MemberType>('developer');
  const [rowSelectionModel, setRowSelectionModel] = useState<Array<GridRowId>>([]);

  const rows = useMemo(() => {
    const data = members[currentType];
    return data ?? [];
  }, [currentType, members]);

  return (
    <Box sx={{ width: '100%', height: '350px' }}>
      <DataGridPro
        columns={columns}
        rows={rows}
        onSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        selectionModel={rowSelectionModel}
        components={{ Toolbar: MembersTableHeader }}
        componentsProps={{ toolbar: { type: currentType, toggleType: setCurrentType } }}
      />
    </Box>
  );
};

interface HeaderProps {
  type: MemberType;
  toggleType: (type: MemberType) => void;
}

const MembersTableHeader: React.FC<HeaderProps> = ({ type, toggleType }) => {
  return (
    <GridToolbarContainer>
      <ToggleButtonGroup
        value={type}
        exclusive
        onChange={(_, newValue) => {
          if (newValue) {
            toggleType(newValue);
          }
        }}
      >
        <ToggleButton value="developer">Developer Users</ToggleButton>
        <ToggleButton value="user">Application Users</ToggleButton>
        <ToggleButton value="m2m">Machine-to-Machine Clients</ToggleButton>
      </ToggleButtonGroup>
    </GridToolbarContainer>
  );
};
