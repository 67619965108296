import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface TextCardWithButtonInput {
  action?: () => void;
  loading?: boolean;
  buttonTitle: string;
  description: string;
  title: string;
  variant: 'danger' | 'warning';
}

export function TextCardWithButton({
  action,
  loading,
  buttonTitle,
  description,
  title,
  variant,
}: TextCardWithButtonInput): JSX.Element {
  const theme = useTheme();
  return (
    <Paper>
      <Grid container direction={'row'} padding={2}>
        <Grid item xs={10}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={2} display={'flex'} alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
          <LoadingButton
            variant="contained"
            color={variant == 'danger' ? 'error' : 'warning'}
            onClick={action}
            loading={loading}
          >
            {buttonTitle}
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
