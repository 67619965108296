import { Logout, ManageAccounts, Person } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { PageContainer } from '../../components/PageContainer';

export const profileSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Dashboard',
      icon: <Person />,
      path: '/profile',
    },
    {
      label: 'Update Profile',
      icon: <ManageAccounts />,
      path: '/profile/update',
    },
  ],
  [
    {
      label: 'Logout',
      icon: <Logout />,
      path: '/logout',
    },
  ],
];

export function ProfilePage(): JSX.Element {
  return (
    <PageContainer sidebarItems={profileSidebarItems}>
      <div>
        <Grid container>
          <Typography variant="h4">Profile</Typography>
        </Grid>
      </div>
    </PageContainer>
  );
}
