import { ArrowForward } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import * as React from 'react';
import { HomePageInfoCard } from './HomePageInfoCard';

export interface HomePageResourceCardProps {
  label: string;
  description: string;
  path: string;
  icon?: JSX.Element;
  children?: JSX.Element;
}

export function HomePageResourceCard(props: HomePageResourceCardProps): JSX.Element {
  const theme = useTheme();
  return (
    <HomePageInfoCard
      icon={props.icon}
      iconAtTheEnd={<ArrowForward sx={{ width: '24px', height: '24px', color: theme.palette.primary.light }} />}
      label={props.label}
      description={props.description}
      path={props.path}
    >
      {props.children}
    </HomePageInfoCard>
  );
}
