import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { Await, Link as ReactRouterLink, useParams, useRouteLoaderData } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { formatDateTimeUTC } from '../../../../helpers';
import { LogEvent, Zambda } from '../../../../lib/client';
import { assert } from '../../../../lib/utils';
import { ZambdaDetailLoaderData } from '../ZambdaDetail';

interface RowProps {
  logEvent: LogEvent;
}

interface PageState {
  readonly events: LogEvent[];
  readonly nextBackwardToken?: string;
  readonly nextForwardToken?: string;
  readonly initialLoading: boolean;
  readonly loadingOldEvents: boolean;
  readonly loadingNewEvents: boolean;
}

export const ZambdaLogEvents: FC = () => {
  const { id, logStreamName } = useParams();
  const data = useRouteLoaderData('zambda-detail') as ZambdaDetailLoaderData;
  const [state, setState] = useState<PageState>({
    events: [],
    initialLoading: true,
    loadingOldEvents: false,
    loadingNewEvents: false,
  });

  useEffect(() => {
    const fetchInitialLogEvents = async (): Promise<void> => {
      assert(id, 'No Zambda ID in request url');
      assert(logStreamName, 'No log stream name in request url');
      let nextToken: string | undefined = undefined;
      const forwardTokens: string[] = [];
      const backwardTokens: string[] = [];
      const events: LogEvent[] = [];
      while (events.length === 0) {
        // An explicit type cast is required here because the value of `nextToken` changes
        // in the loop. TypeScript recognizes that and flags it as a circular dependency.
        const response: Awaited<ReturnType<typeof zapehr.project.zambda.logStream.get>> =
          await zapehr.project.zambda.logStream.get({
            id,
            logStreamName: decodeURIComponent(logStreamName),
            nextToken,
          });
        events.push(...response.logEvents);
        forwardTokens.push(response.nextForwardToken as string);
        backwardTokens.push(response.nextBackwardToken as string);
        if (nextToken === response.nextBackwardToken) {
          break;
        }
        nextToken = response.nextBackwardToken;
      }
      setState((state) => {
        return {
          ...state,
          events: events,
          nextBackwardToken: backwardTokens.pop(),
          nextForwardToken: forwardTokens[0],
          initialLoading: false,
        };
      });
    };
    void fetchInitialLogEvents();
  }, [id, logStreamName]);

  const renderLogEvents = useCallback((): JSX.Element => {
    if (state.initialLoading) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={25} />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {state.events.map((event) => (
          <Row key={event.timestamp} logEvent={event} />
        ))}
      </>
    );
  }, [state.initialLoading, state.events]);

  const loadMoreOldEvents = useCallback(async (): Promise<void> => {
    assert(id, 'No Zambda ID in request url');
    assert(logStreamName, 'No log stream name in request url');
    setState((state) => {
      return {
        ...state,
        loadingOldEvents: true,
      };
    });
    let nextToken = state.nextBackwardToken;
    const events: LogEvent[] = [];
    while (nextToken) {
      const response = await zapehr.project.zambda.logStream.get({
        id,
        logStreamName: decodeURIComponent(logStreamName),
        nextToken,
      });
      if (nextToken !== response.nextBackwardToken) {
        nextToken = response.nextBackwardToken;
      } else {
        nextToken = undefined;
      }
      if (response.logEvents && response.logEvents.length > 0) {
        events.push(...response.logEvents);
        break;
      }
    }
    setState((state) => {
      return {
        ...state,
        events: events.concat(state.events),
        nextBackwardToken: nextToken,
        loadingOldEvents: false,
      };
    });
    return;
  }, [state.nextBackwardToken, id, logStreamName]);

  const renderLoadOldEventsSection = useCallback((): JSX.Element | null => {
    if (!state.nextBackwardToken) {
      return null;
    }
    return (
      <TableRow>
        <TableCell colSpan={2}></TableCell>
        <TableCell>
          {state.loadingOldEvents ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ marginRight: '8px' }} /> Loading older events.
            </Box>
          ) : (
            <>
              There are older events to load.{' '}
              <Link onClick={loadMoreOldEvents} underline="hover" href="#">
                Load more.
              </Link>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  }, [state.nextBackwardToken, state.loadingOldEvents, loadMoreOldEvents]);

  const loadMoreNewEvents = useCallback(async (): Promise<void> => {
    assert(id, 'No Zambda ID in request url');
    assert(logStreamName, 'No log stream name in request url');
    setState((state) => {
      return {
        ...state,
        loadingNewEvents: true,
      };
    });
    const response = await zapehr.project.zambda.logStream.get({
      id,
      logStreamName: decodeURIComponent(logStreamName),
      nextToken: state.nextForwardToken,
    });
    setState((state) => {
      return {
        ...state,
        events: state.events.concat(response.logEvents ?? []),
        nextForwardToken: response.nextForwardToken,
        loadingNewEvents: false,
      };
    });
    return;
  }, [state.nextForwardToken, id, logStreamName]);

  const renderLoadNewEventsSection = useCallback((): JSX.Element | null => {
    if (!state.nextForwardToken) {
      return null;
    }
    return (
      <TableRow>
        <TableCell colSpan={2}></TableCell>
        <TableCell>
          {state.loadingNewEvents ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ marginRight: '8px' }} /> Loading new events.
            </Box>
          ) : (
            <Link onClick={loadMoreNewEvents} underline="hover" href="#">
              Load new events.
            </Link>
          )}
        </TableCell>
      </TableRow>
    );
  }, [state.nextForwardToken, state.loadingNewEvents, loadMoreNewEvents]);

  return (
    <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
      <Await resolve={data.zambda} errorElement={<RootErrorBoundary />}>
        {(zambda: Zambda) => {
          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ReactRouterLink to={`/zambdas/${id}/logs`}>Back to all logs</ReactRouterLink>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" color="text.primary">
                  Logs of {zambda.name} / {decodeURIComponent(logStreamName as string)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minHeight: '60vh',
                      tableLayout: 'fixed',
                      td: { paddingBottom: 0, paddingTop: 0 },
                      tr: { height: '40px' },
                    }}
                    aria-label="collapsible table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width={'50px'} sx={{ fontWeight: 600 }}></TableCell>
                        <TableCell width={'300px'} sx={{ fontWeight: 600 }}>
                          Timestamp
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {renderLoadOldEventsSection()}
                      {renderLogEvents()}
                      {renderLoadNewEventsSection()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <ReactRouterLink to={`/zambdas/${id}/logs`}>Back to all logs</ReactRouterLink>
              </Grid>
            </Grid>
          );
        }}
      </Await>
    </Suspense>
  );
};

const renderMessage = (message: string): JSX.Element => {
  const lines = message.split('\n');
  return (
    <>
      {lines.map((line, index) => {
        try {
          const filteredLine = line.replaceAll('\n', '').replaceAll('\t', '');
          const obj = JSON.parse(filteredLine);
          return (
            <SyntaxHighlighter
              wrapLines={true}
              language="json"
              lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
              style={docco}
              customStyle={{
                backgroundColor: '#fff',
              }}
              key={index}
            >
              {JSON.stringify(obj, null, 2)}
            </SyntaxHighlighter>
          );
        } catch (e: any) {
          return (
            <div key={index} style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
              {line}
            </div>
          );
        }
      })}
    </>
  );
};

export const Row = (props: RowProps): JSX.Element => {
  const { logEvent } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <TableRow>
        <TableCell sx={{ borderBottom: 0, fontFamily: 'monospace' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} sx={{ marginRight: '16px' }}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ borderBottom: 0, fontFamily: 'monospace' }}>{formatDateTimeUTC(logEvent.timestamp)}</TableCell>
        <TableCell
          sx={{
            borderBottom: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingTop: 0,
            paddingBottom: 0,
            fontFamily: 'monospace',
          }}
        >
          {logEvent.message.substring(0, 400)}
        </TableCell>
      </TableRow>
      <TableRow style={{ height: 0 }}>
        <TableCell style={{ wordBreak: 'break-all', fontFamily: 'monospace' }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ paddingBottom: '16px' }}>{renderMessage(logEvent.message)}</div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
