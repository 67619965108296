import { Box, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';

export interface DocumentProps {
  width?: number;
  children?: ReactNode;
}
// todo: use the actual palette
const palette = {
  surface: '#ffffff',
  border: '#e0e0e0',
  shadow: 'rgba(0, 0, 0, 0.1)',
};

export function Document(props: DocumentProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        margin: isMobile ? '10px auto 6px auto' : '20px auto 16px auto',
        padding: isMobile ? '6px' : '10px',
        height: '100%',
        clear: 'both',
        textAlign: 'center',

        '& h1, h2, h3': {
          margin: '12px 0',
        },

        '& article': {
          backgroundColor: palette.surface,
          maxWidth: '900px',
          margin: '8px auto 16px auto',
          padding: isMobile ? ' 6px 12px' : '15px 25px',
          textAlign: 'left',
          border: palette.border,
          borderRadius: '8px',
          boxShadow: `0 1px 3px 0 ${palette.shadow}`,
        },

        '& textarea': {
          margin: '0px',
          minHeight: '100px',
          width: '100%',
        },

        '& img, video': {
          width: '100%',
        },
      }}
    >
      <article style={{ maxWidth: props.width }}>{props.children}</article>
    </Box>
  );
}
