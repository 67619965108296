import * as React from 'react';
import { Link } from 'react-router-dom';
import { Reference } from '../lib/fhir-types';
import { stringify } from '../lib/niftyFunctions';

export interface ReferenceDisplayProps {
  value?: Reference;
  link?: boolean;
}

export function ReferenceDisplay(props: ReferenceDisplayProps): JSX.Element | null {
  if (!props.value) {
    return null;
  }

  const displayString = props.value.display || props.value.reference || stringify(props.value);

  // The "link" prop defaults to "true"; undefined is treated as "true"
  // To disable the link, it must be explicitly "false"
  if (props.link !== false && props.value.reference) {
    return <Link to={`/resources/${props.value.reference}`}>{displayString}</Link>;
  } else {
    return <>{displayString}</>;
  }
}
