import { ElementDefinitionBinding, ElementDefinitionType, QuestionnaireResponse } from './fhir-types';

export interface TypeResolution {
  isArray: boolean;
  isRequired: boolean;
  type: ElementDefinitionType;
  binding: ElementDefinitionBinding | undefined;
  isUnresolved: boolean;
}

export interface PropertyTypeInfo {
  propertyValue: any;
  propertyType: PropertyType;
}

export enum SearchParameterType {
  Number = 'number',
  Date = 'date',
  String = 'string',
  Token = 'token',
  Reference = 'reference',
  Composite = 'composite',
  Quantity = 'quantity',
  Uri = 'uri',
  Special = 'special',
}

export enum PropertyType {
  Address = 'Address',
  Age = 'Age',
  Annotation = 'Annotation',
  Attachment = 'Attachment',
  BackboneElement = 'BackboneElement',
  CodeableConcept = 'CodeableConcept',
  Coding = 'Coding',
  ContactDetail = 'ContactDetail',
  ContactPoint = 'ContactPoint',
  Contributor = 'Contributor',
  Count = 'Count',
  DataRequirement = 'DataRequirement',
  Distance = 'Distance',
  Dosage = 'Dosage',
  Duration = 'Duration',
  Expression = 'Expression',
  Extension = 'Extension',
  HumanName = 'HumanName',
  Identifier = 'Identifier',
  MarketingStatus = 'MarketingStatus',
  Meta = 'Meta',
  Money = 'Money',
  Narrative = 'Narrative',
  ParameterDefinition = 'ParameterDefinition',
  Period = 'Period',
  Population = 'Population',
  ProdCharacteristic = 'ProdCharacteristic',
  ProductShelfLife = 'ProductShelfLife',
  Quantity = 'Quantity',
  Range = 'Range',
  Ratio = 'Ratio',
  Reference = 'Reference',
  RelatedArtifact = 'RelatedArtifact',
  SampledData = 'SampledData',
  Signature = 'Signature',
  SubstanceAmount = 'SubstanceAmount',
  SystemString = 'http://hl7.org/fhirpath/System.String',
  Timing = 'Timing',
  TriggerDefinition = 'TriggerDefinition',
  UsageContext = 'UsageContext',
  base64Binary = 'base64Binary',
  boolean = 'boolean',
  canonical = 'canonical',
  code = 'code',
  date = 'date',
  dateTime = 'dateTime',
  decimal = 'decimal',
  id = 'id',
  instant = 'instant',
  integer = 'integer',
  markdown = 'markdown',
  oid = 'oid',
  positiveInt = 'positiveInt',
  string = 'string',
  time = 'time',
  unsignedInt = 'unsignedInt',
  uri = 'uri',
  url = 'url',
  uuid = 'uuid',
}

export interface InputField {
  name: string;
  label: string;
  helperText?: string;
  required?: boolean;
  maxLength?: number;
  defaultValue?: string;
  placeholder?: string;
  multiline?: boolean;
  minRows?: number;
  list?: boolean;
  createOnly?: boolean; // field only used for create
  type?: 'string' | 'access policy';
  disabled?: boolean;
  isSelect?: boolean;
  json?: boolean;
  options?: string[];
}

export type QuestionnaireResponseInput = Omit<QuestionnaireResponse, 'status'>;

export interface TypedValue {
  readonly type: string;
  readonly value: any;
}

export enum ZambdaState {
  Active = 'Active',
  Draft = 'Draft',
  Failed = 'Failed',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Validating = 'Validating Code File',
}

export enum FetchState {
  needsFetch,
  pending,
  finished,
}

export interface PathHistoryBreadcrumb {
  text: string;
  from: string;
}

export enum TriggerMethodType {
  cron = 'cron',
  http_auth = 'http_auth',
  http_open = 'http_open',
  subscription = 'subscription',
}

export interface AutocompleteOption {
  label: string; // for display
  subtext?: string; // for display
  path?: string;
  group?: string;
  value: string; // for logical id
}

export interface IAMRoleListItem {
  id: string;
  name: string;
  memberCount: number;
}

export interface MemberItem {
  id: string;
  name?: string;
  profile?: string;
}

export type MemberType = 'developer' | 'user' | 'm2m';

export interface Rule {
  action: string | string[];
  resource: string | string[];
  effect: 'Deny' | 'Allow';
  condition?: object | object[] | string | string[];
}

export interface AccessPolicy {
  rule: Rule | Rule[];
}

export interface IAMRole {
  id: string;
  name: string;
  description?: string;
  accessPolicy?: AccessPolicy;
}

export type IAMRolePutPostParams = Omit<IAMRole, 'id|members'>;
