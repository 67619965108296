import { defer as rrDefer } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidJSON } from '../../components/AccessPolicyInput';
import { InputField } from '../types';

export const updateListFieldInput = (data: any, fields: InputField[]): any => {
  // make a dictionary with name as the key and field as the value
  const fieldsDict = Object.fromEntries(fields.map((field) => [field.name, field]));

  // make a list from list fields
  return Object.fromEntries(
    Object.keys(data).map((item: any) => {
      const fieldTemp = fieldsDict[item];
      if (!data[item]) {
        return [item, undefined];
      }
      if (fieldTemp.list) {
        return [item, data[item].split(',')];
      }
      if (fieldTemp.json && isValidJSON(data[item])) {
        return [item, JSON.parse(data[item])];
      }
      return [item, data[item]];
    })
  );
};

export const copyToClipboard = (textToCopy: string, onSuccessMessage: string): void => {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      toast.success(onSuccessMessage);
    })
    .catch((err) => {
      throw err;
    });
};

export const getValueForInputField = (obj: any, field: InputField): string => {
  const value = obj[field.name];
  if (field.type === 'access policy') {
    return JSON.stringify(value, null, 2);
  }

  if (field.list) {
    if (Array.isArray(value)) {
      return value.join(',');
    } else {
      console.error(`Unexpectedly found non-array value for list field ${field.name}`);
      return '';
    }
  }

  if (typeof value !== 'string') {
    console.error(`Unexpectedly found non-string value for field ${field.name}`);
    return '';
  }
  return value;
};

export function commaSeparatedStringToArray(maybeString?: string): string[] {
  return (
    maybeString
      ?.trim()
      .split(',')
      .filter((item) => !!item) ?? []
  );
}

export function prettyJSON(json: object | undefined): string {
  return JSON.stringify(json, null, 2);
}

/**
 * Asserts something is truthy, defined, etc.
 * @param condition anything that resolves truthy or falsy
 * @param message optional error message if assert fails
 */
export function assert(condition: any, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

/**
 * Wrapper for react-router-dom's `defer` function that gives some type safety. Usage:
 *
 * ```ts
 * type Data = {
 *   applications: ReturnType<typeof zapehr.project.applications.list>;
 * };
 * return defer<Data>({
 *  applications: zapehr.project.applications.list(),
 * })
 * ```
 * @param obj Valid input for react-router-dom's `defer`
 * @returns react-router-dom DeferredData, which the package does not export directly
 */
export function defer<T extends Parameters<typeof rrDefer>[0]>(obj: T): ReturnType<typeof rrDefer> {
  return rrDefer(obj);
}
