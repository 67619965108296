import { LicenseInfo } from '@mui/x-license-pro';
import * as React from 'react';
import { useCallback } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useZapehr } from './components';
import { RootErrorBoundary } from './components/RootErrorBoundary';
import { Login } from './pages/Login';
import Callback from './pages/LoginCallback';

const MUI_X_LICENSE_KEY = process.env.MUI_X_LICENSE_KEY;
if (MUI_X_LICENSE_KEY != null) {
  LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);
}

export function UnauthenticatedApp(): JSX.Element {
  const { initializationError, authError } = useZapehr();

  const loader = useCallback(() => {
    if (initializationError) {
      throw initializationError;
    }
    if (authError) {
      throw authError;
    }
    return null;
  }, [authError, initializationError]);

  const router = createBrowserRouter([
    {
      path: '/*',
      element: <Login />,
      errorElement: <RootErrorBoundary />,
      loader,
    },
    { path: '/callback', element: <Callback />, errorElement: <RootErrorBoundary />, loader },
  ]);

  return <RouterProvider router={router} />;
}
