import { capitalize } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../helpers';
import { Resource } from '../lib/fhir-types';
import { getProperties, PropertyDefinition, TypesSchema } from '../lib/schema';
import { Services } from '../services';
import { HumanNameDisplay } from './HumanNameDisplay';
import { ReferenceDisplay } from './ReferenceDisplay';

export interface SearchTableFragmentProps {
  resource: Resource;
  field: string;
  typesSchema: TypesSchema;
}

export function SearchTableFragment(props: SearchTableFragmentProps): JSX.Element {
  const { resource, field, typesSchema } = props;

  if (field === 'id') {
    return <Link to={`/${Services.fhir.rootPath}/${resource.resourceType}/${resource.id}`}>{resource.id}</Link>;
  }

  if (field === 'lastUpdated') {
    return <>{formatDateTime(resource.meta?.lastUpdated)}</>;
  }

  const property = getProperty(resource, field, typesSchema);
  return renderPropertyValue(property);
}

function renderPropertyValue(property: TypedValue | undefined): JSX.Element {
  if (!property) {
    return <></>;
  }

  const { value, propertyDefinition } = property;

  if (!value) {
    return <></>;
  }

  if (propertyDefinition.isArray) {
    if (!value) {
      return <></>;
    }
    return (
      <div>
        {(value as any[]).map((val, index) =>
          renderPropertyValue({
            value: val,
            propertyDefinition: {
              ...propertyDefinition,
              isArray: false,
              path: `${propertyDefinition.path}.${index}`,
            },
          })
        )}
      </div>
    );
  }

  switch (propertyDefinition.type) {
    case 'boolean':
    case 'SystemString':
    case 'code':
    case 'date':
    case 'integer':
    case 'positiveInt':
    case 'string':
    case 'unsignedInt':
    case 'uri':
    case 'url':
    case 'canonical':
      return <div>{value === undefined ? '' : value}</div>;
    case 'dateTime':
    case 'instant':
      return <div>{formatDateTime(value)}</div>;
    case 'HumanName':
      return <HumanNameDisplay value={value} />;
    case 'Reference':
      return <ReferenceDisplay value={value} link={false} />;
  }

  return <></>;
}

interface TypedValue {
  value: any;
  propertyDefinition: PropertyDefinition;
}

function getProperty(resource: Resource, property: string, typesSchema: TypesSchema): TypedValue | undefined {
  const propertyDefinitions = getProperties(resource.resourceType, typesSchema);

  if (property.endsWith('[x]')) {
    const prefix = property.replace('[x]', '');
    const propertyDefinition = propertyDefinitions.find(
      (propertyDefinition) => propertyDefinition.choiceTypes && propertyDefinition.name.startsWith(prefix)
    );
    if (propertyDefinition === undefined) {
      return undefined;
    }
    const type = propertyDefinition.choiceTypes
      ?.map((type) => type.name)
      .find((type) => (resource as any)[prefix + capitalize(type)] !== undefined);
    if (type === undefined) {
      return undefined;
    }
    return {
      value: (resource as any)[prefix + capitalize(type)],
      propertyDefinition: {
        ...propertyDefinition,
        choiceTypes: undefined,
        type: type,
      },
    };
  }

  const propertyDefinition = propertyDefinitions.find((propertyDefinition) => propertyDefinition.name === property);
  if (propertyDefinition === undefined) {
    return undefined;
  }
  return {
    value: (resource as any)[property],
    propertyDefinition: propertyDefinition,
  };
}
