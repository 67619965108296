import { Add } from '@mui/icons-material';
import { alpha, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { FC, Suspense, useState } from 'react';
import { Await, Link, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { Secret } from '../../../lib/client';
import { Services } from '../../../services';

export const SecretsPage: FC = () => {
  const secretsPromise = useLoaderData() as { secrets: Promise<Secret[]> };
  return (
    <Suspense fallback={<SecretsHome secrets={[]} loading />}>
      <Await resolve={secretsPromise.secrets} errorElement={<RootErrorBoundary />}>
        {(secrets: Secret[]) => {
          return <SecretsHome secrets={secrets} loading={false} />;
        }}
      </Await>
    </Suspense>
  );
};

interface SecretsHomeProps {
  secrets: Secret[];
  loading: boolean;
}

const SecretsHome: React.FC<SecretsHomeProps> = ({ secrets, loading }) => {
  const { pathname } = useLocation();
  const [pageSize, setPageSize] = useState(10);
  return pathname === `/${Services.zambdas.rootPath}/secrets` ? (
    <Grid container>
      <Grid item xs={10} display="flex" marginBottom={2}>
        <Typography variant="h4">Secrets</Typography>
        <ArticleShortcutIconButton link="https://docs.oystehr.com/services/zambda/secrets/" />
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <Link to="new">
          <Button aria-label="new" variant="contained" startIcon={<Add />}>
            New Secret
          </Button>
        </Link>
      </Grid>
      <Typography>
        Here, you can view and manage your{' '}
        <MUILink href={'https://docs.oystehr.com/services/zambda/secrets/'} target="_blank">
          Secrets
        </MUILink>
        .
      </Typography>
      <Grid item xs={12}>
        <Paper sx={{ height: '60vh', marginTop: 4 }}>
          <DataGridPro
            loading={loading}
            columns={[
              {
                field: 'key',
                headerName: 'Key',
                flex: 1,
                renderCell: (params) => <Link to={params.row.id}>{params.row.key}</Link>,
              },
              // { field: 'created', headerName: 'Created', flex: 1 },
              // { field: 'status', headerName: 'Status', flex: 1 },
            ]}
            rows={secrets.map((secret: Secret) => ({
              id: secret.name,
              key: secret.name,
            }))}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[10, 25, 100]}
            sx={{
              '& .MuiDataGrid-toolbarContainer': {
                pl: 1.5,
                gap: 4,
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                '& .MuiButtonBase-root': {
                  color: otherColors.charcoal87,
                },
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
              },
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Outlet />
  );
};
