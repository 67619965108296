import { capitalize } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useMemo } from 'react';
import { useZapehr } from '../../contexts/ZapehrProvider';
import { getProperties, getSchema, PropertyDefinition } from '../../lib/schema';
import { PropertyInputProps, TypedValue } from './common';
import { PropertyInfoWrapper } from './PropertyInfoWrapper';
import { PropertyInput } from './PropertyInput';

export interface ComplexPropertyInputProps extends PropertyInputProps {
  showInfo?: boolean;
}

export function ComplexPropertyInput(props: ComplexPropertyInputProps): JSX.Element {
  const { propertyDefinition, value, onChange, showInfo } = props;
  const { currentProject } = useZapehr();
  const schema = getSchema(currentProject?.fhirVersion);

  const childPropertyDefinitions = useMemo(() => {
    return getProperties(propertyDefinition.type, schema);
  }, [propertyDefinition.type, schema]);

  const renderProperty = (property: JSX.Element, definition: PropertyDefinition): JSX.Element => {
    if (showInfo) {
      return (
        <PropertyInfoWrapper
          key={`${definition.path}-childOf-${propertyDefinition.path}-wrapper`}
          propertyDefinition={definition}
        >
          {property}
        </PropertyInfoWrapper>
      );
    } else {
      return property;
    }
  };

  return (
    <Box
      className={propertyDefinition.type}
      style={
        needShowLeftBorder(propertyDefinition)
          ? {
              borderLeftColor: 'lightgray',
              borderLeftWidth: '1px',
              borderLeftStyle: 'dashed',
              paddingLeft: '15px',
            }
          : {}
      }
    >
      {childPropertyDefinitions.map((childPropertyDefinition) => {
        return renderProperty(
          <div
            key={`${childPropertyDefinition.path}-childOf-${propertyDefinition.path}`}
            className={propertyDefinition.type + '-' + childPropertyDefinition.name}
            style={{ width: '100%' }}
          >
            <PropertyInput
              propertyDefinition={{
                ...childPropertyDefinition,
                targetTypes: childPropertyDefinition.targetTypes ?? propertyDefinition.targetTypes,
              }}
              value={getPropertyValue(childPropertyDefinition, value?.value)}
              onChange={(newValue) => {
                const updatedValue = { ...value?.value };
                if (childPropertyDefinition.choiceTypes !== undefined) {
                  const propertyPrefix = childPropertyDefinition.name.replace('[x]', '');
                  for (const type of childPropertyDefinition.choiceTypes) {
                    updatedValue[propertyPrefix + capitalize(type.name)] = undefined;
                  }
                  updatedValue[propertyPrefix + capitalize(newValue.type as string)] = newValue.value;
                } else {
                  updatedValue[childPropertyDefinition.name] = newValue.value;
                }
                onChange({
                  value: updatedValue,
                  type: propertyDefinition.type,
                });
              }}
            />
          </div>,
          childPropertyDefinition
        );
      })}
    </Box>
  );
}

function getPropertyValue(propertyDefinition: PropertyDefinition, value?: any): TypedValue | undefined {
  if (propertyDefinition.choiceTypes === undefined) {
    const val = value?.[propertyDefinition.name];
    return val
      ? {
          value: val,
          type: propertyDefinition.type,
        }
      : undefined;
  } else {
    const propertyPrefix = propertyDefinition.name.replace('[x]', '');
    for (const type of propertyDefinition.choiceTypes) {
      const val = value?.[propertyPrefix + capitalize(type.name)];
      if (val !== undefined) {
        return {
          value: val,
          type: type.name,
        };
      }
    }
    return undefined;
  }
}

function needShowLeftBorder(propertyDefinition: PropertyDefinition): boolean {
  return propertyDefinition.type.includes('.');
}
