import { Box, SxProps, Theme } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useResourceEditingContext } from '../contexts/ResourceEditContextProvider';
import { OperationOutcome } from '../lib/fhir-types';
import { CustomTextField } from './WrappedInputs/CustomTextField';

export interface DateTimeInputProps {
  name?: string;
  placeholder?: string;
  value?: string;
  outcome?: OperationOutcome;
  displayOnly?: boolean;
  onChange?: (value: string) => void;
  inputWidth?: string;
  sx?: SxProps<Theme>;
}
/**
 * The DateTimeInput component is a wrapper around the HTML5 input type="datetime-local".
 * The main purpose is to reconcile time zones.
 * Most of our date/time values are in ISO-8601, which includes a time zone offset.
 * The datetime-local input does not support the time zone offset.
 * @param props The Input props.
 * @returns The JSX element to render.
 */
export function DateTimeInput(props: DateTimeInputProps): JSX.Element {
  const { editModeOn } = useResourceEditingContext();
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={navigator.language}>
      <Box>
        <DateTimePicker
          PopperProps={{
            disablePortal: true,
            popperOptions: { placement: 'auto', strategy: 'fixed' },
          }}
          renderInput={(inputProps) => (
            <CustomTextField
              {...inputProps}
              sx={{
                ...(props.sx ?? {}),
              }}
              value={convertIsoToLocal(inputProps.value as string)}
              displayOnly={!editModeOn}
            />
          )}
          label={props.name}
          value={convertIsoToLocal(props.value)}
          onChange={(newVal: DateTime | null): void => {
            if (props.onChange) {
              props.onChange(convertLocalToIso(newVal));
            }
          }}
          disabled={!editModeOn}
        />
      </Box>
    </LocalizationProvider>
  );
}

/**
 * Converts an ISO-8601 date/time string to a local date/time string.
 * @param isoString The ISO-8601 date/time string to convert.
 * @returns The local date/time string.
 */
export function convertIsoToLocal(isoString: string | undefined): string | null {
  if (!isoString) {
    return null;
  }

  // Convert the ISO-8601 date to a local datetime-local value.
  const date = DateTime.fromISO(isoString);
  if (!date.isValid) {
    return null;
  }

  return date.toISO({ includeOffset: false });
}

/**
 * Converts a local date/time string to an ISO-8601 date/time string.
 * @param localString The local date/time string to convert.
 * @returns The ISO-8601 date/time string.
 */
export function convertLocalToIso(localDateTime: DateTime | null): string {
  if (!localDateTime || !localDateTime.isValid) {
    // If the input is invalid was malformed, return an empty string.
    return '';
  }

  return localDateTime.toISO();
}
