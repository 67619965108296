import { ArrowForward } from '@mui/icons-material';
import { Button, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { otherColors } from '../contexts/AdminThemeProvider';

export interface ResourceSubCardProps {
  label: string;
  path: string;
}
export function ResourceSubCard(props: ResourceSubCardProps): JSX.Element {
  const theme = useTheme();
  return (
    <Link to={props.path} style={{ textDecoration: 'none', margin: 0 }}>
      <Button
        sx={{
          backgroundColor: otherColors.cornflowerBlue12,
          textTransform: 'none',
          px: theme.spacing(2),
          py: theme.spacing(0.5),
          mt: theme.spacing(1),
          ml: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '36px',
          borderRadius: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary }}>
          {props.label}
        </Typography>
        <ArrowForward sx={{ ml: '8px', color: theme.palette.primary.light }} />
      </Button>
    </Link>
  );
}
