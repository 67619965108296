import { Box, Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import * as React from 'react';

export function GridFilterCheckboxInputValue(props: GridFilterInputValueProps): JSX.Element {
  const { item, applyValue } = props;

  const handleFilterChange: CheckboxProps['onChange'] = (event, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '48px',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            inputProps={{ 'aria-label': 'Value' }}
            checked={item.value ?? false}
            onChange={handleFilterChange}
          />
        }
        label="Value"
        labelPlacement="top"
        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard css-184w6rj-MuiFormLabel-root-MuiInputLabel-root"
      />
    </Box>
  );
}
