import { AccountBalance, AddCard, Assessment, Calculate, CreditCard, Receipt, ReceiptLong } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { PageContainer } from '../../components/PageContainer';

export const billingSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Dashboard',
      icon: <AccountBalance />,
      path: '/billing',
    },
    {
      label: 'Billing Estimate',
      icon: <Calculate />,
      path: '/billing/estimate',
    },
  ],
  [
    {
      label: 'Bills',
      icon: <ReceiptLong />,
      path: '/billing/bills',
    },
    {
      label: 'Payments',
      icon: <Receipt />,
      path: '/billing/payments',
    },
  ],
  [
    {
      label: 'Payment Information',
      icon: <CreditCard />,
      path: '/billing/payment',
    },
    {
      label: 'Add Payment Method',
      icon: <AddCard />,
      path: '/billing/payment/add',
    },
  ],
  [
    {
      label: 'Analytics',
      icon: <Assessment />,
      path: '/billing/analytics',
    },
  ],
];

export function BillingPage(): JSX.Element {
  return (
    <PageContainer sidebarItems={billingSidebarItems}>
      <div>
        <Grid container>
          <Typography variant="h4">Billing</Typography>
        </Grid>
      </div>
    </PageContainer>
  );
}
