import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { formatDateTime } from '../../../../helpers';
import { BundleEntry, Resource } from '../../../../lib/fhir-types';
import { normalizeErrorString } from '../../../../lib/niftyFunctions';
import { Services } from '../../../../services';
import { useResourceFromOutletContext } from '..';

export function ResourceHistoryTable(): JSX.Element {
  const { resourceHistory } = useResourceFromOutletContext();

  const { versionId } = useParams() as {
    resourceType: string;
    id: string;
    versionId: string;
    tab: string;
  };

  if (versionId) {
    return <Outlet />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="diagnostic table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourceHistory.entry?.map((entry) => (
            <HistoryRow key={entry.resource?.meta?.versionId} entry={entry} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface HistoryRowProps {
  entry: BundleEntry;
}

function HistoryRow(props: HistoryRowProps): JSX.Element {
  const { response, resource } = props.entry;
  if (resource) {
    return (
      <TableRow>
        {/* todo: use provenance to determine "author"??*/}
        <TableCell>{formatDateTime(resource.meta?.lastUpdated)}</TableCell>
        <TableCell>
          <Link to={getVersionUrl(resource)}>{resource.meta?.versionId}</Link>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell colSpan={3}>{normalizeErrorString(response?.outcome)}</TableCell>
      </TableRow>
    );
  }
}

function getVersionUrl(resource: Resource): string {
  return `/${Services.fhir.rootPath}/${resource.resourceType}/${resource.id}/history/${resource.meta?.versionId}`;
}
