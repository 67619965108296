import { BaseTextFieldProps, InputProps, OutlinedInputProps, TextField, useTheme } from '@mui/material';
import * as React from 'react';

export interface CustomTextFieldProps extends BaseTextFieldProps {
  displayOnly?: boolean;
  onChange?: OutlinedInputProps['onChange'];
  InputProps?: Partial<OutlinedInputProps | InputProps>;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = (props: CustomTextFieldProps) => {
  const { displayOnly, InputProps } = props;
  const theme = useTheme();

  const passThroughProps = Object.fromEntries(Object.entries(props).filter((entry) => entry[0] !== 'displayOnly'));

  if (displayOnly) {
    return (
      <TextField
        {...passThroughProps}
        disabled
        variant="standard"
        sx={{
          ...(props.sx ?? {}),
          backgroundColor: 'rgba(40, 150, 198, 0.04)',
        }}
        InputProps={{
          ...(InputProps ?? {}),
          disableUnderline: true,
          endAdornment: '',
          sx: {
            paddingLeft: '5px',
            '.MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
              color: theme.palette.text.primary,
            },
            '.MuiSelect-icon.Mui-disabled': {
              display: 'none',
            },
          },
        }}
        SelectProps={{
          endAdornment: '',
        }}
      />
    );
  } else {
    return (
      <TextField
        {...passThroughProps}
        variant="outlined"
        sx={{
          ...(props.sx ?? {}),
        }}
      />
    );
  }
};
