export enum ErrorCode {
  // 4xx
  Unauthorized = '401',
  NoRequestBody = '4001',
  InvalidRequestBody = '4002',
  UserNotFound = '4003',
  ValidationError = '4006',
  NoRequestParams = '4007',
  InvalidQueryParams = '4008',
  InvalidPathParams = '4009',
  ResourceNotFound = '4014',
  AccessPolicyViolation = '4018',
  // 5xx
  InternalError = '5001',
}

export const InternalError = {
  code: ErrorCode.InternalError,
  message: 'An unknown error occurred',
};

export interface ApiError {
  readonly code: ErrorCode;
  readonly message: string;
}

export const isApiError = (v: any): boolean => {
  if (v == null) {
    return false;
  }

  if ((v as ApiError).code) {
    return true;
  } else {
    return false;
  }
};

export const isApiErrorOfType = (v: any, type: ErrorCode): boolean => {
  if (isApiError(v)) {
    return (v as ApiError).code === type;
  }
  return false;
};

export const isAccessPolicyViolation = (v: any): boolean => {
  return isApiErrorOfType(v, ErrorCode.AccessPolicyViolation);
};

export const USER_NOT_LOGGED_IN = 'User is not logged in';

export const PROJECT_NOT_SPECIFIED = 'All API requests must specify a project id';
