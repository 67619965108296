import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Await, useActionData, useLoaderData, useLocation, useNavigate, useSubmit } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { MemoizedSearchControl, PageSizeOption, SearchChangeEvent, useZapehr } from '../../../components/index';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { Bundle, OperationOutcome } from '../../../lib/fhir-types';
import { getSchema } from '../../../lib/schema';
import { getNewUrlFromSearchChangeEvent, parseLocationToSearchEvent } from '../../../lib/searchUtils';
import { toast } from '../../../lib/toast';
import { Services } from '../../../services';

export interface ResourceTypeLoaderData {
  [x: string]: any;
  outcome: OperationOutcome | undefined;
  searchResponse: Bundle | undefined;
}

interface ActionData {
  delete?: { error: string | undefined };
}

export function ResourceTypePage(): JSX.Element {
  const deferredData = useLoaderData() as { data: Promise<ResourceTypeLoaderData> };
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingNewData, setLoadingNewData] = useState(false);
  const { currentProject } = useZapehr();
  const schema = getSchema(currentProject?.fhirVersion);

  const { search } = useMemo(() => {
    return parseLocationToSearchEvent(location.pathname + location.search, schema);
  }, [location, schema]);

  useEffect(() => {
    deferredData.data
      .then(() => {
        setLoadingNewData(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((_e) => {});
  }, [deferredData]);

  const submit = useSubmit();
  const actionData = useActionData() as ActionData;

  useEffect(() => {
    const deleteError = actionData?.delete?.error;
    if (deleteError) {
      toast.error('Sorry, an error occurred deleting the resource.');
    }
  }, [actionData]);

  const handleSearchChangeEvent = useCallback(
    (e: SearchChangeEvent) => {
      const newUrl = getNewUrlFromSearchChangeEvent(e);
      console.log('newUrl', newUrl);
      // console.log('newUrl', newUrl);
      // console.log('oldUrl', `${location.pathname}${location.search}`);
      setLoadingNewData(true);
      navigate(newUrl);
    },
    [navigate]
  );

  const deleteResources = useCallback(
    async (ids: string[]) => {
      const deleteData: { [name: string]: string } = {
        ids: ids.join('|'),
      };
      setLoadingNewData(true);
      submit(deleteData, { method: 'delete' });
    },
    [submit]
  );

  const [pageSize, setPageSize] = useState<PageSizeOption>(25);
  const [resourcesForDeletion, setResourcesForDeletion] = useState<string[]>();

  return (
    <Box>
      <Box display="flex">
        <Typography variant="h4" color="textPrimary">
          {search.resourceType}
        </Typography>
        <ArticleShortcutIconButton
          link={`https://www.hl7.org/fhir/${
            currentProject?.fhirVersion === 'r4' ? 'R4B' : 'r5'
          }/${search.resourceType.toLowerCase()}.html`}
        />
      </Box>
      <Suspense
        fallback={
          <MemoizedSearchControl
            isLoadingData={true}
            searchResponse={undefined}
            outcome={undefined}
            checkboxesEnabled={false}
            pageSize={pageSize}
            search={search}
          />
        }
      >
        <Await resolve={deferredData.data} errorElement={<RootErrorBoundary />}>
          {(data) => {
            return (
              <MemoizedSearchControl
                isLoadingData={loadingNewData}
                checkboxesEnabled={true}
                searchResponse={data?.searchResponse}
                outcome={data?.outcome}
                search={search}
                pageSize={pageSize}
                onClick={(e) => navigate(`/${Services.fhir.rootPath}/${e.resource.resourceType}/${e.resource.id}`)}
                onAuxClick={(e) =>
                  window.open(`/${Services.fhir.rootPath}/${e.resource.resourceType}/${e.resource.id}`, '_blank')
                }
                onChange={handleSearchChangeEvent}
                onNew={
                  canCreate(search.resourceType)
                    ? () => {
                        navigate(`/${Services.fhir.rootPath}/${search.resourceType}/new`);
                      }
                    : undefined
                }
                onDelete={setResourcesForDeletion}
                onBulk={(ids: string[]) => {
                  navigate(`/${Services.fhir.rootPath}/bulk/${search.resourceType}?ids=${ids.join(',')}`);
                }}
                onPageSizeChange={(newVal: number) => {
                  setPageSize(newVal as PageSizeOption);
                }}
              />
            );
          }}
        </Await>
      </Suspense>
      {resourcesForDeletion && (
        <ConfirmationDialog
          open={resourcesForDeletion.length > 0}
          fullWidth
          title={`Delete resources?`}
          message={
            <>
              <span>Are you sure you want to delete these resources?</span>
              {resourcesForDeletion.map((resourceId) => {
                return (
                  <>
                    <br />
                    <span>
                      {search.resourceType}/{resourceId}
                    </span>
                  </>
                );
              })}
              <span style={{ display: 'block', color: '#D32F2F', marginTop: '10px' }}>
                This action is permanent and cannot be undone.
              </span>
            </>
          }
          buttonTitle={`Delete`}
          handleAction={async () => {
            setResourcesForDeletion(undefined);
            await deleteResources(resourcesForDeletion);
          }}
          handleClose={() => {
            setResourcesForDeletion(undefined);
          }}
        />
      )}
    </Box>
  );
}

function canCreate(resourceType: string): boolean {
  return resourceType !== 'Bot' && resourceType !== 'ClientApplication';
}
