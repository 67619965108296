import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import BreadcrumbsBasic from '../../../../components/BreadCrumbsBasic';
import { SendSMSForm } from '../../../../components/SendSMSForm';
import { InputField, PathHistoryBreadcrumb } from '../../../../lib/types';
import { Services } from '../../../../services';

const prevPaths: PathHistoryBreadcrumb[] = [{ text: 'SMS', from: `/${Services.messaging.rootPath}/sms` }];

export const smsFields: InputField[] = [
  {
    name: 'resource',
    label: 'Resource',
    placeholder: 'Patient/uuid',
    required: true,
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    placeholder: '1234567890',
    required: true,
  },
  {
    name: 'message',
    label: 'Message',
    placeholder: 'hello',
    required: true,
  },
];

export function SendSMSPage(): JSX.Element {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BreadcrumbsBasic prevPaths={prevPaths} currPathText="Send SMS"></BreadcrumbsBasic>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Send SMS
        </Typography>
        <Grid item xs={12}>
          <SendSMSForm inputFields={smsFields}></SendSMSForm>
        </Grid>
      </Grid>
    </Grid>
  );
}
