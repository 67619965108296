import { Box, Breadcrumbs, Grid, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Services } from '../services';

interface ResourceBreadCrumbProps {
  resourceType: string;
  resourceName: string;
}

export function ResourceBreadCrumb({ resourceType, resourceName }: ResourceBreadCrumbProps): JSX.Element | null {
  const theme = useTheme();

  if (!resourceType) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: 'primary.contrast', marginLeft: '-36px', marginRight: '-36px' }}>
      <Grid container spacing={2} sx={{ padding: '4px 20px', marginTop: 0, marginRight: '16px' }}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ color: theme.palette.text.secondary }} to={`/${Services.fhir.rootPath}/${resourceType}`}>
              {resourceType}
            </Link>
            <Typography color={theme.palette.text.primary}>{resourceName}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </Box>
  );
}
