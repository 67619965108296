import { IconButton, Link as MUILink, SvgIcon, useTheme } from '@mui/material';
import * as React from 'react';

interface ArticleShortcutIconButtonProps {
  link: string;
}

export function ArticleShortcutIconButton({ link }: ArticleShortcutIconButtonProps): JSX.Element {
  const theme = useTheme();

  return (
    <MUILink href={link} target="_blank" marginLeft={1}>
      <IconButton
        sx={{
          bgcolor: theme.palette.primary.light + '1F',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark + '1F',
          },
        }}
      >
        <SvgIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <mask
              id="mask0_14668_19304"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
              fill={theme.palette.primary.light}
            >
              <rect width="24" height="24" />
            </mask>
            <g mask="url(#mask0_14668_19304)">
              <path
                d="M10 17H14V15H10V17ZM10 13H17V11H10V13ZM7 9H17V7H7V9ZM6.625 22C5.30833 22 4.1875 21.5375 3.2625 20.6125C2.3375 19.6875 1.875 18.5667 1.875 17.25C1.875 16.3 2.12083 15.45 2.6125 14.7C3.10417 13.95 3.75 13.3833 4.55 13H2V11H8V17H6V14.575C5.38333 14.7083 4.875 15.025 4.475 15.525C4.075 16.025 3.875 16.6 3.875 17.25C3.875 18.0167 4.14583 18.6667 4.6875 19.2C5.22917 19.7333 5.875 20 6.625 20V22ZM10 21V19H19V5H5V9H3V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H10Z"
                fill={theme.palette.primary.dark}
              />
            </g>
          </svg>
        </SvgIcon>
      </IconButton>
    </MUILink>
  );
}
