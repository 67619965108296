import { CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';
import { useAsyncError, useLocation, useRouteError } from 'react-router-dom';
import { useZapehr } from '../contexts/ZapehrProvider';
import { ApiError, ErrorCode, USER_NOT_LOGGED_IN } from '../lib/errors';
import NotFound from '../pages/NotFound';

export const RootErrorBoundary: FC = () => {
  const error = useRouteError();
  const asyncError = useAsyncError();
  const { errorMessage, errorCode } = useMemo(() => {
    const errorMessage = (error as Error)?.message ?? (asyncError as Error)?.message ?? 'Unknown Error';
    const errorCode = (error as ApiError)?.code ?? (asyncError as ApiError)?.code;
    return { errorMessage, errorCode };
  }, [error, asyncError]);

  const { signIn } = useZapehr();
  const { pathname } = useLocation();

  const isUserLoggedOut = errorMessage === USER_NOT_LOGGED_IN || errorCode == ErrorCode.Unauthorized;

  useEffect(() => {
    if (isUserLoggedOut) {
      void signIn(pathname);
    }
  }, [isUserLoggedOut, signIn, pathname]);

  return (
    <Grid
      container
      direction="column"
      rowSpacing={1}
      sx={{
        alignItems: 'center',
        padding: '40px',
      }}
    >
      {isUserLoggedOut ? (
        <CircularProgress />
      ) : (
        <Grid item xs={8}>
          {errorCode && errorCode === ErrorCode.ResourceNotFound ? (
            <NotFound />
          ) : (
            <Typography>{`Whoops there was an error: ${errorMessage}`}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
