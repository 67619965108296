import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Await, defer, Link, LoaderFunctionArgs, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import { DeepWritable } from 'ts-essentials';
import { useZapehr } from '../../../../components';
import { isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { PageHeader } from '../../../../components/PageHeader';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { TextCardWithButton } from '../../../../components/TextCardWithButton';
import { TextFieldWithCopyButton } from '../../../../components/TextFieldWithCopyButton';
import { toast } from '../../../../lib/toast';
import { assert, copyToClipboard, prettyJSON } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { UserFields, UserFormBody } from '../lib/UserFormBody';

export interface AccessPolicyError {
  attribute: string;
  type: 'info' | 'warning' | 'error';
  message: string;
}

export function userDetailPageLoader(args: LoaderFunctionArgs): ReturnType<typeof defer> {
  const { id } = args.params;
  assert(id, 'No Application ID in request url');
  return defer({ user: zapehr.project.user.get({ id }), roles: zapehr.project.role.list() });
}
export type UserDetailPageLoaderData = {
  user: ReturnType<typeof zapehr.project.user.get>;
  roles: ReturnType<typeof zapehr.project.role.list>;
};

export function UserDetailPage(): JSX.Element {
  const data = useLoaderData() as UserDetailPageLoaderData;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PageHeader text="User Information" />
      </Grid>
      <Grid item xs={12}>
        <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
          <Await resolve={data.user} errorElement={<RootErrorBoundary />}>
            {(user) => {
              return <LoadedComponent user={user} />;
            }}
          </Await>
        </Suspense>
      </Grid>
    </Grid>
  );
}

const initialFormStateFromUser = (
  user: Awaited<ReturnType<typeof zapehr.project.user.get>>
): { defaultValues: UserFields } => {
  return {
    defaultValues: {
      applicationId: '',
      username: '',
      email: user.email ?? '',
      roles: '',
      resource: '',
      accessPolicy: prettyJSON(user.accessPolicy),
    },
  };
};

function LoadedComponent({ user }: { user: Awaited<ReturnType<typeof zapehr.project.user.get>> }): JSX.Element {
  const formReturn = useForm(initialFormStateFromUser(user));
  const { handleSubmit } = formReturn;
  const [loading, setLoading] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const [showLaunchAppDialog, setShowLaunchAppDialog] = useState(false);
  const [appLaunchUrl, setAppLaunchUrl] = useState('');
  const [appLaunchEncounterId, setAppLaunchEncounterId] = useState('');
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { currentProject } = useZapehr();

  const onSubmit = useCallback(
    (data: UserFields): void => {
      setLoading(true);

      if (!isValidJSON(data.accessPolicy)) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        setLoading(false);
        return;
      }

      const updateParams: DeepWritable<Parameters<typeof zapehr.project.user.update>[0]> = {
        id: user.id,
        ...data,
        accessPolicy: JSON.parse(data.accessPolicy),
        roles: isValidJSON(data.roles) ? JSON.parse(data.roles) : [],
      };

      zapehr.project.user
        .update(updateParams)
        .then(() => {
          toast.success('Changes saved');
          revalidator.revalidate();
        })
        .catch((error) => {
          console.error(error);
          toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [revalidator, user.id]
  );

  const deleteHandler = useCallback(async () => {
    setDeletePending(false);

    try {
      await zapehr.project.user.delete({ id: user.id });
      toast.success(`User ${user.name} has been deleted`);
      revalidator.revalidate();
      navigate(`/${Services.app.rootPath}/users`);
    } catch (error) {
      toast.error(`An error occurred: ${(error as any).message || 'Unknown error'}`);
      setDeletePending(false);
    }
  }, [navigate, revalidator, user.id, user.name]);

  const launchAppButtonHandler = (): void => {
    setShowLaunchAppDialog(true);
  };

  const launchApp = useCallback((): void => {
    const params = new URLSearchParams({
      iss: process.env.FHIR_API_URL + '/' + currentProject?.fhirVersion ?? '',
      launch: user.profile.split('/')[1] + ',' + appLaunchEncounterId,
    });
    console.log(params.toString());
    window.open(appLaunchUrl + '?' + params.toString(), '_blank', 'noreferrer');
    setShowLaunchAppDialog(false);
  }, [appLaunchUrl, appLaunchEncounterId, user, currentProject]);

  const launchAppDialog = (
    <Dialog open={showLaunchAppDialog} onClose={() => setShowLaunchAppDialog(false)} fullWidth maxWidth={'md'}>
      <DialogTitle>Revoke access token</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          onChange={(event) => setAppLaunchUrl(event.target.value)}
          sx={{ mt: '20px' }}
          helperText="Enter launch URL"
        />
        <TextField
          variant="standard"
          fullWidth
          onChange={(event) => setAppLaunchEncounterId(event.target.value)}
          sx={{ mt: '20px' }}
          helperText="Enter Encounter id"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            launchApp();
          }}
        >
          Launch
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserFormBody
        formUtils={formReturn}
        hiddenFields={['email', 'username', 'applicationId', 'phone_number', 'resource']}
        assignedRoles={user.roles as DeepWritable<typeof user.roles>}
        renderErrors={(errors) =>
          errors.description && (
            <Grid item>
              <Typography variant="body1" color="error" role="alert">
                {errors.description?.message?.toString()}
              </Typography>
            </Grid>
          )
        }
        renderPreFormBody={() => (
          <>
            <Grid item xs={12}>
              <Typography>
                Profile:&nbsp;
                <Link to={`/${Services.fhir.rootPath}/${user.profile}`}>{user.profile}</Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldWithCopyButton
                textField={
                  <TextField
                    fullWidth
                    value={user.id}
                    label="User ID"
                    helperText="ID cannot be edited"
                    disabled={true}
                  />
                }
                copyButtonOnClick={() => copyToClipboard(user.id, 'User ID copied to clipboard!')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWithCopyButton
                textField={
                  <TextField
                    fullWidth
                    value={user.authenticationMethod}
                    label="Login Connection Name"
                    helperText="Login Connection Name cannot be edited"
                    disabled={true}
                  />
                }
              />
            </Grid>
            {user.email ? (
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={user.email}
                      label="Email"
                      helperText="Email cannot be edited"
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={() => {
                    if (user.email) {
                      copyToClipboard(user.email, 'Email copied to clipboard!');
                    } else {
                      toast.error('Email is not yet loaded.');
                    }
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={user.phoneNumber}
                      label="Phone number"
                      helperText="Phone number cannot be edited"
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={() => {
                    if (user.phoneNumber) {
                      copyToClipboard(user.phoneNumber, 'Phone number copied to clipboard!');
                    } else {
                      toast.error('Phone number is not yet loaded.');
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldWithCopyButton
                textField={
                  <TextField
                    fullWidth
                    value={user.name}
                    label="User name"
                    helperText="Name cannot be edited"
                    disabled={true}
                  />
                }
                copyButtonOnClick={() => copyToClipboard(user.name, 'User name copied to clipboard!')}
              />
            </Grid>
          </>
        )}
        renderActionButton={() => (
          <Grid item container direction="column" spacing={2}>
            <Grid item textAlign="center">
              <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        )}
        renderDeleteButton={() => (
          <Grid item container direction="column" spacing={2}>
            <Grid item textAlign="left" xs={12}>
              <TextCardWithButton
                title={`Delete User`}
                description={`If deleted, this user will lose all access to this project.`}
                variant="danger"
                buttonTitle={`Delete`}
                action={() => setDeletePending(true)}
                loading={loading}
              />
            </Grid>
          </Grid>
        )}
      />
      <Grid item xs={12} paddingTop={4}>
        <TextCardWithButton
          title="Launch app"
          description="Perform SMART's EHR launch"
          variant="warning"
          buttonTitle="Launch"
          action={launchAppButtonHandler}
        />
      </Grid>
      <ConfirmationDialog
        handleAction={deleteHandler}
        open={deletePending}
        message={`Are you sure you want to remove ${user.name} from this project?`}
        buttonTitle="Delete"
        handleClose={() => setDeletePending(false)}
      />
      {launchAppDialog}
    </form>
  );
}
