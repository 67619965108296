import './RolesEditComponent.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { Suspense, useMemo, useState } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IAMRole } from '../../lib/types';

export interface RolesEditComponentProps {
  assignedRolesList: { id: string; name: string }[];
  setRolesValue: (value: string) => void;
}

interface RoleElementProps {
  name: string;
  onRemoveClick: () => void;
}

type RoleChangeAction = 'add' | 'remove';

function RoleElement(props: RoleElementProps): JSX.Element {
  const { name, onRemoveClick } = props;
  return (
    <div className="roleElement">
      <p className="roleName">{name}</p>
      <div className="roleRemoveButton" onClick={onRemoveClick}>
        <HighlightOffIcon style={{ color: '#FFFFFF' }} />
      </div>
    </div>
  );
}

// roles are stored in the Record<id, name> format to allow remove/get them without search
// this function converts an array of roles into such structure
const wrapRolesList = (list: { id: string; name: string }[]): Record<string, string> => {
  const result: Record<string, string> = {};
  if (list) {
    list.map((role) => {
      result[role.id] = role.name;
    });
  }
  return result;
};

export function RolesEditComponent(props: RolesEditComponentProps): JSX.Element {
  const { assignedRolesList, setRolesValue } = props;
  const { roles: allRolesPromise } = useLoaderData() as { roles: Promise<IAMRole[]> };

  const [rolesAreLoaded, setRolesAreLoaded] = useState(false); // prevents infinite loop of setAllRoles call
  const [allRoles, setAllRoles] = useState<Record<string, string>>({}); // all roles of the application
  const [addedRoles, setAddedRoles] = useState(wrapRolesList(assignedRolesList)); // roles that will be passed in request body (shown in the UI as list of chips)

  // add to or remove from the list of assigned roles
  const changeRoleState = (id: string, action: RoleChangeAction): void => {
    switch (action) {
      case 'add':
        addedRoles[id] = allRoles[id];
        break;
      case 'remove':
        delete addedRoles[id];
        break;
    }
    setAddedRoles({ ...addedRoles });
  };

  // availableRoles are shown in the dropdown list, used instead of allRoles to avoid possibility to add one role twice
  const { availableRoles, noAvailableRoles, noAddedRoles } = useMemo((): {
    availableRoles: Record<string, string>;
    noAvailableRoles: boolean;
    noAddedRoles: boolean;
  } => {
    const availableRoles = { ...allRoles };
    Object.keys(addedRoles).map((id) => delete availableRoles[id]);
    const noAddedRoles = Object.keys(addedRoles).length == 0;
    const noAvailableRoles = Object.keys(availableRoles).length == 0;
    setRolesValue(JSON.stringify(Object.keys(addedRoles)));
    return { availableRoles, noAvailableRoles, noAddedRoles };
  }, [allRoles, addedRoles, setRolesValue]);

  // -----------------------------------^^^ Functionality ^^^-----------------------------------

  // ------------------------------------------- UI --------------------------------------------

  if (!allRolesPromise) {
    console.error('allRolesPromise is missing, make sure that you pass it in the loader of the current page');
    setRolesValue(JSON.stringify(Object.keys(addedRoles)));
    return (
      <div className="rolesEditContainer">
        <label className="rolesContainerLabel">Roles</label>
        <p className="text-info">Data about roles is missing on this page.</p>
      </div>
    );
  }

  return (
    <div className="rolesEditContainer">
      <label className="rolesContainerLabel">Roles</label>
      <Suspense fallback={<CircularProgress sx={{ marginBottom: 2 }} />}>
        <Await
          resolve={allRolesPromise}
          errorElement={<p className="text-info">An error occured during loading the list of available roles.</p>}
        >
          {(roles) => {
            if (!rolesAreLoaded) {
              setAllRoles(wrapRolesList(roles));
              setRolesAreLoaded(true);
            }
            return (
              <FormControl
                id="roleSelectorControl"
                variant="outlined"
                sx={noAvailableRoles ? {} : { background: '#FFFFFF' }}
                disabled={noAvailableRoles}
              >
                {/* div is used to prevent label movement, as this selecct element is used not as a field, but more like a button */}
                <div id="roleSelectLabel">{noAvailableRoles ? 'All roles are added' : 'Add Role'}</div>
                <Select
                  value={''}
                  inputProps={{ 'aria-label': noAvailableRoles ? 'All roles are added' : 'Add Role' }}
                  sx={{ zIndex: '10' }}
                  onChange={(e) => {
                    changeRoleState(e.target.value as string, 'add');
                  }}
                >
                  {Object.entries(availableRoles).map(([id, name]) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        </Await>
      </Suspense>

      {noAddedRoles ? (
        <p className="noRolesInfo">No assigned roles.</p>
      ) : (
        <div className="rolesList">
          {Object.entries(addedRoles).map(([id, name]) => (
            <RoleElement name={name} onRemoveClick={() => changeRoleState(id, 'remove')} />
          ))}
        </div>
      )}
    </div>
  );
}
