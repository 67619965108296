export const DEFAULT_IGNORED_PROPERTIES = [
  'meta',
  'implicitRules',
  'language',
  'text',
  'contained',
  'extension',
  'modifierExtension',
];

export const CONFIRMATION_DIALOG_TITLE = 'Are you sure?';

export const CONFIRMATION_DIALOG_DESCRIPTION = 'This action is permanent and cannot be undone.';
