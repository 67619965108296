import { capitalize } from '@mui/material';
import {
  GridColDef,
  GridFilterInputDate,
  GridFilterInputValue,
  GridFilterItem,
  GridFilterOperator,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { SearchChangeEvent } from '../components';
import { GridFilterCheckboxInputValue } from '../components/GridCheckboxInputValue';
import { SearchParameter, TypesSchema } from '../lib/schema';

type SearchParameterType =
  | 'number'
  | 'date'
  | 'string'
  | 'token'
  | 'reference'
  | 'composite'
  | 'quantity'
  | 'uri'
  | 'special';

export const DEFAULT_SEARCH_COUNT = 100;

export interface SearchRequest {
  readonly resourceType: string;
  filters?: Filter[];
  sortRules?: SortRule[];
  offset?: number;
  count?: number;
  fields?: string[];
  name?: string;
  total?: 'none' | 'estimate' | 'accurate';
}

export interface Filter {
  code: string;
  operator: Operator;
  value: string;
  id?: string | number;
  unitSystem?: string;
  unitCode?: string;
}

export interface SortRule {
  code: string;
  descending?: boolean;
}

export type ResourceSearchEvent = {
  url: string;
  query: string;
  search: SearchRequest;
};

/**
 * Search operators.
 * These operators represent "modifiers" and "prefixes" in FHIR search.
 * See: https://www.hl7.org/fhir/search.html
 */
export enum Operator {
  // these will just be evaluated as [paramName]=[search value] as they aren't prefixes or modifiers
  // but rather help communicate to the user how the simple [paramName]=[search value] search will be evaluated
  STARTS_WITH = 'starts with',
  EXACT_MATCH = 'exact match',

  // Numbers
  EQUALS = 'eq',
  NOT_EQUALS = 'ne',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  GREATER_THAN_OR_EQUALS = 'ge',
  LESS_THAN_OR_EQUALS = 'le',

  // Dates
  STARTS_AFTER = 'sa',
  ENDS_BEFORE = 'eb',
  APPROXIMATELY = 'ap',

  // String
  CONTAINS = 'contains',
  EXACT = 'exact',

  // Token
  TEXT = 'text',
  NOT = 'not',
  ABOVE = 'above',
  BELOW = 'below',
  IN = 'in',
  NOT_IN = 'not-in',
  OF_TYPE = 'of-type',

  // All
  MISSING = 'missing',
}

const labelForOperator = (operator: Operator): string => {
  switch (operator) {
    case Operator.EQUALS:
      return 'EQUALS';
    case Operator.NOT_EQUALS:
      return 'NOT EQUAL';
    case Operator.GREATER_THAN:
      return '>';
    case Operator.LESS_THAN:
      return '<';
    case Operator.GREATER_THAN_OR_EQUALS:
      return '≥';
    case Operator.LESS_THAN_OR_EQUALS:
      return '≤';
    case Operator.STARTS_AFTER:
      return 'STARTS AFTER';
    case Operator.ENDS_BEFORE:
      return 'ENDS BEFORE';
    case Operator.APPROXIMATELY:
      return 'APPROX';
    case Operator.CONTAINS:
      return 'CONTAINS';
    case Operator.EXACT:
    case Operator.EXACT_MATCH:
      return 'EXACT';
    case Operator.TEXT:
      return 'TEXT';
    case Operator.NOT:
      return 'NOT';
    case Operator.ABOVE:
      return 'ABOVE';
    case Operator.BELOW:
      return 'BELOW';
    case Operator.IN:
      return 'IN';
    case Operator.NOT_IN:
      return 'NOT IN';
    case Operator.OF_TYPE:
      return 'OF TYPE';
    case Operator.MISSING:
      return 'MISSING';
    case Operator.STARTS_WITH:
      return 'STARTS WITH';
  }
};
const MODIFIER_OPERATORS: Operator[] = [
  Operator.CONTAINS,
  Operator.EXACT,
  Operator.TEXT,
  Operator.NOT,
  Operator.ABOVE,
  Operator.BELOW,
  Operator.IN,
  Operator.NOT_IN,
  Operator.OF_TYPE,
  Operator.MISSING,
];

const PREFIX_OPERATORS: Operator[] = [
  Operator.EQUALS,
  Operator.NOT_EQUALS,
  Operator.GREATER_THAN,
  Operator.LESS_THAN,
  Operator.GREATER_THAN_OR_EQUALS,
  Operator.LESS_THAN_OR_EQUALS,
  Operator.STARTS_AFTER,
  Operator.ENDS_BEFORE,
  Operator.APPROXIMATELY,
];

/* 
  these operators are used to formulate a search that has no prefixes or modifiers.
  they exist to allow such searches to be constructed in the mui data grid filter component
  and to instruct the user how those searches will be interpreted by the server. because
  these filters will be inferred when a url lacking modifiers or prefixes is parsed into its
  key/val/operator components, it is important that only one operator belonging to this category 
  exist per param type;
*/
const FICTIONAL_OPERATORS: Operator[] = [Operator.STARTS_WITH, Operator.EXACT_MATCH];

export const getDefaultSearchQueryForResourceType = (resourceType: string): string => {
  const fields = getDefaultFields(resourceType).map((field) => {
    return getElementParamNameFromFieldName(field);
  });
  const searchRequest: SearchRequest = {
    resourceType,
    fields,
    filters: [],
    sortRules: [],
    offset: 0,
    count: 25,
    total: 'accurate',
  };
  return formatSearchQuery(searchRequest);
};

/**
 * Parses a URL into a SearchRequest.
 *
 * See the FHIR search spec: http://hl7.org/fhir/R4B/search.html
 *
 * @param url The URL to parse.
 * @returns Parsed search definition.
 */
export function parseSearchDefinition(url: string, typesSchema: TypesSchema): SearchRequest {
  const location = new URL(url, 'https://example.com/');
  const resourceType =
    location.pathname
      .replace(/(^\/)|(\/$)/g, '') // Remove leading and trailing slashes
      .split('/')
      .pop() || '';
  const params = new URLSearchParams(location.search);
  let filters: Filter[] | undefined = undefined;
  let sortRules: SortRule[] | undefined = undefined;
  let fields: string[] | undefined = undefined;
  let offset = undefined;
  let count = undefined;
  let total = undefined;

  params.forEach((value, key) => {
    if (key === '_elements') {
      fields = value.split(',').map((param) => getFieldNameForSearchParamName(param));
    } else if (key === '_offset') {
      offset = parseInt(value);
    } else if (key === '_count') {
      count = parseInt(value);
    } else if (key === '_total') {
      total = value;
    } else if (key === '_sort') {
      sortRules = sortRules || [];
      value.split(',').forEach((sortRuleString) => {
        sortRules?.push(parseSortRule(sortRuleString));
      });
    } else {
      filters = filters || [];
      const parsedFilter = parseSearchFilter(key, value, resourceType, typesSchema);
      if (parsedFilter !== undefined) {
        filters.push(parsedFilter);
      }
    }
  });

  return {
    resourceType,
    filters,
    fields,
    offset,
    count,
    total: total ?? 'accurate',
    sortRules,
  };
}

function addDefaultSearchValues(search: SearchRequest): SearchRequest {
  const resourceType = search.resourceType;
  const fields = search.fields ?? getDefaultFields(resourceType);
  const offset = search.offset ?? 0;
  const count = search.count ?? DEFAULT_SEARCH_COUNT;

  return {
    ...search,
    resourceType,
    fields,
    offset,
    count,
  };
}

export function getDefaultFields(resourceType: string): string[] {
  const fields = ['id', 'lastUpdated'];
  switch (resourceType) {
    case 'Patient':
      fields.push('name', 'birthDate', 'gender');
      break;
    case 'Practitioner':
    case 'Organization':
    case 'Questionnaire':
      fields.push('name');
      break;
    case 'CodeSystem':
    case 'ValueSet':
      fields.push('name', 'title', 'status');
      break;
    case 'Condition':
      fields.push('subject', 'code', 'clinicalStatus');
      break;
    case 'Device':
      fields.push('manufacturer', 'deviceName', 'patient');
      break;
    case 'DeviceDefinition':
      fields.push('manufacturer[x]', 'deviceName');
      break;
    case 'DeviceRequest':
      fields.push('code[x]', 'subject');
      break;
    case 'DiagnosticReport':
    case 'Observation':
      fields.push('subject', 'code', 'status');
      break;
    case 'Encounter':
      fields.push('subject');
      break;
    case 'ServiceRequest':
      fields.push('subject', 'code', 'status', 'orderDetail');
      break;
    case 'Subscription':
      fields.push('criteria');
      break;
    case 'User':
      fields.push('email');
      break;
  }
  return fields;
}

const formatSearchRequest = (search: SearchRequest): { url: string; query: string } => {
  const query = formatSearchQuery({ ...search, total: 'accurate', fields: search.fields });
  return { url: `${search.resourceType}${query}`, query };
};

// the query string that appears in the browser location does not match the query string
// sent as a search request to the fhir api. this function maps from the browser location's
// query to the api's query and packages it with some other structured data that is useful in various
// places. might be we'd like to have the browser query match the api query eventually as the
// mismatch could prove confusing to devs. leaving as is for now to keep scope of changes small.
export const parseLocationToSearchEvent = (url: string, typesSchema: TypesSchema): ResourceSearchEvent => {
  const parsedSearch = parseSearchDefinition(url, typesSchema);
  // Fill in the search with default values
  const populatedSearch = addDefaultSearchValues(parsedSearch);
  const formatted = formatSearchRequest(populatedSearch);
  return { ...formatted, search: populatedSearch };
};

/**
 * Parses a URL query parameter into a sort rule.
 *
 * By default, the sort rule is the field name.
 *
 * Sort rules can be reversed into descending order by prefixing the field name with a minus sign.
 *
 * See sorting: http://hl7.org/fhir/R4B/search.html#_sort
 *
 * @param value The URL parameter value.
 * @returns The parsed sort rule.
 */
export function parseSortRule(value: string): SortRule {
  if (value.startsWith('-')) {
    return { code: value.substring(1), descending: true };
  } else {
    return { code: value };
  }
}

/**
 * Parses a URL query parameter into a search filter.
 *
 * FHIR search filters can be specified as modifiers or prefixes.
 *
 * For string properties, modifiers are appended to the key, e.g. "name:contains=eve".
 *
 * For date and numeric properties, prefixes are prepended to the value, e.g. "birthdate=gt2000".
 *
 * See the FHIR search spec: http://hl7.org/fhir/R4B/search.html
 *
 * @param key The URL parameter key.
 * @param value The URL parameter value.
 * @returns The parsed search filter.
 */
function parseSearchFilter(
  key: string,
  value: string,
  resourceType: string,
  typesSchema: TypesSchema
): Filter | undefined {
  let code = key;
  let operator: Operator | undefined;

  for (const modifier of MODIFIER_OPERATORS) {
    const modifierIndex = code.indexOf(':' + modifier);
    if (modifierIndex !== -1) {
      operator = modifier;
      code = code.substring(0, modifierIndex);
    }
  }

  for (const prefix of PREFIX_OPERATORS) {
    if (value.match(new RegExp('^' + prefix + '\\d'))) {
      operator = prefix;
      value = value.substring(prefix.length);
    }
  }

  const availableOperators = getAvailableOperatorsForResourceAndParam(resourceType, code, typesSchema);

  if (!operator) {
    for (const ficOp of FICTIONAL_OPERATORS) {
      const inferredOperator = availableOperators.find((operator) => {
        return operator === ficOp;
      });
      operator = inferredOperator;
    }
  }

  if (!operator) {
    return undefined;
  }

  code = getFieldNameForSearchParamName(code);

  return { code, operator, value };
}

export const getNewUrlFromSearchChangeEvent = (event: SearchChangeEvent): string => {
  const newUrl = `/fhir/resources/${event.definition.resourceType}${formatSearchQuery(event.definition)}`;
  return newUrl;
};

/**
 * Formats a search definition object into a query string.
 * Note: The return value does not include the resource type.
 * @param {!SearchRequest} definition The search definition.
 * @returns Formatted URL.
 */
function formatSearchQuery(definition: SearchRequest): string {
  const params: string[] = [];

  /*
  not sure this is desired.commenting out for now. to be revisited when search ui is made. 
  if (definition.fields) {
    params.push('_elements=' + definition.fields.join(','));
  }*/

  if (definition.filters) {
    definition.filters.forEach((filter) => params.push(formatFilter(filter)));
  }

  if (definition.sortRules && definition.sortRules.length > 0) {
    params.push(formatSortRules(definition.sortRules));
  }

  if (definition.offset !== undefined) {
    params.push('_offset=' + definition.offset);
  }

  if (definition.count !== undefined) {
    params.push('_count=' + definition.count);
  }

  if (definition.total !== undefined) {
    params.push('_total=' + definition.total);
  }

  if (params.length === 0) {
    return '';
  }

  params.sort();
  const result = '?' + params.join('&');
  return result;
}

function formatFilter(filter: Filter): string {
  const modifier = MODIFIER_OPERATORS.includes(filter.operator) ? ':' + filter.operator : '';
  const prefix = PREFIX_OPERATORS.includes(filter.operator) ? filter.operator : '';
  const filterCode = propertyNameToSearchParamMap[filter.code] ?? filter.code;
  return `${filterCode}${modifier}=${prefix}${encodeURIComponent(filter.value !== undefined ? filter.value : '')}`;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function formatSortRules(sortRules: SortRule[] | undefined): string {
  if (!sortRules || sortRules.length === 0) {
    return '';
  }
  return '_sort=' + sortRules.map((sr) => (sr.descending ? '-' + sr.code : sr.code)).join(',');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const searchParamToPropertyNameMap: Record<string, string> = {
  _id: 'id',
  _lastUpdated: 'lastUpdated',
  _tag: 'tag',
  _profile: 'profile',
  _security: 'security',
  _source: 'source',
  _text: 'text',
  _content: 'content',
};

const propertyNameToSearchParamMap: Record<string, string> = {
  id: '_id',
  lastUpdated: '_lastUpdated',
  tag: '_tag',
  profile: '_profile',
  security: '_security',
  source: '_source',
  text: '_text',
  content: '_content',
};

export const getFieldNameForSearchParamName = (searchParamName: string): string => {
  return searchParamToPropertyNameMap[searchParamName] ?? searchParamName;
};

export const getElementParamNameFromFieldName = (fieldName: string): string => {
  return propertyNameToSearchParamMap[fieldName] ?? fieldName;
};

export const getSearchParamNameFromFieldName = (
  fieldName: string,
  resourceType: string,
  typesSchema: TypesSchema
): string => {
  const mappedName = propertyNameToSearchParamMap[fieldName];
  if (mappedName) {
    return mappedName;
  }

  const possibleParams = getSearchParamsForResourceType(resourceType, typesSchema);
  if (possibleParams) {
    const matchedParam = possibleParams
      .map((param) => param.name)
      .find((name) => {
        return name.toLowerCase() === fieldName.toLowerCase();
      });
    if (matchedParam) {
      return matchedParam;
    }
  }
  return fieldName;
};

const getSearchParamsForResourceType = (
  resourceType: string,
  typesSchema: TypesSchema
): SearchParameter[] | undefined => {
  return typesSchema[resourceType].searchParameters;
};

/**
 * Sets the array of filters based on
 *
 * @param {Array} filters The new filters.
 */
export function updateFilters(
  definition: SearchRequest,
  gridFilters: GridFilterItem[],
  typesSchema: TypesSchema
): SearchRequest {
  return {
    ...definition,
    filters: gridFilters.map((gridFilter) => {
      const operator = Object.values(Operator).some((opString: string) => opString === gridFilter.operatorValue)
        ? (gridFilter.operatorValue as Operator)
        : Operator.EQUALS;
      // default to false for boolean MISSING operator, otherwise empty string
      const value = gridFilter.value !== undefined ? gridFilter.value : operator === Operator.MISSING ? false : '';
      return {
        code: getSearchParamNameFromFieldName(gridFilter.columnField, definition.resourceType, typesSchema),
        operator,
        value,
        id: gridFilter.id,
      };
    }),
    offset: 0,
  };
}

/**
 * Sorts the search by the sort model provided by MUI DataGrid.
 *
 * @param {SearchRequest} definition The current search.
 * @param {GridSortModel} newSorting The new sorting rules.
 */
export function updateSortRules(
  definition: SearchRequest,
  newSorting: GridSortModel,
  typesSchema: TypesSchema
): SearchRequest {
  return {
    ...definition,
    sortRules: newSorting.map((sortItem) => ({
      code: getSearchParamNameFromFieldName(sortItem.field, definition.resourceType, typesSchema),
      descending: sortItem.sort === 'desc',
    })),
  };
}

/**
 * Returns a field display name.
 * @param key The field key.
 * @returns The field display name.
 */
export function buildFieldNameString(key: string): string {
  let tmp = key;

  // If dot separated, only the last part
  if (tmp.includes('.')) {
    tmp = tmp.split('.').pop() as string;
  }

  // Special case for ID
  if (tmp === 'id') {
    return 'ID';
  }

  // Special case for Version ID
  if (tmp === 'versionId') {
    return 'Version ID';
  }

  // Remove choice of type
  tmp = tmp.replace('[x]', '');

  // Convert camel case to space separated
  tmp = tmp.replace(/([A-Z])/g, ' $1');

  // Convert dashes and underscores to spaces
  tmp = tmp.replace(/[-_]/g, ' ');

  // Normalize whitespace to single space character
  tmp = tmp.replace(/\s+/g, ' ');

  // Trim
  tmp = tmp.trim();

  // Capitalize the first letter of each word
  return tmp.split(/\s/).map(capitalize).join(' ');
}

export const getOperatorsForField = (
  field: string,
  resourceType: string,
  typesSchema: TypesSchema
): GridFilterOperator[] => {
  const type = typesSchema[resourceType].searchParameters.find((param) => param.name === field)?.type;
  if (type) {
    return searchParamOperatorMap[type as SearchParameterType].map((op) =>
      getGridFilterOperator(op, type as SearchParameterType)
    );
  }
  return [];
};

// maybe we can take this from some fhir schema some day
const searchParamOperatorMap: Record<SearchParameterType, Operator[]> = {
  number: [...PREFIX_OPERATORS, Operator.MISSING],
  date: [...PREFIX_OPERATORS, Operator.MISSING],
  quantity: [...PREFIX_OPERATORS, Operator.MISSING],
  string: [Operator.STARTS_WITH, Operator.CONTAINS, Operator.EXACT, Operator.TEXT, Operator.MISSING],
  token: [
    Operator.EXACT_MATCH,
    Operator.ABOVE,
    Operator.BELOW,
    Operator.TEXT,
    Operator.MISSING,
    Operator.IN,
    Operator.NOT_IN,
    Operator.NOT,
  ],
  reference: [Operator.ABOVE, Operator.BELOW, Operator.TEXT, Operator.MISSING, Operator.NOT_IN],
  composite: [],
  uri: [Operator.MISSING, Operator.ABOVE, Operator.BELOW, Operator.CONTAINS],
  special: [],
};

const getGridFilterOperator = (operator: Operator, paramType: SearchParameterType): GridFilterOperator => {
  const baseOperator: GridFilterOperator = {
    label: labelForOperator(operator),
    value: operator,
    getApplyFilterFn: (_filterItem: GridFilterItem, _column: GridColDef) => {
      return null; // server-side all the things
    },
    InputComponent: paramType === 'date' ? GridFilterInputDate : GridFilterInputValue,
  };

  if (paramType === 'number' || paramType === 'quantity') {
    if (operator !== Operator.MISSING) {
      baseOperator.InputComponentProps = { type: 'number' };
    }
  }

  if (operator === Operator.MISSING) {
    baseOperator.InputComponent = GridFilterCheckboxInputValue;
  }

  if (paramType === 'date') {
    baseOperator.InputComponentProps = { type: 'date' };
  }

  return baseOperator;
};

const getAvailableOperatorsForResourceAndParam = (
  resourceType: string,
  param: string,
  typesSchema: TypesSchema
): Operator[] => {
  const type = typesSchema[resourceType].searchParameters.find(
    (searchParameter) => searchParameter.name === param
  )?.type;
  return type ? searchParamOperatorMap[type as SearchParameterType] : [];
};
