import { TextareaAutosize } from '@mui/base';
import { capitalize, Checkbox, FormControlLabel } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { otherColors, palette } from '../../contexts/AdminThemeProvider';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { DateTimeInput } from '../DateTimeInput';
import { CustomTextField } from '../WrappedInputs/CustomTextField';
import { CodeInput } from './CodeInput';
import { PropertyInputProps } from './common';

export function PrimitivePropertyInput(props: PropertyInputProps): JSX.Element | null {
  const { propertyDefinition, value: typedValue, onChange } = props;
  const { editModeOn } = useResourceEditingContext();
  const value = typedValue?.value;
  const type = propertyDefinition.type;
  const label = capitalize(propertyDefinition.name);

  switch (type) {
    case 'boolean':
      return (
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <Checkbox
              checked={value ?? false}
              onChange={(event) => {
                onChange({
                  value: event.target.checked,
                  type: type,
                });
              }}
              disabled={!editModeOn}
            />
          }
          label={undefined}
        />
      );
    case 'integer':
    case 'unsignedInt':
      return (
        <CustomTextField
          sx={{ width: '100%' }}
          value={value || ''}
          label={label}
          placeholder={label}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            onChange({
              value: parseFloat(e.target.value),
              type: type,
            });
          }}
          type="number"
          inputProps={{
            maxLength: 13,
            step: 1,
            min: 0,
          }}
          displayOnly={!editModeOn}
        />
      );
    case 'positiveInt':
    case 'decimal':
      return (
        <CustomTextField
          sx={{ width: '100%' }}
          value={value || ''}
          label={label}
          placeholder={label}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            onChange({
              value: parseFloat(e.target.value),
              type: type,
            });
          }}
          type="number"
          inputProps={{
            maxLength: 13,
            step: type === 'decimal' ? 'any' : 1,
            min: 0,
          }}
          displayOnly={!editModeOn}
        />
      );
    case 'code':
      return (
        <CodeInput
          valueSet={propertyDefinition.valueSet}
          name={label}
          value={value}
          onChange={(value: string) => {
            onChange({
              value: value,
              type: type,
            });
          }}
        />
      );
    case 'id':
    case 'string':
    case 'uri':
    case 'url':
    case 'canonical':
      return (
        <CustomTextField
          sx={{ width: '100%' }}
          value={value || ''}
          label={label}
          placeholder={label}
          onChange={(event): void => {
            onChange({
              value: event?.target?.value,
              type: type,
            });
          }}
          displayOnly={!editModeOn}
        />
      );
    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            PopperProps={{
              popperOptions: { placement: 'auto', strategy: 'fixed' },
            }}
            label={label}
            inputFormat="yyyy-MM-dd"
            value={value ?? null}
            onChange={(newVal: DateTime | null): void => {
              onChange({
                value: newVal?.toFormat('yyyy-MM-dd') ?? null,
                type: type,
              });
            }}
            renderInput={(params) => <CustomTextField value={value} {...params} displayOnly={!editModeOn} />}
            disabled={!editModeOn}
          />
        </LocalizationProvider>
      );
    case 'time':
      return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <TimePicker
            label={label}
            value={value ?? null}
            onChange={(newVal: string | null): void => {
              onChange({
                value: newVal ?? null,
                type: type,
              });
            }}
            renderInput={(params) => <CustomTextField value={value} {...params} />}
            disabled={!editModeOn}
          />
        </LocalizationProvider>
      );
    case 'dateTime':
    case 'instant':
      return (
        <DateTimeInput
          name={label}
          value={value ?? null}
          sx={{ width: '100%' }}
          onChange={(newValue) => {
            onChange({
              value: newValue,
              type: type,
            });
          }}
        />
      );
    case 'markdown':
      return (
        <TextareaAutosize
          name={label}
          data-testid={label}
          defaultValue={value}
          onChange={(newValue) => {
            onChange({
              value: newValue.target.value,
              type: type,
            });
          }}
          disabled={!editModeOn}
          style={{
            overflow: 'scroll',
            height: 100,
            fontFamily: 'monospace',
            lineHeight: 1.35,
            padding: 10,
            color: otherColors.gray800,
            borderColor: otherColors.gray200,
          }}
          onFocus={(e) => (e.currentTarget.style.outlineColor = palette.primary.light)}
        />
      );
  }
  console.log(`Unknown primitive property type ${type}`);
  return null;
}
