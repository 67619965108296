import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import * as React from 'react';

interface DateTimeFieldProps {
  label: string;
  value: DateTime | undefined;
  setValue: (val: DateTime | undefined) => void;
  disablePast?: boolean;
}

export function DateTimeField({ label, value, setValue, disablePast }: DateTimeFieldProps): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={navigator.language}>
      <DateTimePicker
        disablePast={disablePast}
        disableMaskedInput
        label={label}
        PopperProps={{
          disablePortal: true,
          popperOptions: { placement: 'auto', strategy: 'fixed' },
        }}
        renderInput={(props) => <TextField {...props} variant="outlined" />}
        // This works without timezone issues since I never send the timezone to AWS. It also means I don't have to deal
        // with DST math and how it doesn't impact 'rate' expressions in the same way.
        value={value ?? null}
        onChange={(newVal) => {
          setValue(newVal ?? undefined);
        }}
      />
    </LocalizationProvider>
  );
}
