import { Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography variant="h5">Page Not Found</Typography>
      <Typography variant="h6">
        Click <Link to="/">here</Link> to return home
      </Typography>
    </Box>
  );
};

export default NotFound;
