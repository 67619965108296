import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { alpha, CircularProgress } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { otherColors } from '../../../../contexts/AdminThemeProvider';
import { Services } from '../../../../services';
import { BucketTreeElement, deleteObject, downloadFile } from './Z3ObjectsHelpers';

export interface BodyProps {
  currentFolder: BucketTreeElement | undefined;
  loading: boolean;
  setCurrentFolder: React.Dispatch<React.SetStateAction<BucketTreeElement | undefined>>;
}

export const BodyElement: React.FC<BodyProps> = ({ loading, currentFolder }) => {
  const [objectForDeletion, setObjectForDeletion] = useState<BucketTreeElement>();
  const [objectForDownload, setObjectForDownload] = useState('');
  const [loadingInternal, setLoadingInternal] = useState(false);

  const initiateObjectDeletion = (objectName: string): void => {
    setObjectForDeletion(currentFolder?.getChild(objectName));
  };

  const getRowsData = (): BucketTreeElement[] => {
    if (currentFolder && currentFolder.children) {
      return currentFolder.children;
    }
    return [];
  };

  return (
    <>
      <DataGridPro
        loading={loading || loadingInternal}
        getRowId={(row) => row.name}
        columns={[
          {
            field: 'isFolder',
            headerName: 'Type',
            headerAlign: 'center',
            renderCell: (params) => (
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                {params.row.isFolder ? <FolderIcon /> : <InsertDriveFileIcon />}
              </div>
            ),
            width: 65,
          },
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) =>
              params.row.isFolder ? (
                <Link to={params.row.path}>{params.row.name}</Link>
              ) : (
                <span>{params.row.name}</span>
              ),
          },
          {
            field: 'download',
            headerName: 'Download',
            headerAlign: 'center',
            renderCell: (params) =>
              !params.row.isFolder ? (
                <div
                  style={{ margin: 'auto', textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => downloadFile(params.row.name, currentFolder, setObjectForDownload)}
                >
                  {objectForDownload == params.row.name ? (
                    <CircularProgress style={{ height: '20px', width: '20px' }} />
                  ) : (
                    <DownloadIcon style={{ color: '#2896C6' }} />
                  )}
                </div>
              ) : (
                <></>
              ),
          },
          {
            field: 'delete',
            headerName: 'Delete',
            headerAlign: 'center',
            renderCell: (params) => (
              <div
                style={{ margin: 'auto', textAlign: 'center', cursor: 'pointer' }}
                onClick={() => initiateObjectDeletion(params.row.name)}
              >
                <DeleteIcon style={{ color: '#D32F2F' }} />
              </div>
            ),
          },
        ]}
        rows={getRowsData().map((object: BucketTreeElement) => ({
          name: object.name,
          path: `/${Services.z3.rootPath}/${object.getFullPath().replace(/\/$/, '')}`,
          isFolder: object.isFolder,
        }))}
        components={{ Toolbar: GridToolbar }}
        // todo remove code duplication with SearchControl
        sx={{
          '& .MuiDataGrid-toolbarContainer': {
            pl: 1.5,
            gap: 4,
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
            '& .MuiButtonBase-root': {
              color: otherColors.charcoal87,
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
          },
        }}
      />
      {objectForDeletion && (
        <ConfirmationDialog
          open
          fullWidth
          title={`Delete ${objectForDeletion.name}?`}
          message={
            <>
              <span>Are you sure you want to delete {objectForDeletion.name}?</span>
              {objectForDeletion.isFolder ? (
                <>
                  <span>
                    <i>&nbsp;All the content of {objectForDeletion.name}/ will be also deleted.</i>
                  </span>
                </>
              ) : (
                ''
              )}
              <span style={{ display: 'block', color: '#D32F2F', marginTop: '10px' }}>
                This action is permanent and cannot be undone.
              </span>
            </>
          }
          buttonTitle={`Delete`}
          handleAction={() => deleteObject(objectForDeletion, setObjectForDeletion, setLoadingInternal)}
          handleClose={() => {
            setObjectForDeletion(undefined);
          }}
        />
      )}
    </>
  );
};
