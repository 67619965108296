import { PersonAddAlt1 } from '@mui/icons-material';
import { alpha, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { Suspense } from 'react';
import { Await, Link, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { Developer } from '../../../lib/client';
import { Services } from '../../../services';

export function DevelopersPage(): JSX.Element {
  const { pathname } = useLocation();
  const dataPromise = useLoaderData() as { developers: Promise<Developer[]> };

  return (
    <div>
      {pathname === `/${Services.iam.rootPath}/developers` ? (
        <Grid container>
          <Grid item xs={12} display="flex" marginBottom={2}>
            <Typography variant="h4">Developers</Typography>
            <ArticleShortcutIconButton link="https://docs.oystehr.com/services/iam/developers/" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Developer accounts are used to log into the Developer Console (this website) and administrate{' '}
              <MUILink target="_blank" href="https://docs.oystehr.com/services/project/">
                Projects
              </MUILink>
              . Here, you can manage Developer accounts with Platform API access.
            </Typography>
          </Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
            <Link to={`/${Services.iam.rootPath}/developers/new`}>
              <Button aria-label="new" variant="contained" startIcon={<PersonAddAlt1 />}>
                Invite
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ height: '60vh', marginTop: 4 }}>
              <Suspense fallback={<BodyElement developers={[]} loading />}>
                <Await resolve={dataPromise.developers} errorElement={<RootErrorBoundary />}>
                  {(developers) => {
                    return <BodyElement developers={developers} loading={false} />;
                  }}
                </Await>
              </Suspense>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

interface BodyProps {
  developers: Developer[];
  loading: boolean;
}
const BodyElement: React.FC<BodyProps> = ({ developers, loading }) => {
  return (
    <DataGridPro
      loading={loading}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          renderCell: (params) => <Link to={params.row.id}>{params.row.name}</Link>,
        },
        { field: 'id', headerName: 'ID', flex: 1 },
      ]}
      rows={developers.map((user: Developer) => ({
        id: user.id,
        name: user.name,
      }))}
      components={{ Toolbar: GridToolbar }}
      // todo remove code duplication with SearchControl
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          pl: 1.5,
          gap: 4,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '& .MuiButtonBase-root': {
            color: otherColors.charcoal87,
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
        },
      }}
    />
  );
};
