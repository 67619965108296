import { FormControl, FormHelperText, Grid, Link as MUILink, Stack, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FieldErrors, FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import { DateTimeField } from './DateTimeField';

export interface ZambdaScheduleDefaults {
  start: DateTime | undefined;
  end: DateTime | undefined;
  expression: string | undefined;
  maximumEventAge: number | undefined;
  maximumRetry: number | undefined;
}
interface ZambdaScheduleProps {
  errors: FieldErrors<FieldValues>;
  hasChanged: {
    start: boolean;
    end: boolean;
  };
  setHasChanged: Dispatch<
    SetStateAction<{
      start: boolean;
      end: boolean;
    }>
  >;
  methods: UseFormReturn<FieldValues, any, undefined>;
  expressionRequired: boolean;
  defaults: ZambdaScheduleDefaults;
}

const label = 'Expression';
const maximumRetryLabel = 'Maximum Retry Attempts';
const maximumEventAgeLabel = 'Maximum Event Age (seconds)';

export function ZambdaSchedule({
  errors,
  hasChanged,
  setHasChanged,
  methods,
  expressionRequired,
  defaults,
}: ZambdaScheduleProps): JSX.Element {
  const {
    expression: defaultExpression,
    start: defaultStart,
    end: defaultEnd,
    maximumEventAge: defaultMaximumEventAge,
    maximumRetry: defaultMaximumRetry,
  } = defaults;
  const start = useWatch({ name: 'start', defaultValue: defaultStart });
  const end = useWatch({ name: 'end', defaultValue: defaultEnd });
  const { setValue } = methods;
  const setStart = useCallback(
    (newVal: DateTime | undefined) => {
      setHasChanged({
        ...hasChanged,
        start: true,
      });
      setValue('start', newVal);
    },
    [hasChanged, setHasChanged, setValue]
  );
  const setEnd = useCallback(
    (newVal: DateTime | undefined) => {
      setHasChanged({
        ...hasChanged,
        end: true,
      });
      setValue('end', newVal);
    },
    [hasChanged, setHasChanged, setValue]
  );
  return (
    <>
      <Grid item key={label}>
        <FormControl fullWidth>
          <TextField
            {...methods.register(label.toLowerCase(), {
              required: expressionRequired && `${label} is a required field`,
              pattern: {
                value: /^(rate|cron)\(.*\)$/,
                message: 'Expression must be in the form of "rate()" or "cron()".',
              },
            })}
            label={label}
            fullWidth
            defaultValue={defaultExpression}
            error={!!errors[label.toLowerCase()]}
            helperText={errors[label.toLowerCase()]?.message as string}
            placeholder={'rate(7 days)'}
            InputLabelProps={{ shrink: true }}
          />
          <FormHelperText>
            Learn more about schedule expressions in the{' '}
            <MUILink target="_blank" href="https://docs.oystehr.com/services/zambda/types/scheduled/">
              documentation
            </MUILink>
            .
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item key="Retry" xs={6}>
        <FormControl fullWidth>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <TextField
              {...methods.register('maximumEventAge', {
                min: {
                  value: 60,
                  message: 'Minimum allowed value is 60 seconds.',
                },
                max: {
                  value: 86400,
                  message: 'Maximum allowed value is 86400 seconds.',
                },
              })}
              label={maximumEventAgeLabel}
              type="number"
              fullWidth
              defaultValue={defaultMaximumEventAge}
              error={!!errors.maximumEventAge}
              helperText={errors.maximumEventAge?.message as string}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              {...methods.register('maximumRetry', {
                min: {
                  value: 0,
                  message: 'Minimum allowed value is 0.',
                },
                max: {
                  value: 185,
                  message: 'Maximum allowed value is 185.',
                },
              })}
              label={maximumRetryLabel}
              type="number"
              fullWidth
              defaultValue={defaultMaximumRetry}
              error={!!errors.maximumRetry}
              helperText={errors.maximumRetry?.message as string}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <FormHelperText>
            The maximum age of a request that Zambda sends to a function for processing and the maximum number of
            retries.
          </FormHelperText>
        </FormControl>
      </Grid>{' '}
      <Grid item key="Duration">
        <FormControl fullWidth>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <DateTimeField label="Start" value={start} setValue={setStart} disablePast />
            <DateTimeField label="End" value={end} setValue={setEnd} />
          </Stack>
          <FormHelperText>Times are in UTC.</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}
