import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

export const FullScreenLoadingIndicator: FC<any> = () => {
  return (
    <Box
      sx={{ height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
    >
      <CircularProgress />
    </Box>
  );
};
