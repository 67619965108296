import { LoadingButton } from '@mui/lab';
import { Grid, Paper, TextField, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Services } from '../../../../services';

export function Z3BucketCreatePage(): JSX.Element {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: any): void => {
      setLoading(true);

      zapehr.project.z3
        .createBucket({ bucketName: data.bucketName })
        .then(() => {
          revalidator.revalidate();
          toast.success('Bucket created');
          navigate(`/${Services.z3.rootPath}`);
        })
        .catch((error) => {
          toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
          setLoading(false);
        });
    },
    [navigate, revalidator]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Create Bucket
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={4} padding={2}>
              <Grid item>
                <TextField
                  {...register('bucketName', {
                    required: `Bucket name is a required field`,
                    pattern: { value: /^\S+$/, message: 'Bucket name must not contain spaces.' },
                  })}
                  fullWidth
                  label="Name"
                  error={!!errors.bucketName}
                  helperText={(errors.bucketName?.message as string) || 'Name of the bucket'}
                />
              </Grid>
              <Grid item textAlign="center">
                <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
