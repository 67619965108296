import { Box, Card, CardActionArea, CardActions, CardContent, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { otherColors } from '../contexts/AdminThemeProvider';

export interface HomePageInfoCardProps {
  label: string;
  description?: string;
  icon?: JSX.Element;
  iconAtTheEnd?: JSX.Element;
  centerIcons?: boolean;
  backgroundColor?: string;
  textColor?: string;
  children?: JSX.Element;
  onClick?: () => void;
  path?: string;
}

export function HomePageInfoCard(props: HomePageInfoCardProps): JSX.Element {
  const theme = useTheme();
  const textColor = props.textColor ?? theme.palette.text.primary;
  const backgroundColor = props.backgroundColor ?? otherColors.DarkPrimaryShades16p;
  const centerIcon = props.centerIcons ?? false;

  const CardContentInfo = (): JSX.Element => {
    return (
      <CardContent
        sx={{
          py: theme.spacing(2),
          px: theme.spacing(3),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="row">
          <Box display="flex" alignItems={centerIcon ? 'center' : 'start'}>
            {props.icon}
          </Box>
          <Box display="flex" flexDirection="column" px={theme.spacing(3)}>
            <Typography variant="h6" color={textColor}>
              {props.label}
            </Typography>
            <Typography variant="body2" color={textColor}>
              {props.description}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems={centerIcon ? 'center' : 'start'}>
          {props.iconAtTheEnd}
        </Box>
      </CardContent>
    );
  };

  return (
    <Card sx={{ backgroundColor, boxShadow: 'none', borderRadius: 2, width: '100%' }}>
      <CardActionArea
        onClick={props.onClick}
        sx={{
          width: '100%',
        }}
      >
        {props.path ? (
          <Link
            to={props.path}
            style={{ textDecoration: 'none' }}
            target={props.path.startsWith('http') ? '_blank' : undefined}
          >
            <CardContentInfo />
          </Link>
        ) : (
          <CardContentInfo />
        )}
      </CardActionArea>
      {props.children && (
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            '.MuiButton-root': {
              ml: 0,
            },
          }}
        >
          {props.children}
        </CardActions>
      )}
    </Card>
  );
}
