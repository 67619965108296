import * as React from 'react';
import { Link } from 'react-router-dom';
import { BucketTreeElement } from '../../pages/Z3/Objects/lib/Z3ObjectsHelpers';
import { Services } from '../../services';

export interface BreadcrumbsProps {
  currentFolder: BucketTreeElement | undefined;
  setCurrentFolder: React.Dispatch<React.SetStateAction<BucketTreeElement | undefined>>;
}

const getCrumbLink = (crumb: BucketTreeElement): string => {
  const path = crumb.getFullPath().replace(/\/$/, '');
  return `/${Services.z3.rootPath}/${path}`;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ currentFolder }) => {
  let folder: BucketTreeElement | undefined = currentFolder;
  const crumbs: BucketTreeElement[] = [];
  while (folder) {
    crumbs.unshift(folder);
    folder = folder.parent;
  }

  return (
    <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
      <Link to={`/${Services.z3.rootPath}`}>All buckets</Link>
      <span>&nbsp;{'>'}&nbsp;</span>
      {crumbs.map((crumb, index) => {
        return index != crumbs.length - 1 ? (
          <span>
            <Link to={getCrumbLink(crumb)}>{crumb.name}</Link>
            <span>&nbsp;{'>'}&nbsp;</span>
          </span>
        ) : (
          <span>{crumb.name}</span>
        );
      })}
    </div>
  );
};
