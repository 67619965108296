import Inventory2Icon from '@mui/icons-material/Inventory2';
import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { PageContainer } from '../../components/PageContainer';
import { Services } from '../../services';
import { Z3BucketsPage } from './Buckets';

export const userSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Buckets',
      icon: <Inventory2Icon />,
      path: `/${Services.z3.rootPath}`,
    },
  ],
];

export function Z3Page(): JSX.Element {
  const { pathname } = useLocation();

  return (
    <PageContainer sidebarItems={userSidebarItems}>
      {pathname === `/${Services.z3.rootPath}` ? <Z3BucketsPage /> : <Outlet />}
    </PageContainer>
  );
}
