import { Search } from '@mui/icons-material';
import { Button, Theme } from '@mui/material';
import * as React from 'react';

interface SearchButtonProps {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  theme: Theme;
}

export const SearchButton = ({ disabled, onClick, theme }: SearchButtonProps): JSX.Element => {
  return (
    <Button
      sx={{
        bgcolor: theme.palette.primary.main + '1F',
        color: theme.palette.primary.dark,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        textTransform: 'none',
      }}
      disabled={disabled}
      onClick={onClick}
      endIcon={<Search />}
    >
      Search
    </Button>
  );
};
