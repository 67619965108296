import { StyledComponent } from '@emotion/styled';
import { AccountCircle, Add, ArrowDropDown, ArrowDropUp, Edit, WidgetsOutlined } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Skeleton,
  styled,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';
import * as React from 'react';
import { DetailedHTMLProps, HTMLAttributes, useMemo, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { otherColors } from '../contexts/AdminThemeProvider';
import { FhirVersion, Project, useZapehr } from '../contexts/ZapehrProvider';
import { getSchema } from '../lib/schema';
import { AutocompleteOption } from '../lib/types';
import { Services } from '../services';
import { Logo } from './Logo';
import { SearchBarAutocomplete } from './WrappedInputs/SearchBarAutocomplete';

export const resourcesAutocomplete = (fhirVersion: FhirVersion | undefined): AutocompleteOption[] => {
  const schema = getSchema(fhirVersion);
  return Object.keys(schema)
    .filter((type) => schema[type].isResourceType && type !== 'Parameters' && type !== 'OperationOutcome')
    .map((type) => ({
      label: type,
      path: `/${Services.fhir.rootPath}/${type}`,
      group: 'Resource types',
      value: type,
    }));
};

export const generateDefaultOptionList = (services: typeof Services.all): AutocompleteOption[] => {
  return services
    .filter((service: { includeInNavbar: any; name: string }) => service.includeInNavbar || service.name === 'IAM')
    .flatMap((service): AutocompleteOption[] => {
      // Custom entry for IAM with a specific path, temporary, remove when we add more to IAM
      if (service.name === 'IAM') {
        return [
          {
            label: 'IAM',
            path: `/${service.rootPath}/m2m-clients`,
            value: 'IAM',
            group: 'Service',
          },
          ...service.features.map(
            (feature): AutocompleteOption => ({
              label: `${service.name} / ${feature.name}`,
              path: `/${service.rootPath}/${feature.path}`,
              value: `${service.name} / ${feature.name}`,
              group: 'Service Feature',
            })
          ),
        ];
      }
      return [
        {
          label: service.name,
          path: `/${service.rootPath}`,
          value: service.name,
          group: 'Service',
        },
        ...service.features.map(
          (feature): AutocompleteOption => ({
            label: `${service.name} / ${feature.name}`,
            path: `/${service.rootPath}${feature.path ? '/' + feature.path : ''}`,
            value: `${service.name} / ${feature.name}`,
            group: 'Service Feature',
          })
        ),
      ];
    });
};

export const HeaderOffset: StyledComponent<
  MUIStyledCommonProps<Theme>,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  any
> = styled('div')(({ theme }) => theme.mixins.toolbar);

export function Header(): JSX.Element {
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [profileAnchorElement, setProfileAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [projectAnchorElement, setProjectAnchorElement] = useState<HTMLButtonElement | null>(null);
  const { currentProject, projectsList, switchProject } = useZapehr();
  const theme = useTheme();
  const navigate = useNavigate();

  const headerLinkStyle = { color: 'inherit', width: '100%', textDecoration: 'none' };

  // Media queries for cleaning up header on small viewports
  const shortSearchBarLabel = useMediaQuery('(max-width: 1000px)');
  const hideLogoTypography = useMediaQuery('(max-width: 855px)');
  const noSearchBarLabel = useMediaQuery('(max-width: 750px)');
  const hideSearchBar = useMediaQuery('(max-width: 717px)');
  const noServicesLabel = useMediaQuery('(max-width: 510px)');
  const searchBarLabel = useMemo(() => {
    if (noSearchBarLabel) {
      return '';
    }
    if (shortSearchBarLabel) {
      return 'Search...';
    }
    return 'Search services and resources...';
  }, [noSearchBarLabel, shortSearchBarLabel]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
        <AppBar position="fixed" sx={{ backgroundColor: 'tertiary.main' }}>
          <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(4),
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: theme.spacing(2) }}>
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    display: 'inherit',
                    // fill 100% of parent height
                    alignItems: 'center',
                    alignSelf: 'normal',
                  }}
                >
                  <Box mt={0.1}>
                    <Logo width={125} />
                  </Box>
                  <Typography
                    variant="subtitle2"
                    color="primary.light"
                    marginLeft={1.5}
                    display={hideLogoTypography ? 'none' : 'block'}
                  >
                    CONSOLE
                  </Typography>
                </Link>
                <Divider
                  orientation="vertical"
                  sx={{
                    background: otherColors.white50,
                    marginTop: '5px',
                    marginBottom: '5px',
                  }}
                  flexItem
                />
                <ServicesButton
                  startIcon={<WidgetsOutlined />}
                  onClick={(event) => setMenuAnchorElement(event.currentTarget)}
                  variant="outlined"
                  sx={{
                    height: noServicesLabel ? '36px' : undefined,
                    'span.MuiButton-startIcon': {
                      marginRight: noServicesLabel ? '-4px' : undefined,
                    },
                  }}
                >
                  {noServicesLabel ? '' : 'services'}
                </ServicesButton>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    backgroundColor: otherColors.inputBackground,
                  },
                  label: {
                    color: otherColors.white80,
                  },
                  input: {
                    color: theme.palette.primary.contrastText,
                  },
                  display: hideSearchBar ? 'none' : 'block',
                }}
              >
                <SearchBarAutocomplete
                  disabled={false}
                  optionsList={resourcesAutocomplete(currentProject?.fhirVersion).concat([
                    { label: 'Project Settings', path: '/project', group: 'Project', value: 'Project Settings' },
                    ...generateDefaultOptionList(Services.all),
                  ])}
                  label={searchBarLabel}
                  selectToRedirect={true}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: theme.spacing(2) }}>
                <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <Tooltip
                    title="This is your project's name. Click to see a list of all your projects."
                    placement="top"
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        disabled={!currentProject}
                        endIcon={projectAnchorElement ? <ArrowDropUp /> : <ArrowDropDown />}
                        style={{
                          color: theme.palette.primary.light,
                          border: `1px solid ${theme.palette.primary.light}`,
                          width: '170px',
                        }}
                        onClick={(event) => setProjectAnchorElement(event.currentTarget)}
                        aria-haspopup="true"
                        aria-controls={projectAnchorElement ? 'project-menu' : undefined}
                        aria-expanded={projectAnchorElement ? 'true' : undefined}
                      >
                        {currentProject ? (
                          <span
                            style={{
                              textOverflow: 'ellipsis',
                              display: 'inline-block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              flex: 1,
                            }}
                          >
                            {currentProject.name}
                          </span>
                        ) : (
                          <Skeleton
                            variant="text"
                            width={100}
                            animation="wave"
                            sx={{
                              display: 'inline-flex',
                              bgcolor: theme.palette.primary.dark,
                            }}
                          />
                        )}
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
                <Menu
                  id="project-menu"
                  anchorEl={projectAnchorElement}
                  open={Boolean(projectAnchorElement)}
                  onClose={() => setProjectAnchorElement(null)}
                  MenuListProps={{
                    'aria-labelledby': 'project-button',
                  }}
                >
                  <Box bgcolor="#202A3E" sx={{ margin: '-8px 0' }}>
                    {projectsList ? (
                      projectsList.map((project) => (
                        <ProjectMenuItem
                          project={project}
                          currentProjectId={currentProject?.id}
                          navigate={navigate}
                          switchProject={switchProject}
                          closeMenu={() => setProjectAnchorElement(null)}
                        />
                      ))
                    ) : (
                      <></>
                    )}

                    <Button
                      onClick={() => {
                        setProjectAnchorElement(null);
                        navigate('/project/new');
                      }}
                      variant="contained"
                      startIcon={<Add />}
                      sx={{ width: '100%', height: '45px', borderRadius: 0 }}
                    >
                      Create project
                    </Button>
                  </Box>
                </Menu>
                <IconButton
                  id="account-button"
                  sx={{ color: theme.palette.primary.light, paddingRight: '0' }}
                  onClick={(event) => setProfileAnchorElement(event.currentTarget)}
                  aria-haspopup="true"
                  aria-controls={profileAnchorElement ? 'account-menu' : undefined}
                  aria-expanded={profileAnchorElement ? 'true' : undefined}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="account-menu"
                  anchorEl={profileAnchorElement}
                  open={Boolean(profileAnchorElement)}
                  onClose={() => setProfileAnchorElement(null)}
                  MenuListProps={{
                    'aria-labelledby': 'account-button',
                  }}
                >
                  {process.env.REACT_APP_ENV === 'local' && (
                    <Link to="/version">
                      <MenuItem>Version</MenuItem>
                    </Link>
                  )}
                  <Link to="/logout">
                    <MenuItem>Logout</MenuItem>
                  </Link>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <HeaderOffset id="HeaderOffset" />
      </Box>
      <Popover
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={() => setMenuAnchorElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box bgcolor="tertiary.main" sx={{ color: theme.palette.primary.contrastText }} width={400}>
          <List
            sx={{
              '.MuiButtonBase-root:hover': {
                backgroundColor: 'primary.light',
              },
              '#fhir-resources-list-button:hover': {
                h6: {
                  color: theme.palette.primary.contrastText,
                },
                svg: {
                  color: theme.palette.primary.contrastText,
                },
              },
            }}
          >
            {/* <Divider sx={{ borderColor: otherColors.white12 }} /> */}
            {Services.all
              .filter((service) => service.includeInNavbar)
              .map((service) => {
                return (
                  <ListItem key={service.rootPath}>
                    <Link to={service.rootPath} style={headerLinkStyle}>
                      <ListItemButton dense={true} onClick={() => setMenuAnchorElement(null)}>
                        <ListItemText>
                          <Typography variant="subtitle2">{service.name}</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </Link>
                  </ListItem>
                );
              })}
            {/* todo temporary, remove when we add more to IAM */}
            <ListItem>
              <Link to="/iam/m2m-clients" style={headerLinkStyle}>
                <ListItemButton dense={true} onClick={() => setMenuAnchorElement(null)}>
                  <ListItemText>
                    <Typography variant="subtitle2">IAM</Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

const ServicesButton = styled(Button)(({ theme }) => {
  return {
    color: theme.palette.primary.light,
    paddingLeft: '15px',
    paddingRight: '15px',
    minWidth: 'unset',
  };
});

interface ProjectMenuItemProps {
  project: Project;
  currentProjectId: string | undefined;
  navigate: NavigateFunction;
  switchProject: (projectId: string) => void;
  closeMenu: () => void;
}

const ProjectMenuItem = (props: ProjectMenuItemProps): JSX.Element => {
  const { project, currentProjectId, navigate, switchProject, closeMenu } = props;
  const isCurrentProject = currentProjectId == project.id;
  const commonProjectStyle = {
    transition: '250ms',
    '&:hover': {
      transition: '250ms',
      background: '#2896C6',
    },
  };
  const activeProjectStyle = {
    transition: '250ms',
    background: '#2896C6',
    '&:hover': {
      transition: '250ms',
      background: '#1D7BA4',
    },
  };
  const style = isCurrentProject ? activeProjectStyle : commonProjectStyle;
  return (
    <Box sx={{ display: 'flex', color: '#FFFFFF', paddingBottom: '1px' }}>
      <Box
        sx={{ ...style, flex: 1, cursor: 'pointer', padding: '16px 32px 16px 16px' }}
        onClick={() => {
          closeMenu();
          switchProject(project.id);
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>{project.name}</Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 300 }}>{project.id}</Typography>
      </Box>
      {isCurrentProject && (
        <Box
          sx={{ ...style, display: 'flex', alignItems: 'center', cursor: 'pointer', width: '55px' }}
          onClick={() => {
            closeMenu();
            navigate('/project');
          }}
        >
          <Edit style={{ margin: 'auto', color: '#FFFFFF' }} />
        </Box>
      )}
    </Box>
  );
};
