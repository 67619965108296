import { capitalize } from '@mui/material';
import * as React from 'react';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { CustomTextField } from '../WrappedInputs/CustomTextField';
import { ArrayPropertyInput } from './ArrayPropertyInput';
import { ChoiceTypePropertyInput } from './ChoiceTypePropertyInput';
import { CodingInput } from './CodingInput';
import { PropertyInputProps } from './common';
import { ComplexPropertyInput } from './ComplexPropertyInput';
import { PrimitivePropertyInput } from './PrimitivePropertyInput';
import { ReferenceInput } from './ReferenceInput';

export function PropertyInput(props: PropertyInputProps): JSX.Element | null {
  const { propertyDefinition, value, onChange } = props;
  const { editModeOn } = useResourceEditingContext();

  if (propertyDefinition.choiceTypes !== undefined) {
    return <ChoiceTypePropertyInput propertyDefinition={propertyDefinition} value={value} onChange={onChange} />;
  }

  if (propertyDefinition.isArray) {
    return <ArrayPropertyInput propertyDefinition={propertyDefinition} value={value} onChange={onChange} />;
  }

  if (propertyDefinition.type === 'Reference') {
    if (propertyDefinition.targetTypes?.[0] === 'Resource') {
      return (
        <CustomTextField
          sx={{ width: '100%' }}
          value={value?.value?.reference ?? ''}
          label={capitalize(propertyDefinition.name)}
          onChange={(event): void => {
            const text = event?.target?.value;
            const tokens = text?.split('/');
            onChange({
              type: propertyDefinition.type,
              value: {
                type: tokens[0],
                reference: text,
              },
            });
          }}
          displayOnly={!editModeOn}
        />
      );
    } else {
      return (
        <ReferenceInput
          propertyDefinition={propertyDefinition}
          value={value?.value}
          targetTypes={propertyDefinition.targetTypes}
          onChange={(reference) => {
            onChange({
              value: reference,
              type: propertyDefinition.type,
            });
          }}
        />
      );
    }
  }

  if (propertyDefinition.type === 'Coding' && propertyDefinition.valueSet !== undefined) {
    return (
      <CodingInput
        propertyDefinition={propertyDefinition}
        value={value?.value}
        onChange={(value) => {
          if (value === undefined) {
            onChange({
              value: {},
              type: propertyDefinition.type,
            });
          } else {
            onChange({
              value: value,
              type: propertyDefinition.type,
            });
          }
        }}
      />
    );
  }

  if (propertyDefinition.type === 'CodeableConcept' && propertyDefinition.valueSet !== undefined) {
    return (
      <CodingInput
        propertyDefinition={propertyDefinition}
        value={value?.value?.coding?.[0]}
        onChange={(value) => {
          if (value === undefined) {
            onChange({
              value: {},
              type: propertyDefinition.type,
            });
          } else {
            onChange({
              value: {
                coding: [value],
              },
              type: propertyDefinition.type,
            });
          }
        }}
      />
    );
  }

  const typeFirstChar = propertyDefinition.type.charAt(0);
  if (typeFirstChar === typeFirstChar.toUpperCase()) {
    return (
      <ComplexPropertyInput
        propertyDefinition={propertyDefinition}
        value={value}
        onChange={onChange}
        showInfo={propertyDefinition.type.includes('.')}
      />
    );
  }

  return <PrimitivePropertyInput propertyDefinition={propertyDefinition} value={value} onChange={onChange} />;
}
