import { Avatar, AvatarProps, SxProps } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Reference, Resource } from '../lib/fhir-types';
import { getDisplayString, getImageSrc } from '../lib/niftyFunctions';
import { useResource } from '../lib/useResource';

export interface CustomAvatarProps extends AvatarProps {
  value?: Reference | Resource;
  link?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  sx?: SxProps;
}

export function ResourceAvatar(props: CustomAvatarProps): JSX.Element {
  const resource = useResource(props.value);
  const text = resource ? getDisplayString(resource) : props.alt ?? '';
  const imageUrl = (resource && getImageSrc(resource)) ?? props.src;

  const avatarProps = { ...props };
  delete avatarProps.value;
  delete avatarProps.link;

  const avatar = <Avatar src={imageUrl} alt={text} {...avatarProps} sx={convertSizeToSx(props.size)} />;

  if (props.link) {
    return <Link to={resource ? `/resources/${resource.resourceType}/${resource.id}` : '#'}>{avatar}</Link>;
  }

  return avatar;
}

function convertSizeToSx(size?: string): SxProps {
  switch (size) {
    case 'xsmall':
      return { width: 20, height: 20 };

    case 'small':
      return { width: 24, height: 24 };

    case 'medium':
      return { width: 36, height: 36 };

    case 'large':
      return { width: 56, height: 56 };

    default:
      return null;
  }
}
