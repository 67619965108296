import './ResourceInputForm.css';
import * as React from 'react';
import { TypedValue } from './common';
import { ComplexPropertyInput } from './ComplexPropertyInput';

export interface ResourceInputFormProps {
  resourceType: string;
  value: any;
  onChange: (value: any) => void;
}

export function ResourceInputForm(props: ResourceInputFormProps): JSX.Element {
  const { resourceType, value, onChange } = props;

  return (
    <ComplexPropertyInput
      propertyDefinition={{
        path: resourceType,
        name: resourceType,
        type: resourceType,
        isArray: false,
      }}
      value={{
        value: value,
        type: resourceType,
      }}
      onChange={(newValue: TypedValue) => {
        console.log(`ResourceInputForm newValue=${JSON.stringify(newValue.value)}`);
        onChange(newValue.value);
      }}
      showInfo={true}
    />
  );
}
