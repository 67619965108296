import { Box, useTheme } from '@mui/material';
import * as React from 'react';

export interface HomePageRoundIconProps {
  backgroundColor: string;
  icon: JSX.Element;
}

export function HomePageRoundIcon(props: HomePageRoundIconProps): JSX.Element {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: 999,
        height: '50px',
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: props.backgroundColor,
      }}
    >
      <Box sx={{ height: '24px', width: '24px', color: theme.palette.primary.contrastText }}>{props.icon}</Box>
    </Box>
  );
}
