import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

export interface CreateFolderDialogProps {
  currentLocation: string | undefined;
  handleClose: () => void;
  handleSubmit: (folderName: string) => void;
}

export const CreateFolderDialog: React.FC<CreateFolderDialogProps> = ({
  currentLocation,
  handleClose,
  handleSubmit,
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const onSubmit = (): void => {
    if (!name) {
      setError('Folder name cannot be empty.');
      return;
    }
    if (name.match(/[#/\s]/)) {
      setError('Folder name must not contain spaces, # or / (use "-" instead).');
      return;
    }
    handleSubmit(name);
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      onKeyUp={(e) => {
        if (e.key == 'Enter') {
          onSubmit();
        }
      }}
    >
      <DialogTitle>New folder</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create folder at <i>{currentLocation}</i>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Folder name"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setName(e.target.value);
            setError('');
          }}
          error={error != ''}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSubmit}>
          Create
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
