import { Search } from '@mui/icons-material';
import {
  alpha,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { Await, Link as ReactRouterLink, useParams, useRouteLoaderData } from 'react-router-dom';
import { SearchButton } from '../../../../components';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { LogEvent, Zambda } from '../../../../lib/client';
import { DateTimeField } from '../lib/DateTimeField';
import { ZambdaDetailLoaderData } from '../ZambdaDetail';
import { Row } from '../ZambdaLogEvents';
import { filterEvents } from './filterEvents';

interface PageState {
  readonly events: LogEvent[];
  readonly nextToken?: string;
  readonly initialLoading: boolean;
  readonly loadingEvents: boolean;
}

export const ZambdaLogStreamSearch: FC = () => {
  const { id } = useParams();
  const theme = useTheme();
  const data = useRouteLoaderData('zambda-detail') as ZambdaDetailLoaderData;
  const [state, setState] = useState<PageState>({
    events: [],
    initialLoading: true,
    loadingEvents: false,
  });
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [start, setStart] = useState<DateTime | undefined>(undefined);
  const [end, setEnd] = useState<DateTime | undefined>(undefined);

  useEffect(() => {
    const fetchInitialLogEvents = async (): Promise<void> => {
      setState((state) => {
        return {
          ...state,
          loadingEvents: true,
        };
      });
      const { newEvents, nextToken } = await filterEvents({
        id,
      });
      setState((state) => {
        return {
          ...state,
          loadingEvents: false,
          initialLoading: false,
          events: newEvents,
          nextToken,
        };
      });
    };
    void fetchInitialLogEvents();
  }, [id]);

  const loadMoreEvents = useCallback(async (): Promise<void> => {
    setState((state) => {
      return {
        ...state,
        loadingEvents: true,
      };
    });
    const { newEvents, nextToken } = await filterEvents({
      id,
      filter,
      start,
      end,
      nextToken: state.nextToken,
    });
    setState((state) => {
      return {
        ...state,
        loadingEvents: false,
        events: state.events.concat(newEvents),
        nextToken,
      };
    });
  }, [id, filter, start, end, state.nextToken]);

  const searchFilteredEvents = useCallback(async (): Promise<void> => {
    setState((state) => {
      return {
        ...state,
        loadingEvents: true,
      };
    });
    const { newEvents, nextToken } = await filterEvents({
      id,
      filter,
      start,
      end,
    });
    setState((state) => {
      return {
        ...state,
        loadingEvents: false,
        events: newEvents,
        nextToken,
      };
    });
  }, [id, filter, start, end]);

  const renderLogEvents = useCallback((): JSX.Element => {
    if (state.initialLoading) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={25} />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {state.events.map((event, i) => (
          <Row key={`${event.timestamp}${i}`} logEvent={event} />
        ))}
      </>
    );
  }, [state.initialLoading, state.events]);

  const renderLoadEventsSection = useCallback((): JSX.Element | null => {
    if (!state.nextToken) {
      return null;
    }
    return (
      <TableRow>
        <TableCell colSpan={2}></TableCell>
        <TableCell>
          {state.loadingEvents ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CircularProgress size={16} sx={{ marginRight: '8px' }} /> Loading new events.
            </Box>
          ) : (
            <Link onClick={loadMoreEvents} underline="hover" href="#">
              Load new events.
            </Link>
          )}
        </TableCell>
      </TableRow>
    );
  }, [state.nextToken, state.loadingEvents, loadMoreEvents]);

  return (
    <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
      <Await resolve={data.zambda} errorElement={<RootErrorBoundary />}>
        {(zambda: Zambda) => {
          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ReactRouterLink to={`/zambdas/${id}/logs`}>Back to all logs</ReactRouterLink>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" color="text.primary">
                  Logs of {zambda.name}
                </Typography>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Search"
                    fullWidth={true}
                    sx={{
                      '.MuiInputBase-root': {
                        backgroundColor: alpha(theme.palette.background.paper, 0.12),
                      },
                    }}
                    // issue with label moving without input, modified from https://github.com/mui/material-ui/issues/13898#issuecomment-1109447249
                    InputLabelProps={{
                      sx: {
                        '&.MuiInputLabel-root': {
                          transform: 'translate(45px, 1rem)',
                          cursor: 'text',
                        },
                        '&.Mui-focused': {
                          transform: 'translate(14px, -9px) scale(0.75)',
                        },
                        '&.MuiFormLabel-filled': {
                          transform: 'translate(14px, -9px) scale(0.75)',
                        },
                        '&.MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled) ~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend':
                          {
                            maxWidth: 0,
                          },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search sx={{ color: 'primary.dark' }} />
                        </InputAdornment>
                      ),
                      size: 'medium',
                    }}
                    defaultValue={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Grid>
                <Box sx={{ display: 'flex', gap: 1, mt: 1, mx: 1 }}>
                  <DateTimeField label="Start" value={start} setValue={setStart} />
                  <DateTimeField label="End" value={end} setValue={setEnd} />
                  <SearchButton disabled={state.loadingEvents} onClick={searchFilteredEvents} theme={theme} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minHeight: '60vh',
                      tableLayout: 'fixed',
                      td: { paddingBottom: 0, paddingTop: 0 },
                      tr: { height: '40px' },
                    }}
                    aria-label="collapsible table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width={'50px'} sx={{ fontWeight: 600 }}></TableCell>
                        <TableCell width={'300px'} sx={{ fontWeight: 600 }}>
                          Timestamp
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {renderLogEvents()}
                      {renderLoadEventsSection()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <ReactRouterLink to={`/zambdas/${id}/logs`}>Back to all logs</ReactRouterLink>
              </Grid>
            </Grid>
          );
        }}
      </Await>
    </Suspense>
  );
};
