/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddBox } from '@mui/icons-material';
import { alpha, Button, Grid, Link as MUILink, Paper, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { Suspense } from 'react';
import { Await, Link, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { IAMRoleListItem } from '../../../lib/types';
import { prettyJSON } from '../../../lib/utils';
import { Services } from '../../../services';

export function RolesPage(): JSX.Element {
  const rolesPromise = useLoaderData() as { roles: Promise<IAMRoleListItem[]> };
  const { pathname } = useLocation();
  return (
    <div>
      {pathname === `/${Services.iam.rootPath}/roles` ? (
        <Grid container>
          <Grid item xs={12} display="flex" marginBottom={2}>
            <Typography variant="h4">Roles</Typography>
            <ArticleShortcutIconButton link="https://docs.oystehr.com/services/iam/roles/" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              A Role defines an access policy that can be assigned to any number of{' '}
              <MUILink href={'https://docs.oystehr.com/services/iam/developers/'} target="_blank">
                Developers
              </MUILink>
              ,{' '}
              <MUILink href={'https://docs.oystehr.com/services/app/users/'} target="_blank">
                Users
              </MUILink>
              , and{' '}
              <MUILink href={'https://docs.oystehr.com/services/iam/m2m-clients/'} target="_blank">
                M2M Clients
              </MUILink>
              .
            </Typography>
          </Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
            <Link to={`/${Services.iam.rootPath}/roles/new`}>
              <Button aria-label="new" variant="contained" startIcon={<AddBox />}>
                New Role
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ height: '60vh', marginTop: 4 }}>
              <Suspense fallback={<RoleList roles={[]} loading />}>
                <Await resolve={rolesPromise.roles} errorElement={<RootErrorBoundary />}>
                  {(roles) => {
                    return <RoleList roles={roles} loading={false} />;
                  }}
                </Await>
              </Suspense>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

interface RoleListProps {
  roles: IAMRoleListItem[];
  loading: boolean;
}

function RoleList(props: RoleListProps): JSX.Element {
  const { roles, loading } = props;
  const theme = useTheme();

  return (
    <DataGridPro
      loading={loading}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          renderCell: (params) => <Link to={params.row.id}>{params.row.name}</Link>,
        },
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'members', headerName: 'Members', flex: 1 },
      ]}
      rows={roles.map((role) => ({
        id: role.id,
        name: role.name,
        members: role.memberCount,
      }))}
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          pl: 1.5,
          gap: 4,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '& .MuiButtonBase-root': {
            color: otherColors.charcoal87,
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
        },
      }}
    />
  );
}
