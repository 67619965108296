import { Settings } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { PageContainer } from '../../components/PageContainer';
import { PageHeader } from '../../components/PageHeader';

const sidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Version',
      icon: <Settings />,
      path: '/version',
    },
  ],
];

export function VersionPage(): JSX.Element {
  return (
    <PageContainer sidebarItems={sidebarItems}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageHeader text="Version Information" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Version: {process.env.REACT_APP_VERSION}</Typography>
          <Typography variant="h5">Environment: {process.env.REACT_APP_ENV}</Typography>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
