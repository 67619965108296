import { Box, Breadcrumbs, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { otherColors } from '../contexts/AdminThemeProvider';
import { Patient, Reference } from '../lib/fhir-types';
import { calculateAgeString } from '../lib/niftyFunctions';
import { Services } from '../services';
import { HumanNameDisplay, ResourceAvatar, useResource } from './index';

export interface PatientHeaderProps {
  patient: Patient | Reference;
}

export const PatientHeader: React.FC<PatientHeaderProps> = (props: PatientHeaderProps) => {
  const patient = useResource(props.patient) as Patient | undefined;
  const theme = useTheme();

  if (!patient) {
    return <></>;
  }

  const patientName = patient.name ? (
    <HumanNameDisplay value={patient.name?.[0]} options={{ use: false }} />
  ) : (
    '[blank]'
  );

  return (
    <Box sx={{ bgcolor: 'primary.contrast', marginLeft: '-36px', marginRight: '-36px', marginTop: '-36px' }}>
      <Grid container spacing={2} sx={{ padding: '4px 20px', marginTop: 0, marginRight: '16px' }}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ color: theme.palette.text.secondary }}
              to={`/${Services.fhir.rootPath}/${patient.resourceType}`}
            >
              {patient.resourceType}
            </Link>
            <Typography color={theme.palette.text.primary}>{patientName}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <ResourceAvatar value={patient} color={getDefaultColor(patient)} size="medium" />
            <Typography style={{ display: 'inline-block' }} variant="h5" color={theme.palette.text.primary}>
              {patientName}
            </Typography>
            {patient.birthDate && (
              <div>
                {typographyWrapper(
                  'DOB:',
                  patient.birthDate + ' (' + calculateAgeString(patient.birthDate) + ')',
                  'subtitle2',
                  'body2'
                )}
              </div>
            )}
            {patient.gender && <div>{typographyWrapper('Gender:', patient.gender, 'subtitle2', 'body2')}</div>}
            {patient.address && (
              <div>{typographyWrapper('State:', patient.address?.[0]?.state, 'subtitle2', 'body2')}</div>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );

  function typographyWrapper(valueSub: any, valueBody: any, variantSub: Variant, variantBody: Variant): any {
    return (
      <div>
        <Typography style={{ display: 'inline-block' }} variant={variantSub} color={theme.palette.text.primary}>
          {valueSub} &nbsp;
        </Typography>
        <Typography style={{ display: 'inline-block' }} variant={variantBody} color={theme.palette.text.primary}>
          {valueBody}
        </Typography>
      </div>
    );
  }
};

function getDefaultColor(patient: Patient): string {
  if (patient.gender === 'male') {
    return otherColors.patientMale;
  }
  if (patient.gender === 'female') {
    return otherColors.patientFemale;
  }
  return otherColors.patientOther;
}
