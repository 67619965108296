import { Box, Button, CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import { FhirResource } from 'fhir/r4b';
import * as React from 'react';
import { Suspense, useCallback, useMemo, useState } from 'react';
import {
  Await,
  Link,
  Outlet,
  useLoaderData,
  useMatch,
  useNavigate,
  useOutletContext,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { Document } from '../../../components/index';
import { PatientHeader } from '../../../components/PatientHeader';
import { ResourceHeader } from '../../../components/ResourceHeader';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { SpecimenHeader } from '../../../components/SpecimenHeader';
import { Bundle, BundleEntry, OperationOutcome, Resource } from '../../../lib/fhir-types';
import { getPatient, getSpecimen } from '../../../lib/fhirUtils';
import { normalizeErrorString } from '../../../lib/niftyFunctions';
import { toast } from '../../../lib/toast';
import { Services } from '../../../services';

const tabs: string[] = ['Details', 'Edit', 'History', 'JSON'];

function a11yProps(index: string): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface ResourcePageProps {
  resource: Resource | undefined;
  historyBundle: Bundle | undefined;
  resourceError: OperationOutcome | undefined;
}

export function ResourcePage(): JSX.Element {
  const { id } = useParams() as {
    resourceType: string;
    id: string;
  };

  const { data: dataPromise } = useLoaderData() as { data: Promise<ResourcePageProps> };

  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    async (newResource: Record<string, any>) => {
      try {
        console.log('new resource on submit', newResource);
        const maybeResource = await zapehr.fhir.update({
          id,
          ...newResource,
        } as FhirResource);

        if (!maybeResource.resourceType || maybeResource.resourceType === 'OperationOutcome') {
          throw maybeResource;
        }
        revalidator.revalidate();
        toast.success('Success');
      } catch (err) {
        toast.error(normalizeErrorString(err));
      }
    },
    [id, revalidator]
  );

  return (
    <Suspense fallback={<ResourceHome loading data={undefined} onSubmit={onSubmit} />}>
      <Await resolve={dataPromise} errorElement={<RootErrorBoundary />}>
        {(data) => {
          return <ResourceHome data={data} loading={false} onSubmit={onSubmit} />;
        }}
      </Await>
    </Suspense>
  );
}

interface ResourceHomeProps {
  loading: boolean;
  data: ResourcePageProps | undefined;
  onSubmit: (resource: Resource) => void;
}

const ResourceHome: React.FC<ResourceHomeProps> = ({ loading, data, onSubmit }) => {
  const navigate = useNavigate();
  const match = useMatch(`/${Services.fhir.rootPath}/:resourceType/:id/*`);
  const { resourceType, id } = useParams() as {
    resourceType: string;
    id: string;
  };

  const [showDeletionDialog, setShowDeletionDialog] = useState(false);

  const restoreResource = useCallback(() => {
    const restoredResource = (data?.historyBundle?.entry as BundleEntry[])?.find((e) => !!e.resource)?.resource;
    if (restoredResource) {
      onSubmit(restoredResource);
    } else {
      toast.error('No history to restore');
    }
  }, [data?.historyBundle?.entry, onSubmit]);

  const currentTab = useMemo(() => {
    const defaultTab = tabs[0].toLowerCase();
    // return tab ? tab : defaultTab;
    const selected = match?.params['*'];
    return selected?.split('/')[0] || defaultTab;
  }, [match?.params]);

  const resourceError = data?.resourceError;
  const resource = data?.resource;
  const historyBundle = data?.historyBundle;

  if (!loading && resourceError && isGone(resourceError)) {
    return (
      <Document>
        <Typography variant="h5" fontWeight={'bold'}>
          Deleted
        </Typography>
        <Typography variant="body1">The resource was deleted.</Typography>
        <Button color="error" variant="contained" onClick={restoreResource} style={{ textTransform: 'none' }}>
          Restore
        </Button>
      </Document>
    );
  }

  if (!loading && (!resource || !historyBundle)) {
    return (
      <Document>
        <Typography variant="h5">Resource not found</Typography>
        <Typography variant="h6">
          <Link to={`/${Services.fhir.rootPath}/${resourceType}`}>Return to search page</Link>
        </Typography>
      </Document>
    );
  }

  const patient = resource && getPatient(resource);
  const specimen = resource && getSpecimen(resource);

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      {patient && <PatientHeader patient={patient} />}
      {specimen && <SpecimenHeader specimen={specimen} />}
      {resource && resource.resourceType !== ('Patient' || 'Specimen') && <ResourceHeader resource={resource} />}
      <Stack
        sx={{
          bgcolor: 'primary.contrast',
          padding: '0px 10px',
          marginLeft: '-36px',
          marginRight: '-36px',
          height: '100%',
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Tabs value={currentTab === 'details' ? '' : currentTab}>
          {tabs.map((tab) => (
            <Tab
              component={Link}
              to={`/${Services.fhir.rootPath}/${resourceType}/${id}/${tab === 'Details' ? '' : tab.toLowerCase()}`}
              label={tab}
              sx={{
                '&.MuiTab-root': {
                  fontWeight: 600,
                },
              }}
              {...a11yProps(tab)}
              value={tab === 'Details' ? '' : tab.toLowerCase()}
              key={tab}
            />
          ))}
        </Tabs>
        <Button
          color="error"
          variant="contained"
          onClick={() => setShowDeletionDialog(true)}
          style={{ marginRight: 16, marginBottom: 8, textTransform: 'none' }}
        >
          Delete Resource
        </Button>
      </Stack>
      {currentTab !== 'editor' && (
        <Document>
          {resourceError && <pre data-testid="error">{JSON.stringify(resourceError, undefined, 2)}</pre>}
          <Outlet context={{ resource, resourceHistory: historyBundle, onSubmit, outcome: resourceError }} />
        </Document>
      )}
      <ConfirmationDialog
        open={showDeletionDialog}
        fullWidth
        title={`Delete resource?`}
        message={
          <>
            <span>
              Are you sure you want to delete {resourceType}:{id}?
            </span>
            <span style={{ display: 'block', color: '#D32F2F', marginTop: '10px' }}>
              This action is permanent and cannot be undone.
            </span>
          </>
        }
        buttonTitle={`Delete`}
        handleAction={() => {
          zapehr.fhir
            .delete({ resourceType: resourceType, id: id })
            .then(() => navigate(`/fhir/resources/${resourceType}`))
            .catch((err) => toast.error(normalizeErrorString(err)));
        }}
        handleClose={() => {
          setShowDeletionDialog(false);
        }}
      />
    </>
  );
};

export interface ResourceProps {
  resource: Resource;
  resourceHistory: Bundle;
  onSubmit: (resource: Resource) => void;
  onDelete: (resource: Resource) => void;
  outcome: OperationOutcome;
}

export function useResourceFromOutletContext(): ResourceProps {
  return useOutletContext<ResourceProps>();
}

function isGone(outcome: OperationOutcome): boolean {
  return outcome.id === 'gone';
}
