import { PersonAddAlt1 } from '@mui/icons-material';
import { alpha, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense } from 'react';
import { Await, defer, Link, useLoaderData } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { Services } from '../../../services';

export function usersPageLoader(): ReturnType<typeof defer> {
  return defer({ users: zapehr.project.user.list() });
}

export function UsersPage(): JSX.Element {
  const data = useLoaderData() as { users: ReturnType<typeof zapehr.project.user.list> };

  return (
    <Grid container>
      <Grid item xs={12} display={'flex'} marginBottom={2}>
        <Typography variant="h4">Users</Typography>
        <ArticleShortcutIconButton link="https://docs.oystehr.com/services/app/users/" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Users are the people who log into the{' '}
          <MUILink href={'https://docs.oystehr.com/services/app/applications/'} target="_blank">
            Applications
          </MUILink>{' '}
          you configure for securing the apps you build on top of Oystehr.
        </Typography>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to={`/${Services.app.rootPath}/users/new`}>
          <Button aria-label="new" variant="contained" startIcon={<PersonAddAlt1 />}>
            Invite
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ height: '60vh', marginTop: 4 }}>
          <Suspense fallback={<BodyElement users={[]} loading />}>
            <Await resolve={data.users} errorElement={<RootErrorBoundary />}>
              {(users) => {
                return <BodyElement users={users} loading={false} />;
              }}
            </Await>
          </Suspense>
        </Paper>
      </Grid>
    </Grid>
  );
}

interface BodyProps {
  users: Awaited<ReturnType<typeof zapehr.project.user.list>> | [];
  loading: boolean;
}
const BodyElement: React.FC<BodyProps> = ({ users, loading }) => {
  return (
    <DataGridPro
      loading={loading}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          renderCell: (params) => <Link to={params.row.id}>{params.row.name}</Link>,
        },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'id', headerName: 'ID', flex: 1 },
      ]}
      rows={users.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
      }))}
      components={{ Toolbar: GridToolbar }}
      // todo remove code duplication with SearchControl
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          pl: 1.5,
          gap: 4,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '& .MuiButtonBase-root': {
            color: otherColors.charcoal87,
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
        },
      }}
    />
  );
};
