import { FhirVersion } from '../contexts/ZapehrProvider';
import r4bSchema from '../fhir-schema-r4b.json';
import r5Schema from '../fhir-schema-r5.json';

export interface ChoiceType {
  name: string;
  targetTypes?: string[];
}

export interface PropertyDefinition {
  path: string;
  name: string;
  type: string;
  isArray: boolean;
  description?: string;
  choiceTypes?: ChoiceType[];
  targetTypes?: string[];
  valueSet?: string;
}

export interface SearchParameter {
  name: string;
  type: string;
}

export interface TypesSchema {
  [type: string]: {
    properties: PropertyDefinition[];
    isResourceType: boolean;
    searchParameters: SearchParameter[];
  };
}

export function getSchema(fhirVersion?: FhirVersion): TypesSchema {
  if (fhirVersion === FhirVersion.r4) {
    return r4bSchema;
  }
  if (fhirVersion === FhirVersion.r5) {
    return r5Schema;
  }
  throw new Error(`Unsupported fhir verion ${fhirVersion}`);
}

export function getProperties(type: string, typesSchema: TypesSchema): PropertyDefinition[] {
  return typesSchema[type]?.properties ?? [];
}
