import { CheckCircle, UploadFile } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, FC } from 'react';
import { otherColors } from '../contexts/AdminThemeProvider';
import { ZambdaFileInfo } from '../lib/client';
import { FileInputDropWrapper } from './Z3Components/FileInputComponent';

interface FileUploadProps {
  file: File | ZambdaFileInfo | null;
  isLoading?: boolean;
  isUploadComplete?: boolean;
  uploadError?: string;
  onUpload: (file: File) => void;
  onClear: () => void;
}

const FileUploadBox: FC<FileUploadProps> = ({ file, isLoading, isUploadComplete, uploadError, onUpload, onClear }) => {
  const theme = useTheme();
  const handleZipUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files && files.length > 0) {
      onUpload(files[0]);
    }
  };

  return (
    <>
      {file ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 4,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar
              sx={{
                backgroundColor: uploadError
                  ? otherColors.zambdaFileAvatarBackgroundError
                  : otherColors.zambdaFileAvatarBackground,
              }}
            >
              <UploadFile sx={{ color: uploadError ? 'red' : 'primary.main' }} />
            </Avatar>

            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
              <Typography variant="subtitle1" color={uploadError ? 'red' : undefined}>
                {file.name}
              </Typography>
              {uploadError ? (
                <Typography variant="body2" color="red">
                  {uploadError} • Failed
                </Typography>
              ) : (
                <>
                  {file.size ? (
                    <Typography variant="body2" color="text.secondary">
                      Size: {(Math.floor(file.size) / 1024).toFixed(1)}KB
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {file.lastModified ? (
                    <Typography variant="body2" color="text.secondary">
                      Last Modified: {new Date(file.lastModified).toLocaleString()}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>
          </Box>

          <Box display="flex" gap={1} alignItems="center">
            {isLoading ? <CircularProgress size={theme.spacing(3)} /> : <></>}
            {isUploadComplete ? <CheckCircle color="success" /> : <></>}
            <IconButton onClick={onClear}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <FileInputDropWrapper fileInputCallback={(file: File) => onUpload(file)}>
          <Box
            sx={{
              border: '1px dashed rgba(0, 0, 0, 0.12)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              py: 4,
              mb: 4,
            }}
          >
            <Avatar sx={{ backgroundColor: 'rgba(40, 150, 198, 0.12)' }}>
              <UploadFile sx={{ color: 'primary.main' }} />
            </Avatar>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                component="label"
                variant="text"
                sx={{ textDecoration: 'underline', textTransform: 'none', p: 0, fontSize: '16px' }}
              >
                Upload the Zambda file
                <input type="file" accept=".zip" hidden disabled={false} onChange={handleZipUpload} />
              </Button>
            </Box>

            <Typography variant="body2" color="text.secondary">
              ZIP (max. 50MB)
            </Typography>
          </Box>
        </FileInputDropWrapper>
      )}
    </>
  );
};

export default FileUploadBox;
