import { Chip } from '@mui/material';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { otherColors } from '../../../../contexts/AdminThemeProvider';
import { ZambdaState } from '../../../../lib/types';

interface ZambdaStatusChipProps {
  status?: ZambdaState;
}

const ZambdaStatusChip: FC<ZambdaStatusChipProps> = ({ status }) => {
  const chipColor = useMemo(() => {
    const colorMap: Record<ZambdaState, string> = {
      [ZambdaState.Pending]: otherColors.zambdaPending,
      [ZambdaState.Validating]: otherColors.zambdaValidating,
      [ZambdaState.Failed]: otherColors.zambdaFailed,
      [ZambdaState.Draft]: otherColors.zambdaDraft,
      [ZambdaState.Inactive]: otherColors.zambdaInactive,
      [ZambdaState.Active]: otherColors.zambdaActive,
    };

    return colorMap[status as ZambdaState] ?? null;
  }, [status]);

  return chipColor ? <Chip sx={{ backgroundColor: chipColor, height: '25px', color: 'white' }} label={status} /> : null;
};

export default ZambdaStatusChip;
