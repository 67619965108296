import * as React from 'react';

export interface LogoProps {
  width: number;
}

export function Logo(props: LogoProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 31" style={{ width: props.width }}>
      <path
        d="M131.748 22.5867C131.488 22.5867 131.269 22.4985 131.092 22.322C130.915 22.1455 130.826 21.9279 130.826 21.6691V9.72299C130.826 9.45243 130.915 9.2348 131.092 9.0701C131.269 8.89365 131.488 8.80542 131.748 8.80542H134.939C135.826 8.80542 136.612 8.99364 137.297 9.37008C137.983 9.73476 138.515 10.2406 138.893 10.8876C139.283 11.5346 139.478 12.2875 139.478 13.1463C139.478 13.9462 139.283 14.6579 138.893 15.2814C138.515 15.9049 137.983 16.3931 137.297 16.746C136.612 17.0989 135.826 17.2753 134.939 17.2753H132.67V21.6691C132.67 21.9279 132.581 22.1455 132.404 22.322C132.239 22.4985 132.02 22.5867 131.748 22.5867ZM139.106 22.5867C138.94 22.5867 138.787 22.5514 138.645 22.4808C138.515 22.4102 138.402 22.2985 138.308 22.1455L135.117 17.0283L136.907 16.5166L139.815 21.175C140.028 21.5162 140.057 21.8397 139.904 22.1455C139.75 22.4396 139.484 22.5867 139.106 22.5867ZM132.67 15.5814H134.939C135.483 15.5814 135.962 15.4814 136.375 15.2814C136.789 15.0696 137.114 14.7814 137.351 14.4167C137.587 14.0403 137.705 13.6168 137.705 13.1463C137.705 12.6286 137.587 12.1699 137.351 11.7699C137.114 11.3699 136.789 11.0582 136.375 10.8347C135.962 10.6112 135.483 10.4994 134.939 10.4994H132.67V15.5814Z"
        fill="white"
      />
      <path
        d="M114.177 22.5867C113.917 22.5867 113.698 22.4985 113.521 22.322C113.344 22.1455 113.255 21.9279 113.255 21.6691V9.72299C113.255 9.45243 113.344 9.2348 113.521 9.0701C113.698 8.89365 113.917 8.80542 114.177 8.80542C114.449 8.80542 114.667 8.89365 114.833 9.0701C115.01 9.2348 115.099 9.45243 115.099 9.72299V14.7873H123.325V9.72299C123.325 9.45243 123.414 9.2348 123.591 9.0701C123.768 8.89365 123.987 8.80542 124.247 8.80542C124.519 8.80542 124.738 8.89365 124.903 9.0701C125.068 9.2348 125.151 9.45243 125.151 9.72299V21.6691C125.151 21.9279 125.068 22.1455 124.903 22.322C124.738 22.4985 124.519 22.5867 124.247 22.5867C123.987 22.5867 123.768 22.4985 123.591 22.322C123.414 22.1455 123.325 21.9279 123.325 21.6691V16.4107H115.099V21.6691C115.099 21.9279 115.01 22.1455 114.833 22.322C114.667 22.4985 114.449 22.5867 114.177 22.5867Z"
        fill="white"
      />
      <path
        d="M99.6358 22.5867C99.3758 22.5867 99.1571 22.4985 98.9798 22.322C98.8025 22.1455 98.7139 21.9279 98.7139 21.6691V9.72299C98.7139 9.45243 98.8025 9.2348 98.9798 9.0701C99.1571 8.89365 99.3758 8.80542 99.6358 8.80542H107.649C107.921 8.80542 108.14 8.88777 108.305 9.05246C108.471 9.20539 108.554 9.41125 108.554 9.67006C108.554 9.9171 108.471 10.1171 108.305 10.27C108.14 10.4229 107.921 10.4994 107.649 10.4994H100.558V14.7873H105.646C105.918 14.7873 106.136 14.8696 106.302 15.0343C106.467 15.1873 106.55 15.3873 106.55 15.6343C106.55 15.8813 106.467 16.0872 106.302 16.2519C106.136 16.4048 105.918 16.4813 105.646 16.4813H100.558V20.8927H107.649C107.921 20.8927 108.14 20.9692 108.305 21.1221C108.471 21.275 108.554 21.475 108.554 21.722C108.554 21.9808 108.471 22.1926 108.305 22.3573C108.14 22.5102 107.921 22.5867 107.649 22.5867H99.6358Z"
        fill="white"
      />
      <path
        d="M88.9599 22.5867C88.6998 22.5867 88.4812 22.4985 88.3039 22.322C88.1266 22.1455 88.0379 21.9279 88.0379 21.6691V8.80542H89.8818V21.6691C89.8818 21.9279 89.7931 22.1455 89.6158 22.322C89.4385 22.4985 89.2199 22.5867 88.9599 22.5867ZM84.2084 10.4994C83.9602 10.4994 83.7534 10.4229 83.5879 10.27C83.4342 10.1053 83.3574 9.89945 83.3574 9.65241C83.3574 9.40537 83.4342 9.20539 83.5879 9.05246C83.7534 8.88777 83.9602 8.80542 84.2084 8.80542H93.6936C93.9418 8.80542 94.1427 8.88777 94.2963 9.05246C94.4618 9.20539 94.5446 9.40537 94.5446 9.65241C94.5446 9.89945 94.4618 10.1053 94.2963 10.27C94.1427 10.4229 93.9418 10.4994 93.6936 10.4994H84.2084Z"
        fill="white"
      />
      <path
        d="M75.6377 22.763C74.8813 22.763 74.1603 22.6454 73.4748 22.4101C72.7892 22.1748 72.1923 21.8454 71.6841 21.4219C71.1759 20.9984 70.8036 20.5103 70.5672 19.9574C70.449 19.7103 70.449 19.4809 70.5672 19.2692C70.6972 19.0574 70.9099 18.9221 71.2054 18.8633C71.4182 18.8163 71.625 18.8516 71.8259 18.9692C72.0387 19.0751 72.1982 19.2398 72.3046 19.4633C72.4465 19.7574 72.6769 20.0279 72.9961 20.275C73.327 20.522 73.723 20.722 74.1839 20.8749C74.6449 21.0279 75.1295 21.1043 75.6377 21.1043C76.2051 21.1043 76.7251 21.0161 77.1979 20.8396C77.6825 20.6632 78.0725 20.4103 78.368 20.0809C78.6635 19.7397 78.8112 19.3221 78.8112 18.828C78.8112 18.2046 78.563 17.6458 78.0666 17.1517C77.582 16.6576 76.7783 16.3518 75.6554 16.2341C74.1898 16.093 73.0374 15.6636 72.1982 14.946C71.3591 14.2284 70.9395 13.3402 70.9395 12.2815C70.9395 11.5051 71.1581 10.8463 71.5955 10.3052C72.0328 9.76404 72.6238 9.35231 73.3684 9.06998C74.1248 8.78765 74.964 8.64648 75.8859 8.64648C76.6069 8.64648 77.2333 8.74648 77.7652 8.94646C78.3089 9.14644 78.7817 9.42289 79.1836 9.77581C79.5854 10.1287 79.9341 10.5463 80.2296 11.0286C80.3951 11.2875 80.4601 11.5345 80.4246 11.7698C80.401 11.9933 80.2887 12.1697 80.0878 12.2991C79.8632 12.4403 79.6209 12.4697 79.3609 12.3874C79.1008 12.305 78.9058 12.1521 78.7758 11.9286C78.5867 11.5874 78.3562 11.2992 78.0843 11.0639C77.8125 10.8287 77.4934 10.6463 77.127 10.5169C76.7606 10.3758 76.3292 10.3052 75.8327 10.3052C74.9581 10.2934 74.2253 10.4581 73.6343 10.7993C73.0433 11.1286 72.7479 11.6404 72.7479 12.3344C72.7479 12.6873 72.8365 13.0285 73.0138 13.3579C73.2029 13.6755 73.5398 13.9519 74.0244 14.1872C74.5208 14.4225 75.2299 14.5872 76.1519 14.6813C77.5584 14.8225 78.6517 15.2518 79.4318 15.9694C80.2237 16.6753 80.6196 17.6105 80.6196 18.7751C80.6196 19.4456 80.4778 20.0279 80.1941 20.522C79.9223 21.0161 79.55 21.4337 79.0772 21.7749C78.6162 22.1042 78.0844 22.3513 77.4816 22.516C76.8906 22.6807 76.276 22.763 75.6377 22.763Z"
        fill="white"
      />
      <path
        d="M61.3847 22.5867C61.1365 22.5867 60.9238 22.4985 60.7465 22.322C60.5692 22.1455 60.4805 21.9279 60.4805 21.6691V17.5577L55.7468 10.2347C55.5932 9.98768 55.5341 9.75829 55.5695 9.54654C55.605 9.32303 55.7055 9.14657 55.8709 9.01717C56.0482 8.876 56.2492 8.80542 56.4737 8.80542C56.6747 8.80542 56.8342 8.85247 56.9524 8.94658C57.0706 9.02893 57.1829 9.15245 57.2893 9.31714L61.7925 16.4283H61.0833L65.6575 9.24656C65.752 9.1054 65.8643 8.99952 65.9943 8.92894C66.1243 8.84659 66.2839 8.80542 66.473 8.80542C66.6858 8.80542 66.8631 8.88188 67.0049 9.03481C67.1467 9.17598 67.2295 9.35832 67.2531 9.58183C67.2767 9.79358 67.2176 10.0112 67.0758 10.2347L62.3066 17.5577V21.6691C62.3066 21.9279 62.218 22.1455 62.0407 22.322C61.8634 22.4985 61.6448 22.5867 61.3847 22.5867Z"
        fill="white"
      />
      <path
        d="M45.892 22.7631C44.8519 22.7631 43.9004 22.5925 43.0376 22.2514C42.1748 21.8984 41.4302 21.4102 40.8037 20.7868C40.1891 20.1515 39.7104 19.4045 39.3677 18.5458C39.0249 17.6753 38.8535 16.7224 38.8535 15.6872C38.8535 14.652 39.0249 13.705 39.3677 12.8462C39.7104 11.9875 40.1891 11.2463 40.8037 10.6229C41.4302 9.98762 42.1748 9.49943 43.0376 9.15828C43.9004 8.80536 44.8519 8.62891 45.892 8.62891C46.9321 8.62891 47.8777 8.80536 48.7287 9.15828C49.5915 9.49943 50.3361 9.98762 50.9626 10.6229C51.589 11.2463 52.0736 11.9875 52.4164 12.8462C52.7591 13.705 52.9305 14.652 52.9305 15.6872C52.9305 16.7224 52.7591 17.6753 52.4164 18.5458C52.0736 19.4045 51.589 20.1515 50.9626 20.7868C50.3361 21.4102 49.5915 21.8984 48.7287 22.2514C47.8777 22.5925 46.9321 22.7631 45.892 22.7631ZM45.892 21.1044C46.6603 21.1044 47.3635 20.9691 48.0018 20.6985C48.6519 20.4162 49.2133 20.0339 49.6861 19.5516C50.1588 19.0575 50.5193 18.4811 50.7675 17.8223C51.0276 17.1635 51.1576 16.4518 51.1576 15.6872C51.1576 14.9225 51.0276 14.2108 50.7675 13.552C50.5193 12.8933 50.1588 12.3227 49.6861 11.8404C49.2133 11.3463 48.6519 10.964 48.0018 10.6934C47.3635 10.4229 46.6603 10.2876 45.892 10.2876C45.1237 10.2876 44.4146 10.4229 43.7645 10.6934C43.1263 10.964 42.5707 11.3463 42.098 11.8404C41.637 12.3227 41.2765 12.8933 41.0165 13.552C40.7565 14.2108 40.6264 14.9225 40.6264 15.6872C40.6264 16.4518 40.7565 17.1635 41.0165 17.8223C41.2765 18.4811 41.637 19.0575 42.098 19.5516C42.5707 20.0339 43.1263 20.4162 43.7645 20.6985C44.4146 20.9691 45.1237 21.1044 45.892 21.1044Z"
        fill="white"
      />
      <g clipPath="url(#clip0_2614_1866)">
        <path
          d="M7.8933 21.414C6.11603 20.7809 3.70227 19.8572 0.882179 18.5758C0.53608 18.3138 0.274555 17.9567 0.129867 17.5484C-0.0148197 17.1402 -0.036335 16.6987 0.0679757 16.2784C0.365707 15.1399 1.49131 14.7654 1.56043 14.7465C0.76825 13.4969 0.692298 11.9242 1.37966 10.6472C2.29488 8.94217 4.10254 8.61083 4.31976 8.57452C3.82456 6.83465 4.40027 4.98055 5.76741 3.88973C7.62064 2.41083 9.88476 3.16579 10.0207 3.21345C10.0906 3.07955 11.6681 0.182284 14.9515 0.00754035C17.2437 -0.113494 19.4258 1.14224 20.515 3.21345C22.4571 2.74065 24.4341 3.41088 25.4906 4.87767C26.5782 6.3906 26.2372 8.12064 26.1689 8.43685C26.3846 8.48375 28.2097 8.91947 29.0642 10.7342C29.9756 12.6678 28.9745 14.4508 28.8826 14.6089C28.9836 14.6437 30.0933 15.0514 30.4207 16.23C30.5326 16.6417 30.5331 17.0756 30.4219 17.4875C30.3108 17.8994 30.092 18.2747 29.788 18.575C27.4277 19.7011 25.0122 20.7085 22.5505 21.5933C19.2907 22.7666 17.6235 23.3536 15.7657 23.396C13.6398 23.4451 11.6955 22.7689 7.8933 21.414Z"
          fill="#2F79B2"
        />
        <path
          d="M8.77637 21.6309C7.21262 21.0747 5.08887 20.2633 2.6076 19.1375C2.30308 18.9074 2.07297 18.5936 1.94567 18.235C1.81837 17.8763 1.79944 17.4884 1.89122 17.1192C2.15318 16.119 3.14355 15.7901 3.20436 15.7735C2.50736 14.6756 2.44053 13.294 3.04531 12.1722C3.85057 10.6743 5.44105 10.3832 5.63217 10.3513C5.19646 8.82283 5.70301 7.19399 6.90589 6.2357C8.53646 4.93648 10.5286 5.59971 10.6482 5.64158C10.7097 5.52395 12.0977 2.97868 14.9866 2.82517C17.0034 2.71884 18.9233 3.82201 19.8816 5.64158C21.5904 5.22623 23.3299 5.81503 24.2595 7.10361C25.2164 8.43273 24.9164 9.95259 24.8562 10.2304C25.046 10.2716 26.6518 10.6544 27.4036 12.2486C28.2056 13.9473 27.3248 15.5136 27.2439 15.6525C27.3328 15.6831 28.3092 16.0413 28.5972 17.0767C28.6957 17.4384 28.6961 17.8195 28.5983 18.1814C28.5005 18.5433 28.308 18.873 28.0405 19.1368C25.9638 20.1261 23.8385 21.0111 21.6726 21.7884C18.8044 22.8192 17.3375 23.3349 15.703 23.3721C13.8325 23.4153 12.1217 22.8211 8.77637 21.6309Z"
          fill="#DCF0FF"
        />
        <ellipse
          cx="5.0624"
          cy="5.04493"
          rx="5.0624"
          ry="5.04493"
          transform="matrix(0.970883 0.243556 -0.24386 0.968811 11.6279 10.79)"
          fill="#5BAEEE"
        />
        <ellipse
          cx="1.26105"
          cy="1.2567"
          rx="1.26105"
          ry="1.2567"
          transform="matrix(0.970883 0.243556 -0.24386 0.968811 15.6758 12.5967)"
          fill="white"
        />
        <ellipse
          cx="0.697421"
          cy="0.695014"
          rx="0.697421"
          ry="0.695014"
          transform="matrix(0.970883 0.243556 -0.24386 0.968811 18.3115 14.5435)"
          fill="white"
        />
        <path
          d="M0.226936 22.3352C-0.42777 23.9434 3.43135 26.6924 3.84529 26.9754C7.9634 29.7871 12.4484 29.9006 14.8826 29.9309C17.7939 29.9679 22.7057 29.9642 26.7311 26.7953C28.2502 25.6009 30.4345 23.221 29.8072 22.0644C29.3029 21.1354 27.1709 21.3472 26.2762 21.4713C25.7195 20.856 24.9813 20.4323 24.1677 20.261C22.569 19.9357 21.3423 20.761 21.1228 20.9145C20.5317 20.3188 19.7851 19.8996 18.9673 19.7042C17.0229 19.2503 15.4735 20.3147 15.2198 20.4977C14.9692 20.323 13.5026 19.3396 11.6121 19.7511C10.6684 19.9627 9.8164 20.467 9.17863 21.1914C9.08217 21.0825 7.90796 19.7919 6.18005 20.0249C4.96482 20.1883 4.24707 21 4.06934 21.2126C3.36982 21.1907 0.706952 21.1559 0.226936 22.3352Z"
          fill="#2F79B2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2614_1866">
          <rect width="30.5046" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
