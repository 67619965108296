import './ResourceDiff.css';
import * as React from 'react';
import { Resource } from '../lib/fhir-types';
import { stringify } from '../lib/niftyFunctions';
import { Delta, diff } from '../lib/utils/diff';

export interface ResourceDiffProps {
  original: Resource;
  revised: Resource;
  ignoreMeta?: boolean;
}

export function ResourceDiff(props: ResourceDiffProps): JSX.Element {
  let originalResource = props.original;
  let revisedResource = props.revised;

  if (props.ignoreMeta) {
    originalResource = { ...originalResource, meta: undefined };
    revisedResource = { ...revisedResource, meta: undefined };
  }

  const original = stringify(originalResource, true).match(/[^\r\n]+/g) as string[];
  const revised = stringify(revisedResource, true).match(/[^\r\n]+/g) as string[];
  const deltas = diff(original, revised);
  return (
    <pre style={{ color: 'gray' }}>
      {deltas
        .sort((a, b) => a.original.position - b.original.position)
        .map((delta, index) => (
          <ChangeDiff key={delta.original.position} delta={delta} first={index === 0} />
        ))}
    </pre>
  );
}

function ChangeDiff(props: { delta: Delta; first?: boolean }): JSX.Element {
  return (
    <>
      {props.first && (
        <>
          ...
          <br />
        </>
      )}
      {props.delta.original.lines.length > 0 && (
        <div className="diff-removed">{props.delta.original.lines.join('\n')}</div>
      )}
      {props.delta.revised.lines.length > 0 && <div className="diff-added">{props.delta.revised.lines.join('\n')}</div>}
      ...
      <br />
    </>
  );
}
