import { TextareaAutosize } from '@mui/base';
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { Form } from '../../../../components/index';
import { otherColors, palette } from '../../../../contexts/AdminThemeProvider';
import { Resource } from '../../../../lib/fhir-types';
import { stringify } from '../../../../lib/niftyFunctions';
import { toast } from '../../../../lib/toast';
import { copyToClipboard } from '../../../../lib/utils';
import { useResourceFromOutletContext } from '..';

export function ResourceJson(): JSX.Element {
  const { resource, onSubmit } = useResourceFromOutletContext();
  const defaultJsonValue = stringify(resource, true);
  return (
    <Form
      onSubmit={(formData: Record<string, string>) => {
        let json: Resource | undefined;
        try {
          json = JSON.parse(formData.resource);
        } catch (error) {
          toast.error('Invalid json. Resource could not be saved.');
        }
        if (json) {
          onSubmit(json);
        }
      }}
    >
      <TextareaAutosize
        defaultValue={defaultJsonValue}
        data-testid="resource-json"
        name="resource"
        style={{
          height: 400,
          overflow: 'scroll',
          fontFamily: 'monospace',
          lineHeight: 1.35,
          padding: 3,
          color: otherColors.gray800,
          borderColor: otherColors.gray200,
        }}
        onFocus={(e) => (e.currentTarget.style.outlineColor = palette.primary.light)}
      />
      <Box display="flex" gap="15px">
        <Button type="submit" variant="contained">
          OK
        </Button>
        <Button variant="contained" onClick={() => copyToClipboard(defaultJsonValue, 'JSON value was copied.')}>
          Copy JSON
        </Button>
      </Box>
    </Form>
  );
}
