import { Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { FormEvent } from 'react';
import { otherColors } from '../contexts/AdminThemeProvider';
import { EditContextProvider, useResourceEditingContext } from '../contexts/ResourceEditContextProvider';
import { OperationOutcome, Resource } from '../lib/fhir-types';
import { ResourceInputForm } from './new/ResourceInputForm';
import { ResourceFormFieldErrors } from './ResourceFormFieldErrors';

const CustomDisableTextField = styled(TextField)({
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': {
      borderColor: otherColors.black23,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: otherColors.charcoal87,
      color: otherColors.charcoal87,
    },
    backgroundColor: otherColors.cornflowerBlue04,
  },
  width: '44%',
});

export type ResourceFormMode = 'edit_only' | 'display_only' | 'editable';

export interface ResourceFormProps {
  defaultValue: Resource;
  formMode: ResourceFormMode;
  errors?: string[];
  outcome?: OperationOutcome;
  onSubmit: (resource: Resource) => void;
  onDelete?: (resource: Resource) => void;
}

// https://hl7.org/fhir/R4B/datatypes.html#string
const mapEmptyStringValuesToNull = (object: any): any => {
  try {
    const mapped = Object.entries(object).map((entry) => {
      const [key, val] = entry;
      if (typeof val === 'string' && val === '') {
        return [key, null];
      } else if (typeof val === 'object' && !Array.isArray(val)) {
        return [key, mapEmptyStringValuesToNull(val)];
      }
      return [key, val];
    });
    return Object.fromEntries(mapped);
  } catch (_e) {
    return object;
  }
};

export function ResourceForm(props: ResourceFormProps): JSX.Element {
  return (
    <EditContextProvider
      initialEditMode={props.formMode === 'edit_only'}
      resourceType={props.defaultValue.resourceType}
      initialValue={props.defaultValue}
    >
      <ResourceFormBody {...props} />
    </EditContextProvider>
  );
}

function ResourceFormBody(props: ResourceFormProps): JSX.Element {
  const { errors, formMode } = props;
  const { toggleEditModeOn, editModeOn, formValue, setFormValue } = useResourceEditingContext();

  // console.log('VALUE: ', formValue);
  return (
    <Box>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          props.onSubmit(mapEmptyStringValuesToNull(formValue));
        }}
      >
        {formMode === 'editable' && (
          <IconButton
            onClick={() => {
              toggleEditModeOn();
            }}
          >
            <Edit />
          </IconButton>
        )}
        <Stack direction="row" spacing={2} paddingBottom="20px">
          <CustomDisableTextField
            id="resourceType"
            label="Resource Type"
            value={formValue.resourceType}
            disabled={true}
          />
        </Stack>
        <ResourceInputForm resourceType={formValue.resourceType} value={formValue} onChange={setFormValue} />
        {formMode !== 'display_only' && (
          <Button type="submit" aria-label="new" variant="contained" sx={{ mr: 2, mt: 2 }} disabled={!editModeOn}>
            Save Changes
          </Button>
        )}
        {errors && errors.length > 0 && <ResourceFormFieldErrors errors={errors} />}
        {props.onDelete && (
          <Button
            aria-label="delete"
            variant="contained"
            color="warning"
            sx={{ mt: 2 }}
            onClick={() => {
              (props.onDelete as (resource: Resource) => void)(formValue);
            }}
          >
            Delete
          </Button>
        )}
      </form>
    </Box>
  );
}
