import { LogoutUrlOptions, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ZapehrPlatformClient } from '../lib/client';
import { ErrorCode, InternalError, isApiError, isApiErrorOfType } from '../lib/errors';

export enum FhirVersion {
  r4 = 'r4',
  r5 = 'r5',
}

export interface ZapehrProviderProps {
  children: any;
}

interface DefaultRole {
  id: string;
  name: string;
}
export interface ProjectInfo {
  readonly name: string;
  readonly description: string;
  readonly id: string;
  readonly signupEnabled: boolean;
  readonly defaultPatientRole?: DefaultRole;
  readonly fhirVersion: keyof typeof FhirVersion;
  readonly sandbox: boolean;
}

export interface UpdateProjectInfo {
  readonly name: string;
  readonly description: string;
  readonly signupEnabled: boolean;
  readonly defaultPatientRoleId?: string;
}

export interface ClientInfo {
  readonly name: string;
  readonly auth0ClientId: string;
}

export interface Project {
  readonly id: string;
  readonly name: string;
  readonly fhirVersion: FhirVersion;
}

export interface ZapehrContextProps {
  platformClient: ZapehrPlatformClient;
  authIsLoading: boolean;
  zapehrUserId: string | undefined;
  currentProject: Project | undefined;
  projectsList: Project[] | undefined;
  profileIsLoading: boolean;
  initializationError: Error | undefined;
  authError: Error | undefined;
  userIsAuthenticated: boolean;
  availableProjects: string[];
  fhirVersion: FhirVersion | undefined;
  isSelectingFhirVersion: boolean;
  signIn: (loc?: string) => Promise<void>;
  signOut: () => Promise<void>;
  getAuthToken: () => Promise<string>;
  refreshSavedAuthToken: () => void;
  updateFhirVersion: (fhirVersion: FhirVersion | undefined) => Promise<void>;
  createProject: (projectName: string, fhirVersion: FhirVersion) => Promise<Project>;
  switchProject: (project: string | Project) => void;
  updateProjectData: (id: string) => void;
  isSdkInitialized: boolean;
  isAlertBannerDismissed: boolean;
  setAlertBannerDismissed: (dismissed: boolean) => void;
}

const ZapehrContext = createContext<ZapehrContextProps>({
  platformClient: {},
  authIsLoading: false,
  zapehrUserId: undefined,
  currentProject: undefined,
  projectsList: undefined,
  profileIsLoading: false,
  initializationError: undefined,
  authError: undefined,
  userIsAuthenticated: false,
  availableProjects: [],
  fhirVersion: undefined,
  isSelectingFhirVersion: false,
  signIn: async () => {
    throw 'Not implemented';
  },
  signOut: async () => {
    throw 'Not implemented';
  },
  getAuthToken: async () => {
    throw 'Not implemented';
  },
  refreshSavedAuthToken: async () => {
    throw 'Not implemented';
  },
  updateFhirVersion: async (_version: FhirVersion | undefined) => {
    throw 'Not implemented';
  },
  createProject: async () => {
    throw 'Not implemented';
  },
  switchProject: () => {
    throw 'Not implemented';
  },
  updateProjectData: () => {
    throw 'Not implemented';
  },
  isSdkInitialized: false,
  isAlertBannerDismissed: false,
  setAlertBannerDismissed: function (): void {
    throw new Error('Function not implemented.');
  },
});

export interface ZapehrUser {
  name: string;
  email: string;
  id: string;
  projects: Project[]; // list of project uuids this user belongs to
}

const redirectionOptions: RedirectLoginOptions = {
  redirectUri: `${process.env.REACT_APP_DOMAIN}/callback`,
};

const logoutOptions: LogoutUrlOptions = {
  returnTo: `${process.env.REACT_APP_DOMAIN}/`,
};

export const getBaseHeaders = (token?: string): any => {
  const base: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    base.Authorization = `Bearer ${token}`;
  }
  return base;
};
const isAuthTokenExpired = (token: string): boolean => {
  const expirationTime = JSON.parse(atob(token.split('.')[1]).toString()).exp;
  return expirationTime * 1000 < Date.now();
};

const fhirApiBaseUrl = process.env.FHIR_API_URL;
const platformApiBaseUrl = process.env.PLATFORM_API_URL;
const projectApiBaseUrl = process.env.PROJECT_API_URL;

export const newProjectNameKey = 'newProjectName';

export const ZapehrProvider: FC<ZapehrProviderProps> = ({ children }) => {
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isRegisteringUser, setIsRegisteringUser] = useState(false);
  const [currentProject, setCurrentProject] = useState<Project | undefined>();
  const [projectsList, setProjectsList] = useState<Project[] | undefined>();
  const [fhirVersion, setFhirVersion] = useState<FhirVersion | undefined>();
  const [isSelectingFhirVersion, setIsSelectingFhirVersion] = useState(false);
  const [zapehrUserId, setZapehrUserId] = useState<string | undefined>();
  const [initializationError, setInitializationError] = useState<Error | undefined>();
  const [isSdkInitialized, setIsSdkInitialized] = useState(false);
  const [isAlertBannerDismissed, setAlertBannerDismissed] = useState(() => {
    const isThereNewAlertBanner = false;
    if (!isThereNewAlertBanner) {
      // If no new alert, we consider the banner dismissed by default
      return true;
    }
    // Retrieve the dismissal state from localStorage
    const dismissed = localStorage.getItem('alertBannerDismissed');
    return dismissed === 'true';
  });

  const handleSetAlertBannerDismissed = (dismissed: boolean): void => {
    localStorage.setItem('alertBannerDismissed', dismissed.toString());
    setAlertBannerDismissed(dismissed);
  };

  const {
    isLoading: authIsLoading,
    isAuthenticated,
    error: authError,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently, // do not use directly, use getAuthToken (the function below) instead
  } = useAuth0();
  const [userIsLoggedOut, setUserIsLoggedOut] = useState(false);

  useEffect(() => {
    if (userIsLoggedOut) {
      alert('Your authentication token has expired. You will be logged out.');
    }
  }, [userIsLoggedOut]);

  const authTokenKey = 'authToken';
  const selectedProjectKey = 'selectedProject';

  const signOut = useCallback(async () => {
    localStorage.removeItem(authTokenKey);
    return logout(logoutOptions);
  }, [logout]);

  const getAuthToken = useCallback(async () => {
    let accessToken = localStorage.getItem(authTokenKey);
    if (!accessToken || isAuthTokenExpired(accessToken)) {
      accessToken = await getAccessTokenSilently();
      localStorage.setItem(authTokenKey, accessToken);
    }
    return accessToken;
  }, [getAccessTokenSilently]);

  const refreshSavedAuthToken = useCallback(() => {
    getAccessTokenSilently()
      .then((token) => localStorage.setItem(authTokenKey, token))
      .catch((e) => console.error(e));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (currentProject) {
      setIsLoadingUser(false);
      setIsRegisteringUser(false);
      setInitializationError(undefined);
    }
  }, [currentProject]);

  useEffect(() => {
    if (!fhirVersion) {
      setCurrentProject(undefined);
    } else {
      setIsSelectingFhirVersion(false);
    }
  }, [fhirVersion]);

  useEffect(() => {
    if (!isAuthenticated) {
      setFhirVersion(undefined);
    }
  }, [isAuthenticated]);

  const getUser = useCallback(async (): Promise<ZapehrUser> => {
    const at = await getAuthToken();
    const response = await fetch(`${platformApiBaseUrl}/user/me`, {
      method: 'GET',
      headers: getBaseHeaders(at),
      credentials: 'same-origin',
    });

    const json = await response.json();

    if (!response.ok) {
      if (isApiError(json)) {
        throw json;
      } else {
        throw InternalError;
      }
    }
    return json;
  }, [getAuthToken]);

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        void getUser();
      }
    });
  }, [getUser]);

  const signIn = useCallback(
    async (returnUrl?: string) => {
      const accessToken = localStorage.getItem(authTokenKey);
      if (accessToken && isAuthTokenExpired(accessToken)) {
        setUserIsLoggedOut(true);
      }

      const options = { ...redirectionOptions };

      if (returnUrl) {
        options.appState = { returnTo: returnUrl };
      }
      await loginWithRedirect(options);
    },
    [loginWithRedirect]
  );

  const updateFhirVersion = useCallback(
    async (fhirVersion: FhirVersion | undefined): Promise<void> => {
      setFhirVersion(fhirVersion);
    },
    [setFhirVersion]
  );

  const registerNewUser = useCallback(
    async (userAuth0Id: string, projectName: string, fhirVersion: FhirVersion): Promise<ZapehrUser> => {
      const at = await getAuthToken();
      const response = await fetch(`${platformApiBaseUrl}/register`, {
        method: 'POST',
        headers: getBaseHeaders(at),
        credentials: 'same-origin',
        body: JSON.stringify({ userAuth0Id, projectName, fhirVersion }),
      });

      const json = await response.json();
      if (response.ok) {
        return json;
      } else {
        console.error('error registering user: ', json);
        if (isApiError(json)) {
          throw json;
        } else {
          throw InternalError;
        }
      }
    },
    [getAuthToken]
  );

  const createProject = useCallback(
    // async (userAuth0Id: string, projectName: string, fhirVersion: FhirVersion): Promise<Project> => {
    async (projectName: string, fhirVersion: FhirVersion): Promise<Project> => {
      const at = await getAuthToken();
      const response = await fetch(`${platformApiBaseUrl}/project`, {
        method: 'POST',
        headers: getBaseHeaders(at),
        credentials: 'same-origin',
        body: JSON.stringify({ projectName, fhirVersion }),
        // body: JSON.stringify({ userAuth0Id, projectName, fhirVersion }),
      });
      const json = await response.json();
      if (response.ok) {
        const newProject = {
          id: json.uuid,
          name: projectName,
          fhirVersion: fhirVersion,
        };
        const savedProjects = projectsList || [];
        setProjectsList([...savedProjects, newProject]);
        return newProject;
      } else {
        console.error('error creating project: ', json);
        if (isApiError(json)) {
          throw json;
        } else {
          throw InternalError;
        }
      }
    },
    [getAuthToken, projectsList]
  );

  const request = useCallback(
    async (method: string, path: string, body?: string, projectId?: string): Promise<Response> => {
      const at = await getAuthToken();
      const headers = getBaseHeaders(at);
      headers['x-zapehr-project-id'] = currentProject?.id ?? projectId;
      return await fetch(path, {
        method,
        headers,
        body,
      });
    },
    [currentProject, getAuthToken]
  );

  const getProjectInfo = useCallback(
    async (projectId: string): Promise<ProjectInfo> => {
      const response = await request('GET', `${projectApiBaseUrl}/project`, undefined, projectId);
      const json = await response.json();
      if (response.ok) {
        return json;
      } else {
        if (isApiError(json)) {
          throw json;
        } else {
          throw InternalError;
        }
      }
    },
    [request]
  );

  const getProjectFromList = useCallback(
    (id: string): Project | undefined => {
      return projectsList?.find((proj) => proj.id === id);
    },
    [projectsList]
  );

  const switchProject = useCallback(
    (project: string | Project) => {
      let targetProject;
      if (typeof project === 'string') {
        targetProject = getProjectFromList(project);
      } else {
        targetProject = project;
      }
      if (targetProject && targetProject != currentProject) {
        setIsSdkInitialized(false);
        setCurrentProject(targetProject);
        setFhirVersion(targetProject.fhirVersion);
        localStorage.setItem(selectedProjectKey, targetProject.id);
      }
    },
    [currentProject, getProjectFromList]
  );

  const updateProjectData = useCallback(
    async (id: string) => {
      const updatedProject = await getProjectInfo(id);
      if (currentProject?.id === updatedProject.id) {
        setCurrentProject({
          id: updatedProject.id,
          name: updatedProject.name,
          fhirVersion: FhirVersion[updatedProject.fhirVersion],
        });
      }
      if (!projectsList) {
        return;
      }
      for (let i = 0; i < projectsList?.length; i++) {
        const project = projectsList[i];
        if (project.id == updatedProject.id) {
          projectsList[i] = {
            id: updatedProject.id,
            name: updatedProject.name,
            fhirVersion: FhirVersion[updatedProject.fhirVersion],
          };
          setProjectsList(projectsList);
          break;
        }
      }
    },
    [currentProject?.id, getProjectInfo, projectsList]
  );

  useEffect(() => {
    const selectedProjectId = localStorage.getItem(selectedProjectKey);
    if (selectedProjectId) {
      // will do nothing if saved id is not in the active list
      // example: another account was used in the same browser
      switchProject(selectedProjectId);
    }
  }, [projectsList, switchProject]);

  const initUser = useCallback(
    async (authId: string, fhirVersion: FhirVersion | undefined) => {
      try {
        // Fhir version should be taben from project
        setIsSelectingFhirVersion(false);
        const { projects, id } = await getUser();
        if (projects.length === 0) {
          throw {
            code: ErrorCode.UserNotFound,
          };
        }
        setProjectsList(projects);

        let selectedProjectId = localStorage.getItem(selectedProjectKey);
        selectedProjectId = projects.filter((proj) => proj.id == selectedProjectId)[0]?.id;
        const targetProjectId = selectedProjectId || projects[0].id;

        let project = getProjectFromList(targetProjectId);
        if (!project) {
          const projectInfo = await getProjectInfo(targetProjectId);
          project = {
            id: projectInfo.id,
            name: projectInfo.name,
            fhirVersion: FhirVersion[projectInfo.fhirVersion],
          };
        }
        setCurrentProject(project);
        setFhirVersion(project.fhirVersion);
        setZapehrUserId(id);
      } catch (error) {
        if (!fhirVersion) {
          setIsSelectingFhirVersion(true);
          return; // fhirVersion should be selcted before registration
        }
        if (isApiErrorOfType(error, ErrorCode.UserNotFound)) {
          setIsLoadingUser(true);
          const projectName = localStorage.getItem(newProjectNameKey) || 'SANDBOX';
          localStorage.removeItem(newProjectNameKey);
          const { projects, id } = await registerNewUser(authId, projectName, fhirVersion);
          setProjectsList(projects);
          const projectInfo = projects[0];
          setCurrentProject({
            id: projects[0].id,
            name: projectInfo.name,
            fhirVersion: FhirVersion[projectInfo.fhirVersion],
          });
          setZapehrUserId(id);
        } else {
          setInitializationError(error as Error);
        }
      }
    },
    [getUser, getProjectFromList, getProjectInfo, registerNewUser]
  );

  const auth0Ready = useMemo(() => {
    return isAuthenticated && user?.sub && !authIsLoading;
  }, [isAuthenticated, user, authIsLoading]);

  useEffect(() => {
    if (auth0Ready && !currentProject && !isLoadingUser && user?.sub) {
      void initUser(user?.sub, fhirVersion);
    }
  }, [currentProject, initUser, isLoadingUser, user?.sub, fhirVersion, auth0Ready]);

  // Configure ZapEHR SDK
  useEffect(() => {
    async function initZapehrSDK(): Promise<void> {
      const at = await getAuthToken();
      if (currentProject) {
        zapehr.init({
          ZAPEHR_ACCESS_TOKEN: at,
          ZAPEHR_PROJECT_ID: currentProject.id,
          ZAPEHR_FHIR_API_URL: fhirApiBaseUrl,
          ZAPEHR_PROJECT_API_URL: projectApiBaseUrl,
        });
        setIsSdkInitialized(true);
      }
    }
    if (auth0Ready) {
      void initZapehrSDK();
    }
  }, [auth0Ready, currentProject, getAuthToken]);

  const platformClient: ZapehrPlatformClient = useMemo(() => {
    return {};
  }, []);

  const zapehrContext: ZapehrContextProps = {
    zapehrUserId,
    currentProject: currentProject,
    projectsList: projectsList,
    initializationError,
    platformClient,
    authIsLoading,
    authError,
    userIsAuthenticated: isAuthenticated && !userIsLoggedOut,
    profileIsLoading: (isAuthenticated && isRegisteringUser) || authIsLoading,
    availableProjects: currentProject ? [currentProject.id] : [],
    fhirVersion,
    signIn,
    signOut,
    refreshSavedAuthToken,
    updateFhirVersion,
    createProject,
    switchProject,
    updateProjectData,
    isSelectingFhirVersion,
    isSdkInitialized,
    isAlertBannerDismissed,
    setAlertBannerDismissed: handleSetAlertBannerDismissed,
    getAuthToken,
  };

  return <ZapehrContext.Provider value={zapehrContext}>{children}</ZapehrContext.Provider>;
};

export const useZapehr = (): ZapehrContextProps => useContext(ZapehrContext);

export function usePlatformClient(): ZapehrPlatformClient {
  return useZapehr().platformClient;
}

// todo: find somewhere to put this and the structured definitions that makes sense. dumping it here for now to remove the dependency on mock package
export const GraphQLSchemaResponse: any = {
  data: {
    StructureDefinitionList: {},
    SearchParameterList: [],
  },
};
