import { Email, Sms } from '@mui/icons-material';
import * as React from 'react';
import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { PageContainer } from '../../components/PageContainer';
import { Services } from '../../services';
import { MessagingElement } from './Messaging';

export const messagingSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Messaging',
      icon: <Email />,
      path: `/${Services.messaging.rootPath}`,
    },
    {
      label: 'SMS',
      icon: <Sms />,
      path: `/${Services.messaging.rootPath}/sms`,
    },
  ],
];

const MessagingPage: FC = () => {
  const { pathname } = useLocation();

  return (
    <PageContainer sidebarItems={messagingSidebarItems}>
      {pathname === `/${Services.messaging.rootPath}` ? <MessagingElement /> : <Outlet />}
    </PageContainer>
  );
};

export default MessagingPage;
