import { LoadingButton } from '@mui/lab';
import { CircularProgress, Grid, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Await, useNavigate, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { toast } from '../../../../lib/toast';
import { Services } from '../../../../services';
import { appPageId, AppPageLoaderData } from '../../Applications';
import { userDefaults, UserFields, UserFormBody } from '../lib/UserFormBody';

export function UserCreatePage(): JSX.Element {
  const data = useRouteLoaderData(appPageId) as AppPageLoaderData;
  return (
    <Suspense fallback={<CircularProgress sx={{ margin: 2 }} />}>
      <Await resolve={data.applications} errorElement={<RootErrorBoundary />}>
        {(applications: Awaited<AppPageLoaderData['applications']>) => (
          <UserCreatePageContent applications={applications} />
        )}
      </Await>
    </Suspense>
  );
}

export function UserCreatePageContent({
  applications,
}: {
  applications: Awaited<AppPageLoaderData['applications']>;
}): JSX.Element {
  const formReturn = useForm(userDefaults);
  const { handleSubmit } = formReturn;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: UserFields): void => {
      setLoading(true);

      if (!isValidJSON(data.resource)) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for resource`);
        setLoading(false);
        return;
      }

      if (!isValidJSON(data.accessPolicy)) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        setLoading(false);
        return;
      }

      if (!data.applicationId || data.applicationId === '') {
        toast.error('You need a valid Application to assign user to.');
        setLoading(false);
        return;
      }

      const params: Parameters<typeof zapehr.project.user.invite>[0] = {
        ...data,
        resource: JSON.parse(data.resource),
        accessPolicy: JSON.parse(data.accessPolicy),
        roles: isValidJSON(data.roles) ? JSON.parse(data.roles) : [],
      };

      zapehr.project.user
        .invite(params)
        .then(() => {
          toast.success('User invited');
          revalidator.revalidate();
          navigate(`/${Services.app.rootPath}/users`);
        })
        .catch((error) => {
          toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, revalidator]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Invite User
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserFormBody
            formUtils={formReturn}
            applications={applications}
            hiddenFields={[]}
            renderErrors={(errors) => {
              // todo: typing of errors looks confused
              return (
                errors.root?.description && (
                  <Grid item>
                    <Typography variant="body1" color="error" role="alert">
                      {errors.root?.description?.message?.toString()}
                    </Typography>
                  </Grid>
                )
              );
            }}
            renderActionButton={() => (
              <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                Create
              </LoadingButton>
            )}
          />
        </form>
      </Grid>
    </Grid>
  );
}
