import { LoadingButton } from '@mui/lab';
import { CircularProgress, Grid, TextField, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Await, Link, useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom';
import { isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { TextCardWithButton } from '../../../../components/TextCardWithButton';
import { TextFieldWithCopyButton } from '../../../../components/TextFieldWithCopyButton';
import { Developer } from '../../../../lib/client';
import { toast } from '../../../../lib/toast';
import { copyToClipboard, prettyJSON } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { DeveloperFields, DeveloperFormBody } from '../lib/DeveloperFormBody';

export interface AccessPolicyError {
  attribute: string;
  type: 'info' | 'warning' | 'error';
  message: string;
}

// This function returns a value from a JSON object given a string attribute.
// For example, if json is '{ "names": ["A", "B"] }' and attribute is 'name[0]',
// the function will return "A"
export function getJSONValueFromAttribute(json: any, attribute: string): string | undefined {
  // split on period, open bracket, close bracket
  const attributes = attribute.split(/[.[\]]/).filter((attribute) => attribute !== '');

  let currentJSON = json;
  for (let i = 0; i < attributes.length; i++) {
    if (currentJSON[attributes[i]]) {
      currentJSON = currentJSON[attributes[i]];
    } else {
      return undefined;
    }
  }

  return currentJSON;
}

export function DeveloperDetailPage(): JSX.Element {
  const userPromise = useLoaderData() as { developer: Promise<Developer> };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.primary">
          Developer Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
          <Await resolve={userPromise.developer} errorElement={<RootErrorBoundary />}>
            {(developer: Developer) => {
              return <LoadedComponent developer={developer} />;
            }}
          </Await>
        </Suspense>
      </Grid>
    </Grid>
  );
}

const initialFormStateFromDev = (dev: Developer): { defaultValues: DeveloperFields } => {
  return {
    defaultValues: {
      email: dev.email,
      practitioner: JSON.stringify(prettyJSON(dev.practitioner)),
      accessPolicy: prettyJSON(dev.accessPolicy),
      roles: '',
    },
  };
};

interface LoadedComponentProps {
  developer: Developer;
}

const LoadedComponent: React.FC<LoadedComponentProps> = ({ developer }) => {
  const formReturn = useForm(initialFormStateFromDev(developer));
  const { handleSubmit } = formReturn;
  const [loading, setLoading] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const pathParams = useParams();
  const { id: userId } = pathParams;
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: any): void => {
      setLoading(true);

      if (!userId) {
        setLoading(false);
        throw new Error('No developer ID in request url');
      }

      data.accessPolicy = isValidJSON(data.accessPolicy);
      if (data.roles) {
        data.roles = isValidJSON(data.roles) || [];
      } else {
        data.roles = [];
      }
      console.log('data', data);

      if (!data.accessPolicy) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        setLoading(false);
        return;
      }

      zapehr.project.developer
        .update({ id: userId, ...data })
        .then(() => {
          toast.success('Developer successfully updated');
          // setAccessPolicyErrors([]);
        })
        .catch((error) => {
          toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userId]
  );

  const deleteHandler = useCallback(async () => {
    if (!userId) {
      return;
    }

    try {
      await zapehr.project.developer.delete({ id: userId });
      toast.success('Developer removed from project');
      revalidator.revalidate();
      navigate(`/${Services.iam.rootPath}/developers`);
    } catch (error) {
      toast.error(`An error occurred: ${(error as any).message || 'Unknown error'}`);
      setDeletePending(false);
    }
  }, [navigate, revalidator, userId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DeveloperFormBody
        formUtils={formReturn}
        hiddenFields={['email', 'practitioner']}
        assignedRoles={developer.roles}
        renderErrors={(errors) =>
          errors.description && (
            <Grid item>
              <Typography variant="body1" color="error" role="alert">
                {errors.description?.message?.toString()}
              </Typography>
            </Grid>
          )
        }
        renderPreFormBody={() => (
          <>
            <Grid item xs={12}>
              <Typography>
                Profile:&nbsp;
                <Link to={`/${Services.fhir.rootPath}/${developer.profile}`}>{developer.profile}</Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldWithCopyButton
                textField={
                  <TextField
                    fullWidth
                    value={developer.id}
                    label="User ID"
                    helperText="ID cannot be edited"
                    disabled={true}
                  />
                }
                copyButtonOnClick={() => copyToClipboard(developer.id, 'User ID copied to clipboard!')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWithCopyButton
                textField={
                  <TextField
                    fullWidth
                    value={developer.email}
                    label="Email"
                    helperText="Email cannot be edited"
                    disabled={true}
                  />
                }
                copyButtonOnClick={() => copyToClipboard(developer.email, 'Email copied to clipboard!')}
              />
            </Grid>
          </>
        )}
        renderActionButton={() => (
          <Grid item container direction="column" spacing={2}>
            <Grid item textAlign="center">
              <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        )}
        renderDeleteButton={() => (
          <Grid item container direction="column" spacing={2}>
            <Grid item textAlign="left" xs={12}>
              <TextCardWithButton
                title={`Delete Developer`}
                description={`If deleted, this developer will lose all access to this project.`}
                variant="danger"
                buttonTitle={`Delete`}
                action={() => setDeletePending(true)}
              />
            </Grid>
          </Grid>
        )}
      />
      <ConfirmationDialog
        handleAction={deleteHandler}
        open={deletePending}
        message={`Are you sure you want to remove ${developer.name} from this project?`}
        buttonTitle="Delete"
        handleClose={() => setDeletePending(false)}
      />
    </form>
  );
};
