import './FileInputComponent.css';
import styled from '@emotion/styled';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button } from '@mui/material';
import * as React from 'react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface FileInputProps {
  fileInputCallback: (file: File) => void;
}

export interface FileInputDropWrapperProps extends FileInputProps {
  children?: React.ReactNode;
  // some another HTML element that forces a wrapper to appear
  // this is done to inprove UX and to show user that this element is present on the page
  dragStateTrigger?: HTMLElement | null;
}

const handleDragStateChange = (event: React.SyntheticEvent, toggleDragState: () => void): void => {
  event.preventDefault();
  toggleDragState();
};

const clearInput = (input: HTMLInputElement | undefined): void => {
  if (input) {
    input.value = '';
  }
};

const handleDrop = (
  event: any,
  handleFileInput: (file: File) => void,
  toggleDragState?: () => void,
  hiddenInput?: HTMLInputElement
): void => {
  event.preventDefault();
  event.stopPropagation();
  if (toggleDragState) {
    toggleDragState();
  }
  let input: File[] = [];

  try {
    if (event.dataTransfer) {
      input = event.dataTransfer.files;
    } else if (event?.target.files) {
      input = Array.from(event.target.files);
    }
  } catch (e) {
    toast.error('Sorry there was an error processing your file.');
    console.error(e);
    clearInput(hiddenInput);
    return;
  }
  clearInput(hiddenInput);

  if (!input[0]) {
    toast.error('Sorry, your file was not recognised.');
    console.error('File input event does not have needed valid data (dataTransfer.files or target.files):\n', event);
    return;
  }

  if (input.length > 1) {
    toast.error('It is not allowed to upload multiple files at once.');
    return;
  }

  handleFileInput(input[0]);
};

export const FileInputButton: React.FC<FileInputProps> = ({ fileInputCallback }) => {
  const fileHiddenInput = useRef<HTMLInputElement>(null);
  return (
    <Button aria-label="Upload" component="label" variant="contained" startIcon={<CloudUploadIcon />}>
      Upload
      <VisuallyHiddenInput
        ref={fileHiddenInput}
        type="file"
        onChange={(e) => handleDrop(e, fileInputCallback, undefined, fileHiddenInput.current as HTMLInputElement)}
      />
    </Button>
  );
};

export const FileInputDropArea: React.FC<FileInputProps> = ({ fileInputCallback }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileHiddenInput = useRef<HTMLInputElement>(null);

  const handleInput = (event: any): void => {
    handleDrop(event, fileInputCallback, () => setIsDragOver(false), fileHiddenInput.current as HTMLInputElement);
  };

  return (
    <div
      onDragOver={(e) => handleDragStateChange(e, () => setIsDragOver(true))}
      onDragLeave={(e) => handleDragStateChange(e, () => setIsDragOver(false))}
      onDrop={handleInput}
      className={`file-drop-area ${isDragOver ? 'dragover' : ''}`}
      onClick={() => {
        if (fileHiddenInput.current) fileHiddenInput.current.click();
      }}
    >
      <VisuallyHiddenInput ref={fileHiddenInput} type="file" onChange={handleInput} />
      <CloudUploadIcon className="file-drop-area-image" />
      <span className="file-drop-area-label">Upload</span>
    </div>
  );
};

export const FileInputDropWrapper: React.FC<FileInputDropWrapperProps> = ({
  fileInputCallback,
  children,
  dragStateTrigger,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);

  if (dragStateTrigger) {
    dragStateTrigger.addEventListener('mouseenter', () => {
      setIsDragOver(true);
    });
    dragStateTrigger.addEventListener('mouseleave', () => {
      setIsDragOver(false);
    });
  }

  return (
    <div
      className={`file-drop-area-container ${isDragOver ? 'dragover' : ''}`}
      onDragOver={(e) => handleDragStateChange(e, () => setIsDragOver(true))}
      onDragLeave={(e) => handleDragStateChange(e, () => setIsDragOver(false))}
      onDrop={(e) => handleDrop(e, fileInputCallback, () => setIsDragOver(false))}
    >
      <div className="file-drop-area-overlay">
        <CloudUploadIcon className="file-drop-area-image" />
      </div>
      {children}
    </div>
  );
};
