import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import zapehr, { ZapEHRFHIRError } from '@zapehr/sdk';
import { Communication } from 'fhir/r4b';
import * as React from 'react';
import {
  ActionFunctionArgs,
  createBrowserRouter,
  defer,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
  ShouldRevalidateFunctionArgs,
} from 'react-router-dom';
import { RootErrorBoundary } from './components/RootErrorBoundary';
import { MixpanelProvider } from './contexts/MixpanelProvider';
import { PROJECT_NOT_SPECIFIED, USER_NOT_LOGGED_IN } from './lib/errors';
import { Bundle, OperationOutcome, Resource } from './lib/fhir-types';
import { getDefaultSearchQueryForResourceType } from './lib/searchUtils';
import { AppPage, appPageId, appPageLoader, AppServiceLayout } from './pages/App/Applications';
import { appDetailPageLoader, ApplicationDetailPage } from './pages/App/Applications/ApplicationDetail';
import { CreateApplicationPage } from './pages/App/Applications/CreateApplication';
import { UsersPage, usersPageLoader } from './pages/App/Users';
import { UserCreatePage } from './pages/App/Users/CreateUser';
import { UserDetailPage, userDetailPageLoader } from './pages/App/Users/UserDetail';
import { BillingPage } from './pages/Billing';
import { ResourcesPage } from './pages/Fhir';
import { CreateResourcePage } from './pages/Fhir/CreateResource';
import { ResourcePage, ResourcePageProps } from './pages/Fhir/Resource';
import { ResourceDetails } from './pages/Fhir/Resource/Details';
import { ResourceEdit } from './pages/Fhir/Resource/Edit';
import { ResourceHistoryTable } from './pages/Fhir/Resource/History';
import { ResourceJson } from './pages/Fhir/Resource/Json';
import { ResourceVersionPage } from './pages/Fhir/Resource/ResourceVersion';
import { ResourceTypeLoaderData, ResourceTypePage } from './pages/Fhir/ResourceType';
import { HomePage } from './pages/Home';
import { IAMPage } from './pages/IAM';
import { DevelopersPage } from './pages/IAM/Developers';
import { DeveloperCreatePage } from './pages/IAM/Developers/CreateDeveloper';
import { DeveloperDetailPage } from './pages/IAM/Developers/DeveloperDetail';
import { M2MClientsPage } from './pages/IAM/M2M';
import { M2MClientDetailPage } from './pages/IAM/M2M/ClientDetail';
import { CreateM2MClient } from './pages/IAM/M2M/CreateClient';
import { RolesPage } from './pages/IAM/Roles';
import { CreateRolePage } from './pages/IAM/Roles/CreateRole';
import { RoleDetailLoader, RoleDetailPage } from './pages/IAM/Roles/RoleDetail';
import Callback from './pages/LoginCallback';
import { Logout } from './pages/Logout';
import MessagingPage from './pages/Messaging';
import SMSPage from './pages/Messaging/SMS';
import { SendSMSPage } from './pages/Messaging/SMS/SendSMS';
import NotFound from './pages/NotFound';
import { ProfilePage } from './pages/Profile';
import { UpdateProfilePage } from './pages/Profile/UpdateProfile';
import { ProjectSettingsPage, projectSettingsPageLoader } from './pages/Project';
import { NewProjectPage } from './pages/Project/CreateProject';
import { VersionPage } from './pages/Version';
import { Z3Page } from './pages/Z3';
import { Z3BucketCreatePage } from './pages/Z3/Buckets/CreateBucket';
import { Z3ObjectsPage } from './pages/Z3/Objects';
import { ZambdaServiceLayout, ZambdasPage, zambdasPageLoader } from './pages/Zambdas';
import { CreateZambda } from './pages/Zambdas/Functions/CreateZambda';
import { ZambdaBasicInfo, ZambdaDetail, zambdaDetailLoader } from './pages/Zambdas/Functions/ZambdaDetail';
import { ZambdaLogEvents } from './pages/Zambdas/Functions/ZambdaLogEvents';
import { ZambdaLogStreams } from './pages/Zambdas/Functions/ZambdaLogStreams';
import { ZambdaLogStreamSearch } from './pages/Zambdas/Functions/ZambdaLogStreamSearch';
import { SecretsPage } from './pages/Zambdas/Secrets';
import { SecretCreatePage } from './pages/Zambdas/Secrets/CreateSecret';
import { SecretInformationPage } from './pages/Zambdas/Secrets/Secret';
import { Services } from './services';

const MUI_X_LICENSE_KEY = process.env.MUI_X_LICENSE_KEY;
if (MUI_X_LICENSE_KEY != null) {
  LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export function AuthenticatedApp(): JSX.Element {
  const router = sentryCreateBrowserRouter([
    {
      element: (
        <MixpanelProvider token={process.env.MIXPANEL_TOKEN}>
          <Outlet />
        </MixpanelProvider>
      ),
      children: [
        {
          path: '/*',
          element: <HomePage />,
          errorElement: <RootErrorBoundary />,
          children: [
            {
              path: Services.fhir.rootPath,
              element: <ResourcesPage />,
              children: [
                {
                  path: ':resourceType',
                  children: [
                    {
                      element: <ResourceTypePage />,
                      index: true,
                      shouldRevalidate: ({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs): boolean => {
                        // ResourceTypePage is re-rendered when redirect happens in loader.
                        // In order to avoid re-render shouldRevalidate needs return false if currentUrl or nextUrl causes redirect
                        return currentUrl.toString().includes('?') && nextUrl.toString().includes('?');
                      },
                      loader: (args: LoaderFunctionArgs) => {
                        const { request, params } = args;

                        const { resourceType } = params;
                        const base = params['*'];

                        if (!resourceType || !base) {
                          throw new Error('Resource type is required');
                        }

                        // bug in react-router-dom useSubmit implementation inserts index arg into query
                        const hasIndexFromRRDAction = request.url.includes('?index&');
                        if (hasIndexFromRRDAction) {
                          return redirect(request.url.replace('?index&', '?'));
                        }

                        const querySplit = request.url.split('?');
                        if (querySplit.length !== 2) {
                          return redirect(`${request.url}${getDefaultSearchQueryForResourceType(resourceType)}`);
                        }

                        const dataPromise = async (): Promise<ResourceTypeLoaderData | null> => {
                          const query = `?${querySplit[1]}`;
                          const data: ResourceTypeLoaderData = {
                            searchResponse: undefined,
                            outcome: undefined,
                          };
                          try {
                            // build api query from browser location query
                            const params = query
                              .replace('?', '')
                              .split('&')
                              .map((param) => {
                                const [name, value] = param.split('=');
                                return { name, value };
                              });
                            const searchResponse = await zapehr.fhir.search({ resourceType, params });
                            data.searchResponse = searchResponse as any;
                          } catch (reason) {
                            const error = reason as any;
                            if (error.message && error.message === USER_NOT_LOGGED_IN) {
                              throw error;
                            } else if (error.message && error.message === PROJECT_NOT_SPECIFIED) {
                              return null;
                            } else {
                              data.outcome = reason as OperationOutcome;
                            }
                          }
                          return data;
                        };
                        return defer({ data: dataPromise() });
                      },
                      action: async ({ params, request }: ActionFunctionArgs) => {
                        if (request.method === 'DELETE') {
                          const formData = await request.formData();
                          const idString = formData?.get('ids') as string;
                          const ids = idString?.split('|');
                          const { resourceType } = params;

                          if (!resourceType) {
                            throw new Error('Resource type is required');
                          }

                          try {
                            await Promise.all(
                              ids.map((id: string) => {
                                return zapehr.fhir.delete({ resourceType, id });
                              })
                            );
                            return { delete: { error: undefined } };
                          } catch (e) {
                            // console.log('error deleting resource', e);
                            const err = e as ZapEHRFHIRError;
                            return { delete: { error: err.cause ?? 'Error deleting resources' } };
                          }
                        }
                        return null;
                      },
                    },
                    {
                      path: 'new',
                      element: <CreateResourcePage />,
                    },
                    {
                      path: ':id',
                      element: <ResourcePage />,
                      loader: (args: LoaderFunctionArgs) => {
                        const { params } = args;
                        const { resourceType, id } = params;
                        if (!resourceType || !id) {
                          throw new Error('Resource type and id are required');
                        }
                        const asyncFetcher = async (): Promise<ResourcePageProps> => {
                          const promises = Promise.all([
                            zapehr.fhir.get({ resourceType, id }),
                            zapehr.fhir.history({ resourceType, id }),
                          ]);
                          const [maybeResource, maybeHistoryBundle] = await promises;
                          let resource: Resource | undefined = maybeResource;
                          let historyBundle: Bundle | undefined = maybeHistoryBundle;
                          let resourceError: OperationOutcome | undefined;
                          if (maybeResource.resourceType === 'OperationOutcome') {
                            resourceError = { ...maybeResource } as OperationOutcome;
                            resource = undefined;
                          }
                          // sanity check
                          if ((maybeHistoryBundle as unknown as OperationOutcome).resourceType === 'OperationOutcome') {
                            historyBundle = undefined;
                          }
                          return { resource, historyBundle, resourceError };
                        };
                        return defer({ data: asyncFetcher() });
                      },
                      children: [
                        {
                          element: <ResourceDetails />,
                          index: true,
                        },
                        {
                          path: 'edit',
                          element: <ResourceEdit />,
                        },
                        {
                          path: 'history',
                          element: <ResourceHistoryTable />,
                          children: [
                            {
                              path: ':versionId/:tab?',
                              element: <ResourceVersionPage />,
                              loader: (args: LoaderFunctionArgs) => {
                                const { params } = args;
                                const { resourceType, id } = params;
                                if (!resourceType || !id) {
                                  throw new Error('Resource type and id are required');
                                }
                                return defer({ history: zapehr.fhir.history({ resourceType, id }) });
                              },
                            },
                          ],
                        },
                        {
                          path: 'json',
                          element: <ResourceJson />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: `${Services.iam.rootPath}`,
              element: <IAMPage />,
              children: [
                {
                  path: 'developers',
                  element: <DevelopersPage />,
                  loader: () => {
                    return defer({ developers: zapehr.project.developer.list() });
                  },
                  children: [
                    {
                      path: ':id',
                      element: <DeveloperDetailPage />,
                      loader: (args: LoaderFunctionArgs) => {
                        const { params } = args;
                        const { id } = params;
                        if (!id) {
                          throw new Error('No developer ID in request url');
                        }
                        return defer({
                          developer: zapehr.project.developer.get({ id }),
                          roles: zapehr.project.role.list(),
                        });
                      },
                    },
                    {
                      path: 'new',
                      element: <DeveloperCreatePage />,
                      loader: () => {
                        return defer({
                          roles: zapehr.project.role.list(),
                        });
                      },
                      action: async ({ request }: ActionFunctionArgs) => {
                        console.log('invite dev request', request);
                        if (request.method === 'POST') {
                          console.log('invite dev request getting data', request);
                          try {
                            const data = await request.json();
                            console.log('invite dev request data', data);
                            return zapehr.project.developer.invite(data);
                          } catch (error) {
                            console.log('invite dev request error', error);
                          }
                        }
                        return;
                      },
                    },
                  ],
                },
                {
                  path: 'm2m-clients',
                  element: <M2MClientsPage />,
                  loader: () => {
                    return defer({ m2mUsers: zapehr.project.m2m.list() });
                  },
                  shouldRevalidate: ({ currentUrl, currentParams }: ShouldRevalidateFunctionArgs) => {
                    return (
                      currentUrl.pathname === `/${Services.iam.rootPath}/m2m-clients/new` ||
                      currentUrl.pathname === `/${Services.iam.rootPath}/m2m-clients/` + currentParams.id
                    );
                  },
                  children: [
                    {
                      path: ':id',
                      element: <M2MClientDetailPage />,
                      loader: (args: LoaderFunctionArgs) => {
                        const { id } = args.params;
                        if (!id) {
                          throw new Error('No M2M Client ID in request url');
                        }
                        return defer({ m2mUser: zapehr.project.m2m.get({ id }), roles: zapehr.project.role.list() });
                      },
                    },
                    {
                      path: 'new',
                      element: <CreateM2MClient />,
                      loader: () => {
                        return defer({ roles: zapehr.project.role.list() });
                      },
                    },
                  ],
                },
                {
                  path: 'roles',
                  element: <RolesPage />,
                  loader: () => {
                    return defer({ roles: zapehr.project.role.list() });
                  },
                  children: [
                    {
                      path: ':roleId',
                      element: <RoleDetailPage />,
                      loader: RoleDetailLoader,
                    },
                    {
                      path: 'new',
                      element: <CreateRolePage />,
                    },
                  ],
                },
              ],
            },
            { path: 'billing', element: <BillingPage /> },
            {
              path: 'profile',
              element: <ProfilePage />,
            },
            {
              path: 'profile/update',
              element: <UpdateProfilePage />,
            },
            {
              path: 'project',
              element: <ProjectSettingsPage />,
              loader: projectSettingsPageLoader,
            },
            {
              path: 'project/new',
              element: <NewProjectPage />,
            },
            {
              path: Services.app.rootPath,
              element: <AppServiceLayout />,
              loader: appPageLoader,
              id: appPageId,
              children: [
                {
                  index: true,
                  element: <AppPage />,
                },
                {
                  path: ':id',
                  element: <ApplicationDetailPage />,
                  loader: appDetailPageLoader,
                },
                {
                  path: 'new',
                  element: <CreateApplicationPage />,
                },
                {
                  path: 'users',
                  element: <UsersPage />,
                  loader: usersPageLoader,
                },
                {
                  path: 'users/:id',
                  element: <UserDetailPage />,
                  loader: userDetailPageLoader,
                },
                {
                  path: 'users/new',
                  element: <UserCreatePage />,
                  loader: () => {
                    return defer({
                      roles: zapehr.project.role.list(),
                    });
                  },
                },
              ],
            },
            {
              path: 'zambdas',
              element: <ZambdaServiceLayout />,
              children: [
                {
                  index: true,
                  element: <ZambdasPage />,
                  loader: zambdasPageLoader,
                },
                {
                  path: ':id',
                  id: 'zambda-detail',
                  element: <ZambdaDetail />,
                  loader: zambdaDetailLoader,
                  children: [
                    {
                      path: 'info',
                      element: <ZambdaBasicInfo />,
                    },
                    {
                      path: 'logs',
                      element: <ZambdaLogStreams />,
                    },
                    {
                      path: 'logs/:logStreamName',
                      element: <ZambdaLogEvents />,
                      // this route manages its own data fetching
                    },
                    {
                      path: 'logs/search',
                      element: <ZambdaLogStreamSearch />,
                    },
                    {
                      index: true,
                      element: <Navigate to="info" replace />,
                    },
                  ],
                },
                {
                  path: 'new',
                  element: <CreateZambda />,
                },
                {
                  path: 'secrets',
                  element: <SecretsPage />,
                  loader: () => {
                    return defer({ secrets: zapehr.project.secret.list() });
                  },
                  shouldRevalidate: ({ currentUrl, currentParams }: ShouldRevalidateFunctionArgs) => {
                    return (
                      currentUrl.pathname === `/${Services.zambdas.rootPath}/secrets/new` ||
                      currentUrl.pathname === `/${Services.zambdas.rootPath}/secrets/${currentParams.name}`
                    );
                  },
                  children: [
                    {
                      path: ':name',
                      element: <SecretInformationPage />,
                      loader: (args: LoaderFunctionArgs) => {
                        const { params } = args;
                        const { name } = params;
                        if (!name) {
                          throw new Error('No secret name in request url');
                        }
                        return defer({ secret: zapehr.project.secret.get({ name }) });
                      },
                    },
                    {
                      path: 'new',
                      element: <SecretCreatePage />,
                    },
                  ],
                },
              ],
            },
            {
              path: Services.messaging.rootPath,
              element: <MessagingPage />,
              loader: () => {
                return defer({ messagingConfig: zapehr.project.messaging.getMessagingConfig() });
              },
              children: [
                {
                  path: 'sms',
                  element: <SMSPage />,
                  loader: () => {
                    // todo this needs to be updated for user's selection,
                    // ticket here https://github.com/masslight/zapehr/issues/2549
                    return defer({
                      messages: zapehr.fhir.list<Communication>({
                        resourceType: 'Communication',
                        params: [
                          { name: 'medium', value: 'SMSWRIT' },
                          { name: '_count', value: 1000 },
                          { name: '_offset', value: 0 },
                        ],
                      }),
                    });
                  },
                  shouldRevalidate: ({ currentUrl, currentParams }: ShouldRevalidateFunctionArgs) => {
                    return (
                      currentUrl.pathname === `/${Services.messaging.rootPath}/sms/new` ||
                      currentUrl.pathname === `/${Services.messaging.rootPath}/sms/${currentParams.id}`
                    );
                  },
                  children: [
                    {
                      path: 'new',
                      element: <SendSMSPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: Services.z3.rootPath,
              element: <Z3Page />,
              loader: () => {
                return defer({ buckets: zapehr.project.z3.listBuckets() });
              },
              children: [
                {
                  path: 'new',
                  element: <Z3BucketCreatePage />,
                },
                {
                  path: ':id',
                  element: <Z3ObjectsPage />,
                  loader: (args: LoaderFunctionArgs) => {
                    const { params } = args;
                    const { id } = params;
                    if (!id) {
                      throw new Error('No bucket ID in request url');
                    }
                    return defer({ objects: zapehr.project.z3.listObjects({ bucketName: id, 'objectPath+': '' }) });
                  },
                  children: [
                    {
                      path: '*',
                      element: <Z3ObjectsPage />,
                    },
                  ],
                },
              ],
            },
            { path: 'logout', element: <Logout /> },
            { path: 'version', element: <VersionPage /> },
            {
              path: '*',
              loader: ({ params }: LoaderFunctionArgs) => {
                const pathname = params['*'];
                if (pathname) {
                  throw new Error('404 Not Found');
                }
                return null;
              },
              element: <NotFound />,
            },
          ],
        },
        { path: '/callback', element: <Callback /> },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<HomePage />} />;
}
