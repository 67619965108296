import { DeleteOutline } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { otherColors } from '../../contexts/AdminThemeProvider';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { killEvent } from '../../lib/utils/dom';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { getDefaultValue, PropertyInputProps, TypedValue } from './common';
import { PropertyInput } from './PropertyInput';

export function ArrayPropertyInput(props: PropertyInputProps): JSX.Element {
  const { propertyDefinition, value, onChange } = props;
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const { editModeOn } = useResourceEditingContext();
  const values = value ? (value.value as any[]) : undefined;

  const handleDelete = useCallback(
    (index: number) => {
      const updated = values ? [...values] : [];
      updated.splice(index, 1);
      onChange({
        value: updated,
        type: propertyDefinition.type,
      });
      handleCloseConfirmDeleteDialog();
    },
    [onChange, propertyDefinition.type, values]
  );

  const handleCloseConfirmDeleteDialog = (): void => {
    setDeleteIndex(null);
  };

  return (
    <Grid container direction={'column'} spacing={0}>
      {deleteIndex !== null && (
        <ConfirmationDialog
          handleAction={() => handleDelete(deleteIndex)}
          open={deleteIndex !== null}
          buttonTitle="Delete"
          handleClose={handleCloseConfirmDeleteDialog}
        />
      )}
      {values?.map((value, index) => (
        <Grid key={`${propertyDefinition.path}[${index}]`} item xs={12} container>
          <Grid container direction={'row'} spacing={0} alignItems={'flex-start'}>
            <Grid
              item
              xs={11}
              sx={{
                padding: '5px',
                marginLeft: '-5px',
                borderRadius: '5px',
                borderColor: hoveredIndex === index ? 'error.dark' : otherColors.clear,
                borderWidth: '2px',
                borderStyle: 'dashed',
                backgroundColor: hoveredIndex === index ? otherColors.destructiveWarningBackground : 'inherit',
                transitionTimingFunction: 'ease-in',
                transitionDelay: '0.05s', // prevents strobe light effect when cursor is hovered near the edge of button
                transitionDuration: '0.2s',
              }}
            >
              <PropertyInput
                propertyDefinition={{
                  ...propertyDefinition,
                  isArray: false,
                  path: `${propertyDefinition.path}.${index}`,
                }}
                value={{
                  value: value,
                  type: propertyDefinition.type,
                }}
                onChange={(newValue: TypedValue) => {
                  const updated = values !== undefined ? [...values] : [];
                  updated[index] = newValue.value;
                  onChange({
                    value: updated,
                    type: propertyDefinition.type,
                  });
                }}
              />
            </Grid>
            <Grid item xs={1} sx={{ textAlign: 'center' }}>
              {editModeOn && (
                <IconButton
                  aria-label="remove"
                  sx={{ mb: 2 }}
                  onClick={(e) => {
                    killEvent(e);
                    setDeleteIndex(index);
                    setHoveredIndex(null);
                  }}
                  onMouseOver={() => {
                    setHoveredIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(null);
                  }}
                >
                  <DeleteOutline color="error" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <div style={{ textAlign: 'left' }}>
          {editModeOn && (
            <Button
              onClick={(e) => {
                killEvent(e);
                const updated = values !== undefined ? [...values] : [];
                updated.push(getDefaultValue(propertyDefinition.type));
                onChange({
                  value: updated,
                  type: propertyDefinition.type,
                });
              }}
              sx={{ padding: 0, minWidth: '47px', fontWeight: 'bold' }}
            >
              {`Add ${propertyDefinition.name}`}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
