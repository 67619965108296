import * as React from 'react';
import { FC, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { useZapehr } from '../../components';

export const Logout: FC<any> = () => {
  const { userIsAuthenticated, signOut } = useZapehr();

  useEffect(() => {
    if (!userIsAuthenticated) {
      redirect('/');
    }
    void signOut();
  }, [userIsAuthenticated, signOut]);

  return <></>;
};
