import { Observation, Patient, Reference, Resource, Specimen } from './fhir-types';

/**
 * Tries to return the patient for the given the resource.
 * @param resource Any FHIR resource.
 * @returns The patient associated with the resource, if available.
 */
export function getPatient(resource: Resource): Patient | Reference | undefined {
  if (resource.resourceType === 'Patient') {
    return resource;
  }
  if (
    resource.resourceType === 'DiagnosticReport' ||
    resource.resourceType === 'Encounter' ||
    resource.resourceType === 'Observation' ||
    resource.resourceType === 'ServiceRequest'
  ) {
    return (resource as any).subject as Reference;
  }
  return undefined;
}

/**
 * Tries to return the specimen for the given the resource.
 * @param resource Any FHIR resource.
 * @returns The specimen associated with the resource, if available.
 */
export function getSpecimen(resource: Resource): Specimen | Reference | undefined {
  if (resource.resourceType === 'Specimen') {
    return resource;
  }
  if (resource.resourceType === 'Observation') {
    return (resource as Observation).specimen;
  }
  if (resource.resourceType === 'DiagnosticReport' || resource.resourceType === 'ServiceRequest') {
    return (resource as any).specimen?.[0];
  }
  return undefined;
}
