/* The functions in this file were authored by medplum (https://github.com/medplum/medplum) */

import { ZapEHRFHIRError } from '@zapehr/sdk';
import { DateTime } from 'luxon';
import { formatHumanName } from '../helpers';
import {
  Attachment,
  Device,
  Extension,
  Observation,
  OperationOutcome,
  Patient,
  Practitioner,
  Reference,
  RelatedPerson,
  Resource,
} from './fhir-types';
import { SearchRequest } from './searchUtils';
import { TypedValue } from './types';

export type ProfileResource = Patient | Practitioner | RelatedPerson;

/**
 * Returns a reference string for a resource.
 * @param resource The FHIR resource.
 * @returns A reference string of the form resourceType/id.
 */
function getReferenceString(resource: Resource): string {
  return resource.resourceType + '/' + resource.id;
}

/**
 * Returns true if the resource is a "ProfileResource".
 * @param resource The FHIR resource.
 * @returns True if the resource is a "ProfileResource".
 */
function isProfileResource(resource: Resource): boolean {
  return (
    resource.resourceType === 'Patient' ||
    resource.resourceType === 'Practitioner' ||
    resource.resourceType === 'RelatedPerson'
  );
}

/**
 * Returns a display string for a profile resource if one is found.
 * @param resource The profile resource.
 * @returns The display name if one is found.
 */
function getProfileResourceDisplayString(resource: ProfileResource): string | undefined {
  const names = resource.name;
  if (names && names.length > 0) {
    return formatHumanName(names[0]);
  }
  return undefined;
}

/**
 * Returns a display string for a device resource if one is found.
 * @param device The device resource.
 * @returns The display name if one is found.
 */
function getDeviceDisplayString(device: Device): string | undefined {
  const rawDevice = device as any;
  return rawDevice.displayName ?? (rawDevice.deviceName as any[])?.[0]?.name;
}

/**
 * Returns a display string for the resource.
 * @param resource The input resource.
 * @return Human friendly display string.
 */
export function getDisplayString(resource: Resource): string {
  if (isProfileResource(resource)) {
    const profileName = getProfileResourceDisplayString(resource as ProfileResource);
    if (profileName) {
      return profileName;
    }
  }
  if (resource.resourceType === 'Device') {
    const deviceName = getDeviceDisplayString(resource as Device);
    if (deviceName) {
      return deviceName;
    }
  }
  if (resource.resourceType === 'Observation') {
    const obs = resource as Observation;
    let _a: any, _b: any;
    if ('code' in resource && ((_a = obs.code) === null || _a === void 0 ? void 0 : _a.text)) {
      return (_b = obs.code) === null || _b === void 0 ? void 0 : _b.text;
    }
  }

  if ('name' in resource && resource.name && typeof resource.name === 'string') {
    return resource.name;
  }
  return getReferenceString(resource);
}

/**
 * Returns an image URL for the resource, if one is available.
 * @param resource The input resource.
 * @returns The image URL for the resource or undefined.
 */
export function getImageSrc<T extends Resource>(resource: T): string | undefined {
  if (isProfileResource(resource)) {
    const prof = resource as ProfileResource;
    const photos = prof.photo;
    if (photos) {
      for (const photo of photos) {
        const url = getPhotoImageSrc(photo);
        if (url) {
          return url;
        }
      }
    }
  }
  return undefined;
}
function getPhotoImageSrc(photo: Attachment): string | undefined {
  if (photo.url && photo.contentType && photo.contentType.startsWith('image/')) {
    return photo.url;
  }
  return undefined;
}

/**
 * Calculates the age string for display using the age appropriate units.
 * If the age is greater than or equal to 2 years, then the age is displayed in years.
 * If the age is greater than or equal to 1 month, then the age is displayed in months.
 * Otherwise, the age is displayed in days.
 * @param birthDateStr The birth date or start date in ISO-8601 format YYYY-MM-DD.
 * @param endDateStr Optional end date in ISO-8601 format YYYY-MM-DD. Default value is today.
 * @returns The age string.
 */
export function calculateAgeString(birthDateStr: string, endDateStr?: string | undefined): string {
  const { years, months, days } = calculateAge(birthDateStr, endDateStr);
  if (years >= 2) {
    return years.toString().padStart(3, '0') + 'Y';
  } else if (months >= 1) {
    return months.toString().padStart(3, '0') + 'M';
  } else {
    return days.toString().padStart(3, '0') + 'D';
  }
}

/**
 * Calculates the age in years from the birth date.
 * @param birthDateStr The birth date or start date in ISO-8601 format YYYY-MM-DD.
 * @param endDateStr Optional end date in ISO-8601 format YYYY-MM-DD. Default value is today.
 * @returns The age in years, months, and days.
 */
function calculateAge(
  birthDateStr: string,
  endDateStr?: string
): {
  years: number;
  months: number;
  days: number;
} {
  const startDateLuxon = DateTime.fromISO(birthDateStr, { zone: 'UTC' });
  const endDateLuxon = endDateStr ? DateTime.fromISO(endDateStr, { zone: 'UTC' }) : DateTime.now();
  const { years, months, days } = endDateLuxon.diff(startDateLuxon, ['years', 'months', 'days']);
  return { years, months, days };
}

/**
 * FHIR JSON stringify.
 * Removes properties with empty string values.
 * Removes objects with zero properties.
 * See: https://www.hl7.org/fhir/json.html
 * @param value The input value.
 * @param pretty Optional flag to pretty-print the JSON.
 * @returns The resulting JSON string.
 */
export function stringify(
  value: Resource | Reference | Extension | TypedValue | SearchRequest | undefined,
  pretty?: boolean | undefined
): string {
  return JSON.stringify(value, stringifyReplacer, pretty ? 2 : undefined);
}

/**
 * Evaluates JSON key/value pairs for FHIR JSON stringify.
 * Removes properties with empty string values.
 * Removes objects with zero properties.
 * @param {string} k Property key.
 * @param {*} v Property value.
 */
function stringifyReplacer(k: any, v: any): any {
  return !isArrayKey(k) && isEmpty(v) ? undefined : v;
}

/**
 * Returns true if the key is an array key.
 * @param k The property key.
 * @returns True if the key is an array key.
 */
function isArrayKey(k: string): boolean {
  return !!k.match(/\d+$/);
}
/**
 * Returns true if the value is empty (null, undefined, empty string, or empty object).
 * @param v Any value.
 * @returns True if the value is an empty string or an empty object.
 */
function isEmpty(v: any): boolean {
  if (v === null || v === undefined) {
    return true;
  }
  const t = typeof v;
  return (t === 'string' && v === '') || (t === 'object' && Object.keys(v).length === 0);
}

/**
 * Normalizes an error object into a displayable error string.
 * @param error The error value which could be a string, Error, OperationOutcome, or other unknown type.
 * @returns A display string for the error.
 */
export function normalizeErrorString(error: unknown): string {
  if (!error) {
    return 'Unknown error';
  }
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object' && 'resourceType' in error && error.resourceType === 'OperationOutcome') {
    const outcomeMessage = getErrorMessageFromOperationOutcome(error as OperationOutcome);
    if (outcomeMessage) {
      return outcomeMessage;
    }
  }
  if (error instanceof ZapEHRFHIRError) {
    const outcomeMessage = getErrorMessageFromOperationOutcome(error.cause as OperationOutcome);
    if (outcomeMessage) {
      return outcomeMessage;
    }
  }
  if (error instanceof Error) {
    return error.message;
  }
  return JSON.stringify(error);
}

const getErrorMessageFromOperationOutcome = (outcome: OperationOutcome): string | undefined => {
  const { issue } = outcome;

  if (!issue || !issue.length) {
    return undefined;
  }

  const { details } = issue[0];

  if (!details || !details.text) {
    return undefined;
  }

  let message = details.text;

  issue.slice(1).forEach((item) => {
    const { details } = item;

    if (details && details.text) {
      message += `\n ${details.text}`;
    }
  });
  return message;
};
