import { PropertyDefinition } from '../../lib/schema';

export interface TypedValue {
  value: any;
  type: string;
}

export interface PropertyInputProps {
  propertyDefinition: PropertyDefinition;
  value?: TypedValue;
  onChange: (value: TypedValue) => void;
}

const TYPES_DEFAULT_EMPTY_STRING = ['string', 'uri', 'url', 'canonical', 'markdown', 'code'];
const TYPES_DEFAULT_NULL = ['date'];

export function getDefaultValue(type: string): any {
  if (type === 'boolean') {
    return false;
  }
  if (TYPES_DEFAULT_EMPTY_STRING.includes(type)) {
    return '';
  }
  if (TYPES_DEFAULT_NULL.includes(type)) {
    return null;
  }
  return {};
}

export function getValueSetName(valueSet: string): string {
  const valueSetName = valueSet.split('|')[0];
  return valueSetName.split('/').pop() ?? '';
}
