import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FC, Suspense } from 'react';
import { Await, useAsyncError, useLoaderData } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { InfoCard } from '../../../components/InfoCard';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { copyToClipboard } from '../../../lib/utils';

interface MessagingServiceInfo {
  phoneNumber: string;
  phoneNumberType: string | undefined;
  rateLimits: string;
}

interface MessagingConfig {
  conversationConfig: MessagingServiceInfo | undefined;
  transactionalSMSConfig: MessagingServiceInfo | undefined;
}

export function MessagingElement(): JSX.Element {
  const info = useLoaderData() as { messagingConfig: MessagingConfig };

  return (
    <Grid container direction="column" rowSpacing={5}>
      <Grid item xs={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid item xs={10} display="flex" marginBottom={2}>
              <Typography variant="h4">Messaging</Typography>
              <ArticleShortcutIconButton link="https://docs.oystehr.com/services/messaging/" />
            </Grid>
            <Grid item xs={12}>
              <Suspense
                fallback={<CircularProgress sx={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />}
              >
                <Await
                  resolve={info.messagingConfig}
                  errorElement={
                    <MessagingConfigSection
                      messagingConfig={{ conversationConfig: undefined, transactionalSMSConfig: undefined }}
                    />
                  }
                >
                  {(messagingConfig) => {
                    return <MessagingConfigSection messagingConfig={messagingConfig} />;
                  }}
                </Await>
              </Suspense>
            </Grid>
            <InfoCard
              icon={{ img: FileCopyOutlinedIcon, bg: otherColors.applicationsIconBackground }}
              header="Twilio Documentation"
              label="Messaging setup and configuration docs"
              link={{ name: 'View Docs', url: 'https://docs.oystehr.com/services/messaging/' }}
            />
            <InfoCard
              icon={{ img: SupportAgentIcon, bg: otherColors.iamIconBackground }}
              header="Get help with setting up your account"
              label="Contact our specialists to support you in Messaging configuration"
              link={{ name: 'Support', url: 'mailto:support@oystehr.com' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface MessagingConfigSectionProps {
  messagingConfig: MessagingConfig;
}

interface ServiceType {
  service: 'conversation' | 'sms';
}

interface MSInfoComponentProps extends ServiceType {
  messagingServiceInfo: MessagingServiceInfo;
}
const serviceNames = { conversation: 'Conversation', sms: 'Transactional SMS' };

const MessagingConfigSection: FC<MessagingConfigSectionProps> = ({ messagingConfig }) => {
  // handle error case
  const asyncError = useAsyncError() as { code: string; message: string };
  if (asyncError?.code?.startsWith('403')) {
    return (
      <Grid item xs={12}>
        <Paper sx={{ height: '100%' }}>
          <Grid container direction="column" padding={2} alignItems={'center'}>
            <Grid container direction="row" justifyContent={'center'}>
              <Typography variant="h5">Forbidden</Typography>
              <GppBadOutlinedIcon sx={{ color: '#FF0033', height: '35px', width: '35px' }} />
            </Grid>
            <Typography variant="body2" maxWidth={'80%'} textAlign={'center'} marginTop={1} marginBottom={1}>
              Sorry, you do not have permission to view information about messaging services configuration. Please
              ensure that your access policy allows this action.
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  // handle normal case
  const conversationConfig = messagingConfig.conversationConfig;
  const smsConfig = messagingConfig.transactionalSMSConfig;
  return (
    <Grid item xs={12} style={{ display: 'flex', columnGap: '20px', marginTop: '35px' }}>
      {conversationConfig ? (
        <MSInfoComponent service="conversation" messagingServiceInfo={conversationConfig} />
      ) : (
        <MSSetupComponent service="conversation" />
      )}
      {smsConfig ? (
        <MSInfoComponent service="sms" messagingServiceInfo={smsConfig} />
      ) : (
        <MSSetupComponent service="sms" />
      )}
    </Grid>
  );
};

// MS = Messaging Service
const MSSetupComponent: FC<ServiceType> = ({ service }) => {
  return (
    <Grid item xs={12}>
      <Paper sx={{ height: '100%' }}>
        <Grid container direction="column" padding={2}>
          <Grid item xs={10} marginBottom={2}>
            <Typography variant="h6">{serviceNames[service]} service is not connected yet</Typography>
            <Typography variant="body2" marginTop={2}>
              Please use docs or contact our support team to connect your Twilio account for {serviceNames[service]}.{' '}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

// MS = Messaging Service
const MSInfoComponent: FC<MSInfoComponentProps> = ({ service, messagingServiceInfo }) => {
  const config = {
    phoneNumber: formatPhoneNumber(messagingServiceInfo.phoneNumber),
    phoneType: messagingServiceInfo.phoneNumberType,
    rateLimits: messagingServiceInfo.rateLimits,
  };
  return (
    <Grid item xs={12}>
      <Paper sx={{ height: '100%' }}>
        <Grid container direction="column" padding={2}>
          <Typography variant="h5">Your {serviceNames[service]} service details</Typography>
          <Grid item xs={10} marginTop={2}>
            <Typography
              variant="body2"
              style={{ fontSize: '16px', color: '#2896C6', cursor: 'pointer', width: 'fit-content' }}
              onClick={() => copyToClipboard(config.phoneNumber, `"${config.phoneNumber}" is copied to clipboard`)}
            >
              {config.phoneNumber}
            </Typography>
            <Typography variant="body2">{config.phoneType}</Typography>
            <Typography variant="body2">Rate limit: {config.rateLimits}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  const countryCode = cleanedNumber.substring(0, 1);
  const areaCode = cleanedNumber.substring(1, 4);
  const firstPart = cleanedNumber.substring(4, 7);
  const secondPart = cleanedNumber.substring(7);

  const formattedNumber = `+${countryCode} ${areaCode} ${firstPart} ${secondPart}`;

  return formattedNumber;
};
