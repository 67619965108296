import { ContentCopy } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import * as React from 'react';

interface TextFieldWithCopyButtonInput {
  textField: JSX.Element;
  copyButtonOnClick?: () => void;
}

export function TextFieldWithCopyButton({ textField, copyButtonOnClick }: TextFieldWithCopyButtonInput): JSX.Element {
  return (
    <Grid container direction="row" spacing={0} alignContent="center">
      <Grid item xs={11}>
        {textField}
      </Grid>
      <Grid item xs={1} textAlign="center" display="flex" justifyContent="center" alignSelf="start" mt={1}>
        <IconButton onClick={copyButtonOnClick} aria-label="Copy value">
          <ContentCopy />
        </IconButton>
      </Grid>
    </Grid>
  );
}
