import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { HomePageRoundIcon } from './HomePageRoundIcon';

interface InfoCardProps {
  icon?: { img: React.ElementType; bg: string };
  header: string;
  label: string;
  link?: { name: string; url: string; newTab?: boolean };
}

export const InfoCard: FC<InfoCardProps> = ({ icon, header, label, link }) => {
  const Icon = icon?.img;
  // making opening link in new tab by default
  // true if link.newTab is true or undefined, false only if link.newTab is false
  const linkToNewTab = link?.newTab == false ? false : true;
  return (
    <Grid item xs={12}>
      <Paper sx={{ marginTop: 3, backgroundColor: '#e3edf6' }}>
        <Grid container direction="column" padding="16px 24px">
          <Box display="flex" flexDirection="row">
            {Icon ? <HomePageRoundIcon backgroundColor={icon.bg} icon={<Icon />} /> : <></>}
            <Box
              display="flex"
              flexDirection="column"
              sx={{ paddingRight: link ? 3 : 0, paddingLeft: icon ? 3 : 0 }}
              flex={1}
            >
              <Typography variant="h6">{header}</Typography>
              <Typography variant="body2">{label}</Typography>
            </Box>
            {link ? (
              <Button
                component={Link}
                to={link.url}
                target={linkToNewTab ? '_blank' : ''}
                variant="contained"
                color="primary"
                sx={{ height: 'fit-content', alignSelf: 'center', minWidth: 120 }}
              >
                {link.name}
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};
