import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import BreadcrumbsBasic from '../../../../components/BreadCrumbsBasic';
import { SecretUpsertForm } from '../../../../components/SecretUpsertForm';
import { InputField, PathHistoryBreadcrumb } from '../../../../lib/types';

const prevPaths: PathHistoryBreadcrumb[] = [{ text: 'Secrets', from: '/zambdas/secrets' }];

export const secretFields: InputField[] = [
  {
    name: 'name',
    label: 'Key',
    placeholder: 'SECRET_KEY',
    required: true,
  },
  {
    name: 'value',
    label: 'Value',
    placeholder: '1111111111',
    required: true,
  },
];

export function SecretCreatePage(): JSX.Element {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BreadcrumbsBasic prevPaths={prevPaths} currPathText="Create Secret"></BreadcrumbsBasic>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Create Secret
        </Typography>
        <Grid item xs={12}>
          <SecretUpsertForm method="create" secretFields={secretFields} />
        </Grid>
      </Grid>
    </Grid>
  );
}
