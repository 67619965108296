import { Add } from '@mui/icons-material';
import { alpha, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import * as React from 'react';
import { Suspense } from 'react';
import { Await, Link, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { ArticleShortcutIconButton } from '../../../components/ArticleShortcutIconButton';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { M2MClient } from '../../../lib/client';
import { InputField } from '../../../lib/types';
import { prettyJSON } from '../../../lib/utils';
import { Services } from '../../../services';

export function M2MClientsPage(): JSX.Element {
  const usersPromise = useLoaderData() as { m2mUsers: Promise<M2MClient[]> };
  const { pathname } = useLocation();
  return (
    <div>
      {pathname === `/${Services.iam.rootPath}/m2m-clients` ? (
        <Grid container>
          <Grid item xs={12} display="flex" marginBottom={2}>
            <Typography variant="h4" sx={{ margin: 0 }}>
              M2M Clients
            </Typography>
            <ArticleShortcutIconButton link="https://docs.oystehr.com/services/iam/m2m-clients/" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              M2M Clients are used to access Oystehr APIs from scripts and server-side code like{' '}
              <MUILink href={'https://docs.oystehr.com/services/zambda/'} target="_blank">
                Zambda Functions
              </MUILink>
              .
            </Typography>
          </Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
            <Link to={`/${Services.iam.rootPath}/m2m-clients/new`}>
              <Button aria-label="new" variant="contained" startIcon={<Add />}>
                Create
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ height: '60vh', marginTop: 4 }}>
              <Suspense fallback={<M2MClientList loading clients={[]} />}>
                <Await resolve={usersPromise.m2mUsers} errorElement={<RootErrorBoundary />}>
                  {(m2mUsers) => {
                    return <M2MClientList loading={false} clients={m2mUsers} />;
                  }}
                </Await>
              </Suspense>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

interface M2MListProps {
  clients: M2MClient[];
  loading: boolean;
}

function M2MClientList(props: M2MListProps): JSX.Element {
  const { clients, loading } = props;

  return (
    <DataGridPro
      loading={loading}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 0.75,
          renderCell: (params) => <Link to={params.row.id}>{params.row.name}</Link>,
        },
        { field: 'description', headerName: 'Description', flex: 1.25 },
        { field: 'id', headerName: 'ID', flex: 1 },
      ]}
      rows={clients.map((client: M2MClient) => ({
        id: client.id,
        name: client.name,
        description: client.description,
      }))}
      components={{ Toolbar: GridToolbar }}
      // todo remove code duplication with SearchControl
      sx={{
        '& .MuiDataGrid-toolbarContainer': {
          pl: 1.5,
          gap: 4,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '& .MuiButtonBase-root': {
            color: otherColors.charcoal87,
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
        },
      }}
    />
  );
}

export const m2mClientFields: InputField[] = [
  {
    name: 'name',
    label: 'Name',
    helperText: 'Name of the M2M Client',
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    helperText: 'Description of the M2M Client',
    required: true,
    maxLength: 140,
    multiline: true,
    minRows: 2,
  },
  {
    name: 'accessPolicy',
    label: 'Access policy',
    helperText: 'Access policy of the M2M Client',
    required: true,
    multiline: true,
    minRows: 10,
    defaultValue: prettyJSON({
      rule: [
        {
          resource: ['*'],
          action: ['*'],
          effect: 'Allow',
        },
      ],
    }),
    type: 'access policy',
  },
  {
    name: 'jwksUrl',
    label: 'JWKS url',
    helperText: 'An URL of JWK set used for getting an access token by signed authentication JWT',
  },
];
