import { capitalize, Grid, MenuItem, Stack } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { Reference } from '../../lib/fhir-types';
import { PropertyDefinition } from '../../lib/schema';
import { AutocompleteOption } from '../../lib/types';
import { CustomTextField } from '../WrappedInputs/CustomTextField';
import { FhirFormAutocomplete } from '../WrappedInputs/FhirFormAutocomplete';

export interface ReferenceInputProps {
  propertyDefinition: PropertyDefinition;
  value?: Reference;
  targetTypes?: string[];
  onChange: (value: Reference | null) => void;
  placeholder?: string;
}

export function ReferenceInput(props: ReferenceInputProps): JSX.Element {
  const { editModeOn, useReferenceLink, useReferenceOptions, getFilteredReferenceOptions } =
    useResourceEditingContext();
  const { value, targetTypes, onChange, placeholder, propertyDefinition } = props;
  const referenceLink = useReferenceLink(propertyDefinition.path.split('.').slice(1).join('.'));

  const passthroughType = useMemo(() => {
    if (value?.type) {
      return value.type;
    }
    if (targetTypes && targetTypes.length === 1) {
      return targetTypes[0];
    }

    return '';
  }, [value, targetTypes]);

  const { asyncOptions } = useReferenceOptions(passthroughType);

  const displayLink: boolean = useMemo(() => {
    return !editModeOn && referenceLink !== undefined;
  }, [editModeOn, referenceLink]);

  // console.log('reference link destination', referenceLink?.destination);

  const handleTypeChange = useCallback(
    (input: string | undefined) => {
      const exsitingType = value?.type;

      if (input && input === exsitingType) {
        return;
      }

      onChange({ type: input ?? '', reference: '' });
    },
    [onChange, value]
  );

  const updateOptionsList = useCallback(
    async (searchParam: string, searchValue: string): Promise<AutocompleteOption[]> => {
      return getFilteredReferenceOptions(passthroughType, searchParam, searchValue);
    },
    [passthroughType, getFilteredReferenceOptions]
  );

  const handleReferenceChange = useCallback(
    (input: string) => {
      const type = value?.type;
      if (!type) {
        onChange({ type: passthroughType, reference: input ?? '' });
      } else {
        onChange({ type, reference: input ?? '' });
      }
    },
    [onChange, passthroughType, value?.type]
  );

  const singleTypeReference = targetTypes?.length === 1;

  const renderTypeSelectionView = (): JSX.Element | null => {
    if (targetTypes) {
      if (singleTypeReference) {
        return null;
      }
      return (
        <Grid item sx={{ flexGrow: 1, flexBasis: 0 }}>
          <CustomTextField
            select
            label="Type"
            value={value?.type ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => handleTypeChange(e.target.value)}
            sx={{ width: '100%' }}
            displayOnly={!editModeOn}
          >
            {targetTypes.map((targetType) => (
              <MenuItem key={targetType} value={targetType}>
                {targetType}
              </MenuItem>
            ))}
          </CustomTextField>
        </Grid>
      );
    }
    return (
      <CustomTextField
        value={passthroughType}
        sx={{ width: '100%' }}
        label={placeholder}
        onChange={(event): void => handleTypeChange(event.target.value)}
      />
    );
  };
  return (
    <Grid container gap={2}>
      {renderTypeSelectionView()}
      <Grid item={placeholder ? false : true} sx={{ flexGrow: 1, flexBasis: 0 }}>
        {displayLink && referenceLink ? (
          <Stack direction="row" ml={2} spacing={2}>
            <Link to={referenceLink.destination}>{referenceLink.display}</Link>
          </Stack>
        ) : (
          <FhirFormAutocomplete
            value={value?.reference}
            initialPropertyValue={value?.reference}
            optionsAsync={asyncOptions ? () => asyncOptions : undefined}
            optionsList={[]}
            label={capitalize(propertyDefinition.name)}
            sx={{ width: '100%' }}
            onChange={(option) => handleReferenceChange(option?.value ?? '')}
            updateOptionsList={updateOptionsList}
          />
        )}
      </Grid>
    </Grid>
  );
}
