import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { PropertyDefinition } from '../../lib/schema';

const DATA_TYPES_WITH_OWN_PAGES = ['BackboneElement', 'Dosage', 'ProductShelfLife', 'MarketingStatus', 'Narrative'];

export interface PropertyInfoWrapperProps {
  propertyDefinition: PropertyDefinition;
  children: ReactNode;
}

export function PropertyInfoWrapper(props: PropertyInfoWrapperProps): JSX.Element {
  const { propertyDefinition, children } = props;

  const linkUrl = useMemo(() => {
    let linkUrl = '';
    const resourceName = propertyDefinition.path.split('.').slice(0, 1).pop();
    linkUrl = `https://www.hl7.org/fhir/R4B/${resourceName?.toLowerCase()}-definitions.html#${propertyDefinition.path}`;
    return linkUrl.replace('[x]', '_x_');
  }, [propertyDefinition]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={linkUrl}
          variant="h6"
          color={'secondary'}
          underline="hover"
          marginRight="20px"
        >{`${propertyDefinition.name.replace('[x]', '')}`}</Link>
        {renderTypeInfo(propertyDefinition)}
      </Box>
      {propertyDefinition.description && (
        <Typography variant="caption" color={'text.primary'} mb={1} textAlign={'left'}>
          {propertyDefinition.description}
        </Typography>
      )}
      {children}
    </Box>
  );
}

function renderTypeInfo(propertyDefinition: PropertyDefinition): JSX.Element {
  const choiceTypes = propertyDefinition.choiceTypes;
  if (choiceTypes !== undefined) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {choiceTypes.map((type, index) => {
          return (
            <span key={`${propertyDefinition.path}-wrapper-type-link-${type.name}`}>
              {renderLink(type.name)}
              {index < choiceTypes.length - 1 && choiceTypes.length > 1 && (
                <Typography variant="caption" marginLeft={'5px'} marginRight={'5px'}>
                  |
                </Typography>
              )}
            </span>
          );
        })}
      </div>
    );
  }

  if (propertyDefinition.isArray) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography marginRight="10px">{'['}</Typography>
        {renderLink(propertyDefinition.type)}
        <Typography marginLeft="10px">{']'}</Typography>
      </Box>
    );
  }

  return renderLink(propertyDefinition.type);
}

function renderLink(type: string): JSX.Element {
  const typeToShow = type.includes('.') ? 'BackboneElement' : type;
  return (
    <Link variant="caption" target="_blank" rel="noopener noreferrer" href={makeLink(typeToShow)}>
      {typeToShow}
    </Link>
  );
}

function makeLink(type: string): string {
  if (DATA_TYPES_WITH_OWN_PAGES.includes(type)) {
    return `http://hl7.org/fhir/R4B/${type}.html`;
  }
  if (type === 'Reference') {
    return 'http://hl7.org/fhir/R4B/references.html#Reference';
  }
  if (type === 'UsageContext' || type === 'Expression' || type === 'ContactDetail') {
    return `http://hl7.org/fhir/R4B/metadatatypes.html#${type}`;
  }
  return `http://hl7.org/fhir/R4B/datatypes.html#${type}`;
}
