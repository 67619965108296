import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { toast } from '../../../../lib/toast';
import { Services } from '../../../../services';
import { developerDefaults, DeveloperFormBody } from '../lib/DeveloperFormBody';

export function DeveloperCreatePage(): JSX.Element {
  const formReturn = useForm(developerDefaults);
  const { handleSubmit } = formReturn;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: any): void => {
      setLoading(true);
      console.log('data accessPolicy', data.accessPolicy);
      data.practitioner = isValidJSON(data.practitioner);

      data.accessPolicy = isValidJSON(data.accessPolicy);
      data.roles = isValidJSON(data.roles) ? JSON.parse(data.roles) : [];

      if (!data.practitioner) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for practitioner`);
        setLoading(false);
        return;
      }

      if (!data.accessPolicy) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        setLoading(false);
        return;
      }

      zapehr.project.developer
        .invite(data)
        .then(() => {
          toast.success('Developer invited');
          revalidator.revalidate();
          navigate(`/${Services.iam.rootPath}/developers`);
        })
        .catch((error) => {
          toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, revalidator]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Invite Developer
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DeveloperFormBody
              formUtils={formReturn}
              hiddenFields={[]}
              renderErrors={(errors) => {
                // todo: typing of errors looks confused
                return (
                  errors.root?.description && (
                    <Grid item>
                      <Typography variant="body1" color="error" role="alert">
                        {errors.root?.description?.message?.toString()}
                      </Typography>
                    </Grid>
                  )
                );
              }}
              renderActionButton={() => (
                <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                  Create
                </LoadingButton>
              )}
            />
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
