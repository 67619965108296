import './SearchControl.css';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { alpha, Box, Button, Grid, Paper } from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { otherColors } from '../contexts/AdminThemeProvider';
import { useZapehr } from '../contexts/ZapehrProvider';
import { fhirResourceCsvDownloadFields } from '../helpers';
import { Bundle, FhirResource, OperationOutcome, Resource } from '../lib/fhir-types';
import { getSchema } from '../lib/schema';
import {
  buildFieldNameString,
  getOperatorsForField,
  SearchRequest,
  updateFilters,
  updateSortRules,
} from '../lib/searchUtils';
import { SearchTableFragment } from './SearchTableFragment';

export class SearchChangeEvent extends Event {
  readonly definition: SearchRequest;

  constructor(definition: SearchRequest) {
    super('change');
    this.definition = definition;
  }
}

export class SearchLoadEvent extends Event {
  readonly response: Bundle;

  constructor(response: Bundle) {
    super('load');
    this.response = response;
  }
}

export class SearchClickEvent extends Event {
  readonly resource: Resource;
  readonly browserEvent: MouseEvent;

  constructor(resource: Resource, browserEvent: MouseEvent) {
    super('click');
    this.resource = resource;
    this.browserEvent = browserEvent;
  }
}

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
const optionsConst = [...PAGE_SIZE_OPTIONS] as const;
type PageSizeTuple = typeof optionsConst;
export type PageSizeOption = PageSizeTuple[number];

export interface SearchControlProps {
  search: SearchRequest;
  outcome: OperationOutcome | undefined;
  searchResponse: Bundle | undefined;
  pageSize: PageSizeOption;
  checkboxesEnabled?: boolean;
  hideToolbar?: boolean;
  hideFilters?: boolean;
  isLoadingData?: boolean;
  onPageSizeChange?: (newVal: number) => void;
  onLoad?: (e: SearchLoadEvent) => void;
  onChange?: (e: SearchChangeEvent) => void;
  onClick?: (e: SearchClickEvent) => void;
  onAuxClick?: (e: SearchClickEvent) => void;
  onNew?: () => void;
  onExport?: () => void;
  onDelete?: (ids: string[]) => void;
  onPatch?: (ids: string[]) => void;
  onBulk?: (ids: string[]) => void;
}

interface ResourceFilterModel extends GridFilterModel {
  resourceType: string;
}

const makeFilterModelFromSearch = (searchRequest: SearchRequest): ResourceFilterModel => {
  return {
    resourceType: searchRequest.resourceType,
    items: (searchRequest.filters ?? []).map((filter) => ({
      columnField: filter.code,
      operatorValue: filter.operator,
      value: filter.value ?? '',
      id: filter.id ?? uuidv4(),
    })),
    linkOperator: GridLinkOperator.And,
  };
};

const makeSortModelFromSearch = (searchRequest: SearchRequest): GridSortModel => {
  return (
    (searchRequest.sortRules ?? []).map((sortRule) => ({
      field: sortRule.code ?? uuidv4(),
      sort: sortRule.descending ? 'desc' : 'asc',
    })) ?? []
  );
};

/**
 * The SearchControl component represents the embeddable search table control.
 * It includes the table, rows, headers, sorting, etc.
 * It does not include the field editor, filter editor, pagination buttons.
 */
export function SearchControl(props: SearchControlProps): JSX.Element {
  const { search, searchResponse, outcome, isLoadingData, pageSize, onPageSizeChange, onChange } = props;

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { currentProject } = useZapehr();
  const schema = getSchema(currentProject?.fhirVersion);

  /**
   * Emits a change event to the optional change listener.
   * @param newSearch The new search definition.
   */
  const emitSearchChange = useCallback(
    (newSearch: SearchRequest) => {
      if (onChange) {
        // console.log('emitting change: ', newSearch);
        onChange(new SearchChangeEvent(newSearch));
      }
    },
    [onChange]
  );

  const filterModelChange = useCallback(
    (model: GridFilterModel, _details: GridCallbackDetails<'filter'>) => {
      filterModelRef.current = { resourceType: search.resourceType, ...model };
      const newFilters = updateFilters(search, model.items, schema);
      // console.log('new filters', newFilters, search, model.items);
      // console.log('filterModelChange', model, details);
      const nonEmptyFilters = {
        ...newFilters,
        filters: (newFilters.filters ?? []).filter(
          (filterVal) => filterVal.value != undefined && filterVal.value !== ''
        ),
      };
      emitSearchChange(nonEmptyFilters);
    },
    [emitSearchChange, search, schema]
  );

  const sortModelChange = useCallback(
    (model: GridSortModel, _details: GridCallbackDetails<any>) => {
      sortModelRef.current = model;
      // console.log('model, details', model, details, updateSortRules(search, model));
      const newSortRules = updateSortRules(search, model, schema);
      emitSearchChange(newSortRules);
    },
    [emitSearchChange, search, schema]
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      const newSearch = { ...search };
      newSearch.offset = pageSize * newPage;
      newSearch.count = pageSize;
      emitSearchChange(newSearch);
    },
    [emitSearchChange, search, pageSize]
  );

  const currentPage = useMemo(() => {
    if (!search.count || search.offset === undefined) {
      return 0;
    }
    return search.offset / search.count;
  }, [search]);

  useEffect(() => {
    if (search.count !== pageSize) {
      const newSearch = { ...search };
      newSearch.count = pageSize;
      emitSearchChange(newSearch);
    }
  }, [emitSearchChange, search, pageSize]);

  const { fields, resources } = useMemo(() => {
    const fields = search.fields ?? ['id', 'lastUpdated'];

    const lastResult = searchResponse;
    const entries = lastResult?.entry || [];
    const resources: FhirResource[] = entries
      .map((e) => e.resource)
      .filter((resource) => !!resource && !!resource.id) as FhirResource[];

    /*
    console.log('resources', resources);
    console.log('fields', fields);
    console.log('search to render', search);
    */

    return { fields, resources };
  }, [search, searchResponse]);

  // duplicating state like this is not ideal
  // here's the ticket that led us here: https://github.com/masslight/zapehr/issues/1285#event-8910288387
  // can revisit if we move away from the default, "search-as-you-type" implementation
  const gridApiRef = useGridApiRef();
  const filterModelRef = useRef<ResourceFilterModel>(makeFilterModelFromSearch(search));
  const sortModelRef = useRef<GridSortModel>(makeSortModelFromSearch(search));
  useEffect(() => {
    if (search.resourceType !== filterModelRef.current.resourceType && gridApiRef.current) {
      filterModelRef.current = makeFilterModelFromSearch(search);
      sortModelRef.current = makeSortModelFromSearch(search);
      gridApiRef.current.setFilterModel(filterModelRef.current);
      gridApiRef.current.setSortModel(sortModelRef.current);
    }
  }, [gridApiRef, search]);

  /*
  console.log('current filter model', filterModelRef.current);
  console.log('current fields', fields);
  */

  return (
    <Box sx={{ height: 'auto' }} data-testid="search-control">
      {!props.hideToolbar && (
        <div>
          {/* TODO: Make this more responsive */}
          <Grid container justifyContent={'space-between'} alignItems={'flex-start'} marginBottom={3} marginTop={1}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid item>
                  <Button aria-label="new" variant="contained" startIcon={<Add />} onClick={props.onNew}>
                    Add
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    aria-label="delete"
                    loading={isLoadingData}
                    variant="contained"
                    color="warning"
                    disabled={Object.keys(selectionModel).length === 0}
                    onClick={() => (props.onDelete as (ids: GridSelectionModel) => any)(selectionModel)}
                  >
                    Delete
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      <Paper sx={{ height: '100%' }}>
        <Box sx={{ height: '100%', mt: 1 }}>
          <DataGridPro
            apiRef={gridApiRef}
            loading={isLoadingData}
            checkboxSelection
            disableSelectionOnClick
            disableColumnMenu
            autoHeight
            pagination
            paginationMode="server"
            page={currentPage}
            pageSize={pageSize}
            rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            onPageChange={handlePageChange}
            onPageSizeChange={onPageSizeChange}
            sortingMode="server"
            rowCount={searchResponse?.total ?? search.count ?? 25}
            components={{ Toolbar: GridToolbar }}
            onSelectionModelChange={(ids) => {
              setSelectionModel(ids);
            }}
            columns={fields.map((field): GridColDef => {
              return {
                field: field,
                headerName: buildFieldNameString(field),
                renderCell: (params: GridRenderCellParams<{ field: string }>) => {
                  const resource = resources.find((r) => r.id === params.row.id);
                  if (resource) {
                    return <SearchTableFragment resource={resource} field={field} typesSchema={schema} />;
                  }
                  return <></>;
                },
                filterOperators: getOperatorsForField(field, search.resourceType, schema),
                minWidth: 120,
                flex: 1,
              };
            })}
            rows={(resources ?? []).map((resource) => {
              const typeOfResource = resource.resourceType;
              return fhirResourceCsvDownloadFields(typeOfResource, resource);
            })}
            sortModel={sortModelRef.current}
            onSortModelChange={sortModelChange}
            sortingOrder={['asc', 'desc']}
            filterMode={'server'}
            filterModel={filterModelRef.current}
            onFilterModelChange={filterModelChange}
            headerHeight={56}
            sx={{
              '& .MuiDataGrid-toolbarContainer': {
                pl: 1.5,
                gap: 4,
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                '& .MuiButtonBase-root': {
                  color: otherColors.charcoal87,
                },
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
              },
            }}
          />
        </Box>
      </Paper>
      {outcome && (
        <div data-testid="search-error" className="empty-search">
          <pre style={{ textAlign: 'left' }}>{JSON.stringify(outcome, undefined, 2)}</pre>
        </div>
      )}
    </Box>
  );
}

export const MemoizedSearchControl = SearchControl;
