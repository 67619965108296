import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useZapehr } from '../contexts/ZapehrProvider';

interface AdminSidebarProps {
  adminSidebarOpen: boolean;
  setAdminSidebarOpen: any;
  children: ReactNode;
  sidebarItems: AdminSidebarItem[][];
}

export interface AdminSidebarItem {
  label: string;
  icon: ReactElement;
  path: string;
}

const closedDrawerWidth = 55;
const drawerWidth = 250;
const alertBannerHeight = 48;

export const AdminSidebar: FC<AdminSidebarProps> = ({
  adminSidebarOpen,
  setAdminSidebarOpen,
  children,
  sidebarItems,
}) => {
  const { isAlertBannerDismissed } = useZapehr();
  const theme = useTheme();

  const { pathname } = useLocation();

  const selectedItem = useMemo(() => {
    // not sure why exactly this is a nested array but just going with it for now
    let outerIndex = 0;
    let innerIndex = 0;
    let longestSidebarPath = 0;

    sidebarItems.forEach((list, oi) => {
      list.forEach((item, ii) => {
        const { path } = item;
        if (
          (pathname === path || pathname.startsWith(path + '?') || pathname.startsWith(path + '/')) &&
          path.length > longestSidebarPath
        ) {
          longestSidebarPath = path.length;
          outerIndex = oi;
          innerIndex = ii;
        }
      });
    });

    return [outerIndex, innerIndex];
  }, [pathname, sidebarItems]);

  const label = `${adminSidebarOpen ? 'Close' : 'Open'} sidebar`;

  return (
    <Box height="100%" width="100%">
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': {
            width: adminSidebarOpen ? drawerWidth : closedDrawerWidth,
            top: isAlertBannerDismissed ? '64px' : `${64 + alertBannerHeight}px`,
            transitionProperty: 'width',
            transitionTimingFunction: adminSidebarOpen
              ? theme.transitions.easing.easeOut
              : theme.transitions.easing.sharp,
            transitionDuration: adminSidebarOpen
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen,
          },
        }}
      >
        <Box
          sx={{
            pl: 1,
          }}
        >
          <Tooltip title={label} placement="right">
            <IconButton onClick={() => setAdminSidebarOpen(!adminSidebarOpen)} aria-label={label}>
              <KeyboardDoubleArrowRight
                sx={{
                  transform: adminSidebarOpen ? 'rotate(180deg);' : 'rotate(360deg)',
                  // transitionDuration: '500ms',
                  transitionProperty: 'transform',
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        <List>
          {sidebarItems.map((sidebarItemsSublist, outerIndex) => {
            const sublistPaths = sidebarItemsSublist.map((item) => item.path).join('-');
            return (
              <div key={sublistPaths}>
                {sidebarItemsSublist.map((sidebarItem: AdminSidebarItem, innerIndex) => {
                  const isSelected = selectedItem[0] === outerIndex && selectedItem[1] === innerIndex;
                  return (
                    <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }} key={sidebarItem.label}>
                      <Link to={sidebarItem.path} style={{ width: '100%' }}>
                        <Tooltip title={sidebarItem.label} placement="right">
                          <ListItemButton selected={isSelected}>
                            <ListItemIcon sx={{ alignItems: 'center', minWidth: '40px' }}>
                              {sidebarItem.icon}
                            </ListItemIcon>
                            <ListItemText primary={sidebarItem.label} sx={{ whiteSpace: 'nowrap' }} />
                          </ListItemButton>
                        </Tooltip>
                      </Link>
                    </ListItem>
                  );
                })}
                {outerIndex != sidebarItems.length - 1 && <Divider />}
              </div>
            );
          })}
        </List>
      </Drawer>
      <Box
        sx={{
          height: '100%',
          marginLeft: adminSidebarOpen ? `${drawerWidth}px` : `${closedDrawerWidth}px`,
          paddingLeft: '36px',
          paddingRight: '36px',
          paddingTop: isAlertBannerDismissed ? '36px' : `${36 + alertBannerHeight}px`,
          paddingBottom: '48px',
          transitionProperty: 'margin-left',
          transitionTimingFunction: adminSidebarOpen
            ? theme.transitions.easing.easeOut
            : theme.transitions.easing.sharp,
          transitionDuration: adminSidebarOpen // todo why is ms required here but not above
            ? `${theme.transitions.duration.leavingScreen}ms`
            : `${theme.transitions.duration.enteringScreen}ms`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
