import { LoadingButton } from '@mui/lab';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useState } from 'react';
import { Await, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsBasic from '../../../../components/BreadCrumbsBasic';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { SecretUpsertForm } from '../../../../components/SecretUpsertForm';
import { Secret } from '../../../../lib/client';
import { toast } from '../../../../lib/toast';
import { InputField, PathHistoryBreadcrumb } from '../../../../lib/types';

export function SecretInformationPage(): JSX.Element {
  const secretPromise = useLoaderData() as { secret: Promise<Secret> };
  const navigate = useNavigate();
  const pathParams = useParams();
  const { name: secretName } = pathParams;

  const [loading, setLoading] = useState(false);
  const [showDeleteSecretDialog, setShowDeleteSecretDialog] = useState(false);

  const prevPaths: PathHistoryBreadcrumb[] = [{ text: 'Secrets', from: '/zambdas/secrets' }];

  const handleDelete = useCallback(async (): Promise<void> => {
    setLoading(true);

    if (!secretName) {
      setLoading(false);
      throw new Error('No secret name in request url');
    }

    zapehr.project.secret
      .delete({ name: secretName })
      .then(() => {
        toast.success('Secret deleted');
        handleCloseDeleteDialog();
        navigate('/zambdas/secrets');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, secretName]);

  const handleCloseDeleteDialog = (): void => {
    setShowDeleteSecretDialog(false);
  };

  return (
    <>
      <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
        <Await resolve={secretPromise.secret} errorElement={<RootErrorBoundary />}>
          {(secret: Secret) => {
            const secretFields: InputField[] = [
              {
                name: 'name',
                label: 'Key',
                required: false,
                defaultValue: secret.name,
                helperText: 'The key cannot be updated',
              },
              {
                name: 'value',
                label: 'Value',
                placeholder: '**********',
                defaultValue: secret.value,
                required: true,
              },
            ];

            return (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <BreadcrumbsBasic prevPaths={prevPaths} currPathText={secret.name}></BreadcrumbsBasic>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" mt={4} mb={5} color="text.primary">
                    {secret.name}
                  </Typography>
                  <Grid item xs={12}>
                    <SecretUpsertForm method="update" secretFields={secretFields} />
                  </Grid>
                  <Grid item xs={12} marginTop={4}>
                    <Paper>
                      <Grid container direction="column" padding={2}>
                        <Grid item>
                          <Typography variant="h5" color="text.primary">
                            Delete Secret
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" color="text.primary">
                            If you delete this secret, all features using it will stop working.
                          </Typography>
                        </Grid>
                        <Grid item paddingTop={2}>
                          <LoadingButton
                            type="button"
                            variant="contained"
                            color="error"
                            loading={loading}
                            onClick={() => setShowDeleteSecretDialog(true)}
                          >
                            Delete
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                {showDeleteSecretDialog && (
                  <ConfirmationDialog
                    handleAction={handleDelete}
                    open={showDeleteSecretDialog}
                    buttonTitle="Delete"
                    handleClose={handleCloseDeleteDialog}
                  />
                )}
              </Grid>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}
