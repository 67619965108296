import { capitalize } from '@mui/material';
import zapehr, { BatchInputRequest } from '@zapehr/sdk';
import * as React from 'react';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useZapehr } from '../contexts/ZapehrProvider';
import { formatHumanName, isCapitalized } from '../helpers';
import {
  Bundle,
  BundleEntry,
  BundleLink,
  HumanName,
  Resource,
  ValueSet,
  ValueSetExpansionContains,
} from '../lib/fhir-types';
import { getProperties, getSchema, TypesSchema } from '../lib/schema';
import { DEFAULT_SEARCH_COUNT } from '../lib/searchUtils';
import { AutocompleteOption } from '../lib/types';
import { Services } from '../services';

const RESOURCES_WITH_HUMAN_NAME_AS_NAME = ['Patient', 'Person', 'Practitioner', 'RelatedPerson'];

interface UseValueSetObject {
  isLoadingValueSet: boolean;
  options: AutocompleteOption[];
  getAsyncOptions: Promise<AutocompleteOption[]> | undefined;
  getValueMatchingCode: (code: string) => ValueSetExpansionContains | undefined;
}

interface ReferenceLink {
  display: string;
  destination: string;
}
interface UseReferenceOptionsObject {
  isLoading: boolean;
  references: Resource[];
  options: AutocompleteOption[];
  asyncReferences: Promise<Resource[]> | undefined;
  asyncOptions: Promise<AutocompleteOption[]> | undefined;
}

const getValueSetMatchingId = (setId: string, valueSets: ValueSet[]): ValueSet | undefined => {
  return valueSets.find((vs) => vs.id === setId.split('|')[0]);
};

const mapValueSetExpansionToAutocompleteOptions = (setId: string, valueSets: ValueSet[]): AutocompleteOption[] => {
  const matchingSet = getValueSetMatchingId(setId, valueSets);
  if (matchingSet) {
    const contains = matchingSet.expansion?.contains ?? [];
    const options = contains.map((element) => {
      let label: string | undefined = element.display ? `${element.display} - ${element.code}` : undefined;
      if (element.system?.endsWith('CodeSystem/v3-NullFlavor') && label === undefined) {
        label = `Unknown ${element.code ? '- '.concat(element.code) : ''}`;
      }
      return {
        label: label ?? element.code ?? undefined,
        value: element.code ?? undefined,
      };
    });
    return options.filter((entry) => {
      const { label, value } = entry;
      return label !== undefined && value !== undefined;
    }) as AutocompleteOption[];
  }
  return [];
};

const mapResourceListToAutocompleteOption = (resources: Resource[]): AutocompleteOption[] => {
  return resources.map((resource) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let label = resource.id!;
    let subtext = undefined;
    const name = (resource as any).name;
    if (name && Array.isArray(name) && RESOURCES_WITH_HUMAN_NAME_AS_NAME.includes(resource.resourceType)) {
      const firstHumanName = (name as HumanName[])?.at(0);
      if (firstHumanName) {
        label = formatHumanName(firstHumanName);
      } else {
        label = '[No Name]';
      }
      subtext = `ID: ${resource.id}`;
    }
    if (name && typeof name === 'string') {
      label = (name as string | undefined) ?? '[No Name]';
      subtext = `ID: ${resource.id}`;
    }
    const value = `${resource.resourceType}/${resource.id}`;
    return {
      label,
      subtext,
      value,
    };
  });
};

const getAllReferenceTypesForResourceType = (resourceType: string, schema: TypesSchema): string[] => {
  const allRefTypes = new Set<string>();
  grabReferenceTypesFromPropertyType(resourceType, new Set<string>(), schema).forEach((refType) =>
    allRefTypes.add(refType)
  );
  return Array.from(allRefTypes.values());
};

const grabReferenceTypesFromPropertyType = (type: string, used: Set<string>, schema: TypesSchema): string[] => {
  if (used.has(type) || type === 'Reference' || isCapitalized(type) === false) {
    return [];
  }
  used.add(type);
  const targetTypes: string[] = [];
  getProperties(type, schema).forEach((property) => {
    if (property.targetTypes !== undefined) {
      targetTypes.push(...property.targetTypes);
    }
    targetTypes.push(...grabReferenceTypesFromPropertyType(property.type, used, schema));
    property.choiceTypes?.forEach((choiceType) => {
      targetTypes.push(...grabReferenceTypesFromPropertyType(choiceType.name, used, schema));
    });
  });
  return targetTypes;
};

const getAllValueSetsForResourceType = (resourceType: string, schema: TypesSchema): string[] => {
  const allValueSets = new Set<string>();
  grabCodeTypesFromPropertyType(resourceType, new Set<string>(), schema).forEach((codeType) => {
    allValueSets.add(codeType);
  });
  return Array.from(allValueSets.values());
};

const grabCodeTypesFromPropertyType = (type: string, used: Set<string>, schema: TypesSchema): string[] => {
  if (used.has(type) || type === 'Reference' || type.charAt(0) !== capitalize(type.charAt(0))) {
    return [];
  }
  used.add(type);
  const allValueSets: string[] = [];
  getProperties(type, schema).forEach((property) => {
    if (property.valueSet !== undefined) {
      const systemMinusVersion = property.valueSet?.split('|')[0];
      allValueSets.push(`/ValueSet/$expand?url=${systemMinusVersion}`);
    }
    allValueSets.push(...grabCodeTypesFromPropertyType(property.type, used, schema));
    property.choiceTypes?.forEach((choiceType) => {
      allValueSets.push(...grabCodeTypesFromPropertyType(choiceType.name, used, schema));
    });
  });
  return allValueSets;
};

const mapURLToGetRequest = (url: string): BatchInputRequest => {
  return {
    method: 'GET',
    url,
  };
};

export const getBatchRequestForResourceType = (resourceType: string, schema: TypesSchema): BatchInputRequest[] => {
  const referenceRequests = getAllReferenceTypesForResourceType(resourceType, schema)
    .map((t) => `/${t}?_count=${DEFAULT_SEARCH_COUNT}&_elements=id,name`)
    .map((url) => mapURLToGetRequest(url));

  console.log('reference requests', referenceRequests);
  const valueSetRequests = getAllValueSetsForResourceType(resourceType, schema).map((url) => mapURLToGetRequest(url));

  console.log('value set requests', valueSetRequests);

  return [...referenceRequests, ...valueSetRequests];
};

export const getSearchResultsFromBatchResponse = (batchResponse: Bundle): Record<string, Resource[]> => {
  let entries = (batchResponse.entry ?? []) as BundleEntry[];

  if (batchResponse.type === 'batch-response') {
    entries = entries.filter((entry) => entry.response && entry.response.outcome?.id === 'ok');
  }

  const searchResults: Record<string, Resource[]> = {};

  entries.forEach((entry) => {
    const { resource } = entry;
    if (resource && resource.resourceType === 'Bundle' && resource.type === 'searchset') {
      const { link, entry } = resource;
      if (link && entry) {
        const selfLinkUrl = (link as BundleLink[]).find((l) => l.relation === 'self')?.url;
        if (selfLinkUrl) {
          const [key] = selfLinkUrl.split('/').pop()?.split('?') ?? [];
          if (key) {
            searchResults[key] = entry.map((e) => e.resource).filter((r) => !!r) as Resource[];
          }
        }
      }
    }
  });
  return searchResults;
};

export const getValueSetsFromBatchResponse = (batchResponse: Bundle): ValueSet[] => {
  let entries = (batchResponse.entry ?? []) as BundleEntry[];

  if (batchResponse.type === 'batch-response') {
    entries = entries.filter((entry) => entry.response && entry.response.outcome?.id === 'ok');
  }

  return entries.flatMap((entry) => {
    const { resource } = entry;
    if (resource && resource.resourceType === 'ValueSet') {
      return resource as ValueSet;
    }
    return [];
  });
};

export interface ResourceEditContextProps {
  editModeOn: boolean;
  formValue: Resource;
  getInitialValueForPath: (path: string) => any | undefined;
  setFormValue: (newVal: Resource) => void;
  toggleEditModeOn: () => void;
  useReferenceOptions: (referenceType: string) => UseReferenceOptionsObject;
  useValueSetOptions: (setId: string) => UseValueSetObject;
  useReferenceLink: (path: string) => ReferenceLink | undefined;
  getFilteredReferenceOptions: (
    resourceType: string,
    searchParam: string,
    searchValue: string
  ) => Promise<AutocompleteOption[]>;
}

const ResourceEditContext = createContext<ResourceEditContextProps>({
  editModeOn: false,
  formValue: {} as Resource,
  getInitialValueForPath: (_path: string) => undefined,
  setFormValue: () => {
    throw new Error('Not implemented');
  },
  toggleEditModeOn: () => {
    throw new Error('Not implemented');
  },
  useValueSetOptions: function (_setId: string): UseValueSetObject {
    throw new Error('Function not implemented.');
  },
  useReferenceOptions: function (_referenceType: string): UseReferenceOptionsObject {
    throw new Error('Function not implemented.');
  },
  useReferenceLink: function (_path: string): ReferenceLink | undefined {
    throw new Error('Function not implemented.');
  },
  getFilteredReferenceOptions: function (
    _resourceType: string,
    _searchParam: string,
    _searchValue: string
  ): Promise<AutocompleteOption[]> {
    throw new Error('Function not implemented');
  },
});

interface ProviderProps {
  children: ReactNode;
  resourceType: string;
  initialEditMode: boolean;
  initialValue: Resource;
}

type ResourceOptionLoadingState = 'initial' | 'loading' | 'loaded' | 'error';

interface InputOptionData {
  valueSets: ValueSet[];
  referenceOptions: Record<string, Resource[]>;
}

export const EditContextProvider: FC<ProviderProps> = ({ children, initialEditMode, resourceType, initialValue }) => {
  const [editModeOn, setEditModeOn] = useState(initialEditMode);
  const [formValue, setFormValue] = useState(initialValue);
  const [batchRequest, setBatchRequest] = useState<Promise<InputOptionData> | undefined>();
  const [valueSets, setValueSets] = useState<ValueSet[]>([]);
  const [referenceOptions, setReferenceOptions] = useState<Record<string, Resource[]>>({});
  const [loadingState, setLoadingState] = useState<ResourceOptionLoadingState>('initial');
  const { currentProject } = useZapehr();
  const schema = getSchema(currentProject?.fhirVersion);

  useEffect(() => {
    setFormValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const fetchInputOptionData = async (): Promise<InputOptionData> => {
      setLoadingState('loading');
      const batchRequest = getBatchRequestForResourceType(resourceType, schema);
      const response = await zapehr.fhir.batch({ requests: batchRequest });
      if (response.resourceType === 'Bundle') {
        console.log('Bundle from batch request', response);
        const valueSets: ValueSet[] = getValueSetsFromBatchResponse(response);
        const references = getSearchResultsFromBatchResponse(response);
        setValueSets(valueSets);
        setReferenceOptions(references);
        setLoadingState('loaded');
        return { valueSets, referenceOptions: references };
      } else {
        console.log('batch request result', response);
        setLoadingState('error');
        return { valueSets: [], referenceOptions: {} };
      }
    };
    if (batchRequest === undefined) {
      setBatchRequest(fetchInputOptionData());
    }
  }, [resourceType, batchRequest, schema]);

  const getReferenceOptions = useCallback(
    (referenceType: string): Promise<Resource[]> | undefined => {
      if (batchRequest) {
        return batchRequest.then((data) => {
          const { referenceOptions } = data;
          return referenceOptions[referenceType] ?? [];
        });
      }
      return undefined;
    },
    [batchRequest]
  );

  const getFilteredReferenceOptions = useCallback(
    async (resourceType: string, searchParam: string, searchValue: string): Promise<AutocompleteOption[]> => {
      const response = await zapehr.fhir.search({
        resourceType: resourceType,
        params: [
          { name: searchParam, value: searchValue },
          { name: '_count', value: DEFAULT_SEARCH_COUNT },
        ],
      });
      if (!response || !response.entry) {
        return [];
      }
      const entries = response.entry;
      const resources = entries.map((entry) => entry.resource as Resource);
      const options = mapResourceListToAutocompleteOption(resources);
      return options;
    },
    []
  );

  const useReferenceOptions = useCallback(
    (referenceType: string): UseReferenceOptionsObject => {
      const references = referenceOptions[referenceType] ?? [];
      const options = mapResourceListToAutocompleteOption(references);
      return {
        isLoading: loadingState === 'loading',
        references,
        options,
        asyncOptions: getReferenceOptions(referenceType)?.then((refs) => mapResourceListToAutocompleteOption(refs)),
        asyncReferences: getReferenceOptions(referenceType),
      };
    },
    [getReferenceOptions, referenceOptions, loadingState]
  );

  const getAsyncValueSetOptions = useCallback(
    (setId: string): Promise<AutocompleteOption[]> | undefined => {
      if (batchRequest) {
        return batchRequest.then((data) => {
          const { valueSets } = data;
          return mapValueSetExpansionToAutocompleteOptions(setId, valueSets);
        });
      }
      return undefined;
    },
    [batchRequest]
  );

  const useValueSetOptions = useCallback(
    (setId: string) => {
      const getValueMatchingCode = (code: string): ValueSetExpansionContains | undefined => {
        const valueSet = getValueSetMatchingId(setId, valueSets);
        const contains = (valueSet?.expansion?.contains ?? []) as ValueSetExpansionContains[];
        const element = contains.find((element) => element.code === code);
        return element;
      };

      const valSetObj: UseValueSetObject = {
        isLoadingValueSet: loadingState === 'loading',
        options: mapValueSetExpansionToAutocompleteOptions(setId, valueSets),
        getValueMatchingCode,
        getAsyncOptions: getAsyncValueSetOptions(setId),
      };
      return valSetObj;
    },
    [getAsyncValueSetOptions, loadingState, valueSets]
  );

  const valueForPathElement = (pathElement: string, object: any): any | undefined => {
    const maybeIndex = parseInt(pathElement);
    if (maybeIndex) {
      if (Array.isArray(object)) {
        try {
          return (object as any[])[maybeIndex];
        } catch (e) {
          return undefined;
        }
      }
    }
    return object[`${pathElement}`];
  };

  const getInitialValueForPath = useCallback(
    (path: string): any | undefined => {
      // console.log('getting initial value for path', path);
      let extractedValue = { ...initialValue };

      const pathElements = path.split('.');

      pathElements.forEach((element) => {
        if (extractedValue !== undefined) {
          extractedValue = valueForPathElement(element, extractedValue);
        }
      });
      // console.log('getting initial value for path returning', JSON.stringify(extractedValue));
      return extractedValue;
    },
    [initialValue]
  );

  const useReferenceLink = useCallback(
    (propertyPath: string): ReferenceLink | undefined => {
      const initialVal = getInitialValueForPath(propertyPath);

      if (!initialVal || typeof initialVal !== 'object' || !initialVal.reference) {
        return undefined;
      }

      const { type, reference } = initialVal;
      const [maybeType, referenceId] = reference.split('/');
      const referenceType = maybeType ?? type;

      if (!referenceType || !referenceId) {
        return undefined;
      }

      const references = referenceOptions[referenceType] ?? [];
      const optionData = references.find((entry) => {
        return entry?.id === referenceId;
      });
      const destination = `/${Services.fhir.rootPath}/${referenceType}/${referenceId}`;
      let display = referenceId;

      if (optionData) {
        const nameString = (optionData as any).name as string | undefined;
        const name = (optionData as any).name as HumanName[] | undefined;
        if (!!nameString && typeof nameString === 'string') {
          display = nameString;
        } else if (name && name.length) {
          const [nameToUse] = name;
          display = formatHumanName(nameToUse);
        }
      }

      return { destination, display };
    },
    [getInitialValueForPath, referenceOptions]
  );

  const editContextProps: ResourceEditContextProps = {
    editModeOn,
    formValue,
    setFormValue,
    getInitialValueForPath,
    toggleEditModeOn: () => {
      setEditModeOn(!editModeOn);
    },
    useReferenceOptions,
    useValueSetOptions,
    useReferenceLink,
    getFilteredReferenceOptions,
  };

  return <ResourceEditContext.Provider value={editContextProps}>{children}</ResourceEditContext.Provider>;
};

export const useResourceEditingContext = (): ResourceEditContextProps => useContext(ResourceEditContext);
