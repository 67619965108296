import { capitalize } from '@mui/material';
import * as React from 'react';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { CustomTextField } from '../WrappedInputs/CustomTextField';
import { FhirFormAutocomplete } from '../WrappedInputs/FhirFormAutocomplete';
import { getValueSetName } from './common';

export interface CodeInputProps {
  name: string;
  valueSet?: string;
  value?: string;
  onChange: (value: string) => void;
}

export function CodeInput(props: CodeInputProps): JSX.Element {
  const { name, valueSet, value, onChange } = props;
  const { editModeOn, useValueSetOptions } = useResourceEditingContext();
  const label = capitalize(name);
  const { getAsyncOptions } = useValueSetOptions(getValueSetName(valueSet ?? ''));

  if (valueSet === undefined) {
    return (
      <CustomTextField
        sx={{ width: '100%' }}
        value={value || ''}
        label={label}
        placeholder={label}
        onChange={(event): void => {
          onChange(event?.target?.value);
        }}
        displayOnly={!editModeOn}
      />
    );
  }

  return (
    <FhirFormAutocomplete
      optionsAsync={
        getAsyncOptions &&
        (() => {
          return getAsyncOptions;
        })
      }
      sx={{ width: '100%' }}
      label={label}
      onChange={(option) => onChange(option?.value ?? '')}
      value={value}
      disabled={!editModeOn}
    />
  );
}
