/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { isValidJSON, JSON_INVALID_ERROR_MESSAGE } from '../../../../components/AccessPolicyInput';
import { toast } from '../../../../lib/toast';
import { prettyJSON } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { RoleFormBody } from '../lib/RoleFormBody';

const roleDefaults = {
  defaultValues: {
    name: '',
    description: '',
    accessPolicy: prettyJSON({
      rule: [],
    }),
  },
};

export function CreateRolePage(): JSX.Element {
  const formReturn = useForm(roleDefaults);
  const { handleSubmit } = formReturn;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: any): void => {
      setLoading(true);
      let shouldNav = false;

      data.accessPolicy = isValidJSON(data.accessPolicy);

      if (!data.accessPolicy) {
        toast.error(`${JSON_INVALID_ERROR_MESSAGE} for access policy`);
        setLoading(false);
        return;
      }

      zapehr.project.role
        .create(data)
        .then(() => {
          toast.success('Role created');
          revalidator.revalidate();
          shouldNav = true;
        })
        .catch((error) => {
          toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
        })
        .finally(() => {
          setLoading(false);
          if (shouldNav) {
            navigate(`/${Services.iam.rootPath}/roles`);
          }
        });
    },
    [navigate, revalidator]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Create a new role
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RoleFormBody
              formUtils={formReturn}
              renderErrors={(errors) => {
                // todo: typing of errors looks confused
                return (
                  errors.root?.description && (
                    <Grid item>
                      <Typography variant="body1" color="error" role="alert">
                        {errors.root?.description?.message?.toString()}
                      </Typography>
                    </Grid>
                  )
                );
              }}
              renderUpdateButton={() => (
                <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                  Create
                </LoadingButton>
              )}
            />
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
