import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab, Typography } from '@mui/material';
import * as React from 'react';
import { Suspense } from 'react';
import { Await, Link, useLoaderData, useParams } from 'react-router-dom';
import { Document, ResourceDiff, TitleBar } from '../../../../components/index';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { Bundle, BundleEntry, Resource } from '../../../../lib/fhir-types';
import { Services } from '../../../../services';

export function ResourceVersionPage(): JSX.Element {
  const { history: dataPromise } = useLoaderData() as { history: Promise<Bundle> };
  return (
    <Suspense fallback={<PageContent historyBundle={undefined} loading />}>
      <Await resolve={dataPromise} errorElement={<RootErrorBoundary />}>
        {(historyBundle) => {
          return <PageContent historyBundle={historyBundle} loading={false} />;
        }}
      </Await>
    </Suspense>
  );
}

interface PageContentProps {
  loading: boolean;
  historyBundle: Bundle | undefined;
}

const PageContent: React.FC<PageContentProps> = ({ loading, historyBundle }) => {
  const { resourceType, id, versionId, tab } = useParams() as {
    resourceType: string;
    id: string;
    versionId: string;
    tab: string;
  };
  const [currTab, setCurrTab] = React.useState(tab || 'diff');

  if (!historyBundle && !loading) {
    return (
      <Document>
        <Typography variant="h5">Resource not found</Typography>
        <Typography variant="h6">
          <Link to={`${Services.fhir.rootPath}/${resourceType}`}>Return to search page</Link>
        </Typography>
      </Document>
    );
  }

  const entries = (historyBundle?.entry ?? []) as BundleEntry[];
  const index = entries.findIndex((entry) => entry.resource?.meta?.versionId === versionId);
  if (index === -1 && !loading) {
    return (
      <Document>
        <Typography variant="h5">Version not found</Typography>
        <Typography variant="h6">
          <Link to={`/${Services.fhir.rootPath}/${resourceType}/${id}`}>Return to resource</Link>
        </Typography>
      </Document>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const value = entries[index].resource as Resource;
  const prev = index < entries.length - 1 ? entries[index + 1].resource : undefined;
  return (
    <>
      <TitleBar>
        <Typography variant="body1" fontWeight={'bold'}>{`${resourceType} ${id}`}</Typography>
      </TitleBar>
      <TabContext value={currTab}>
        <TabList
          onChange={(e) => {
            const eventTarget = e.target as HTMLElement;
            setCurrTab(eventTarget.innerText.toLowerCase());
          }}
        >
          <Tab label="Diff" value="diff" sx={{ textTransform: 'none' }} />
          <Tab label="Raw" value="raw" sx={{ textTransform: 'none' }} />
        </TabList>
        <Document>
          <TabPanel value="diff">
            {prev ? (
              <>
                <ul>
                  <li>Current: {value.meta?.versionId}</li>
                  <li>
                    Previous:{' '}
                    <Link to={`/${Services.fhir.rootPath}/${resourceType}/${id}/history/${prev.meta?.versionId}`}>
                      {prev.meta?.versionId}
                    </Link>
                  </li>
                </ul>
                <ResourceDiff original={prev} revised={value} />
              </>
            ) : (
              <>
                <ul>
                  <li>Current: {value.meta?.versionId}</li>
                  <li>Previous: (none)</li>
                </ul>
                <pre>{JSON.stringify(value, undefined, 2)}</pre>
              </>
            )}
          </TabPanel>
          <TabPanel value="raw">
            <pre>{JSON.stringify(value, undefined, 2)}</pre>
          </TabPanel>
        </Document>
      </TabContext>
    </>
  );
};
