import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { PageContainer } from '../../../components/PageContainer';
import { profileSidebarItems } from '..';

export function UpdateProfilePage(): JSX.Element {
  return (
    <PageContainer sidebarItems={profileSidebarItems}>
      <div>
        <Grid container>
          <Typography variant="h4">Update Profile</Typography>
        </Grid>
      </div>
    </PageContainer>
  );
}
