import { Add, Calculate, Key } from '@mui/icons-material';
import { alpha, Box, Button, Grid, Link as MUILink, Paper, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { FC, Suspense, useState } from 'react';
import { Await, Link, Outlet, useLoaderData } from 'react-router-dom';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { ArticleShortcutIconButton } from '../../components/ArticleShortcutIconButton';
import { PageContainer } from '../../components/PageContainer';
import { RootErrorBoundary } from '../../components/RootErrorBoundary';
import { otherColors } from '../../contexts/AdminThemeProvider';
import { Zambda } from '../../lib/client';
import { ZambdaState } from '../../lib/types';
import { defer } from '../../lib/utils';
import ZambdaStatusChip from './Functions/lib/ZambdaStatusChip';

const zambdaSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Functions',
      icon: <Calculate />,
      path: '/zambdas',
    },
    {
      label: 'Secrets',
      icon: <Key />,
      path: '/zambdas/secrets',
    },
  ],
];

export function ZambdaServiceLayout(): JSX.Element {
  return (
    <PageContainer sidebarItems={zambdaSidebarItems}>
      <Outlet />
    </PageContainer>
  );
}

export function zambdasPageLoader(): ReturnType<typeof defer> {
  return defer<ZambdasPageLoaderData>({
    zambdas: zapehr.project.zambda.list(),
  });
}
export type ZambdasPageLoaderData = {
  zambdas: ReturnType<typeof zapehr.project.zambda.list>;
};

export function ZambdasPage(): JSX.Element {
  const data = useLoaderData() as ZambdasPageLoaderData;

  return (
    <Grid container>
      <Grid item xs={10} display="flex" marginBottom={2}>
        <Typography variant="h4">Zambdas</Typography>
        <ArticleShortcutIconButton link="https://docs.oystehr.com/services/zambda/" />
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <Link to="new">
          <Button aria-label="new" variant="contained" startIcon={<Add />}>
            New Zambda
          </Button>
        </Link>
      </Grid>
      <Typography>
        Zambdas are functions that can be used to execute your code. They can be used to process data received from{' '}
        <MUILink href={'https://api-reference.oystehr.com/'} target="_blank">
          Oystehr's APIs
        </MUILink>{' '}
        or to perform operations on third-party services.
      </Typography>

      <Suspense fallback={<ZambdaList loading zambdas={[]} />}>
        <Await resolve={data.zambdas} errorElement={<RootErrorBoundary />}>
          {(zambdas: Awaited<ZambdasPageLoaderData['zambdas']>) => {
            return <ZambdaList zambdas={zambdas} loading={false} />;
          }}
        </Await>
      </Suspense>
    </Grid>
  );
}

interface ZambdaListProps {
  zambdas: readonly Zambda[];
  loading: boolean;
}

const ZambdaList: FC<ZambdaListProps> = ({ zambdas, loading }) => {
  const [pageSize, setPageSize] = useState(10);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Zambda name',
      flex: 2,
      renderCell: (params) => <Link to={`/zambdas/${params.row.id}`}>{params.value}</Link>,
    },
    { field: 'triggerMethod', headerName: 'Trigger method', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const status = params.value as ZambdaState;
        return <ZambdaStatusChip status={status} />;
      },
    },
    {
      field: 'logs',
      headerName: 'Logs',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={`/zambdas/${params.row.id}/logs`}>
            <Typography
              color="primary.main"
              sx={{ fontSize: 14, fontWeight: 600, letterSpacing: '0.4px', textTransform: 'uppercase' }}
            >
              See Logs
            </Typography>
          </Link>
        );
      },
    },
  ];

  return (
    <Grid item xs={12}>
      <Paper sx={{ marginTop: 4 }}>
        <Box sx={{ height: '100%', width: '100%' }}>
          <DataGridPro
            columns={columns}
            rows={zambdas}
            loading={loading}
            headerHeight={56}
            autoHeight
            getRowId={(row) => row.id}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[10, 25, 100]}
            sx={{
              '& .MuiDataGrid-toolbarContainer': {
                pl: 1.5,
                gap: 4,
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                '& .MuiButtonBase-root': {
                  color: otherColors.charcoal87,
                },
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
            }}
          />
        </Box>
      </Paper>
    </Grid>
  );
};
