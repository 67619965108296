import { Box } from '@mui/system';
import * as React from 'react';

export interface ResourceFormFieldErrorsProps {
  errors: string[];
}

export const ResourceFormFieldErrors = ({ errors }: ResourceFormFieldErrorsProps): JSX.Element => {
  return (
    <ul>
      {errors?.map((error: any) => (
        <li key={error}> {error && <Box sx={{ marginTop: '10px', color: 'red' }}> {error}</Box>} </li>
      ))}
    </ul>
  );
};
