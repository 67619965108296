import * as React from 'react';
import { FC, useEffect } from 'react';
import { useZapehr } from '../../components';
import { FullScreenLoadingIndicator } from '../../components/FullScreenLoadingIndicator';

export const Login: FC<any> = () => {
  const { userIsAuthenticated, signIn, initializationError, authIsLoading } = useZapehr();

  if (initializationError) {
    console.log('init error', initializationError);
    throw initializationError;
  }

  useEffect(() => {
    if (!userIsAuthenticated && !authIsLoading) {
      void signIn();
    }
  }, [userIsAuthenticated, signIn, authIsLoading]);

  return <FullScreenLoadingIndicator />;
};
