import { LoadingButton } from '@mui/lab';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { Form, Link, useNavigate } from 'react-router-dom';
import { FhirVersion, useZapehr } from '../../../components';
import { toast } from '../../../lib/toast';

export interface NewProjectFormProps {
  isFirstProject?: boolean;
  submitCallback?: (name: string, version: FhirVersion) => Promise<void>;
}

interface NewProjectFields {
  name: string;
  fhirVersion: FhirVersion;
}

export function NewProjectPage(props: NewProjectFormProps): JSX.Element {
  const { isFirstProject, submitCallback } = props;

  const { createProject, switchProject } = useZapehr();
  const navigate = useNavigate();

  const formReturn: UseFormReturn<NewProjectFields> = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = formReturn;

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: { name: string; fhirVersion: FhirVersion }): Promise<void> => {
      setLoading(true);
      if (submitCallback) {
        // project creation for new users is done during registerNewUser call in the ZapehrProvider
        // so in this case submitCallback will just pass needed data there
        await submitCallback(data.name, data.fhirVersion);
      } else {
        createProject(data.name, data.fhirVersion)
          .then((resp): void => {
            toast.success('Project was created successfully.');
            switchProject(resp);
            navigate('/');
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.message || 'Project creation failed');
          })
          .finally(() => setLoading(false));
      }
    },
    [createProject, navigate, submitCallback, switchProject]
  );

  return (
    <Grid id="grid1" container sx={{ height: 'calc(100vh - 64px)', width: '100%' }}>
      <Grid
        id="grid2"
        container
        item
        xs={12}
        alignContent={'center'}
        alignItems={'center'}
        width={'100%'}
        sx={{ background: '#F3F4F6' }}
      >
        <Box
          sx={{
            boxShadow: '0px 8px 38px 7px #0000001F',
            width: '400px',
            margin: 'auto',
            background: '#FFFFFF',
            borderRadius: '8px',
            padding: '40px',
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: '24px' }}>
            Let's set up your {isFirstProject ? 'first' : 'new'} project
          </Typography>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <TextField
                {...register('name', {
                  required: `Project name is a required field`,
                  pattern: {
                    value: /^[a-zA-Z0-9]([-\s][a-zA-Z0-9]|[a-zA-Z0-9])*$/,
                    message:
                      "Project name must start and end with an alphanumeric character and can only contain alphanumeric characters, spaces and '-'.",
                  },
                })}
                label="Project name"
                fullWidth
                error={!!errors['name']}
                helperText={errors['name']?.message as string}
                sx={{ marginBottom: '24px' }}
              />
              <Typography variant="h6">Select FHIR version</Typography>
              <Typography variant="body1" sx={{ marginBottom: '24px' }}>
                Your project's FHIR version will determine what version of the FHIR specification that your data is
                stored in. Read more about FHIR versions{' '}
                <Link to="https://hl7.org/fhir/directory.html" target="_blank" style={{ cursor: 'pointer' }}>
                  here
                </Link>
                . Note: some Oystehr services are not compatible with R5. You can read more about Oystehr's FHIR support
                <Link
                  to="https://docs.oystehr.com/services/fhir/#fhir-versions"
                  target="_blank"
                  style={{ cursor: 'pointer' }}
                >
                  here
                </Link>
              </Typography>
              <Controller
                name="fhirVersion"
                control={control}
                defaultValue={FhirVersion.r4}
                render={({ field }) => (
                  <RadioGroup {...field} defaultValue={FhirVersion.r4} sx={{ marginBottom: '24px' }}>
                    <FormControlLabel value={FhirVersion.r4} control={<Radio />} label="R4B (Recommended)" />
                    <FormControlLabel value={FhirVersion.r5} control={<Radio />} label="R5 (in beta)" />
                  </RadioGroup>
                )}
              />
              <LoadingButton type="submit" variant="contained" fullWidth loading={loading}>
                {isFirstProject ? "Let's start" : 'Create'}
              </LoadingButton>
            </FormControl>
          </Form>
        </Box>
      </Grid>
    </Grid>
  );
}
