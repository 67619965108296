import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import {
  Box,
  Divider,
  Grid,
  Link as MUILink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { resourcesAutocomplete, useZapehr } from '../../components';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { ArticleShortcutIconButton } from '../../components/ArticleShortcutIconButton';
import { PageContainer } from '../../components/PageContainer';
import { SearchBarAutocomplete } from '../../components/WrappedInputs/SearchBarAutocomplete';
import { otherColors } from '../../contexts/AdminThemeProvider';
import { Services } from '../../services';

const resources = {
  Foundation: {
    Conformance: [
      { name: 'CapabilityStatement', version: 'N' },
      { name: 'StructureDefinition', version: 'N' },
      { name: 'ImplementationGuide', version: '1' },
      { name: 'SearchParameter', version: '3' },
      { name: 'MessageDefinition', version: '1' },
      { name: 'OperationDefinition', version: 'N' },
      { name: 'CompartmentDefinition', version: '1' },
      { name: 'StructureMap', version: '2' },
      { name: 'GraphDefinition', version: '1' },
      { name: 'ExampleScenario', version: '0' },
    ],
    Terminology: [
      { name: 'CodeSystem', version: 'N' },
      { name: 'ValueSet', version: 'N' },
      { name: 'ConceptMap', version: '3' },
      { name: 'NamingSystem', version: '2' },
      { name: 'TerminologyCapabilities', version: '0' },
    ],
    Security: [
      { name: 'Provenance', version: '3' },
      { name: 'AuditEvent', version: '3' },
      { name: 'Consent', version: '2' },
    ],
    Documents: [
      { name: 'Composition', version: '2' },
      { name: 'DocumentManifest', version: '2' },
      { name: 'DocumentReference', version: '3' },
      { name: 'CatalogEntry', version: '0' },
    ],
    Other: [
      { name: 'Basic', version: '1' },
      { name: 'Binary', version: 'N' },
      { name: 'Bundle', version: 'N' },
      { name: 'Linkage', version: '0' },
      { name: 'MessageHeader', version: '4' },
      { name: 'Subscription', version: '3' },
      { name: 'SubscriptionStatus', version: '0' },
      { name: 'SubscriptionTopic', version: '0' },
    ],
  },
  Base: {
    Individuals: [
      { name: 'Patient', version: 'N' },
      { name: 'Practitioner', version: '3' },
      { name: 'PractitionerRole', version: '2' },
      { name: 'RelatedPerson', version: '2' },
      { name: 'Person', version: '2' },
      { name: 'Group', version: '1' },
    ],
    'Entities #1': [
      { name: 'Organization', version: '3' },
      { name: 'OrganizationAffiliation', version: '0' },
      { name: 'HealthcareService', version: '2' },
      { name: 'Endpoint', version: '2' },
      { name: 'Location', version: '3' },
    ],
    'Entities #2': [
      { name: 'Substance', version: '2' },
      { name: 'BiologicallyDerivedProduct', version: '0' },
      { name: 'Device', version: '2' },
      { name: 'DeviceMetric', version: '1' },
      { name: 'NutritionProduct', version: '0' },
    ],
    Workflow: [
      { name: 'Task', version: '2' },
      { name: 'Appointment', version: '3' },
      { name: 'AppointmentResponse', version: '3' },
      { name: 'Schedule', version: '3' },
      { name: 'Slot', version: '3' },
      { name: 'VerificationResult', version: '0' },
    ],
    Management: [
      { name: 'Encounter', version: '2' },
      { name: 'EpisodeOfCare', version: '2' },
      { name: 'Flag', version: '1' },
      { name: 'List', version: '1' },
      { name: 'Library', version: '3' },
    ],
  },
  Clinical: {
    Summary: [
      { name: 'AllergyIntolerance', version: '3' },
      { name: 'AdverseEvent', version: '0' },
      { name: 'Condition', version: '3' },
      { name: 'Procedure', version: '3' },
      { name: 'FamilyMemberHistory', version: '2' },
      { name: 'ClinicalImpression', version: '0' },
      { name: 'DetectedIssue', version: '1' },
    ],
    Diagnostics: [
      { name: 'Observation', version: 'N' },
      { name: 'Media', version: '1' },
      { name: 'DiagnosticReport', version: '3' },
      { name: 'Specimen', version: '2' },
      { name: 'BodyStructure', version: '1' },
      { name: 'ImagingStudy', version: '3' },
      { name: 'QuestionnaireResponse', version: '3' },
      { name: 'MolecularSequence', version: '1' },
    ],
    Medications: [
      { name: 'MedicationRequest', version: '3' },
      { name: 'MedicationAdministration', version: '2' },
      { name: 'MedicationDispense', version: '2' },
      { name: 'MedicationStatement', version: '3' },
      { name: 'Medication', version: '3' },
      { name: 'MedicationKnowledge', version: '0' },
      { name: 'Immunization', version: '3' },
      { name: 'ImmunizationEvaluation', version: '0' },
      { name: 'ImmunizationRecommendation', version: '1' },
    ],
    'Care Provision': [
      { name: 'CarePlan', version: '2' },
      { name: 'CareTeam', version: '2' },
      { name: 'Goal', version: '2' },
      { name: 'ServiceRequest', version: '2' },
      { name: 'NutritionOrder', version: '2' },
      { name: 'VisionPrescription', version: '2' },
      { name: 'RiskAssessment', version: '1' },
      { name: 'RequestGroup', version: '2' },
    ],
    'Request & Response': [
      { name: 'Communication', version: '2' },
      { name: 'CommunicationRequest', version: '2' },
      { name: 'DeviceRequest', version: '1' },
      { name: 'DeviceUseStatement', version: '0' },
      { name: 'GuidanceResponse', version: '2' },
      { name: 'SupplyRequest', version: '1' },
      { name: 'SupplyDelivery', version: '1' },
    ],
  },
  Financial: {
    Support: [
      { name: 'Coverage', version: '2' },
      { name: 'CoverageEligibilityRequest', version: '2' },
      { name: 'CoverageEligibilityResponse', version: '2' },
      { name: 'EnrollmentRequest', version: '0' },
      { name: 'EnrollmentResponse', version: '0' },
    ],
    Billing: [
      { name: 'Claim', version: '2' },
      { name: 'ClaimResponse', version: '2' },
      { name: 'Invoice', version: '0' },
    ],
    Payment: [
      { name: 'PaymentNotice', version: '2' },
      { name: 'PaymentReconciliation', version: '2' },
    ],
    General: [
      { name: 'Account', version: '2' },
      { name: 'ChargeItem', version: '0' },
      { name: 'ChargeItemDefinition', version: '0' },
      { name: 'Contract', version: '1' },
      { name: 'ExplanationOfBenefit', version: '2' },
      { name: 'InsurancePlan', version: '0' },
    ],
  },
  Specialized: {
    'Public Health & Research': [
      { name: 'ResearchStudy', version: '1' },
      { name: 'ResearchSubject', version: '0' },
    ],
    'Definitional Artifacts': [
      { name: 'ActivityDefinition', version: '3' },
      { name: 'DeviceDefinition', version: '0' },
      { name: 'EventDefinition', version: '0' },
      { name: 'ObservationDefinition', version: '0' },
      { name: 'PlanDefinition', version: '3' },
      { name: 'Questionnaire', version: '3' },
      { name: 'SpecimenDefinition', version: '0' },
    ],
    'Evidence - Based Medicine': [
      { name: 'Citation', version: '0' },
      { name: 'Evidence', version: '1' },
      { name: 'EvidenceReport', version: '0' },
      { name: 'EvidenceVariable', version: '1' },
    ],
    'Quality Reporting & Testing': [
      { name: 'Measure', version: '3' },
      { name: 'MeasureReport', version: '3' },
      { name: 'TestScript', version: '2' },
      { name: 'TestReport', version: '0' },
    ],
    'Medication Definition': [
      { name: 'MedicinalProductDefinition', version: '1' },
      { name: 'PackagedProductDefinition', version: '1' },
      { name: 'AdministrableProductDefinition', version: '1' },
      { name: 'ManufacturedItemDefinition', version: '1' },
      { name: 'Ingredient', version: '1' },
      { name: 'ClinicalUseDefinition', version: '1' },
      { name: 'RegulatedAuthorization', version: '1' },
      { name: 'SubstanceDefinition', version: '1' },
    ],
  },
};

const resourceSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'All Resources',
      icon: <WidgetsOutlinedIcon />,
      path: `/${Services.fhir.rootPath}`,
    },
  ],
  [
    {
      label: 'Patient',
      icon: <PeopleOutlineOutlinedIcon />,
      path: `/${Services.fhir.rootPath}/Patient`,
    },
    {
      label: 'Practitioner',
      icon: <BiotechOutlinedIcon />,
      path: `/${Services.fhir.rootPath}/Practitioner`,
    },
    {
      label: 'Medication',
      icon: <MedicalServicesOutlinedIcon />,
      path: `/${Services.fhir.rootPath}/Medication`,
    },
    {
      label: 'Schedule',
      icon: <EventOutlinedIcon />,
      path: `/${Services.fhir.rootPath}/Schedule`,
    },
    {
      label: 'Slot',
      icon: <CalendarMonthOutlinedIcon />,
      path: `/${Services.fhir.rootPath}/Slot`,
    },
  ],
];

const resourceColors = [
  otherColors.resource1,
  otherColors.resource2,
  otherColors.resource3,
  otherColors.resource4,
  otherColors.resource5,
];

function getGroupWithMostItems(resources: any): number {
  let largest = 0;
  Object.keys(resources).forEach((group: any) => {
    largest = Math.max(Object.keys(resources[group]).length, largest);
  });

  return largest;
}

function getCategoryWithMostItems(group: any): number {
  let largest = 0;
  Object.keys(group).forEach((category: any) => {
    largest = Math.max(group[category].length, largest);
  });

  return largest;
}

// How this works:
// We want to generate a table which is row-based. To do this we need to get the first element
// of each category, then the second element of each category, and so on. The code below loops
// through each category for as many rows are needed, which we determine
// with `getCategoryWithMostItems`. We loop `categoryWithMostItems` times, and each time
// loop with variable `i` through each category of the group, getting the element at
// index `i` each time.
function getTableRows(group: any): any[] {
  const tableRows: any[] = [];
  const categoryWithMostItems = getCategoryWithMostItems(group);

  for (let i = 0; i < categoryWithMostItems; i++) {
    tableRows.push(
      <TableRow key={i}>
        {Object.keys(group).map((category: any) => {
          const item = group[category][i];
          return (
            <TableCell
              key={`${category}.${item?.name ?? `blank-cell #${i}`}`}
              sx={{
                border: 'none',
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 3.2,
                paddingRight: 0,
                wordWrap: 'break-word',
              }}
            >
              {item ? (
                <>
                  <Link to={`/${Services.fhir.rootPath}/${item.name}`} style={{ color: 'black' }}>
                    {item.name}
                  </Link>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      display: 'inline',
                      marginLeft: 1,
                      ...(item.version === 'N' && {
                        backgroundColor: otherColors.cornflowerBlue12,
                        borderRadius: 1,
                        paddingLeft: 0.5,
                        paddingRight: 0.5,
                        paddingTop: 0.2,
                        paddingBottom: 0.2,
                      }),
                    }}
                    title="FHIR Version"
                    aria-label={`FHIR Version: ${item.version}`}
                  >
                    {item.version}
                  </Typography>
                </>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  return tableRows;
}

export function ResourcesPage(): JSX.Element {
  const { pathname } = useLocation();

  return (
    <PageContainer sidebarItems={resourceSidebarItems}>
      {pathname === `/${Services.fhir.rootPath}` ? <HomeElement /> : <Outlet />}
    </PageContainer>
  );
}

const HomeElement: FC = () => {
  const { currentProject } = useZapehr();
  const groupWithMostItems = getGroupWithMostItems(resources);

  // add empty categories to groups with less than groupWithMostItems items
  Object.keys(resources).map((group: any) => {
    const numCategories = Object.keys((resources as any)[group]).length;
    const numCategoriesToAddToGroup = groupWithMostItems - numCategories;
    for (let i = 0; i < numCategoriesToAddToGroup; i++) {
      (resources as any)[group][`Empty Category #${i}`] = [];
    }
  });

  return (
    <Box>
      <Box>
        <Box display="flex" marginBottom={2}>
          <Typography variant="h4">FHIR Resources</Typography>
          <ArticleShortcutIconButton link="https://docs.oystehr.com/services/fhir/" />
        </Box>

        <Typography variant="body1">
          FHIR is the standard for health care data storage and exchange on which Oystehr is built.
          <br /> For more information about FHIR resources, refer to the{' '}
          <MUILink target="_blank" href="http://hl7.org/fhir/R4B/resourcelist.html">
            HL7 FHIR Resource List
          </MUILink>
          .
        </Typography>
        <Box sx={{ width: '50%', marginTop: '10px' }}>
          <SearchBarAutocomplete
            optionsList={resourcesAutocomplete(currentProject?.fhirVersion)}
            disabled={false}
            label={'Search resource types'}
            selectToRedirect={true}
          />
        </Box>
      </Box>

      {Object.keys(resources).map((group: string) => {
        return (
          <Fragment key={group}>
            <Typography variant="h5" sx={{ marginTop: 4 }}>
              {group}
            </Typography>
            <Divider sx={{ borderColor: 'primary.main' }} />
            <Table sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  {Object.keys((resources as any)[group]).map((category: string, index: number) => {
                    return (
                      <TableCell
                        key={category}
                        sx={{
                          fontSize: 20,
                          border: 'none',
                          paddingLeft: 0,
                          paddingBottom: 1,
                          width: `${100 / groupWithMostItems}%`,
                          wordWrap: 'break-word',
                        }}
                      >
                        {category.includes('Empty Category') ? undefined : (
                          <Grid container alignItems="baseline">
                            <Grid item xs={1} marginRight={0.8}>
                              <Box
                                sx={{
                                  height: '15px',
                                  width: '15px',
                                  backgroundColor: resourceColors[index],
                                  borderRadius: '50%',
                                  // display: 'inline-block',
                                }}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              {category}
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                    );
                  })}
                  {/* add columns for resource groups with fewer categories than the groups
                        with the highest number of categories */}
                  {/* {(() => {
                      const numCategories = Object.keys((resources as any)[group]).length;
                      const numColumnsToAddToGroup = groupWithMostItems - numCategories;
                      return [...Array(numColumnsToAddToGroup)].map((index) => (
                        <TableCell key={index} sx={{ border: 'none', display: 'inline-block' }}>
                          this is a test this is a test this is a test this is a test
                        </TableCell>
                      ));
                    })()} */}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRows((resources as any)[group])}</TableBody>
            </Table>
          </Fragment>
        );
      })}
    </Box>
  );
};
