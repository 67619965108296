import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { CONFIRMATION_DIALOG_DESCRIPTION, CONFIRMATION_DIALOG_TITLE } from '../lib/constants';

export interface DialogProps {
  open: boolean;
  maxWidth?: string;
  fullWidth?: boolean;
  handleClose: () => void;
  buttonTitle?: string;
  color?: 'inherit' | 'error' | 'primary' | 'warning' | 'success' | 'info' | 'secondary';
  title?: string;
  message?: string | JSX.Element;
  handleAction: () => void;
}

export function ConfirmationDialog(props: DialogProps): JSX.Element | null {
  const { open, handleClose, handleAction, buttonTitle, color, message, title } = props;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title ?? CONFIRMATION_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message ?? CONFIRMATION_DIALOG_DESCRIPTION}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color={color ?? 'error'}
          onClick={() => {
            handleAction();
          }}
        >
          {buttonTitle ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
