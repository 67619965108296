import { Grid, Paper, TextField } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AccessPolicyInput } from '../../../../components/AccessPolicyInput';
import { prettyJSON } from '../../../../lib/utils';

export interface RoleFields {
  name: string;
  description: string;
  accessPolicy: string;
}

export type RoleFieldName = 'name' | 'description' | 'accessPolicy';

interface FormProps {
  formUtils: UseFormReturn<RoleFields>;
  renderErrors?: (errors: any) => ReactElement;
  renderPreFormBody?: () => ReactElement;
  renderUpdateButton?: () => ReactElement;
  renderDeleteButton?: () => ReactElement;
  renderMembersTable?: () => ReactElement;
}

export const RoleFormBody: React.FC<FormProps> = ({
  formUtils,
  renderErrors,
  renderPreFormBody,
  renderUpdateButton,
  renderMembersTable,
  renderDeleteButton,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = formUtils;

  const accessPolicy: string | undefined = useWatch({
    name: 'accessPolicy',
    control,
    defaultValue:
      formUtils.getValues().accessPolicy ??
      prettyJSON({
        rule: [],
      }),
  });
  console.log('access policy in form body', accessPolicy);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} mt={4}>
        <Paper>
          <Grid container direction="column" spacing={4} padding={2}>
            {renderErrors && renderErrors(errors)}
            {renderPreFormBody && renderPreFormBody()}
            <Grid item sx={{ display: 'inherit' }}>
              <TextField
                {...register('name', {
                  required: `Name is a required field`,
                })}
                fullWidth
                label="Name"
                error={!!errors.name}
                helperText={'A name for this role'}
              />
            </Grid>
            <Grid item sx={{ display: 'inherit' }}>
              <TextField
                {...register('description', {
                  required: false,
                })}
                fullWidth
                multiline
                label="Description"
                error={!!errors.description}
                helperText={(errors.description?.message as string) || 'What access does this role grant/deny?'}
              />
            </Grid>
            <Grid item sx={{ display: 'inherit' }}>
              <AccessPolicyInput
                config={{
                  label: 'Access Policy',
                  helperText: 'Access policy defining the permissions conferred by this role',
                }}
                accessPolicy={accessPolicy}
                setAccessPolicy={(newVal: string | undefined) => setValue('accessPolicy', newVal ?? '')}
                accessPolicyErrors={[]}
              />
            </Grid>
            {renderUpdateButton && (
              <Grid item textAlign="center">
                {renderUpdateButton()}
              </Grid>
            )}
            {renderMembersTable && (
              <Grid item sx={{ display: 'inherit' }}>
                {renderMembersTable()}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {renderDeleteButton && renderDeleteButton()}
    </Grid>
  );
};
