import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  Await,
  defer,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { PageHeader } from '../../../../components/PageHeader';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { TextCardWithButton } from '../../../../components/TextCardWithButton';
import { TextFieldWithCopyButton } from '../../../../components/TextFieldWithCopyButton';
import { toast } from '../../../../lib/toast';
import { assert, commaSeparatedStringToArray, copyToClipboard } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { ApplicationFields, ApplicationFormBody, mapAppToAppFields } from '../lib/ApplicationFormBody';

export function appDetailPageLoader(args: LoaderFunctionArgs): ReturnType<typeof defer> {
  const { id } = args.params;
  assert(id, 'No Application ID in request url');
  return defer({ application: zapehr.project.application.get({ id }) });
}
export type AppDetailPageLoaderData = { application: ReturnType<typeof zapehr.project.application.get> };

export function ApplicationDetailPage(): JSX.Element {
  const data = useLoaderData() as AppDetailPageLoaderData;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PageHeader text="Application Information" />
      </Grid>
      <Grid item xs={12}>
        <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
          <Await resolve={data.application} errorElement={<RootErrorBoundary />}>
            {(application: Awaited<AppDetailPageLoaderData['application']>) => {
              return <ApplicationPageContent application={application} loading={false} />;
            }}
          </Await>
        </Suspense>
      </Grid>
    </Grid>
  );
}

interface ApplicationPageProps {
  application: Awaited<ReturnType<typeof zapehr.project.application.get>>;
  loading: boolean;
}

const ApplicationPageContent: React.FC<ApplicationPageProps> = ({ application, loading }) => {
  const formReturn: UseFormReturn<ApplicationFields> = useForm({
    values: mapAppToAppFields(application),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formReturn;
  // const [accessPolicy, setAccessPolicy] = React.useState<string | undefined>(undefined);
  const [actionLoading, setActionLoading] = useState(false);
  const [showDeleteApplicationDialog, setShowDeleteApplicationDialog] = useState(false);
  const [secretValue, setSecretValue] = useState<string | null>(null);
  const [showRotateSecretDialog, setShowRotateSecretDialog] = useState(false);
  const [showUpdatedSecretDialog, setShowUpdatedSecretDialog] = useState(false);
  const [showRevokeRefreshTokenDialog, setShowRevokeRefreshTokenDialog] = useState(false);
  const [refreshTokenToRevoke, setRefreshTokenToRevoke] = useState('');
  const [showRevokeAccessTokenDialog, setShowRevokeAccessTokenDialog] = useState(false);
  const [accessTokenToRevoke, setAccessTokenToRevoke] = useState('');
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { id: appUUID } = useParams();

  const saveChangesButtonHandler = useCallback(
    (data: ApplicationFields): void => {
      if (actionLoading || loading) {
        return;
      }

      setActionLoading(true);

      zapehr.project.application
        .update({
          id: application.id,
          clientId: application.clientId,
          ...data,
          allowedCallbackUrls: commaSeparatedStringToArray(data.allowedCallbackUrls),
          allowedCORSOriginsUrls: commaSeparatedStringToArray(data.allowedCORSOriginsUrls),
          allowedWebOriginsUrls: commaSeparatedStringToArray(data.allowedWebOriginsUrls),
          allowedLogoutUrls: commaSeparatedStringToArray(data.allowedLogoutUrls),
          loginRedirectUri: data.loginRedirectUri || '',
        })
        .then(() => {
          toast.success('Changes saved');
          revalidator.revalidate();
        })
        .catch((error) => {
          toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        })
        .finally(() => {
          setActionLoading(false);
        });
    },
    [actionLoading, application.clientId, application.id, loading, revalidator]
  );

  const rotateSecretButtonHandler = (): void => {
    setShowRotateSecretDialog(true);
  };

  const rotateSecret = useCallback((): void => {
    if (actionLoading || loading) {
      return;
    }
    setActionLoading(true);
    zapehr.project.application
      .rotateSecret({ id: application.id })
      .then((result) => {
        setSecretValue(result.secret ?? null);
        setShowUpdatedSecretDialog(true);
        toast.success('Successfully rotated client secret');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setActionLoading(false);
      });
    handleCloseRotateSecretDialog();
  }, [actionLoading, loading, application.id]);

  const deleteApplicationButtonHandler = (): void => {
    setShowDeleteApplicationDialog(true);
  };

  const deleteApplication = useCallback((): void => {
    if (actionLoading || loading) {
      return;
    }
    setActionLoading(true);
    handleCloseDeleteDialog();
    zapehr.project.application
      .delete({ id: application.id })
      .then(() => {
        toast.success(`Application "${application.name}" has been deleted`);
        revalidator.revalidate();
        navigate(`/${Services.app.rootPath}`);
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        setActionLoading(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  }, [actionLoading, application.id, application.name, loading, navigate, revalidator]);

  const copyZapEHRIDButtonHandler = (): void => {
    copyToClipboard(application.id, 'zapEHR ID copied to clipboard!');
  };

  const copyClientIDButtonHandler = (): void => {
    copyToClipboard(application.clientId, 'Client ID copied to clipboard!');
  };

  const copySecretButtonHandler = (): void => {
    if (secretValue) {
      copyToClipboard(secretValue, 'Secret key copied to clipboard!');
    } else {
      toast.error('An error occurred copying the secret key, please try again or contact us');
    }
  };

  const handleCloseDeleteDialog = (): void => {
    setShowDeleteApplicationDialog(false);
  };

  const handleCloseRotateSecretDialog = (): void => {
    setShowRotateSecretDialog(false);
  };

  const revokeRefreshTokenButtonHandler = (): void => {
    setShowRevokeRefreshTokenDialog(true);
  };

  const revokeRefreshTokenDialog = (
    <Dialog
      open={showRevokeRefreshTokenDialog}
      onClose={() => setShowRevokeRefreshTokenDialog(false)}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle>Revoke refresh token</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          onChange={(event) => setRefreshTokenToRevoke(event.target.value)}
          sx={{ mt: '20px' }}
          helperText="Enter refresh token"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            revokeRefreshToken();
          }}
        >
          Revoke
        </Button>
      </DialogActions>
    </Dialog>
  );

  const revokeRefreshToken = useCallback((): void => {
    if (actionLoading || loading) {
      return;
    }
    setActionLoading(true);
    zapehr.project.application
      .revokeRefreshToken({ id: application.id, token: refreshTokenToRevoke })
      .then((_) => {
        setRefreshTokenToRevoke('');
        toast.success('Successfully revoked refresh token');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setActionLoading(false);
      });
    setShowRevokeRefreshTokenDialog(false);
  }, [actionLoading, loading, refreshTokenToRevoke, application.id]);

  const revokeAccessTokenButtonHandler = (): void => {
    setShowRevokeAccessTokenDialog(true);
  };

  const revokeAccessTokenDialog = (
    <Dialog
      open={showRevokeAccessTokenDialog}
      onClose={() => setShowRevokeAccessTokenDialog(false)}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle>Revoke access token</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          onChange={(event) => setAccessTokenToRevoke(event.target.value)}
          sx={{ mt: '20px' }}
          helperText="Enter access token"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            revokeAccessToken();
          }}
        >
          Revoke
        </Button>
      </DialogActions>
    </Dialog>
  );

  const revokeAccessToken = useCallback((): void => {
    if (actionLoading || loading) {
      return;
    }
    setActionLoading(true);
    zapehr.project.application
      .revokeAccessToken({ id: application.id, token: accessTokenToRevoke })
      .then((_) => {
        setAccessTokenToRevoke('');
        toast.success('Successfully revoked access token');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setActionLoading(false);
      });
    setShowRevokeAccessTokenDialog(false);
  }, [actionLoading, loading, accessTokenToRevoke, application.id]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit(saveChangesButtonHandler)}>
            <Grid container direction="column" spacing={4} padding={2}>
              {errors.description && (
                <Grid item>
                  <Typography variant="body1" color="error" role="alert">
                    {errors.description?.message?.toString()}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={appUUID}
                      label="zapEHR ID"
                      helperText="This ID cannot be edited."
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={copyZapEHRIDButtonHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldWithCopyButton
                  textField={
                    <TextField
                      fullWidth
                      value={application.clientId}
                      label="Client ID"
                      helperText="This ID cannot be edited."
                      disabled={true}
                    />
                  }
                  copyButtonOnClick={copyClientIDButtonHandler}
                />
              </Grid>
              <ApplicationFormBody formUtils={formReturn} loading={loading} />
              <Grid item xs={12} textAlign={'center'}>
                <LoadingButton variant="contained" type="submit" sx={{ width: '200px' }} loading={actionLoading}>
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      {!loading && (
        <>
          <Grid item xs={12}>
            <TextCardWithButton
              title="Rotate secret"
              description="Your secret key can be used to get tokens to call the API. Once you generate a new one, you will not be able to access it again from this page. Store it somewhere securely."
              variant="warning"
              buttonTitle="Rotate"
              action={rotateSecretButtonHandler}
              loading={actionLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextCardWithButton
              title="Revoke refresh token"
              description=""
              variant="warning"
              buttonTitle="Revoke"
              action={revokeRefreshTokenButtonHandler}
              loading={actionLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextCardWithButton
              title="Revoke access token"
              description=""
              variant="warning"
              buttonTitle="Revoke"
              action={revokeAccessTokenButtonHandler}
              loading={actionLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextCardWithButton
              title={`Delete ${application.name}`}
              description={`If you delete this application, all apps using it will stop working.`}
              variant="danger"
              buttonTitle="Delete Application"
              action={deleteApplicationButtonHandler}
              loading={actionLoading}
            />
          </Grid>
        </>
      )}
      <Dialog
        open={showUpdatedSecretDialog}
        onClose={() => setShowUpdatedSecretDialog(false)}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Updated secret key</DialogTitle>
        <DialogContent>
          <TextFieldWithCopyButton
            textField={
              <TextField
                variant="standard"
                fullWidth
                value={secretValue}
                sx={{ mt: '20px' }}
                helperText="This is your new secret key. Store it securely."
                disabled={true}
              />
            }
            copyButtonOnClick={copySecretButtonHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShowUpdatedSecretDialog(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {revokeRefreshTokenDialog}
      {revokeAccessTokenDialog}
      {showDeleteApplicationDialog && (
        <ConfirmationDialog
          handleAction={deleteApplication}
          open={showDeleteApplicationDialog}
          buttonTitle="Delete"
          handleClose={handleCloseDeleteDialog}
        />
      )}
      {showRotateSecretDialog && (
        <ConfirmationDialog
          handleAction={rotateSecret}
          open={showRotateSecretDialog}
          handleClose={handleCloseRotateSecretDialog}
        />
      )}
    </Grid>
  );
};
