import { ErrorOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from '../lib/toast';
import { InputField } from '../lib/types';
import { Services } from '../services';

interface SecretUpsertFormProps {
  method: 'create' | 'update';
  secretFields: InputField[];
}

export function SecretUpsertForm({ method, secretFields }: SecretUpsertFormProps): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any): void => {
    setLoading(true);
    if (method === 'update') {
      const secretKey = secretFields.find((secret) => secret.name === 'name');
      data.name = secretKey?.defaultValue;
    }
    zapehr.project.secret
      .create(data)
      .then(() => {
        toast.success(`Secret ${method}d`);
        method === 'create' && navigate(`/${Services.zambdas.rootPath}/secrets`);
      })
      .catch((error) => {
        toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [showValue, setShowValue] = React.useState(false);

  const handleClickShowValue = (): void => setShowValue((show) => !show);

  return (
    <Paper>
      <Typography variant="h5" color="text.primary" padding={2}>
        Basic Information
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={4} padding={2}>
          {secretFields.map((field) => (
            <Grid item key={field.name}>
              <TextField
                {...register(field.name, { required: field.required && `${field.label} is a required field` })}
                label={field.label}
                fullWidth
                disabled={method === 'update' && field.label === 'Key' ? true : false}
                error={!!errors[field.name]}
                helperText={(errors[field.name]?.message as string) || field.helperText}
                defaultValue={field.defaultValue}
                placeholder={field.placeholder}
                InputLabelProps={{ shrink: true }}
                type={field.label === 'Value' && !showValue ? 'password' : 'text'}
                InputProps={{
                  endAdornment:
                    field.label === 'Value' ? (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowValue}>
                          {showValue ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
              />
            </Grid>
          ))}
          {method === 'update' && (
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <ErrorOutline color="warning" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="text.primary">
                    If this Secret is in use in a Zambda Function, updating its value may cause errors when running the
                    Zambda Function.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item textAlign="left">
            <LoadingButton type="submit" variant="contained" loading={loading}>
              {method === 'create' ? 'Create Secret' : 'Update'}
            </LoadingButton>
            {/* <Link to="/secrets">
              <Button variant="text" sx={{ marginLeft: '8px' }}>
                Cancel
              </Button>
            </Link> */}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
