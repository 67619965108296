import * as React from 'react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FullScreenLoadingIndicator } from '../../components/FullScreenLoadingIndicator';
import { FhirVersion, newProjectNameKey, useZapehr } from '../../contexts/ZapehrProvider';
import { NewProjectPage } from '../Project/CreateProject';

const Callback: FC = () => {
  const {
    authIsLoading,
    userIsAuthenticated,
    authError,
    initializationError,
    currentProject,
    refreshSavedAuthToken,
    updateFhirVersion,
    isSelectingFhirVersion,
  } = useZapehr();
  const navigate = useNavigate();

  if (authError) {
    console.error('auth error', authError);
    throw authError;
  }
  if (initializationError) {
    console.error('init error', initializationError);
    throw initializationError;
  }

  useEffect(() => {
    if (userIsAuthenticated && !authIsLoading && !isSelectingFhirVersion && currentProject) {
      refreshSavedAuthToken();
      navigate('/');
    }
  }, [authIsLoading, navigate, userIsAuthenticated, isSelectingFhirVersion, currentProject, refreshSavedAuthToken]);

  const onFormSubmit = async (name: string, version: FhirVersion): Promise<void> => {
    localStorage.setItem(newProjectNameKey, name);
    await updateFhirVersion(version);
  };

  if (userIsAuthenticated && !authIsLoading && isSelectingFhirVersion) {
    return <NewProjectPage isFirstProject submitCallback={onFormSubmit} />;
  } else {
    return <FullScreenLoadingIndicator />;
  }
};

export default Callback;
