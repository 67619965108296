import { Search } from '@mui/icons-material';
import {
  alpha,
  Autocomplete,
  Box,
  createFilterOptions,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AutocompleteOption } from '../../lib/types';

export interface AutocompleteProps {
  optionsList: AutocompleteOption[];
  label?: string;
  placeholder?: string;
  freeSolo?: boolean;
  selectToRedirect?: boolean;
  sx?: SxProps;
  reference?: boolean;
  onChange?: (value: AutocompleteOption | null) => void;
  value?: string;
  disabled?: boolean;
}

export const SearchBarAutocomplete: FC<AutocompleteProps> = ({
  optionsList: options,
  label,
  placeholder,
  freeSolo,
  selectToRedirect,
  sx,
  onChange,
  value,
  disabled,
}) => {
  const selectedOption: AutocompleteOption | null = useMemo(() => {
    if (value) {
      return options.find((option) => option.value === value) ?? null;
    }
    return null;
  }, [options, value]);

  const navigate = useNavigate();
  const theme = useTheme();
  const redirectStyle = selectToRedirect ?? false;
  const sxStyle: SxProps = sx ?? {};

  const sortedOptions = useMemo(() => {
    return options.sort((a, b) => {
      if (a.value === selectedOption?.value) {
        return -1;
      }
      if (b.value === selectedOption?.value) {
        return 1;
      }
      if (a.group && b.group) {
        const groupCompare = a.group.localeCompare(b.group);
        if (groupCompare > 0) {
          return 1;
        } else if (groupCompare < 0) {
          return -1;
        }
      }

      return a.label.localeCompare(b.label);
    });
  }, [options, selectedOption?.value]);

  return (
    <Box>
      <Autocomplete
        componentsProps={{
          popper: { disablePortal: true, popperOptions: { placement: 'bottom-start', strategy: 'fixed' } },
        }}
        options={sortedOptions}
        freeSolo={freeSolo}
        value={selectedOption}
        onChange={(_, val) => {
          console.log('val selected', val);
          if (val !== null) {
            let value: AutocompleteOption;
            if (typeof val === 'string') {
              value = { value: val, label: val };
            } else {
              value = val;
            }
            if (selectToRedirect) {
              if (value.path !== undefined) {
                navigate(value.path);
              } else {
                navigate(value.label);
              }
            } else if (onChange) {
              console.log('val selected on change finished', value);
              onChange(value);
            }
          } else {
            onChange?.(null);
          }
        }}
        disabled={disabled}
        // on select item remove focus
        blurOnSelect={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        groupBy={(option) => option.group || ''}
        // size="small"
        filterOptions={createFilterOptions({
          // Match on either label or group
          // Todo this is not ideal because of how it does the matching.
          // For example, label "FHIR Admin" with group "Service"
          // will match on "FHIR Admin Service" when it shouldn't.
          // We should try to find a way to match on label _or_ group.
          stringify: (option) => `${option.label} ${option.group}`,
        })}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Stack>
              <Typography variant="body1" sx={{ width: '100%' }}>
                {option.label}
              </Typography>
              <Typography variant="overline" sx={{ width: '100%' }}>
                {option.subtext}
              </Typography>
            </Stack>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            fullWidth={true}
            sx={
              redirectStyle
                ? {
                    '.MuiInputBase-root': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.12),
                    },
                  }
                : sx !== undefined
                ? sxStyle
                : { width: '50%' }
            }
            // issue with label moving without input, modified from https://github.com/mui/material-ui/issues/13898#issuecomment-1109447249
            InputLabelProps={
              redirectStyle
                ? {
                    sx: {
                      '&.MuiInputLabel-root': {
                        transform: 'translate(45px, 0.5rem)',
                        cursor: 'text',
                      },
                      '&.Mui-focused': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                      },
                      '&.MuiFormLabel-filled': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                      },
                      '&.MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled) ~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend':
                        {
                          maxWidth: 0,
                        },
                    },
                  }
                : {}
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: redirectStyle && (
                <InputAdornment position="start">
                  <Search sx={{ color: 'primary.light' }} />
                </InputAdornment>
              ),
              ...(disabled ? { endAdornment: '' } : {}),
              size: redirectStyle ? 'small' : 'medium',
            }}
          />
        )}
      />
    </Box>
  );
};
