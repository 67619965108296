import { Settings } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  LinearProgress,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Await, Outlet, useLoaderData, useLocation } from 'react-router-dom';
import { ProjectInfo, useZapehr } from '../../components';
import { AdminSidebarItem } from '../../components/AdminSidebar';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { PageContainer } from '../../components/PageContainer';
import { RootErrorBoundary } from '../../components/RootErrorBoundary';
import { TextFieldWithCopyButton } from '../../components/TextFieldWithCopyButton';
import { useMixpanel } from '../../contexts/MixpanelProvider';
import { toast } from '../../lib/toast';
import { copyToClipboard, defer } from '../../lib/utils';
import { SelectRoleWidget } from './lib/SelectRoleWidget';

export const projectSidebarItems: AdminSidebarItem[][] = [
  [
    {
      label: 'Settings',
      icon: <Settings />,
      path: '/project',
    },
  ],
];

interface ConfirmDialogConfig {
  title: string;
  message: string;
  data: ProjectInfoFormValues;
}

const confirmationDialogDef = (data: ProjectInfoFormValues): ConfirmDialogConfig => {
  return {
    title: 'Confirm default patient role',
    message: `You are making a change to your default patient role. Anyone who self registers through one of your project's 
      applications will be assigned this role and all the access privileges it confers. 
      Make sure you've carefully reviewed the access policy on this role before making the change.`,
    data,
  };
};

export function projectSettingsPageLoader(): ReturnType<typeof defer> {
  return defer<ProjectSettingsPageLoaderData>({
    settings: zapehr.project.project.get(),
    roles: zapehr.project.role.list(),
  });
}
export type ProjectSettingsPageLoaderData = {
  settings: ReturnType<typeof zapehr.project.project.get>;
  roles: ReturnType<typeof zapehr.project.role.list>;
};

export function ProjectSettingsPage(): JSX.Element {
  const { pathname } = useLocation();
  const data = useLoaderData() as ProjectSettingsPageLoaderData;
  const { currentProject } = useZapehr();
  return (
    <PageContainer sidebarItems={projectSidebarItems}>
      {pathname === '/project' ? (
        <Suspense fallback={<ProjectSettings projectInfo={{ ...(currentProject ?? {}) }} />}>
          <Await resolve={data.settings} errorElement={<RootErrorBoundary />}>
            {(projectInfo: Awaited<ProjectSettingsPageLoaderData['settings']>) => {
              return <ProjectSettings projectInfo={projectInfo} />;
            }}
          </Await>
        </Suspense>
      ) : (
        <Outlet />
      )}
    </PageContainer>
  );
}

interface ProjectSettingsProps {
  projectInfo: Partial<ProjectInfo>;
}

interface ProjectInfoFormValues {
  readonly name: string | undefined;
  readonly description: string | null | undefined;
  readonly signupEnabled: boolean;
  readonly defaultPatientRoleId?: string | null | undefined;
  readonly sandbox: boolean | undefined;
}
const sandboxEnabledHelperText = (
  <span>
    Indicates whether the project is in sandbox mode - currently for testing eRx features without affecting real medical
    services. If you'd like to disable sandbox mode, please reach out to{' '}
    <a href="mailto:support@oystehr.com">support@oystehr.com</a>.
  </span>
);

const defaultRoleHelperText = `When user signup is enabled, you must designate a default patient role. This role will
automatically be associated with any user who self registers through one of your applications
and will define the scope of their access to fhir and other resources in your project.`;

const formDefaultsFromFromProjectInfo = (project: Partial<ProjectInfo> | undefined): ProjectInfoFormValues => {
  if (project) {
    const { signupEnabled, defaultPatientRole, name, description, sandbox } = project;
    return {
      signupEnabled: signupEnabled ?? false,
      name,
      description,
      defaultPatientRoleId: defaultPatientRole?.id ?? null,
      sandbox: sandbox,
    };
  } else {
    return {
      signupEnabled: false,
      name: undefined,
      description: undefined,
      defaultPatientRoleId: undefined,
      sandbox: true,
    };
  }
};

const ProjectSettings: FC<ProjectSettingsProps> = ({ projectInfo }) => {
  const [loading, setLoading] = useState(false);
  const [formDefaultSource, setFormDefaultSource] = useState<Partial<ProjectInfo> | undefined>(projectInfo);
  const formDefaults = useMemo(() => {
    return formDefaultsFromFromProjectInfo(formDefaultSource);
  }, [formDefaultSource]);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProjectInfoFormValues>({ defaultValues: formDefaults });
  const signupEnabled = watch('signupEnabled', formDefaults.signupEnabled);
  const defaultPatientRoleId = watch('defaultPatientRoleId', formDefaults.defaultPatientRoleId);
  const [confirmDialogConfig, setConfirmDialogConfig] = useState<ConfirmDialogConfig | null>(null);
  const { track } = useMixpanel();
  const { updateProjectData } = useZapehr();

  const copyProjectId = useCallback((): void => {
    if (projectInfo?.id) {
      copyToClipboard(projectInfo.id, 'Project ID copied to clipboard!');
      track('Project ID Copied');
    } else {
      toast.error('Project ID is not yet loaded.');
    }
  }, [projectInfo, track]);

  useEffect(() => {
    if (!signupEnabled) {
      setValue('defaultPatientRoleId', null);
    }
  }, [setValue, signupEnabled]);

  const existingDefaultRole = useMemo(() => {
    if (formDefaultSource) {
      return formDefaultSource?.defaultPatientRole ?? null;
    }
    return undefined;
  }, [formDefaultSource]);

  const saveChangesButtonHandler = (data: ProjectInfoFormValues): void => {
    if (data.defaultPatientRoleId && projectInfo?.defaultPatientRole?.id !== data.defaultPatientRoleId) {
      setConfirmDialogConfig(confirmationDialogDef(data));
    } else {
      persistChanges(data);
    }
  };

  const handleSubmitConfirmation = (): void => {
    if (confirmDialogConfig) {
      persistChanges(confirmDialogConfig.data);
      setConfirmDialogConfig(null);
    }
  };

  useEffect(() => {
    setFormDefaultSource(projectInfo);
  }, [projectInfo]);

  const persistChanges = (data: ProjectInfoFormValues): void => {
    setLoading(true);
    zapehr.project.project
      .update({
        name: data.name ?? '',
        description: data.description ?? '',
        signupEnabled: data.signupEnabled,
        defaultPatientRoleId: data.defaultPatientRoleId,
      })
      .then((newProjectInfo) => {
        toast.success('Changes saved');
        setFormDefaultSource(newProjectInfo);
        if (projectInfo.id && newProjectInfo.name) {
          updateProjectData(projectInfo.id);
        }
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Grid container sx={{ height: '100%', width: '100%' }}>
      <Grid item xs={12}>
        <Typography variant="h4" mb={8}>
          Project Settings
        </Typography>
      </Grid>
      <Grid container item xs={12} alignContent={'center'} alignItems={'center'} width={'100%'}>
        <Paper sx={{ height: '100%' }}>
          <form onSubmit={handleSubmit(saveChangesButtonHandler)}>
            <Grid container item spacing={4} padding={2}>
              <Grid item xs={12}>
                {projectInfo.id ? (
                  <TextFieldWithCopyButton
                    textField={
                      <TextField
                        fullWidth
                        label="Project ID"
                        defaultValue={projectInfo.id}
                        disabled
                        helperText="This value cannot be changed"
                      />
                    }
                    copyButtonOnClick={copyProjectId}
                  />
                ) : (
                  <LinearProgress sx={{ maxWidth: '300px' }} />
                )}
              </Grid>
              <Grid item xs={12}>
                {projectInfo.name ? (
                  <TextField
                    {...register('name', { required: true, maxLength: 256 })}
                    fullWidth
                    label="Name"
                    helperText="Project name"
                    defaultValue={projectInfo.name}
                    error={!!errors.name}
                  />
                ) : (
                  <LinearProgress sx={{ maxWidth: '300px' }} />
                )}
              </Grid>
              <Grid item xs={12}>
                {projectInfo.description ? (
                  <TextField
                    {...register('description', { required: true, maxLength: 1000 })}
                    fullWidth
                    label="Description"
                    helperText="Project description"
                    multiline
                    minRows={2}
                    defaultValue={projectInfo.description}
                    error={!!errors.description}
                  />
                ) : (
                  <LinearProgress sx={{ maxWidth: '300px' }} />
                )}
              </Grid>
              <Grid item xs={12}>
                {projectInfo.sandbox !== undefined && (
                  <TextField
                    fullWidth
                    label="Sandbox Mode"
                    value={projectInfo.sandbox ? 'Enabled' : 'Disabled'}
                    disabled
                    helperText={sandboxEnabledHelperText}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {projectInfo.signupEnabled !== undefined && (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox {...register('signupEnabled')} defaultChecked={projectInfo?.signupEnabled} />}
                      label="User signup on Applications"
                    />
                    <FormHelperText>
                      For Applications, whether users are permitted to sign themselves up with a signup button on the
                      auth page; if this checkbox is checked, users will be permitted to sign themselves up. Regardless
                      of this setting, users can be invited using the{' '}
                      <code>
                        <Link target="_blank" href="https://api-reference.oystehr.com/reference/post_user-invite">
                          invite
                        </Link>
                      </code>{' '}
                      endpoint.
                    </FormHelperText>
                    {signupEnabled && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingTop: '20px',
                        }}
                      >
                        <FormHelperText>{defaultRoleHelperText}</FormHelperText>
                        <SelectRoleWidget
                          updateCurrentSelection={(updatedSelection: string | null) => {
                            setValue('defaultPatientRoleId', updatedSelection, { shouldDirty: true });
                          }}
                          currentSelection={defaultPatientRoleId}
                          existingDefaultRole={existingDefaultRole}
                        />
                      </Box>
                    )}
                  </FormGroup>
                )}
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{ width: '200px' }}
                  loading={loading}
                  disabled={!projectInfo}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <ConfirmationDialog
        open={confirmDialogConfig !== null}
        title={confirmDialogConfig?.title}
        message={confirmDialogConfig?.message}
        handleClose={() => {
          setConfirmDialogConfig(null);
        }}
        handleAction={handleSubmitConfirmation}
      />
    </Grid>
  );
};
