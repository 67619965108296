import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, TextField } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ApplicationPutPostParams } from '../../../../lib/client';

export interface ApplicationFields {
  name: string;
  description?: string;
  loginRedirectUri?: string;
  allowedCallbackUrls?: string;
  allowedLogoutUrls?: string;
  allowedWebOriginsUrls?: string;
  allowedCORSOriginsUrls?: string;
  logoUri?: string;
  shouldSendInviteEmail?: boolean;
  refreshTokenEnabled?: boolean;
}

export const mapAppToAppFields = (
  app: Awaited<ReturnType<typeof zapehr.project.application.get>>
): ApplicationFields => {
  const { name, description, loginRedirectUri, logoUri, shouldSendInviteEmail, refreshTokenEnabled } = app;

  const allowedWebOriginsUrls = app.allowedWebOriginsUrls?.join(',');
  const allowedCallbackUrls = app.allowedCallbackUrls?.join(',');
  const allowedCORSOriginsUrls = app.allowedCORSOriginsUrls?.join(',');
  const allowedLogoutUrls = app.allowedLogoutUrls?.join(',');

  return {
    name,
    description,
    loginRedirectUri,
    logoUri,
    allowedWebOriginsUrls,
    allowedCallbackUrls,
    allowedLogoutUrls,
    allowedCORSOriginsUrls,
    shouldSendInviteEmail,
    refreshTokenEnabled,
  };
};

export const mapAppFieldsToAppPutPostParams = (data: ApplicationFields): ApplicationPutPostParams => {
  const mapOptionalStringToList = (maybeString?: string): string[] => {
    return (
      maybeString
        ?.trim()
        .split(',')
        .filter((item) => !!item) ?? []
    );
  };
  return {
    ...data,
    allowedCallbackUrls: mapOptionalStringToList(data.allowedCallbackUrls),
    allowedCORSOriginsUrls: mapOptionalStringToList(data.allowedCORSOriginsUrls),
    allowedWebOriginsUrls: mapOptionalStringToList(data.allowedWebOriginsUrls),
    allowedLogoutUrls: mapOptionalStringToList(data.allowedLogoutUrls),
    logoUri: data.logoUri || undefined,
  };
};

interface FormProps {
  formUtils: UseFormReturn<ApplicationFields>;
  loading: boolean;
}

export const ApplicationFormBody: React.FC<FormProps> = ({ formUtils, loading }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = formUtils;
  return (
    <>
      <Grid item>
        <TextField
          {...register('name', {
            required: 'Name is a required field',
          })}
          fullWidth
          label={'Name'}
          error={!!errors['name']}
          helperText={(errors['name']?.message as string) || 'Name of the application'}
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('description', {
            maxLength: {
              value: 140,
              message: 'Description has a max length of 140 characters',
            },
          })}
          fullWidth
          label={'Description'}
          error={!!errors['description']}
          helperText={(errors['description']?.message as string) || 'Description of the application'}
          multiline
          minRows={2}
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('loginRedirectUri', {
            required: false,
          })}
          fullWidth
          label={'Login Redirect URI'}
          error={!!errors['loginRedirectUri']}
          helperText={(errors['loginRedirectUri']?.message as string) || 'URI to redirect users to'}
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('allowedCallbackUrls', {
            required: 'Allowed Callback URLs is a required field',
          })}
          fullWidth
          label={'Allowed Callback URLs'}
          error={!!errors['allowedCallbackUrls']}
          helperText={
            (errors['allowedCallbackUrls']?.message as string) || 'Callback URLs that are allowed, comma-separated list'
          }
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('allowedLogoutUrls', {
            required: false,
          })}
          fullWidth
          label={'Allowed Logout URLs'}
          error={!!errors['allowedLogoutUrls']}
          helperText={
            (errors['allowedLogoutUrls']?.message as string) || 'Logout URLs that are allowed, comma-separated list'
          }
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('allowedWebOriginsUrls', {
            required: false,
          })}
          fullWidth
          label={'Allowed Web Origins'}
          error={!!errors['allowedWebOriginsUrls']}
          helperText={
            (errors['allowedWebOriginsUrls']?.message as string) || 'Web Origins that are allowed, comma-separated list'
          }
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('allowedCORSOriginsUrls', {
            required: false,
          })}
          fullWidth
          label={'Allowed CORS Origins'}
          error={!!errors['allowedCORSOriginsUrls']}
          helperText={
            (errors['allowedCORSOriginsUrls']?.message as string) ||
            'CORS Origins that are allowed, comma-separated list'
          }
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <TextField
          {...register('logoUri', {
            required: false,
          })}
          fullWidth
          label={'Logo URI'}
          error={!!errors['logoUri']}
          helperText={
            (errors['logoUri']?.message as string) || 'URI to a logo for the Application that will go on auth pages'
          }
          disabled={loading}
        />
      </Grid>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox {...register('shouldSendInviteEmail')} defaultChecked={watch('shouldSendInviteEmail')} />
            }
            label="Send Test Invite Email"
          />
          <FormHelperText>
            For this application, whether an automatic demo email is sent to users who are invited to this application.
          </FormHelperText>
        </FormGroup>
      </Grid>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox {...register('refreshTokenEnabled')} defaultChecked={watch('refreshTokenEnabled')} />}
            label="Refresh Token Enabled"
          />
          <FormHelperText>
            For this application, whether a refresh token should be provided during authorization.
          </FormHelperText>
        </FormGroup>
      </Grid>
    </>
  );
};
