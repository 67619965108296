import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, CircularProgress, Grid, Paper, Tab, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useCallback, useState } from 'react';
import {
  Await,
  Link,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useRevalidator,
  useRouteLoaderData,
} from 'react-router-dom';
import BreadcrumbsBasic from '../../../../components/BreadCrumbsBasic';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RootErrorBoundary } from '../../../../components/RootErrorBoundary';
import { Zambda } from '../../../../lib/client';
import { toast } from '../../../../lib/toast';
import { InputField, PathHistoryBreadcrumb } from '../../../../lib/types';
import { assert, copyToClipboard, defer } from '../../../../lib/utils';
import { ZambdaForms } from '../lib/ZambdaForms';

const prevPaths: PathHistoryBreadcrumb[] = [{ text: 'Zambdas', from: '/zambdas' }];

export function zambdaDetailLoader({ params }: LoaderFunctionArgs): ReturnType<typeof defer> {
  const { id } = params;
  assert(id, 'No Zambda ID in request url');
  return defer<ZambdaDetailLoaderData>({
    zambda: zapehr.project.zambda.get({ id }),
  });
}
export type ZambdaDetailLoaderData = {
  zambda: ReturnType<typeof zapehr.project.zambda.get>;
};

export function ZambdaDetail(): JSX.Element {
  const data = useLoaderData() as ZambdaDetailLoaderData;
  const tab = useLocation().pathname.split('/').pop() === 'info' ? 'info' : 'logs';
  const theme = useTheme();

  async function handleCopyZambdaURL(zambda: any): Promise<void> {
    const url = `${process.env.PROJECT_API_URL}/zambda/${zambda.id}/${
      zambda.triggerMethod === 'http_open' ? 'execute-public' : 'execute'
    }`;
    await copyToClipboard(url, `Zambda URL copied to clipboard:\n${url}`);
  }

  return (
    <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
      <Await resolve={data.zambda} errorElement={<RootErrorBoundary />}>
        {(zambda: Zambda) => {
          return (
            <Grid container>
              <Grid item xs={12} marginBottom={3}>
                <BreadcrumbsBasic prevPaths={prevPaths} currPathText={zambda.name}></BreadcrumbsBasic>
              </Grid>

              <Grid item xs={12} marginBottom={2}>
                <Typography variant="h4" color="text.primary">
                  {zambda.name}
                </Typography>
                {['http_open', 'http_auth'].includes(zambda.triggerMethod) && (
                  <Typography variant="body1" color="text.primary" marginTop={2}>
                    <Button
                      sx={{
                        bgcolor: theme.palette.primary.main + '1F',
                        color: theme.palette.primary.dark,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        },
                        marginRight: 1,
                        textTransform: 'none',
                      }}
                      onClick={() => handleCopyZambdaURL(zambda)}
                      endIcon={<ContentCopyIcon />}
                    >
                      Copy Zambda API URL
                    </Button>
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <TabContext value={tab}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList value={tab}>
                          <Tab
                            label="Basic Info"
                            value="info"
                            icon={<SettingsOutlinedIcon />}
                            iconPosition="start"
                            to="info"
                            component={Link}
                          />
                          <Tab
                            label="Logs"
                            value="logs"
                            icon={<ViewListOutlinedIcon />}
                            iconPosition="start"
                            to="logs"
                            component={Link}
                          />
                        </TabList>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value="info" sx={{ p: 0 }}>
                        <Outlet />
                      </TabPanel>
                      <TabPanel value="logs" sx={{ p: 0 }}>
                        <Outlet />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </TabContext>
              </Grid>
            </Grid>
          );
        }}
      </Await>
    </Suspense>
  );
}

export function ZambdaBasicInfo(): JSX.Element {
  const navigate = useNavigate();
  const data = useRouteLoaderData('zambda-detail') as ZambdaDetailLoaderData;
  const [showDeleteZambdaDialog, setShowDeleteZambdaDialog] = useState(false);
  const revalidator = useRevalidator();
  const { id: zambdaId } = useParams<{ id: string }>();

  const handleDelete = useCallback(async (): Promise<void> => {
    assert(zambdaId, 'No zambda ID in request url');

    zapehr.project.zambda
      .delete({ id: zambdaId })
      .then(() => {
        toast.success('Zambda deleted');
        revalidator.revalidate();
        navigate('/zambdas');
      })
      .catch((error: any) => {
        toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      });

    handleCloseDeleteDialog();
  }, [zambdaId, revalidator, navigate]);

  const handleCloseDeleteDialog = (): void => {
    setShowDeleteZambdaDialog(false);
  };
  return (
    <Suspense fallback={<CircularProgress sx={{ marginTop: 2 }} />}>
      <Await resolve={data.zambda} errorElement={<RootErrorBoundary />}>
        {(zambda: Zambda) => {
          const fields: InputField[] = [
            {
              name: 'name',
              label: 'Name',
              required: true,
              defaultValue: zambda.name,
            },
            {
              name: 'triggerMethod',
              label: 'Trigger method',
              required: true,
              defaultValue: zambda.triggerMethod,
              isSelect: true,
            },
            {
              name: 'status',
              label: 'Status',
              required: false,
              defaultValue: zambda.status,
              disabled: true,
            },
            {
              name: 'expression',
              label: 'Expression',
              required: false,
              defaultValue: zambda.schedule?.expression ?? undefined,
              disabled: true,
            },
            {
              name: 'start',
              label: 'Start',
              required: false,
              defaultValue: zambda.schedule?.start ?? undefined,
              disabled: true,
            },
            {
              name: 'end',
              label: 'End',
              required: false,
              defaultValue: zambda.schedule?.end ?? undefined,
              disabled: true,
            },
            {
              name: 'runtime',
              label: 'Runtime',
              required: false,
              defaultValue: zambda.runtime,
              isSelect: true,
              options: ['nodejs18.x', 'nodejs20.x'],
            },
            {
              name: 'maximumEventAge',
              label: 'Maximum Event Age',
              required: false,
              defaultValue: zambda.schedule?.retryPolicy?.maximumEventAge?.toString() ?? undefined,
              disabled: true,
            },
            {
              name: 'maximumRetry',
              label: 'Maximum Retry',
              required: false,
              defaultValue: zambda.schedule?.retryPolicy?.maximumRetry?.toString() ?? undefined,
              disabled: true,
            },
          ];
          return (
            <>
              <ZambdaForms method="update" zambdaFields={fields} zambdaId={zambdaId} zambdaFileInfo={zambda.fileInfo} />
              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={2} padding={2} mt={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="text.primary">
                        Delete Zambda
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="text.primary">
                        If you delete this zambda, all features using it will stop working.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        component="label"
                        variant="contained"
                        sx={{ backgroundColor: 'error.main', '&:hover': { backgroundColor: 'error.main' } }}
                        onClick={() => setShowDeleteZambdaDialog(true)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {showDeleteZambdaDialog && (
                <ConfirmationDialog
                  handleAction={handleDelete}
                  open={showDeleteZambdaDialog}
                  buttonTitle="Delete"
                  handleClose={handleCloseDeleteDialog}
                />
              )}
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
