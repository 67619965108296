import { Box, Link, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { formatDateTime } from '../helpers';
import { Reference, Specimen } from '../lib/fhir-types';
import { useResource } from './index';

export interface SpecimenHeaderProps {
  specimen: Specimen | Reference;
}

export function SpecimenHeader(props: SpecimenHeaderProps): JSX.Element | null {
  const specimen: Specimen | undefined = useResource(props.specimen);
  if (!specimen) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: 'primary.contrast', marginLeft: '-36px', marginRight: '-36px', marginTop: '-36px' }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ padding: '4px 25px' }}>
        <Typography variant="subtitle1" color="text.primary">
          Type:{' '}
          <Link underline="hover" color="text.primary" href={`/${specimen.resourceType}/`}>
            {specimen.resourceType}
          </Link>
        </Typography>

        <Typography variant="subtitle1" color="text.primary">
          Collected: {formatDateTime(specimen?.collection?.collectedDateTime)}
        </Typography>
        <Typography variant="subtitle1" color="text.primary">
          Age: {getAge(specimen)}
        </Typography>
      </Stack>
    </Box>
  );
}

function getAge(specimen: Specimen): string | undefined {
  const collectedDateStr = specimen.receivedTime;
  if (!collectedDateStr) {
    return undefined;
  }

  const collectedDate = DateTime.fromISO(collectedDateStr);
  const ageInDays = -collectedDate.diffNow('days').days;
  const ageFormatted = ageInDays.toFixed(0).padStart(3, '0');
  return ageFormatted + 'D';
}
