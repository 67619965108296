import { MenuItem, Stack } from '@mui/material';
import * as React from 'react';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { PropertyDefinition } from '../../lib/schema';
import { CustomTextField } from '../WrappedInputs/CustomTextField';
import { getDefaultValue, PropertyInputProps } from './common';
import { PropertyInput } from './PropertyInput';

export function ChoiceTypePropertyInput(props: PropertyInputProps): JSX.Element {
  const { propertyDefinition, value, onChange } = props;
  const { editModeOn } = useResourceEditingContext();
  const type = value?.type ?? propertyDefinition.choiceTypes?.[0].name ?? '';

  return (
    <Stack spacing={2} direction="row">
      <CustomTextField
        select
        value={type}
        sx={{ minWidth: 'auto' }}
        onChange={(event) => {
          const newType = event.target.value;
          onChange({
            value: getDefaultValue(newType),
            type: newType,
          });
        }}
        displayOnly={!editModeOn}
      >
        {propertyDefinition.choiceTypes?.map((type) => {
          return (
            <MenuItem key={`${propertyDefinition.path}-${type.name}-option`} value={type.name}>
              {type.name}
            </MenuItem>
          );
        })}
      </CustomTextField>
      <div style={{ width: '100%' }}>
        <PropertyInput
          propertyDefinition={{
            ...propertyDefinition,
            choiceTypes: undefined,
            type: type,
            targetTypes: getTargetTypes(type, propertyDefinition),
          }}
          value={{
            value: value?.value,
            type: type,
          }}
          onChange={onChange}
        />
      </div>
    </Stack>
  );
}

function getTargetTypes(type: string, propertyDefinition: PropertyDefinition): string[] | undefined {
  return propertyDefinition.choiceTypes?.find((choiceType) => choiceType.name === type)?.targetTypes;
}
