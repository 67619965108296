import './App.css';
import { Box } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import * as React from 'react';
import { AuthenticatedApp } from './AuthenticatedApp';
import { useZapehr } from './components';
import { ToastContainer } from './lib/toast';
import { UnauthenticatedApp } from './UnauthenticatedApp';

const MUI_X_LICENSE_KEY = process.env.MUI_X_LICENSE_KEY;
if (MUI_X_LICENSE_KEY != null) {
  LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);
}

export function App(): JSX.Element {
  const { profileIsLoading, currentProject, userIsAuthenticated, isSdkInitialized } = useZapehr();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <ToastContainer />
      <Box
        sx={{
          height: '100%',
          minHeight: '100vh',
          width: '100%',
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div id="content_spacer_div" />
        {!profileIsLoading && currentProject && userIsAuthenticated && isSdkInitialized ? (
          <AuthenticatedApp />
        ) : (
          <UnauthenticatedApp />
        )}
      </Box>
    </Box>
  );
}
