import zapehr from '@zapehr/sdk';
import { useEffect, useState } from 'react';
import { Device, Reference, Resource } from './fhir-types';

const system: Device = {
  resourceType: 'Device',
  id: 'system',
  deviceName: [
    {
      name: 'System',
      type: 'other',
    },
  ],
};

/**
 * React Hook to use a FHIR reference.
 * Handles the complexity of resolving references and caching resources.
 * @param value The resource or reference to resource.
 * @returns The resolved resource.
 */
export function useResource<T extends Resource>(value: Reference | T | undefined): T | undefined {
  const [resource, setResource] = useState<T | undefined>(getInitialResource(value));

  useEffect(() => {
    let subscribed = true;

    if (!resource && value && 'reference' in value && value.reference) {
      const refString = value.reference;
      if (!refString) {
        setResource(undefined);
      } else {
        const [resourceType, id] = refString.split('/');
        if (!resourceType || !id) {
          setResource(undefined);
        } else {
          zapehr.fhir
            .get({ resourceType, id })
            .then((r) => {
              if (subscribed) {
                setResource(r as T);
              }
            })
            .catch(() => setResource(undefined));
        }
      }
    } else if (value && (value as T).resourceType) {
      setResource(value as T);
    }

    return (() => (subscribed = false)) as () => void;
  }, [resource, value]);

  return resource;
}

/**
 * Returns the initial resource value based on the input value.
 * If the input value is a resource, returns the resource.
 * If the input value is a reference to system, returns the system resource.
 * If the input value is a reference to a resource available in the cache, returns the resource.
 * Otherwise, returns undefined.
 * @param medplum The medplum client.
 * @param value The resource or reference to resource.
 * @returns An initial resource if available; undefined otherwise.
 */
function getInitialResource<T extends Resource>(value: Reference | T | undefined): T | undefined {
  if (!value) {
    return undefined;
  }

  if ('resourceType' in value) {
    return value;
  }

  if ('reference' in value) {
    if (value.reference === 'system') {
      return system as T;
    }
  }

  return undefined;
}
