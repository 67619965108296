import zapehr from '@zapehr/sdk';
import { toast } from 'react-toastify';
import { Z3Object } from '../../../../lib/client';

// -------------------------------------------- Bucket files tree --------------------------------------------
// -----------------------------------------------------------------------------------------------------------

export class BucketTreeElement {
  name: string;
  parent: BucketTreeElement | undefined;
  children: BucketTreeElement[];
  isFolder: boolean;

  constructor(name: string, parent: BucketTreeElement | undefined, isFolder: boolean) {
    this.name = name;
    this.parent = parent;
    this.children = [];
    this.isFolder = isFolder;
  }

  addChild(childName: string, isFolder: boolean): BucketTreeElement {
    const newChild = new BucketTreeElement(childName, this, isFolder);
    // we cannot return newChild directly cause it will provide incorrect reference to the element (outside of the tree hierarchy)
    // so we save index where it was inserted into and return pointer to an array element
    let childIndex = 0;
    if (this.children.length == 0) {
      this.children.push(newChild);
      return this.children[childIndex];
    }
    for (let i = 0; i < this.children.length; i++) {
      const currentChildName = this.children[i].name;
      if (currentChildName == childName) {
        childIndex = i;
        break;
      }
      if (currentChildName > childName) {
        childIndex = i;
        this.children.splice(i, 0, newChild);
        break;
      }
      if (i == this.children.length - 1) {
        childIndex = this.children.push(newChild) - 1;
        break;
      }
    }
    return this.children[childIndex];
  }

  removeChild(childName: string): void {
    let childIndex = -1;
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].name == childName) {
        childIndex = i;
        break;
      }
    }
    if (childIndex != -1) {
      this.children.splice(childIndex, 1);
    }
  }

  getChild(childName: string): BucketTreeElement | undefined {
    return this.children.find((element) => element.name == childName);
  }

  getFullPath(): string {
    return (this.parent ? this.parent.getFullPath() : '') + this.name + (this.isFolder ? '/' : '');
  }

  getHierarchyList(): string[] {
    if (this.children.length == 0) {
      return [];
    }
    let result: string[] = [];
    this.children.forEach((child) => {
      result.push(child.getFullPath());
      result = [...result, ...child.getHierarchyList()];
    });
    return result;
  }
}

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ Bucket files tree ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// -----------------------------------------------------------------------------------------------------------

// -------------------------------------------- Files tree helpers --------------------------------------------
// ------------------------------------------------------------------------------------------------------------

export function splitObjectPath(fullPath: string): { bucketName: string; objectPath: string } {
  const slashIndex = fullPath.indexOf('/');
  return {
    bucketName: fullPath.slice(0, slashIndex),
    objectPath: fullPath.slice(slashIndex + 1),
  };
}

export function buildBucketTree(objectsList: Z3Object[], bucketName: string): BucketTreeElement {
  const objects = [...objectsList];
  const root: BucketTreeElement = new BucketTreeElement(bucketName, undefined, true);
  if (objects.length == 0) {
    return root;
  }
  if (objects[0].key == bucketName + '/') {
    delete objects[0];
  }
  objects.forEach((object) => {
    const isFolder = object.key.endsWith('/');
    let target = root;
    const objectPath = object.key
      .replace(root.name + '/', '') // remove root from path
      .replace(/\/$/, '') // remove last slash to avoid adding invalid child to empty folders
      .split('/');
    const lastIndex = objectPath.length - 1;
    objectPath.forEach((pathElement, index) => {
      target = target.addChild(pathElement, index != lastIndex ? true : isFolder);
    });
  });
  return root;
}

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ Files tree helpers ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// -----------------------------------------------------------------------------------------------------------

// ----------------------------------------------S3 interactions----------------------------------------------
// -----------------------------------------------------------------------------------------------------------

interface PresignedURLResponse {
  signedUrl: string;
}

export async function createFolder(
  folderName: string,
  currentFolder: BucketTreeElement | undefined,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  setLoadingState(true);
  const folderFullPath = currentFolder?.getFullPath() + folderName;
  const { bucketName, objectPath: folderPath } = splitObjectPath(folderFullPath);
  let uploadLink = '';
  await zapehr.project.z3
    .getPresignedUrl({ action: 'upload', bucketName, 'objectPath+': folderPath, isFolder: true })
    .then((data: PresignedURLResponse) => {
      uploadLink = data.signedUrl;
    })
    .catch((error: any) => {
      toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      setLoadingState(false);
    });
  if (!uploadLink) {
    return;
  }
  await fetch(uploadLink, { method: 'PUT' })
    .then((response) => {
      if (response.ok) {
        toast.success('Folder was created successfully');
        currentFolder?.addChild(folderName, true);
      } else {
        toast.error(`An error occurred: ${response.statusText || 'Unknown error'}`);
      }
    })
    .catch((error) => {
      toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
    })
    .finally(() => setLoadingState(false));
}

export async function uploadFile(
  filesList: File | null | undefined,
  currentFolder: BucketTreeElement | undefined,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  if (!filesList) {
    return;
  }
  setLoadingState(true);
  const file = filesList;
  if (!file) {
    return;
  }
  const fileFullPath = currentFolder?.getFullPath() + file.name;
  const { bucketName, objectPath: filePath } = splitObjectPath(fileFullPath);
  let uploadLink = '';
  await zapehr.project.z3
    .getPresignedUrl({ action: 'upload', bucketName, 'objectPath+': filePath })
    .then((data: PresignedURLResponse) => {
      uploadLink = data.signedUrl;
    })
    .catch((error: any) => {
      toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      setLoadingState(false);
    });
  if (!uploadLink) {
    return;
  }
  await fetch(uploadLink, { method: 'PUT', body: file })
    .then(() => {
      toast.success('File was uploaded successfully');
      currentFolder?.addChild(file.name, false);
    })
    .catch((error) => {
      toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
    })
    .finally(() => {
      setLoadingState(false);
    });
}

export async function downloadFile(
  name: string,
  currentFolder: BucketTreeElement | undefined,
  setObjectForDownload: React.Dispatch<React.SetStateAction<string>>
): Promise<void> {
  setObjectForDownload(name);
  const fileFullPath = currentFolder?.getFullPath() + name;
  const { bucketName, objectPath: filePath } = splitObjectPath(fileFullPath);
  const presignedURL = await zapehr.project.z3.getPresignedUrl({
    action: 'download',
    bucketName,
    'objectPath+': filePath,
  });
  if (presignedURL) {
    let downloadResponse;
    try {
      downloadResponse = await fetch(presignedURL.signedUrl, { method: 'GET' });
    } catch (error) {
      setObjectForDownload('');
      toast.error(`An error occured: ${error}`);
      return;
    }

    if (!downloadResponse || downloadResponse.status != 200) {
      setObjectForDownload('');
      toast.error(`An error occured: ${downloadResponse ? downloadResponse.statusText : 'unknown error'}`);
      return;
    }

    const blob = await downloadResponse.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${name.endsWith(':') ? name.substring(0, name.length - 1) : name}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  }
  setObjectForDownload('');
}

export function deleteObject(
  objectToDelete: BucketTreeElement,
  setObjectForDeletion: React.Dispatch<React.SetStateAction<BucketTreeElement | undefined>>,
  setLoasingState: React.Dispatch<React.SetStateAction<boolean>>
): void {
  setObjectForDeletion(undefined);
  setLoasingState(true);
  if (!objectToDelete) {
    return;
  }
  const { bucketName, objectPath } = splitObjectPath(objectToDelete.getFullPath());

  let objectChildren: string[] | undefined;
  if (objectToDelete.isFolder) {
    objectChildren = objectToDelete.getHierarchyList();
  }

  zapehr.project.z3
    .deleteObject({ bucketName, 'objectPath+': objectPath, children: objectChildren })
    .then(() => {
      toast.success('Object was deleted successfully');
      objectToDelete.parent?.removeChild(objectToDelete.name);
    })
    .catch((error: any) => {
      toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
    })
    .finally(() => {
      setLoasingState(false);
    });
}

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ S3 interactions ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// -----------------------------------------------------------------------------------------------------------
