import { capitalize, Skeleton, useTheme } from '@mui/material';
import * as React from 'react';
import { useResourceEditingContext } from '../../contexts/ResourceEditContextProvider';
import { Coding } from '../../lib/fhir-types';
import { PropertyDefinition } from '../../lib/schema';
import { FhirFormAutocomplete } from '../WrappedInputs/FhirFormAutocomplete';
import { getValueSetName } from './common';

export interface CodingInputProps {
  propertyDefinition: PropertyDefinition;
  value?: Coding;
  onChange: (newVal: Coding | undefined) => void;
}

export function CodingInput(props: CodingInputProps): JSX.Element {
  const { propertyDefinition, value, onChange } = props;
  const { editModeOn, useValueSetOptions } = useResourceEditingContext();
  const { options, getValueMatchingCode, isLoadingValueSet } = useValueSetOptions(
    getValueSetName(propertyDefinition.valueSet ?? '')
  );
  const theme = useTheme();

  return isLoadingValueSet ? (
    <Skeleton
      variant="text"
      width={200}
      animation="wave"
      sx={{
        display: 'inline-flex',
        bgcolor: theme.palette.primary.light,
      }}
    />
  ) : (
    <FhirFormAutocomplete
      optionsList={options}
      value={value?.code}
      label={capitalize(propertyDefinition.name)}
      sx={{ width: '100%' }}
      onChange={(code) => {
        const element = getValueMatchingCode(code?.value ?? '');
        if (element) {
          onChange({
            system: element.system,
            version: element.version,
            code: element.code,
            display: element.display,
          });
        } else {
          onChange(undefined);
        }
      }}
      disabled={!editModeOn}
    />
  );
}
