import zapehr from '@zapehr/sdk';
import { DateTime } from 'luxon';
import { LogEvent } from '../../../../lib/client';
import { assert } from '../../../../lib/utils';

interface FilterEventsProps {
  id: string | undefined;
  filter?: string;
  start?: DateTime;
  end?: DateTime;
  nextToken?: string;
}

export async function filterEvents({
  id,
  filter,
  start,
  end,
  nextToken,
}: FilterEventsProps): Promise<{ newEvents: LogEvent[]; nextToken: string | undefined }> {
  assert(id, 'No Zambda ID in request url');
  const startUtc = start ? convertToUtc(start).toMillis() : undefined;
  const endUtc = end ? convertToUtc(end).toMillis() : undefined;
  const newEvents: LogEvent[] = [];
  let newToken: string | undefined = nextToken;
  // As long as there are results to return, return them. If there are no more results, exit out of the loop.
  do {
    const response: Awaited<ReturnType<typeof zapehr.project.zambda.logStream.search>> =
      await zapehr.project.zambda.logStream.search({
        id,
        start: startUtc,
        end: endUtc,
        filter: filter?.length ? filter : undefined,
        nextToken: newToken,
      });
    newEvents.push(...response.logEvents);
    newToken = response.nextToken;
  } while (newEvents.length === 0 && newToken != null);
  return { newEvents, nextToken: newToken };
}

const convertToUtc = (datetime: DateTime): DateTime => {
  return datetime.setZone('UTC', { keepLocalTime: true });
};
