import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Button, Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Suspense, useMemo, useRef, useState } from 'react';
import { Await, useLoaderData, useLocation } from 'react-router-dom';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { Breadcrumbs } from '../../../components/Z3Components/BreadcrumbsComponent';
import { CreateFolderDialog } from '../../../components/Z3Components/CreateFolderDialog';
import { FileInputButton, FileInputDropWrapper } from '../../../components/Z3Components/FileInputComponent';
import { Z3Object } from '../../../lib/client';
import { BodyElement } from './lib/BodyElement';
import { BucketTreeElement, buildBucketTree, createFolder, uploadFile } from './lib/Z3ObjectsHelpers';

export function Z3ObjectsPage(): JSX.Element {
  const pathname = decodeURI(useLocation().pathname);
  const bucketName = (pathname as string).split('/')[2];
  const dataPromise = useLoaderData() as { objects: Promise<Z3Object[]> };
  const [rootFolder, setRootFolder] = useState<BucketTreeElement>();
  const [currentFolder, setCurrentFolder] = useState<BucketTreeElement>();
  const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] = useState(false);
  const [bodyLoading, setBodyLoading] = useState(false);
  const fileInputButtonContainer = useRef<HTMLDivElement>(null);

  useMemo(() => {
    const pathParts = pathname.split('/');
    if (!rootFolder) {
      return;
    }

    let folder: BucketTreeElement | undefined = rootFolder;
    // ['', 'z3', '{bucketName}', ...folders]
    if (pathParts.length > 3) {
      for (let i = 3; i < pathParts.length; i++) {
        folder = folder?.getChild(pathParts[i]);
      }
    }
    setCurrentFolder(folder);
  }, [pathname, rootFolder]);

  const onFolderCreationSubmit = async (name: string): Promise<void> => {
    setIsCreateFolderDialogOpen(false);
    await createFolder(name, currentFolder, setBodyLoading);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">{bucketName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Manage objects of selected bucket with Platform API access</Typography>
        </Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs currentFolder={currentFolder} setCurrentFolder={setCurrentFolder} />
          <div style={{ display: 'flex', columnGap: '20px' }}>
            <Button
              id="btnCreateFolder"
              aria-label="new"
              variant="contained"
              startIcon={<CreateNewFolderIcon />}
              onClick={() => {
                document.getElementById('btnCreateFolder')?.blur();
                setIsCreateFolderDialogOpen(true);
              }}
            >
              Add Folder
            </Button>
            <div ref={fileInputButtonContainer}>
              <FileInputButton fileInputCallback={(file: File) => uploadFile(file, currentFolder, setBodyLoading)} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ height: '60vh', marginTop: 4 }}>
            <Suspense fallback={<BodyElement loading currentFolder={undefined} setCurrentFolder={setCurrentFolder} />}>
              <Await resolve={dataPromise.objects} errorElement={<RootErrorBoundary />}>
                {(objects) => {
                  if (!rootFolder) {
                    setRootFolder(buildBucketTree(objects, bucketName));
                  }
                  return (
                    <FileInputDropWrapper
                      fileInputCallback={(file: File) => uploadFile(file, currentFolder, setBodyLoading)}
                      dragStateTrigger={fileInputButtonContainer.current}
                    >
                      <BodyElement
                        loading={bodyLoading}
                        currentFolder={currentFolder}
                        setCurrentFolder={setCurrentFolder}
                      />
                    </FileInputDropWrapper>
                  );
                }}
              </Await>
            </Suspense>
          </Paper>
        </Grid>
      </Grid>
      {isCreateFolderDialogOpen ? (
        <CreateFolderDialog
          currentLocation={currentFolder?.getFullPath()}
          handleClose={() => setIsCreateFolderDialogOpen(false)}
          handleSubmit={onFolderCreationSubmit}
        />
      ) : (
        ''
      )}
    </div>
  );
}
