import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, Button, Grid, Paper, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Suspense, useMemo, useState } from 'react';
import { Await, Link, Outlet, useLoaderData, useLocation, useRevalidator } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { RootErrorBoundary } from '../../../components/RootErrorBoundary';
import { otherColors } from '../../../contexts/AdminThemeProvider';
import { Z3Bucket } from '../../../lib/client';
import { Services } from '../../../services';

export function Z3BucketsPage(): JSX.Element {
  const { pathname } = useLocation();
  const dataPromise = useLoaderData() as { buckets: Promise<Z3Bucket[]> };

  return (
    <div>
      {pathname === `/${Services.z3.rootPath}` ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">Buckets</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Manage buckets with Platform API access</Typography>
          </Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
            <Link to={`/${Services.z3.rootPath}/new`}>
              <Button aria-label="new" variant="contained" startIcon={<AddIcon />}>
                Create
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ height: '60vh', marginTop: 4 }}>
              <Suspense fallback={<BodyElement buckets={[]} loading />}>
                <Await resolve={dataPromise.buckets} errorElement={<RootErrorBoundary />}>
                  {(buckets) => {
                    return <BodyElement buckets={buckets} loading={false} />;
                  }}
                </Await>
              </Suspense>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

interface BodyProps {
  buckets: Z3Bucket[];
  loading: boolean;
}

const BodyElement: React.FC<BodyProps> = ({ buckets, loading }) => {
  const bucketsList: Z3Bucket[] = useMemo(() => {
    return buckets;
  }, [buckets]);
  const [bucketForDeletion, setBucketForDeletion] = useState('');
  const [loadingInternal, setLoadinginternal] = useState(false);
  const revalidator = useRevalidator();

  const confirmBucketDeletion = (): void => {
    setLoadinginternal(true);
    zapehr.project.z3
      .deleteBucket({ bucketName: bucketForDeletion })
      .then(() => {
        // remove bucket from the list shown on the page
        bucketsList.splice(
          bucketsList.findIndex((bucket) => bucket.name == bucketForDeletion),
          1
        );
        revalidator.revalidate();
        toast.success('Bucket deleted');
      })
      .catch((error) => {
        toast.error(`An error occurred: ${JSON.stringify(error.message) || 'Unknown error'}`);
      })
      .finally(() => {
        setBucketForDeletion('');
        setLoadinginternal(false);
      });
  };

  // making sure that buckets is not undefined
  buckets = buckets ? buckets : [];
  return (
    <>
      <DataGridPro
        loading={loading || loadingInternal}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => <Link to={params.row.name}>{params.row.name}</Link>,
          },
          { field: 'id', headerName: 'UUID', flex: 1.5 },
          {
            field: 'delete',
            headerName: 'Delete',
            headerAlign: 'center',
            renderCell: (params) => (
              <div
                style={{ margin: 'auto', textAlign: 'center', cursor: 'pointer' }}
                onClick={() => setBucketForDeletion(params.row.name)}
              >
                <DeleteIcon style={{ color: '#D32F2F' }} />
              </div>
            ),
          },
        ]}
        rows={bucketsList.map((bucket: Z3Bucket) => ({
          id: bucket.id,
          name: bucket.name,
        }))}
        components={{ Toolbar: GridToolbar }}
        // todo remove code duplication with SearchControl
        sx={{
          '& .MuiDataGrid-toolbarContainer': {
            pl: 1.5,
            gap: 4,
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
            '& .MuiButtonBase-root': {
              color: otherColors.charcoal87,
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
          },
        }}
      />
      {bucketForDeletion && !loadingInternal && (
        <ConfirmationDialog
          handleAction={confirmBucketDeletion}
          open={bucketForDeletion != ''}
          buttonTitle="Delete"
          handleClose={() => setBucketForDeletion('')}
        />
      )}
    </>
  );
};
