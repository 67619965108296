import * as React from 'react';
import { ResourceForm } from '../../../../components/ResourceForm';
import { useResourceFromOutletContext } from '..';

export function ResourceEdit(): JSX.Element {
  const { resource, onSubmit, onDelete } = useResourceFromOutletContext();
  // would like to make this "editable", get rid of details/edit distinction and just have an
  // edit button
  return <ResourceForm defaultValue={resource} onSubmit={onSubmit} onDelete={onDelete} formMode={'edit_only'} />;
}
