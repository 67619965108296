import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import BreadcrumbsBasic from '../../../../components/BreadCrumbsBasic';
import { InputField, PathHistoryBreadcrumb } from '../../../../lib/types';
import { ZambdaForms } from '../lib/ZambdaForms';

const prevPaths: PathHistoryBreadcrumb[] = [{ text: 'Zambdas', from: '/zambdas' }];

export function CreateZambda(): JSX.Element {
  const fields: InputField[] = [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'My_New_Zambda',
      required: true,
    },
    {
      name: 'triggerMethod',
      label: 'Trigger method',
      defaultValue: 'http_auth',
      required: true,
      isSelect: true,
    },
    {
      name: 'expression',
      label: 'Expression',
      required: false,
      defaultValue: undefined,
      disabled: true,
    },
    {
      name: 'start',
      label: 'Start',
      required: false,
      defaultValue: undefined,
      disabled: true,
    },
    {
      name: 'end',
      label: 'End',
      required: false,
      defaultValue: undefined,
      disabled: true,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BreadcrumbsBasic prevPaths={prevPaths} currPathText="Create Zambda"></BreadcrumbsBasic>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" color="text.primary">
          Create Zambda
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ZambdaForms method="create" zambdaFields={fields} />
      </Grid>
    </Grid>
  );
}
