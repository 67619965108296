import { Box } from '@mui/material';
import * as React from 'react';
import { FC, ReactNode, useState } from 'react';
import { AdminSidebar, AdminSidebarItem } from './AdminSidebar';

interface PageContainerProps {
  children: ReactNode;
  sidebarItems?: AdminSidebarItem[][];
}

export const PageContainer: FC<PageContainerProps> = ({ children, sidebarItems }) => {
  const [adminSidebarOpen, setAdminSidebarOpen] = useState(true);

  return (
    <Box
      id="content_container"
      sx={{
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      {sidebarItems ? (
        <AdminSidebar
          adminSidebarOpen={adminSidebarOpen}
          setAdminSidebarOpen={setAdminSidebarOpen}
          children={children}
          sidebarItems={sidebarItems}
        />
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};
