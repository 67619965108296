import { json } from '@codemirror/lang-json';
import { syntaxTree } from '@codemirror/language';
import { Diagnostic, linter, lintGutter } from '@codemirror/lint';
import { FormatAlignLeft } from '@mui/icons-material';
import { Button, Grid, TextField, Typography } from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import * as React from 'react';
import { toast } from '../lib/toast';
import { prettyJSON } from '../lib/utils';
import { AccessPolicyError } from '../pages/IAM/Developers/DeveloperDetail';
import { blankAccessPolicies, BlankAccessPolicyButton } from './BlankAccessPolicyButton';

export interface AccessPolicyInputConfig {
  label: string;
  helperText: string;
}
interface props {
  config: AccessPolicyInputConfig;
  // defaultValue: string | undefined;
  accessPolicy: string | undefined;
  setAccessPolicy: (data: string | undefined) => void;
  accessPolicyErrors: AccessPolicyError[];
}

export const JSON_INVALID_ERROR_MESSAGE = 'JSON is not valid';

// https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
const CustomReactCodeMirror = React.forwardRef((editorProps: any, ref) => {
  const {
    component: Component,
    onChange,
    setAccessPolicy,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    accessPolicyErrors,
    value: accessPolicy,
    ...customEditorProps
  } = editorProps;
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      // component.editor.focus();
      // Component.focus();
      console.log('test');
    },
  }));

  const accessPolicyLinter = linter((view) => {
    const diagnostics: Diagnostic[] = [];
    // diagnostics.push({
    //   from: 40,
    //   to: 50,
    //   severity: 'warning',
    //   message: 'example',
    // });

    const document = view.state.doc;
    console.log(1, document);
    syntaxTree(view.state)
      .cursor()
      .iterate((node) => {
        const attribute = view.state.doc.sliceString(node.from, node.to);
        // try {
        //   // console.log(1, node, JSON.parse(attribute));
        //   const path = [];
        //   let parent = node.node.parent;
        //   // console.log(1, node.type, attribute);
        //   while (parent && parent.node.parent) {
        //     // path.push(JSON.parse(attribute));
        //     parent = parent.node.parent;
        //     console.log(JSON.parse(view.state.doc.sliceString(parent.from, parent.to)));
        //     // console.log(2, parent.type, JSON.parse(view.state.doc.sliceString(parent.from, parent.to)));
        //   }
        //   console.log(path);
        // } catch (error) {
        // }
        // console.log(attribute);
        accessPolicyErrors.forEach((accessPolicyError: AccessPolicyError) => {
          // parse and then stringify so it is in a consistent format
          // with accessPolicyError
          let parseJSON = undefined;
          try {
            parseJSON = JSON.parse(attribute);
          } catch (error) {
            return;
          }

          if (JSON.stringify(parseJSON) === JSON.stringify(accessPolicyError.attribute)) {
            diagnostics.push({
              from: node.from,
              to: node.to,
              severity: accessPolicyError.type,
              message: accessPolicyError.message,
            });
          }
        });
      });
    console.log(4, diagnostics);
    return diagnostics;
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Component
          value={accessPolicy}
          {...customEditorProps}
          extensions={[json(), lintGutter(), accessPolicyLinter]}
          onChange={onChange}
          style={{ width: 'auto' }}
        />
      </Grid>
      <Grid item xs={12} marginLeft={5.8} marginBottom={2}>
        <Button
          variant="contained"
          startIcon={<FormatAlignLeft />}
          onClick={() => {
            let parsedJSON = undefined;

            try {
              parsedJSON = JSON.parse(accessPolicy);
            } catch (error) {
              toast.error(JSON_INVALID_ERROR_MESSAGE);
              return;
            }
            const formattedJSON = prettyJSON(parsedJSON);
            setAccessPolicy(formattedJSON);
          }}
        >
          Format JSON
        </Button>
        {blankAccessPolicies.includes(accessPolicy) && <BlankAccessPolicyButton setAccessPolicy={setAccessPolicy} />}

        {accessPolicyErrors.length > 0 && (
          <>
            <Typography color="warning.main" variant="h5" marginTop={2}>
              Messages
            </Typography>
            <ul style={{ marginTop: 0 }}>
              {accessPolicyErrors.map((error: AccessPolicyError) => (
                <li>
                  Type: {error.type}, Message: {error.message}
                </li>
              ))}
            </ul>
          </>
        )}
      </Grid>
    </Grid>
  );
});

export const isValidJSON = (value: string | undefined): object | boolean => {
  if (!value) {
    return false;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return false;
  }
};

interface AccessPolicyInput {
  text: string[];
  length: number;
}

export function AccessPolicyInput({ accessPolicy, config, setAccessPolicy, accessPolicyErrors }: props): JSX.Element {
  const onChange = React.useCallback(
    (value: any) => {
      console.log('access policy onChange', value);
      if (!value) {
        console.log('access policy undefined');
        setAccessPolicy(undefined);
      } else if (typeof value === 'string') {
        setAccessPolicy(value);
      } else if (value.text !== undefined) {
        const { text } = value as AccessPolicyInput;
        setAccessPolicy(text.join('\n'));
      }
    },
    [setAccessPolicy]
  );

  return (
    <TextField
      label={config.label}
      helperText={isValidJSON(accessPolicy) ? config.helperText : 'Invalid JSON'}
      InputProps={{
        inputComponent: CustomReactCodeMirror,
        inputProps: {
          component: ReactCodeMirror,
          onChange: onChange,
          setAccessPolicy: setAccessPolicy,
          accessPolicyErrors: accessPolicyErrors,
          value: accessPolicy,
        },
      }}
      error={!isValidJSON(accessPolicy)}
      InputLabelProps={{ shrink: true }}
      fullWidth
      sx={{ '& .cm-theme-light': { height: '100%' } }}
      // sx={{ width: '100%', height: '100%' }}
    />
  );
}
