import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SearchButton } from '../../../../components';
import { formatDateTimeUTC } from '../../../../helpers';
import { LogStream } from '../../../../lib/client';

export function ZambdaLogStreams(): JSX.Element {
  const { id: zambdaId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentLogStreams, setCurrentLogStreams] = React.useState<readonly LogStream[]>([]);
  const [tokens, setTokens] = React.useState<string[]>(['']);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getLogStreamsAndNextTokenForPage = async (
    pageNumber: number
  ): Promise<{ newLogStreams: readonly LogStream[]; nextToken: string | undefined }> => {
    if (!zambdaId) {
      throw new Error('No Zambda ID in request url');
    }
    const response = await zapehr.project.zambda.logStream.list({
      id: zambdaId,
      token: tokens[pageNumber] !== '' ? tokens[pageNumber] : undefined,
    });
    return { newLogStreams: response.logStreams, nextToken: response.nextToken };
  };

  const handleRefresh = async (): Promise<void> => {
    setIsLoading(true);
    const { newLogStreams, nextToken } = await getLogStreamsAndNextTokenForPage(0);
    setCurrentLogStreams(newLogStreams);
    setPageNumber(0);
    setTokens(nextToken ? ['', nextToken] : ['']);
    setIsLoading(false);
  };

  // fetch log streams for the first page
  React.useEffect(() => {
    const fetchLogStreams = async (): Promise<void> => {
      if (!zambdaId) {
        return;
      }
      setIsLoading(true);
      const response = await zapehr.project.zambda.logStream.list({
        id: zambdaId,
        token: tokens[pageNumber] !== '' ? tokens[pageNumber] : undefined,
      });
      setCurrentLogStreams(response.logStreams);
      if (response.nextToken) {
        setTokens([...tokens, response.nextToken]);
      }
      setIsLoading(false);
    };
    if (zambdaId && currentLogStreams.length === 0) {
      void fetchLogStreams();
    }
  }, [currentLogStreams.length, pageNumber, tokens, zambdaId]);

  const handlePagination = async (pageNumber: number, moveRight: boolean): Promise<void> => {
    setIsLoading(true);

    if ((pageNumber === tokens.length - 1 && moveRight) || (pageNumber === 0 && !moveRight)) {
      // if we are on the last page and the user tries to navigate to the next page, or we are
      // on the first page and the user tries to navigate to the previous page, do nothing
      return;
    }
    pageNumber += moveRight ? 1 : -1;
    setPageNumber(pageNumber);
    const { newLogStreams, nextToken } = await getLogStreamsAndNextTokenForPage(pageNumber);
    setCurrentLogStreams(newLogStreams);
    if (moveRight && pageNumber == tokens.length - 1 && nextToken && nextToken !== tokens[pageNumber]) {
      // add new token to the list
      setTokens([...tokens, nextToken]);
    }
    setIsLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', mt: -3, mb: 1, gap: 1, justifyItems: 'space-between' }}>
        <SearchButton onClick={() => navigate('search')} theme={theme} />
        <Button
          sx={{
            bgcolor: theme.palette.primary.main + '1F',
            color: theme.palette.primary.dark,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            textTransform: 'none',
          }}
          onClick={async () => await handleRefresh()}
          endIcon={<ReplayIcon />}
        >
          Refresh
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, td: { paddingBottom: 0, paddingTop: 0 }, tr: { height: '40px' } }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell width={'50%'} sx={{ fontWeight: 600 }}>
                Log stream
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Last event time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              renderLoadingSpinner()
            ) : currentLogStreams.length === 0 ? (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={2}>No log streams found.</TableCell>
              </TableRow>
            ) : (
              <>
                {currentLogStreams.map((logStream: LogStream) => (
                  <TableRow key={logStream.logStreamName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <Link to={`/zambdas/${zambdaId}/logs/${encodeURIComponent(logStream.logStreamName)}`}>
                        ${logStream.logStreamName}
                      </Link>
                    </TableCell>
                    <TableCell>{formatDateTimeUTC(logStream.lastEventTimestamp)}</TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {/* logStream pagination buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 1 }}>
          <IconButton
            disabled={pageNumber === 0}
            onClick={async () => {
              await handlePagination(pageNumber, false);
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography sx={{ mx: 2 }}>{pageNumber + 1}</Typography>
          <IconButton
            disabled={pageNumber === tokens.length - 1}
            onClick={async () => {
              await handlePagination(pageNumber, true);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </TableContainer>
    </Box>
  );
}

const renderLoadingSpinner = (): JSX.Element => {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={25} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
