import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography } from '@mui/material';
import zapehr from '@zapehr/sdk';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { Application } from '../../../../lib/client';
import { toast } from '../../../../lib/toast';
import { commaSeparatedStringToArray } from '../../../../lib/utils';
import { Services } from '../../../../services';
import { ApplicationFields, ApplicationFormBody, mapAppToAppFields } from '../lib/ApplicationFormBody';

const emptyApplication: Application = { id: '', name: '', clientId: '', description: '' };

export function CreateApplicationPage(): JSX.Element {
  const formReturn: UseFormReturn<ApplicationFields> = useForm({
    values: mapAppToAppFields(emptyApplication),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formReturn;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmit = useCallback(
    (data: ApplicationFields): void => {
      if (loading) {
        return;
      }
      setLoading(true);

      zapehr.project.application
        .create({
          ...data,
          loginRedirectUri: data.loginRedirectUri || undefined,
          allowedCallbackUrls: commaSeparatedStringToArray(data.allowedCallbackUrls),
          allowedCORSOriginsUrls: commaSeparatedStringToArray(data.allowedCORSOriginsUrls),
          allowedWebOriginsUrls: commaSeparatedStringToArray(data.allowedWebOriginsUrls),
          allowedLogoutUrls: commaSeparatedStringToArray(data.allowedLogoutUrls),
          logoUri: data.logoUri || undefined,
        })
        .then(() => {
          toast.success('Client successfully created');
          revalidator.revalidate();
          navigate(`/${Services.app.rootPath}`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`An error occurred: ${error.message || 'Unknown error'}`);
        });
    },
    [loading, navigate, revalidator]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" mt={4} mb={5} color="text.primary">
          Create Application
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={4} padding={2}>
              {errors.description && (
                <Grid item>
                  <Typography variant="body1" color="error" role="alert">
                    {errors.description?.message?.toString()}
                  </Typography>
                </Grid>
              )}
              <ApplicationFormBody formUtils={formReturn} loading={false} />
              <Grid item textAlign="center">
                <LoadingButton type="submit" variant="contained" sx={{ width: '200px' }} loading={loading}>
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
