import { Typography } from '@mui/material';
import zapehr, { ZapEHRFHIRError } from '@zapehr/sdk';
import { FhirResource } from 'fhir/r4b';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, TitleBar } from '../../../components/index';
import { ResourceForm } from '../../../components/ResourceForm';
import { Resource } from '../../../lib/fhir-types';
import { Services } from '../../../services';

interface PageState {
  resource: Resource;
  errors?: string[];
}

export function CreateResourcePage(): JSX.Element {
  const navigate = useNavigate();
  const { resourceType } = useParams() as {
    resourceType: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState<PageState>({ resource: { resourceType } });
  const onSubmit = useCallback(
    async (formData: Resource): Promise<void> => {
      try {
        const result = await zapehr.fhir.create(formData as FhirResource);
        navigate(`/${Services.fhir.rootPath}/${result.resourceType}/${result.id}`);
      } catch (e: any) {
        const err = e as ZapEHRFHIRError;
        console.log('Error creating resource!', err);
        const errors: string[] = err.cause.issue[0].expression?.map(
          (field: any) => `${err.cause.issue[0].details.text} for ${field}`
        );
        setState({ resource: formData, errors });
      }
    },
    [navigate]
  );

  return (
    <>
      <TitleBar>
        <Typography variant="body1" fontWeight={'bold'}>
          New&nbsp;{resourceType}
        </Typography>
      </TitleBar>
      <Document>
        <ResourceForm errors={state.errors} defaultValue={state.resource} onSubmit={onSubmit} formMode="edit_only" />
      </Document>
    </>
  );
}
