import { BreakpointsOptions, Components, createTheme, PaletteColor, ThemeProvider } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import * as React from 'react';
import { FC, ReactNode } from 'react';

export const fonts = ['Work Sans'];

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 400,
    md: 700,
    lg: 1000,
    xl: 1400,
  },
};

export const typography: TypographyOptions = {
  fontFamily: fonts.join(','),
  h1: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-1px',
    // [`@media screen and (max-width: ${breakpoints.values!.sm}px)`]: {
    //   fontSize: 28
    // }
  },
  h2: {
    fontSize: 60,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    // [`@media screen and (max-width: ${breakpoints.values!.sm}px)`]: {
    //   fontSize: 22
    // }
  },
  h3: {
    fontSize: 48,
    fontWeight: 500,
    letterSpacing: '0px',
  },
  h4: {
    fontSize: 34,
    fontWeight: 500,
    letterSpacing: '0.25px',
  },
  h5: {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: '0px',
  },
  h6: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.1px',
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  button: {},
  caption: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.40px',
  },
  overline: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '1px',
  },
  fontWeightMedium: 500,
};

export const components: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        '&:not($sizeLarge):not($sizeSmall) $label': {
          fontSize: 14,
          fontWeight: 500,
          textTransform: 'capitalize',
          letterSpacing: '0.46px',
        },
      },
      sizeLarge: {
        '& $label': {
          fontSize: 15,
          letterSpacing: '0.40px',
        },
      },
      sizeSmall: {
        '& $label': {
          fontSize: 13,
          letterSpacing: '0.40px',
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
};

export const otherColors = {
  border: '#999999',
  clear: 'rgba(255,255,255,0.0)',
  destructiveWarningBackground: 'rgba(255,70, 70, 0.2)',
  inputBackground: '#2F4359',
  zambdaPending: '#ED6C02',
  zambdaFailed: '#D32F2F',
  zambdaDraft: '#2896C6',
  zambdaActive: '#2E7D32',
  zambdaInactive: 'rgba(0, 0, 0, 0.08)',
  zambdaValidating: '266AD1',
  zambdaFileAvatarBackground: 'rgba(40, 150, 198, 0.12)',
  zambdaFileAvatarBackgroundError: 'rgba(211, 47, 47, 0.04)',
  patientMale: '#79A3D2',
  patientFemale: '#C58686',
  patientOther: '#6CB578',
  resource1: '#66BB6A',
  resource2: '#99C8FF',
  resource3: '#3D5AFE',
  resource4: '#FF8F00',
  resource5: '#FF3D00',
  charcoal87: 'rgba(50, 63, 83, 0.87)',
  cornflowerBlue04: 'rgba(40, 150, 198, 0.04)',
  cornflowerBlue12: 'rgba(40, 150, 198, 0.12)',
  black23: 'rgba(0, 0, 0, 0.23)',
  gray87: 'rgba(0, 0, 0, 0.87)',
  white12: 'rgba(255, 255, 255, 0.12)',
  white50: 'rgba(255, 255, 255, 0.50)',
  white80: 'rgba(255, 255, 255, 0.80)',
  idBackground: '#E7E9EC',
  DarkPrimaryShades16p: 'rgba(144, 202, 249, 0.16)',
  homePageSideBarBackground:
    'linear-gradient(208deg, rgba(58, 147, 186, 0.42) 0%, rgba(48, 62, 188, 0.00) 97.4%), #263954',
  applicationsIconBackground: 'linear-gradient(207deg, #87F6C7 8.24%, #31827D 86.28%)',
  fhirIconBackground: 'linear-gradient(199deg, #87D2F6 10.76%, #2C6F8C 89.24%)',
  iamIconBackground: 'linear-gradient(199deg, #FDCA7E 10.76%, #CD511B 89.24%)',
  messagingIconBackground: 'linear-gradient(206deg, #B6CAFF 8.26%, #5E5BE5 90.1%)',
  zambdasIconBackground: 'linear-gradient(207deg, #E78CEF 15.31%, #7C3EA1 87.62%)',
  z3IconBackground: 'linear-gradient(207deg, #ea9a80 15.31%, #b8002e 87.62%)',
  infoCardsIconBackground: 'linear-gradient(207deg, #87D2F6 15.31%, #2C6F8C 87.62%)',
  gray800: '#424242',
  gray200: '#eeeeee',
};

// https://stackoverflow.com/questions/50069724/how-to-add-custom-mui-palette-colors
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColor;
  }
}
const { palette: p } = createTheme(); // TODO: once https://github.com/mui/material-ui/issues/17410 is resolved, export directly from mui
export const palette = {
  text: {
    primary: 'rgba(50, 63, 83, 0.87)',
    secondary: 'rgba(50, 63, 83, 0.6)',
    disabled: 'rgba(50, 63, 83, 0.38)',
  },
  primary: {
    main: '#2896C6',
    dark: '#1D7BA4',
    light: '#4AC0F2',
    contrast: '#FFFFFF',
  },
  secondary: {
    main: '#266AD1',
    dark: '#1B54AC',
    light: '#3D89FD',
    contrast: '#FFFFFF',
  },
  tertiary: p.augmentColor({ color: { main: '#202A3E' } }),
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    contrast: '#FFFFFF',
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    contrast: '#FFFFFFF',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrast: '#FFFFFFF',
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    contrast: '#FFFFFFF',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};
interface AdminThemeProviderProps {
  children: ReactNode;
}

export const AdminThemeProvider: FC<AdminThemeProviderProps> = ({ children }) => {
  const theme = createTheme({
    palette: palette,
    components: components,
    direction: 'ltr',
    breakpoints: breakpoints,
    typography: typography,
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
