import { Box } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';
import { otherColors } from '../contexts/AdminThemeProvider';

export interface TitleBarProps {
  children?: ReactNode;
}

export function TitleBar(props: TitleBarProps): JSX.Element {
  return (
    <Box
      sx={{
        marginTop: '-36px',
        marginLeft: '-36px',
        width: 'calc(100% + 72px)',
        display: 'flex',
        height: '50px',
        padding: '15px',
        backgroundColor: 'white',
        borderBottom: `2px solid ${otherColors.gray200}`,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {props.children}
    </Box>
  );
}
